import { useEffect } from 'react';
import Modal from 'react-modal';
import { Button, ButtonType, ColorType, Icon, Label, LabelType } from '..';
import { Info } from '../../../assets/icons';
import { ButtonSize } from '../Button.component';
import { Form } from '../Form.component';
import { useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const AlertModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const {
    title = '',
    messageHeader = '',
    messageHeaderType = LabelType.LABEL_S,
    messageHeaderColor = ColorType.GREY6,
    message = '',
    icon = Info,
    onRenderBody,
    width = '400px',
    height = '176px',
    buttons = [
      {
        label: 'OK',
        onclick: null,
        type: ButtonType.PRIMARY,
        size: ButtonSize.SMALL,
        className: 'min-w-fit w-20',
        dataTestId: '',
      },
    ],
    iconColor,
    onClose,
    mutationHook,
    mutationParams,
    shouldCloseOnOverlayClick = true,
    testId = '',
  } = modalProps || {};

  let triggerQuery: any;
  let queryResult: any;
  if (mutationHook) {
    [triggerQuery, queryResult] = mutationHook();
  }

  const handleClose = (qResult?: any) => {
    onClose && onClose(qResult);
    hideModal();
  };

  // this may trigger from ESC or clicking overlay to close.
  const onRequestClose = () => {
    handleClose();
  };

  const renderHeader = () => {
    return (
      <div className='flex flex-row gap-3 items-start'>
        <Icon src={icon} color={iconColor} className='pt-px' />
        <Label text={title} type={LabelType.LABEL_M} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderBody = () => {
    if (messageHeader) {
      return (
        <div className='flex flex-col gap-4'>
          <Label
            text={messageHeader}
            type={messageHeaderType}
            color={messageHeaderColor}
            className='ml-[36px] mt-2 text-grey6'
          />
          <Label
            text={message}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            className='ml-[36px] text-grey6'
          />
        </div>
      );
    }
    return (
      <Label
        text={message}
        type={LabelType.BODY3}
        color={ColorType.GREY6}
        className='pl-[36px] mt-2'
      />
    );
  };

  useEffect(() => {
    if (queryResult?.isSuccess || queryResult?.isError) {
      handleClose(queryResult);
    }
  }, [queryResult]);

  const renderFooter = () => {
    if (mutationHook) {
      return (
        <div className='flex mt-6 justify-end gap-2'>
          <Form
            className='w-full flex justify-end gap-2'
            onSubmit={() => {
              triggerQuery && triggerQuery(mutationParams);
            }}
            queryResult={queryResult}
          >
            {buttons.map((button: any) => {
              return (
                <Button
                  key={button.label}
                  label={button.label || ''}
                  type={button.type || ButtonType.PRIMARY}
                  size={button.size || ButtonSize.SMALL}
                  className={button.className || 'min-w-fit w-20'}
                  onClick={() => {
                    !button.isSubmit && handleClose();
                  }}
                  isSumbit={button.isSubmit}
                  disableDelayTime={button.disableDelayTime}
                  disableDelayLabel={button.disableDelayLabel}
                  dataTestId={button.dataTestId}
                />
              );
            })}
          </Form>
        </div>
      );
    }
    return (
      <div className='flex mt-6 justify-end gap-2'>
        {buttons.map((button: any) => {
          return (
            <Button
              key={button.label}
              label={button.label || ''}
              type={button.type || ButtonType.PRIMARY}
              size={button.size || ButtonSize.SMALL}
              className={button.className || 'min-w-fit w-20'}
              onClick={() => {
                handleClose();
                button.onClick && button.onClick();
              }}
              disableDelayTime={button.disableDelayTime}
              disableDelayLabel={button.disableDelayLabel}
              dataTestId={button.dataTestId}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        content: {
          top: '50%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          margin: 0,
          borderRadius: 10,
          borderWidth: 0,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      testId={testId}
    >
      <div className='flex flex-col justify justify-between w-full p-5'>
        {renderHeader()}
        {onRenderBody ? onRenderBody() : renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
