export enum GridColumnType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    PILL = 'PILL',
    CURRENCY = 'CURRENCY',
    DATETIME = 'DATETIME',
    CHECKBOX = 'CHECKBOX',
    RADIOBTN = 'RADIOBTN',
    TIMEZONE = 'TIMEZONE',
}