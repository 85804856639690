import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Card,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../../_ui';
import { Location } from '../../../stores/types';
import { IconSize } from '../../../constant/IconSize.constant';
import { AddPlus2, ChargerEV } from '../../../assets/icons';
import { BreakerEditor } from '../BreakerEditor.component';
import { USER_ROLE, useAuth } from '../../../hooks';
import { useAllowedFeatures } from '../../../hooks/useFeaturePersonalization';

interface IProps {
  location: Location;
  showWarning: Function;
}

const LocationDetails = ({ location, showWarning }: IProps) => {
  const auth = useAuth();
  const { chargers } = location;
  const { showModal, hideModal } = useGlobalModalContext();
  const { t } = useTranslation();
  const { allowPowermanEdit } = useAllowedFeatures();

  const handleAddMainBreaker = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('pm_add_main_breaker'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <BreakerEditor locationID={location.id} />,
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <Card className='mb-4 flex'>
      <div className='flex w-full justify-between'>
        <div className='flex gap-x-4 items-center'>
          <div className='flex bg-white w-60'>
            <Label
              text={location.name}
              type={LabelType.H5}
              color={ColorType.BLACK}
              className='truncate'
            />
          </div>
          <div className='flex items-center gap-x-2'>
            <Label
              text={t('no_active_power_managed')}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.BLACK}
            />
            <Label text='|' type={LabelType.LABEL_M} color={ColorType.GREY3} />
            <div className='flex items-center gap-x-1'>
              <Icon
                src={ChargerEV}
                color={ColorType.GREY5}
                size={IconSize.SIZE_20x20}
              />
              <Label
                text={(chargers || []).length}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY5}
              />
            </div>
          </div>
        </div>
        {(auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
          <div>
            <Button
              type={ButtonType.TERTIARY}
              icon={AddPlus2}
              label={t('pm_add_main_breaker')}
              dataTestId='mainBreakerBtn'
              onClick={() => {
                showWarning(location.id, (actionValue: boolean) => {
                  if (actionValue) {
                    handleAddMainBreaker();
                  }
                });
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default LocationDetails;
