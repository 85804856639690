import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from './utils';

export enum API_KEYS {
  CHARGERS = 'chargers',
  SESSIONS = 'sessions',
  LOCATION = 'locations',
  LOCATIONV3 = 'locationsV3',
  USERGROUPS = 'usergroups',
  INVITES = 'invites',
  PRICE_MODELS = 'pricemodels',
  NOTIFICATIONS = 'notifications',
  DELEGATION_INVITES = 'delegationinvites',
  ACCESS = 'access',
  CARDS = 'cards',
  VEHICLES = 'vehicles',
  WHITELABELS = 'whitelabels',
  DISCOUNT = 'priceadjustments',
  CHARGERS_BY_BATCH = 'chargers-by-batch',
  USERS = 'users',
  CHARGER_DETAIL = 'chargerdetails',
  PROVISION = 'provision',
  COMPANIES = 'companies',
  GOOGLE_ANALYTICS = 'mp/collect',
  HISTORICAL = 'historical',
  MESSAGE = 'messages',
  CIRCUITS = 'Circuits',
  BREAKERS = 'Breakers',
  PAYMENT_TERMINALS = 'paymentterminals',
  PAYMENT_TERMINALS_COMBINED = 'combinedpaymentterminals',
  PERSONALIZATION = 'personalization',
  SUPPORT_NOTE = 'supportnote',
}

/**
 * When switching company, we don't refetch company list.
 * The usergroup and invites will be refetch after select company id changed.
 * @returns tag list
 */
export const getSwithingCompanyinvalidates = (newCompanyId: string) => {
  if (newCompanyId.toUpperCase() === 'ALL') {
    return Object.values(API_KEYS)
      .filter(
        (val) =>
          val !== API_KEYS.COMPANIES &&
          val !== API_KEYS.GOOGLE_ANALYTICS &&
          val !== API_KEYS.USERGROUPS &&
          val !== API_KEYS.INVITES &&
          val !== API_KEYS.PERSONALIZATION &&
          val !== API_KEYS.VEHICLES &&
          val !== API_KEYS.CIRCUITS &&
          val !== API_KEYS.CARDS &&
          val !== API_KEYS.PAYMENT_TERMINALS_COMBINED,
      )
      .map((val) => ({
        type: val,
        id: 'LIST',
      }));
  }

  return Object.values(API_KEYS)
    .filter(
      (val) =>
        val !== API_KEYS.COMPANIES &&
        val !== API_KEYS.GOOGLE_ANALYTICS &&
        val !== API_KEYS.USERGROUPS &&
        val !== API_KEYS.INVITES &&
        val !== API_KEYS.PERSONALIZATION,
    )
    .map((val) => ({
      type: val,
      id: 'LIST',
    }));
};

export const Api = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'base',
  // keepUnusedDataFor: 10,
  // refetchOnMountOrArgChange: true,
}).enhanceEndpoints({
  // Please remember add you endpoint key here as a tag
  addTagTypes: Object.values(API_KEYS),
});
