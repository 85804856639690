import ReactGA from 'react-ga4';

export enum GA_LABEL {
  LOG_OUT = 'Log out',
  LANG_SETTING = 'Language settings',
  NOTFICATION_SETTINGS = 'Notification settings',
  ACCOUNT_SETTINGS = 'Account settings',
  SIGNUP_SUCCESS = 'Signup success',
  LOGIN_SUCCESS = 'Login success',
}

export enum GA_CATEGORY {
  AUTH = 'Auth',
  TOP_NAV = 'Top nav',
}
export enum GA_ACTION {
  CLICK = 'Click',
}
export const GA_LOCAL_STORAGE_KEY_CLIENT_ID = 'GA_LOCAL_STORAGE_KEY_CLIENT_ID';
export const GA_LOCAL_STORAGE_KEY_SESSION_ID =
  'GA_LOCAL_STORAGE_KEY_SESSION_ID';

export const pushUserProperties = (userId: string, properties: any) => {
  ReactGA.set({
    userId,
    userProperties: properties,
  });
};

export const pushEventToGA = (
  category: GA_CATEGORY,
  action: GA_ACTION,
  label: GA_LABEL | string,
  value?: string,
) => {
  ReactGA.send({
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
};
