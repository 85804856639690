import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  Icon,
  Label,
  LabelType,
  getHexColorByType,
} from '../../_ui';
import { useLazyFetchNewLocationsByCompanyIdQuery } from '../../../services/location.api';
import { useLazyFetchPricingByCompanyIdQuery } from '../../../services/charger.api';
import { Price } from '../../../stores/types/price.interface';
import { IconSize } from '../../../constant/IconSize.constant';
import { ArrowRight } from '../../../assets/icons';
import { useCompany } from '../../../hooks/useCompany';

interface SelectDropdown {
  id?: string;
  label: string;
  selected: boolean;
}

export const ChargerCompany = (props: any) => {
  const { t } = useTranslation();
  const { nextStep, wizardState, setWizardState, chargerInfo, companyInfo } =
    props;

  const { companies: companyList } = useCompany();
  const [triggerFetchLocationByCompanyIds, { data }] =
    useLazyFetchNewLocationsByCompanyIdQuery();

  const [triggerFetchPricingByCompanyIdQuery, { data: priceModels }] =
    useLazyFetchPricingByCompanyIdQuery();
  const [companyDropdown, setCompanyDropdown] = useState<SelectDropdown[]>([]);
  const [locationDropdown, setLocationDropdown] = useState<SelectDropdown[]>(
    [],
  );

  useEffect(() => {
    setWizardState({
      ...wizardState,
      selLocation: null,
    });
  }, [data]);

  useEffect(() => {
    if (priceModels && priceModels.length > 0) {
      const dropdownOptions = priceModels.map((priceModel: Price) => ({
        id: priceModel.id,
        label: priceModel.displayName,
        selected: priceModels.length === 1,
      }));
      if (priceModels.length === 1) {
        setWizardState({
          ...wizardState,
          pricingModels: dropdownOptions,
          selPriceModel: {
            id: priceModels[0].id,
            label: priceModels[0].displayName,
            selected: true,
          },
        });
      } else {
        setWizardState({
          ...wizardState,
          pricingModels: dropdownOptions,
        });
      }
    }
  }, [priceModels]);

  useEffect(() => {
    if (companyList && companyList.length > 0) {
      setCompanyDropdown(
        companyList.map((companyObj) => ({
          id: companyObj.id,
          label: companyObj.name,
          selected: wizardState.selCompany?.id === companyObj.id,
        })),
      );
    }
  }, [companyList, wizardState]);

  useEffect(() => {
    setLocationDropdown([]);
    if (data && data.entities && data.entities.length > 0) {
      setLocationDropdown(
        data.entities.map((locationObj) => ({
          id: locationObj.id,
          label: locationObj.name,
          selected: wizardState.selLocation?.id === locationObj.id,
        })),
      );
    }
  }, [data, wizardState]);

  useEffect(() => {
    if (locationDropdown.length === 1 && !wizardState.selLocation) {
      setWizardState({
        ...wizardState,
        selLocation: locationDropdown[0],
      });
      setLocationDropdown(
        locationDropdown.map((locationObj) => ({
          ...locationObj,
          selected: true,
        })),
      );
    }
  }, [locationDropdown]);

  useEffect(() => {
    setWizardState({
      chargerInfo,
      companyInfo,
      pricingModels: [],
      selLocation: null,
      selCompany: null,
      selPriceModel: null,
      chargerId: chargerInfo.id,
      hidden: chargerInfo.hiddenOnMap,
      directions: chargerInfo?.directions || '',
      note: chargerInfo?.internalNote || '',
      installer: chargerInfo.deploymentInfo?.installationPerson || '',
      installationDate: chargerInfo.deploymentInfo?.installationDate || null,
      installerContact: chargerInfo.deploymentInfo?.installerContactEmail || '',
      deployed: chargerInfo.deploymentInfo.deployed,
      deploymentDate: chargerInfo.deploymentInfo.deployedDate || null,
    });
  }, []);

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col'>
        <Label
          text={t('chargers_move_step_1_description_1')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-col'>
        <Label
          text={t('chargers_move_step_1_description_2')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='border-y border-grey2' />
      <div className='flex flex-row gap-4'>
        <div className='flex flex-col gap-2 w-[280px] justify-between pb-2'>
          <Label
            text={t('charger_current_company')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <div className='flex flex-row justify-between'>
            <Label
              text={companyInfo?.name}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            <Icon src={ArrowRight} size={IconSize.SIZE_20x20} />
          </div>
        </div>
        <div className='flex flex-col gap-2 w-[220px] justify-between'>
          <Label
            text={t('charger_new_company')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <Dropdown
            headerWidth='220px'
            items={companyDropdown.filter(
              (item) => item.id !== companyInfo?.id,
            )}
            onItemClick={(selectedItem: any) => {
              setLocationDropdown([]);
              triggerFetchLocationByCompanyIds(selectedItem);
              triggerFetchPricingByCompanyIdQuery(selectedItem.id);
              setWizardState({
                ...wizardState,
                selLocation: null,
                selCompany: selectedItem,
              });
            }}
            placeholder={t('charger_choose_new_company')}
            placeholderLabelType={LabelType.BODY3_G4}
            labelType={LabelType.BODY3}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            contentDivWidth={220}
            contentDivHeight={150}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
          />
        </div>
      </div>
      <div className='flex flex-row gap-4'>
        <div className='flex flex-col w-[280px] justify-between pb-2'>
          <Label
            text={t('charger_current_location')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <div className='flex flex-row justify-between'>
            <Label
              text={chargerInfo.location.name}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            <Icon src={ArrowRight} size={IconSize.SIZE_20x20} />
          </div>
        </div>
        <div className='flex flex-col w-[220px] justify-between'>
          <Label
            text={t('charger_new_location')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            className='mb-2'
          />
          <Dropdown
            headerWidth='220px'
            items={locationDropdown.filter(
              (item) => item.id !== chargerInfo.location.id,
            )}
            onItemClick={(selectedItem: any) => {
              setWizardState({
                ...wizardState,
                selLocation: selectedItem,
              });
            }}
            placeholder={t('charger_choose_new_location')}
            placeholderLabelType={
              wizardState.selCompany
                ? LabelType.BODY3_G4
                : LabelType.BODY3_DISABLED
            }
            labelType={LabelType.BODY3}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            contentDivWidth={220}
            contentDivHeight={200}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            placement='top-end'
            disabled={!locationDropdown.length || !wizardState.selCompany}
          />
        </div>
      </div>
      <div className='flex flex-col mb-5'>
        <Button
          label={t('next')}
          type={ButtonType.PRIMARY}
          disabled={
            !(
              wizardState.selCompany &&
              wizardState.selLocation &&
              wizardState.pricingModels.length > 0
            )
          }
          size={ButtonSize.SMALL_FULL}
          onClick={() => {
            nextStep();
          }}
        />
      </div>
    </div>
  );
};
