import React, { useEffect, useMemo, useState } from 'react';

export interface FormQueryResult {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
}

interface FormProps {
  className?: string;
  children?: any;
  onSubmit: Function;
  queryResult: FormQueryResult | FormQueryResult[];
  onQuerySuccess?: Function;
  onQueryFailed?: Function;
}

export const Form = ({
  className,
  children,
  onSubmit,
  queryResult,
  onQuerySuccess,
  onQueryFailed,
}: FormProps) => {
  const [disabled, setDisabled] = useState(false);

  const isSuccess = useMemo(() => {
    if (Array.isArray(queryResult)) {
      return queryResult.every((query) => {
        return query.isSuccess;
      });
    }
    return queryResult?.isSuccess;
  }, [queryResult]);

  const isError = useMemo(() => {
    if (Array.isArray(queryResult)) {
      return queryResult.some((query) => {
        return query.isError;
      });
    }
    return queryResult?.isError;
  }, [queryResult]);

  const isLoading = useMemo(() => {
    if (Array.isArray(queryResult)) {
      return queryResult.some((query) => {
        return query.isLoading; // claim loading , charger update loading
      });
    }
    return queryResult?.isLoading;
  }, [queryResult]);

  useEffect(() => {
    setDisabled(!!isLoading);
    if (isSuccess) {
      onQuerySuccess && onQuerySuccess();
    } else if (isError) {
      onQueryFailed && onQueryFailed();
    }
  }, [isSuccess, isError, isLoading]);

  const traverseChildren = (nextchildren: any): React.ReactNode => {
    return React.Children.map(nextchildren, (child: any) => {
      if (!React.isValidElement(child)) return child;

      const { children: childChildren, ...otherProps } = child.props as any;
      if (childChildren) {
        return React.cloneElement(child, {
          ...otherProps,
          children: traverseChildren(childChildren),
        });
      }
      const { isSumbit } = child.props as any;

      if (isSumbit) {
        // isSumbit = true means this is submit button, we need to overwrite the onClick and isLoading
        return React.cloneElement(child, {
          ...otherProps,
          disabled: disabled || otherProps.disabled,
          onClick: onSubmit,
          isLoading,
        });
      }
      // TODO: CB To be more accurate, the disable should only override from elements like input, dropdown, switch
      return React.cloneElement(child, {
        ...otherProps,
        disabled: disabled || otherProps.disabled,
      });
    });
  };

  return (
    <div className={className}>{children && traverseChildren(children)}</div>
  );
};
