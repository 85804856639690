/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { StringParam, useQueryParam } from 'use-query-params';
import { NUMBER } from '../../constant/Number.constant';
import { URL_SEARCH_PARAM_KEY } from '../../constant/UrlSearchParams.constant';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useDeletePricingModelMutation } from '../../services/pricing.api';
import { Price } from '../../stores/types/price.interface';
import { addSearchParam } from '../../utils/SearchParam.Utils';
import {
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  MODAL_TYPES,
  Menu,
  MenuItem,
  Skeleton,
  useGlobalModalContext,
} from '../_ui';
import { Label, LabelType } from '../_ui/Label.component';
import { Pill } from '../_ui/Pill.component';
import { FreePricing } from './ChargerPricing/FreePricing';
import { TieredPricing } from './ChargerPricing/TieredPricing';
import { TimeOfUsePricing } from './ChargerPricing/TimeOfUsePricing';
import { IconSize } from '../../constant/IconSize.constant';
import { More, NoChargers } from '../../assets/icons';
import { useCompany } from '../../hooks/useCompany';

interface InputProps {
  pricingData: Price;
  totalNonSyntheticChargers: number;
  isPricingLoading?: boolean;
  totalDataLength: number;
}

export const RuleComponent = memo(
  ({
    pricingData,
    totalNonSyntheticChargers,
    isPricingLoading,
    totalDataLength,
  }: InputProps) => {
    const { navigateWithCompanyId } = useNavigateWithSearchParam();
    const { t } = useTranslation();
    const { showModal } = useGlobalModalContext();
    const [triggerDeletePriceModels] = useDeletePricingModelMutation();
    const onDeleteRule = (price: Price) => {
      triggerDeletePriceModels(price);
    };
    const { companyId: companyIdSearchParam } = useCompany();

    const ruleName = useMemo(() => {
      return pricingData.synthetic === true &&
        pricingData.chargers &&
        pricingData.chargers.length > NUMBER.ZERO
        ? `${pricingData.chargers[0].locationName} ${
            pricingData.chargers[0].chargerName
          } (${t('pricing_rule_imported')})`
        : pricingData.displayName;
    }, [pricingData]);

    const handleDeleteRule = (price: Price) => {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: t('pricing_rule_removal_title'),
        width: '400px',
        height: 'max-content',
        message: (
          <Trans
            i18nKey='pricing_rule_removal_content'
            values={{ ruleName }}
            components={{
              bold: <strong />,
            }}
          />
        ),
        iconColor: ColorType.NEGATIVE1,
        buttons: [
          {
            label: t('cancel'),
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
          },
          {
            label: t('delete'),
            type: ButtonType.DESTRUCTIVE,
            size: ButtonSize.SMALL,
            onClick: () => onDeleteRule(price),
            dataTestId: 'deleteConfirm',
          },
        ],
      });
    };

    const handleEditRule = (price: any) => {
      navigateWithCompanyId(
        `../${RoutePath.PRICING_EDIT}`,
        companyIdSearchParam,
        {
          state: { price },
        },
      );
    };

    /**
     * @description It returns a string that says how many chargers are in the rule, and how many chargers there are in
     * total
     * @param {Price} priceData - Price - this is the price data that is passed to the component.
     * @returns A string that is the number of chargers in the rule and the total number of chargers.
     */
    const renderChargerCount = (priceData: Price) => {
      const chargerText = t('company_locations_chargers');
      let totalChargers = priceData.chargers.length;
      let chargersInRule = priceData.chargers.length;
      if (priceData.synthetic === false) {
        totalChargers = totalNonSyntheticChargers;
        chargersInRule = priceData.chargers.length;
      }
      return `${chargersInRule}/${totalChargers} ${chargerText}`;
    };

    /* Grouping the chargers by location and then returning a list of chargers for each location. */
    const renderChargerWithLocation = (pricing: Price) => {
      const chargersGroupByLocation = _.groupBy(
        pricing.chargers,
        'locationName',
      );
      const items: any = [];
      Object.entries(chargersGroupByLocation).forEach((key) => {
        items.push(
          <div>
            <div className='items-center'>
              <Label
                text={key[0]}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.BLACK}
              />
            </div>
            <div className='flex flex-row gap-1 py-2 px-0 flex-wrap'>
              {key[1].map((charger) => {
                return (
                  <Pill
                    key={charger.chargerId}
                    label={charger.chargerName}
                    labelColor={ColorType.GREY6}
                  />
                );
              })}
            </div>
          </div>,
        );
      });
      return items;
    };

    const renderNoChargerMessage = () => {
      return (
        <Label
          text={t('pricing_no_charger_message')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.GREY6}
        />
      );
    };

    const renderPopUp = () => {
      return (
        <Menu placement='bottom-end' icon={More} dataTestId='menuWrapper'>
          {!pricingData?.synthetic && (
            <MenuItem
              label={t('edit_rule')}
              onClick={() => handleEditRule(pricingData)}
              dataTestId='editRule'
            />
          )}
          {!pricingData?.defaultPrice && (
            <MenuItem
              label={t('delete_rule')}
              labelType={LabelType.BODY3}
              labelColor={ColorType.NEGATIVE1}
              onClick={() => handleDeleteRule(pricingData)}
              dataTestId='deleteRule'
            />
          )}
        </Menu>
      );
    };

    /**
     * @description It takes a pricePeriod object as an argument and returns a Label component with the pricePeriod's
     * price and pricingType
     * @param {any} pricePeriod - the price period object
     * @returns A label component with the price and price type.
     */
    const renderPriceType = (pricePeriod: any) => {
      const price = Number(pricePeriod?.price.replace('$', '')).toFixed(2);
      let priceType = '';
      switch (pricePeriod.pricingType) {
        case 'BILLED_BY_EFFECTIVE_CHARGING_TIME':
          priceType = 'hr charging';
          break;
        case 'BILLED_BY_TIME_PLUGGED_IN':
          priceType = 'hr plugged in';
          break;
        case 'BILLED_BY_KWH':
          priceType = 'kWh';
          break;
        default:
          priceType = '';
          break;
      }
      return (
        <div className='flex justify-between items-baseline'>
          <Label
            text={`$${price}/`}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
          <Label
            text={priceType}
            type={LabelType.BODY4}
            color={ColorType.GREY6}
          />
        </div>
      );
    };

    const additionalFees = () => {
      if (pricingData.idleRate && Number(pricingData.idleRate) > 0) {
        return (
          <div className='flex flex-col gap-2 mt-3'>
            <Label
              text={t('idle_fee_additional_label')}
              type={LabelType.LABEL_M_MEDIUM}
            />
            <div className='flex flex-row justify-between'>
              <Label
                text={t('idle_fee_label')}
                type={LabelType.LABEL_S_MEDIUM}
              />
              <div className='flex justify-between items-baseline'>
                <Label
                  text={`$${Number(pricingData.idleRate).toFixed(2)}/`}
                  type={LabelType.BODY3}
                />
                <Label
                  text='min'
                  type={LabelType.BODY4}
                  color={ColorType.GREY6}
                />
              </div>
            </div>
          </div>
        );
      }
      return null;
    };

    const renderPricingDetails = () => {
      return (
        <div className='flex flex-row'>
          <div className='w-[355px] border-x-2 border-b-2 border-grey2 bg-grey1'>
            <div
              className='px-6 py-4'
              style={{ borderBottomLeftRadius: '0.25rem' }}
            >
              <Label
                text={t('pricing_schedule')}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.BLACK}
              />
              {TimeOfUsePricing(pricingData, renderPriceType)}
              {TieredPricing(pricingData, renderPriceType)}
              {FreePricing(pricingData)}
              {additionalFees()}
            </div>
          </div>
          <div className='flex flex-grow w-[581px] border-x-2 border-b-2 border-grey2'>
            <div
              className='gap-2 px-6 py-4'
              style={{
                borderBottomRightRadius: '0.25rem',
              }}
            >
              <Label
                text={t('pricing_chargers_using_this_rule')}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.BLACK}
                className='pb-1'
              />
              {pricingData &&
              (!pricingData.chargers ||
                pricingData.chargers.length === NUMBER.ZERO)
                ? renderNoChargerMessage()
                : renderChargerWithLocation(pricingData)}
            </div>
          </div>
        </div>
      );
    };

    const renderHeader = () => {
      return (
        <div
          data-testid={ruleName}
          className='flex flex-row h-14 bg-grey2 p-4 rounded-t justify-between'
        >
          <div className='flex flex-row gap-8'>
            <div
              className='flex flex-row gap-4 items-center w-[600px]'
              data-testid={ruleName}
            >
              <Label
                className='truncate'
                text={ruleName}
                type={LabelType.LABEL_M}
                color={
                  pricingData.synthetic === false
                    ? ColorType.BLACK
                    : ColorType.GREY5
                }
                isLoading={isPricingLoading}
                skeletonLoadingColor='bg-grey3'
              />
              <Label
                className='whitespace-nowrap'
                text={!isPricingLoading ? renderChargerCount(pricingData) : ''}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
                isLoading={isPricingLoading}
                skeletonLoadingColor='bg-grey3'
              />
            </div>
          </div>
          {renderPopUp()}
        </div>
      );
    };

    const renderPricingSkeleton = () => {
      return (
        <div className='flex'>
          <div className='px-6 py-4 border-x-2 border-b-2 border-grey2 w-2/5 bg-grey1'>
            <Label
              text={t('pricing_schedule')}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.BLACK}
            />
            <div className='py-2'>
              <Skeleton height='h-5' width='w-20' />
              <div className='flex justify-between'>
                <Skeleton height='h-5' width='w-20' />
                <div>
                  <Skeleton height='h-5' width='w-20' />
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2 px-6 py-4 border-x-2 border-b-2 border-grey2 w-3/5'>
            <Label
              text={t('pricing_chargers_using_this_rule')}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.BLACK}
              className='pb-1'
            />
            <div>
              <div className='items-center'>
                <Skeleton height='h-5' width='w-20' />
              </div>
              <div className='flex flex-row gap-1 py-2 px-0 flex-wrap'>
                <Pill isLoading={isPricingLoading} />
                <Pill isLoading={isPricingLoading} />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const renderEmptyPricing = () => {
      if (!isPricingLoading && totalDataLength === 0) {
        return (
          <div className='flex flex-col items-center mt-2'>
            <Icon
              src={NoChargers}
              size={IconSize.SIZE_48x48}
              className='mb-1'
            />
            <Label
              text={t('access_no_chargers')}
              type={LabelType.H4}
              color={ColorType.GREY5}
              className='mb-2'
            />
            <Label
              text={t('pricing_empty_desc')}
              type={LabelType.BODY2}
              color={ColorType.GREY5}
              className='mb-2'
            />
          </div>
        );
      }
      if (isPricingLoading) {
        return renderPricingSkeleton();
      }
      return null;
    };

    return (
      <div className='price-list-item pb-4' data-testid='priceListItem'>
        <div className='rounded-t'>
          {renderEmptyPricing()}
          {pricingData && totalDataLength > 0 && (
            <>
              {renderHeader()}
              {renderPricingDetails()}
            </>
          )}
        </div>
      </div>
    );
  },
);
