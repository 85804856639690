import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const provisionApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchModels: builder.query<any, void>({
      query: () => ({
        url: `${API_KEYS.PROVISION}/models`,
        method: 'GET',
        params: { scope: SCOPE_TYPE.NONE },
        needCompanyId: false,
      }),
    }),
    fetchChargerStaticData: builder.query<any, void>({
      query: () => ({
        url: `${API_KEYS.PROVISION}/charger/static-data`,
        method: 'GET',
        params: { scope: SCOPE_TYPE.NONE },
        needCompanyId: false,
      }),
    }),
  }),
});

export const { useFetchModelsQuery, useFetchChargerStaticDataQuery } =
  provisionApi;
