import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateBreakerMutation,
  useUpdateBreakerMutation,
} from '../../services/pm.api';
import { SCOPE_TYPE } from '../../services/utils';
import {
  AddSubBreaker,
  Breaker,
  Circuit,
  DropdownOption,
} from '../../stores/types/pm.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  FormInput,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType } from '../_ui/Input.component';
import { Label, LabelType } from '../_ui/Label.component';
import { minRequireRating } from './utils';

export interface SubBreakerProp {
  companyId?: string | null | undefined;
  data: {
    breaker: Breaker;
    circuit: Circuit;
    parentBreaker: Breaker | undefined;
    parentBreakerOptions: DropdownOption[];
  };
  isEdit?: boolean;
}
export const SubBreakerModal = ({
  companyId,
  data,
  isEdit = false,
}: SubBreakerProp) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [triggerCreateBreaker, createBreakerQueryResult] =
    useCreateBreakerMutation();
  const [triggerUpdateBreaker, editBreakerQueryResult] =
    useUpdateBreakerMutation();
  const [subBreakerDetails, setSubBreakerDetails] = useState<AddSubBreaker>({
    id: data.breaker?.id,
    parentBreakerId: isEdit ? data.parentBreaker?.id : data.breaker.id,
    name: '',
    breakerSpace: '',
    breakerRating: 0,
  });

  const mainBreakerOptions = useMemo(() => {
    let dataOptions = data.parentBreakerOptions.map((item: DropdownOption) => {
      item.selected =
        item.id === (subBreakerDetails.parentBreakerId || subBreakerDetails.id);
      return item;
    });
    // prevent user action to choose self breaker id as a parent breaker
    if (isEdit) {
      dataOptions = dataOptions.filter(
        (optionItem: any) => optionItem.id !== data.breaker.id,
      );
    }
    return dataOptions;
  }, [data, isEdit]);

  const ratingValidation = useMemo(() => {
    const { breakerRating } = subBreakerDetails;
    return (
      _.isNumber(Number(breakerRating)) &&
      breakerRating > 0 &&
      breakerRating >= minRequireRating(data.breaker)
    );
  }, [subBreakerDetails.breakerRating]);

  const handleInputChange = (key: string, value: any) => {
    setSubBreakerDetails({ ...subBreakerDetails, [key]: value });
  };

  const maximumContinuousLoadPerc = () => {
    return +((subBreakerDetails.breakerRating / 100) * 80).toFixed(2);
  };

  const isDuplicateName = (newName: string) => {
    // find duplicate on edit sub-breaker
    if (isEdit) {
      const findIndex = data.parentBreakerOptions.findIndex(
        (subBreaker: any) => {
          const breakerName = subBreaker?.label?.toLowerCase();
          return (
            breakerName === newName.toLowerCase().trim() &&
            breakerName !== data.breaker.name?.toLowerCase()
          );
        },
      );
      return findIndex !== -1;
    }

    // find duplicate on add sub-breaker
    const findIndex = data.parentBreakerOptions.findIndex(
      (subBreaker: any) =>
        subBreaker?.label?.toLowerCase() === newName.toLowerCase().trim(),
    );
    return findIndex !== -1;
  };

  const nameValidation = (breakerName: any) => {
    if (!breakerName) {
      return false;
    }
    if (isDuplicateName(breakerName)) {
      return false;
    }
    return true;
  };

  const handleSaveClick = () => {
    if (isEdit) {
      triggerUpdateBreaker({
        breakerId: data.breaker.id,
        body: subBreakerDetails,
        scope: SCOPE_TYPE.COMPANY,
        companyId: companyId || '',
      });
    } else {
      const { breakerRating, breakerSpace, name, parentBreakerId } =
        subBreakerDetails;
      triggerCreateBreaker({
        body: { breakerRating, breakerSpace, name, parentBreakerId },
        scope: SCOPE_TYPE.COMPANY,
        companyId: companyId || '',
      });
    }
  };

  const onValidation = () => {
    const { name, breakerRating } = subBreakerDetails;
    if (!name) {
      return false;
    }
    if (isDuplicateName(name)) {
      return false;
    }
    if (!ratingValidation) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isEdit) {
      setSubBreakerDetails({
        ...subBreakerDetails,
        name: data.breaker?.name,
        breakerRating: data.breaker?.breakerRating || 0,
        breakerSpace: data.breaker?.breakerSpace,
      });
    }
  }, [data.breaker, isEdit]);

  return (
    <Form
      onSubmit={handleSaveClick}
      queryResult={isEdit ? editBreakerQueryResult : createBreakerQueryResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='divide-y divide-grey3'>
        <div className='flex flex-col pb-4 gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('sub_breaker_label_1')}
          />
          <Dropdown
            items={mainBreakerOptions}
            onItemClick={(selectedItem: any) => {
              handleInputChange('parentBreakerId', selectedItem.id);
            }}
            headerWidth='100%'
            placeholderLabelType={LabelType.BODY3_G4}
            labelType={LabelType.BODY3_G4}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded'
          />
        </div>
        <div className='flex flex-col pt-4 gap-y-4 pb-5'>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              showErrorIcon
              defaultValue={subBreakerDetails.name}
              label={t('sub_breaker_modal_field_2')}
              inputType={InputType.CHARGELAB_ACCEPTED_CHARACTER}
              onChange={(event: any) => {
                handleInputChange('name', event.target.value?.trim());
              }}
              placeholder={t('sub_breaker_name')}
              width='100%'
              errorLabel={
                subBreakerDetails.name ? t('sub_breaker_name_validation') : ''
              }
              onValidation={nameValidation}
              dataTestId='subBreakerName'
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row'>
              <FormInput
                defaultValue={subBreakerDetails.breakerSpace}
                label={t('sub_breaker_label_2')}
                secondLabel={`(${t('optional')})`}
                secondLabelType={LabelType.LABEL_M_MEDIUM}
                secondLabelColor={ColorType.GREY6}
                placeholder={t('sub_breaker_label_2')}
                inputType={InputType.TEXT}
                onChange={(event: any) => {
                  handleInputChange('breakerSpace', event.target.value);
                }}
                width='100%'
                dataTestId='breakerSpace'
              />
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <Label
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              text={t('sub_breaker_modal_field_3')}
            />
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <div className='w-[58px]'>
                  <FormInput
                    showErrorIcon
                    defaultValue={subBreakerDetails.breakerRating}
                    placeholder='0'
                    inputType={InputType.NUMBER}
                    onChange={(event: any) => {
                      handleInputChange('breakerRating', event.target.value);
                    }}
                    props={{ style: { width: '58px' } }}
                    errorLabel={t('sub_breaker_valid_rating')}
                    validaitonQueryResult={!ratingValidation}
                    wrapperClassName='inline-block'
                    dataTestId='breakerRating'
                  />
                </div>

                <Label
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  text={t('pm_amps')}
                  className='mt-2.5'
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('sub_breaker_modal_field_4')}
              />
              <div>&nbsp;</div>
              <Label
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
                text={t('sub_breaker_modal_field_5')}
              />
            </div>
            {subBreakerDetails.breakerRating &&
            Number(subBreakerDetails.breakerRating) > 0 ? (
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${maximumContinuousLoadPerc()} amps`}
              />
            ) : (
              <Label
                type={LabelType.BODY3}
                color={ColorType.GREY4}
                text={t('sub_breaker_label_3')}
              />
            )}
          </div>
          <Button
            label={t('save')}
            size={ButtonSize.SMALL_FULL}
            type={ButtonType.PRIMARY}
            disabled={!onValidation()}
            isSumbit
            dataTestId='saveSubBreaker'
          />
        </div>
      </div>
    </Form>
  );
};
