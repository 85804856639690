import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import { store, persistor } from './stores/store';
import { store } from './stores/store';

import { Snackbar } from './components/_ui';
import { AuthProvider } from './hooks';
import './index.css';
import { i18n } from './services/translation/i18n';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <React.StrictMode>
      <AuthProvider>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </AuthProvider>
    </React.StrictMode>
    <Snackbar />
    {/* </PersistGate> */}
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals((metric) => console.log(metric));
