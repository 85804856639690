import { memo } from 'react';
import { Icon } from '../Icon.component';
import { LabelType } from '../Label.component';
import { ColorType } from '../index';
/**
 * MenuItem Component
 */

interface MenuItemProps {
  label?: string; // (Optional) A string representing the main text of the menu item. Defaults to 'menuitem'.
  iconBefore?: any; // (Optional) A path to the image or icon that should appear before the label.
  iconAfter?: any; // (Optional) A path to the image or icon that should appear after the label.
  onClick?: any; // (Optional) A callback function that will be triggered when the menu item is clicked.
  renderAfter?: any; // (Optional) A function that returns a JSX element. This will render after the main content, allowing for additional customization.
  labelType?: string; // (Optional) Additional CSS class names to be applied to the label. Defaults to 'text-sm text-black font-normal'.
  labelColor?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export const MenuItem = memo(
  ({
    label = 'menuitem',
    iconBefore,
    iconAfter,
    onClick,
    renderAfter,
    labelType = LabelType.BODY3,
    labelColor = ColorType.BLACK,
    disabled = false,
    dataTestId = '',
  }: MenuItemProps) => {
    return (
      <button
        type='button'
        className='hover:bg-grey1 p-2 w-full h-[40px] text-left'
        onClick={onClick}
        disabled={disabled}
        data-testid={dataTestId}
      >
        <div className='flex flex-row justify-between items-center'>
          <div
            className={`flex flex-row items-center gap-1
             ${disabled ? LabelType.BODY3 : labelType} 
            ${disabled ? `text-${ColorType.GREY3}` : `text-${labelColor}`}
          `}
          >
            {iconBefore && <Icon src={iconBefore} className='mr-2' />}
            {label}
          </div>
          {iconAfter && <Icon src={iconAfter} className='mr-2' />}
          {renderAfter && renderAfter()}
        </div>
      </button>
    );
  },
);
