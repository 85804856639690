import { ColorType, Icon, Label, LabelType } from '../../_ui';
import { IconSize } from '../../../constant/IconSize.constant';
import { ChargerEV } from '../../../assets/icons';

interface Props {
  chargerName: string;
  showTopBorder?: boolean;
  chargerModel: string;
  chargerVendor: string;
}

const ViewChargerItem = ({
  chargerName,
  showTopBorder = false,
  chargerModel,
  chargerVendor,
}: Props) => {
  // var MAKE_MODEL needs to be replaced with origin field getting from api.
  return (
    <div
      className='px-4 py-2'
      style={{
        borderBottom: '1px solid #D1D6DB',
        borderTop: showTopBorder ? '1px solid #D1D6DB' : '',
      }}
    >
      <div className='flex justify-between'>
        <div className='flex items-center'>
          <div className='pr-2 pt-1'>
            <Icon src={ChargerEV} />
          </div>
          <div className='flex flex-row w-60'>
            <Label
              text={chargerName}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.BLACK}
              className='truncate'
            />
          </div>
          <div className='flex flex-row ml-4 w-[628px]'>
            <Label
              text={`${chargerVendor} ${chargerModel}`}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY5}
              className='truncate'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewChargerItem;
