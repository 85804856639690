/* eslint-disable react/jsx-wrap-multilines */
import { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';

import 'react-multi-date-picker/styles/layouts/prime.css';
import { ArrowLeft, ArrowRight } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { formatDate } from '../../../utils/Date.Util';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType, getHexColorByType } from '../shared/Colors.g';
import './Month-Picker.css';

export enum CustomInputType {
  DEFAULT,
  FORMINPUTDATE,
}
export interface InputProps {
  months: Array<Date>;
  format?: string;
  onChange?: (newMonths: any) => void;
  inputBG?: string;
  minDate?: Date;
  maxDate?: Date;
  customInputType?: CustomInputType;
  customFormInputDivWidth?: string;
  customFormInputErrorLabel?: string;
  disabled?: boolean;
}

export interface MonthListPluginProps {
  months: Array<Date>;
}

// custom month list component
const MonthListPlugin = ({ months }: MonthListPluginProps) => {
  const { t } = useTranslation();
  // there is no months selected
  if (!months || months.length === 0) {
    return (
      <div className='w-[224px] flex flex-col gap-2 items-start'>
        <Label
          text={t('inclued_months')}
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.BLACK}
        />
        <Label
          text={t('no_months')}
          type={LabelType.BODY3}
          color={ColorType.GREY4}
        />
      </div>
    );
  }

  // display month list
  return (
    <div className='h-[280px] flex flex-col gap-2 items-start'>
      <Label
        className='bg-white whitespace-nowrap'
        text={t('inclued_months')}
        type={LabelType.LABEL_M_MEDIUM}
        color={ColorType.BLACK}
      />
      <div className='flex flex-col gap-2 overflow-auto items-start'>
        {months.map((month) => {
          return (
            <Label
              className=' whitespace-nowrap'
              text={formatDate(month, 'MMMM yyyy')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          );
        })}
      </div>
    </div>
  );
};

export const MonthPicker = memo(
  ({
    months,
    onChange,
    format = 'MMM YYYY',
    inputBG = 'bg-grey1 hover:bg-grey2',
    minDate,
    maxDate,
    customInputType = CustomInputType.DEFAULT,
    customFormInputDivWidth = '',
    customFormInputErrorLabel = '',
    disabled = false,
  }: InputProps) => {
    const auth = useAuth();
    const theme = useSelector(getCurrentTheme);
    const componentRef = useRef(null);
    const monthShortNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const onDateChanged = useCallback(
      (dateObjects: DateObject | DateObject[] | null) => {
        let newMonth: Date[] = [];
        if (Array.isArray(dateObjects)) {
          dateObjects.forEach((dateObject) => {
            if (dateObject instanceof DateObject) {
              newMonth.push(dateObject.toDate());
            }
          });
        } else if (dateObjects) {
          newMonth = [dateObjects.toDate()];
        }

        // return new date array directly
        onChange && onChange(newMonth);
      },
      [onChange],
    );

    // when calendar is open, find all rmdp-day div, overwrite the background and font color according to different status
    const onCalendarOpen = () => {
      const rmdpDays = document.querySelectorAll(
        '.rmdp-month-picker .rmdp-day',
      );

      rmdpDays?.forEach((rmdpDay) => {
        const isToday = rmdpDay.classList.contains('rmdp-today');
        const isSelected = rmdpDay.classList.contains('rmdp-selected');
        const isHover = rmdpDay.classList.contains('hover');
        const isDisabled = rmdpDay.classList.contains('rmdp-disabled');
        const element = rmdpDay as HTMLElement;
        if (isDisabled) {
          element.style.backgroundColor = getHexColorByType(ColorType.GREY0);
          const span = element.querySelector('.sd') as HTMLElement;
          if (span) {
            span.style.color = getHexColorByType(ColorType.GREY3);
            span.style.backgroundColor = getHexColorByType(ColorType.GREY0);
          }
        } else if (isSelected) {
          element.style.backgroundColor = isHover
            ? theme.brand_3
            : theme.brand_2;
          const span = element.querySelector('.sd') as HTMLElement;
          if (span) {
            span.style.color = getHexColorByType(ColorType.WHITE);
            span.style.backgroundColor = isHover
              ? theme.brand_3
              : theme.brand_2;
          }
        } else {
          element.style.backgroundColor = isHover
            ? getHexColorByType(ColorType.GREY3)
            : getHexColorByType(ColorType.WHITE); // getHexColorByType(ColorType.BLACK);
          const span = element.querySelector('.sd') as HTMLElement;
          if (span) {
            span.style.color = getHexColorByType(ColorType.GREY6);
            span.style.backgroundColor = isHover
              ? getHexColorByType(ColorType.GREY3)
              : getHexColorByType(ColorType.WHITE); // getHexColorByType(ColorType.BLACK);
          }
        }
      });
    };

    useEffect(() => {
      onCalendarOpen();
    }, []);
    // add event listener to montior mouseenter&mouseleave, add/remove hover class name
    useEffect(() => {
      const elements = document.querySelectorAll('.rmdp-day');

      const handleHover = (e: any) => {
        (e.target as HTMLElement).classList.add('hover');
      };

      const handleLeave = (e: any) => {
        (e.target as HTMLElement).classList.remove('hover');
      };

      elements.forEach((element) => {
        element.addEventListener('mouseenter', handleHover);
        element.addEventListener('mouseleave', handleLeave);
      });

      return () => {
        elements.forEach((element) => {
          element.removeEventListener('mouseenter', handleHover);
          element.removeEventListener('mouseleave', handleLeave);
        });
      };
    }, [componentRef.current]);

    // observer component changes: month changes; arrow change -> year page change.
    useEffect(() => {
      const handleRefChange = () => {
        onCalendarOpen();
      };

      handleRefChange();

      const observer = new MutationObserver(handleRefChange);

      const config = { attributes: true, childList: true, subtree: true };

      if (componentRef.current) {
        observer.observe(componentRef.current, config);
      }

      return () => {
        observer.disconnect();
      };
    }, [componentRef.current]);

    return (
      <Calendar
        ref={componentRef}
        locale={auth.user.attributes.profile?.sitehostDashboardLanguage}
        currentDate={new DateObject({ date: maxDate })}
        renderButton={(direction: any, handleClick: any) => {
          return (
            <Icon
              onClick={(e: any) => {
                handleClick(e);
              }}
              src={direction === 'right' ? ArrowRight : ArrowLeft}
              size={IconSize.SIZE_20x20}
            />
          );
        }}
        value={months}
        onChange={(dateObjects: DateObject | DateObject[] | null) => {
          onDateChanged(dateObjects);
        }}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        onlyMonthPicker
        multiple
        months={monthShortNames}
        disabled={disabled}
        monthYearSeparator=' '
        formatMonth={(month, year) => {
          return '';
        }}
        formatYear={(year) => {
          return year;
        }}
        plugins={[<MonthListPlugin months={months} />]}
      />
    );
  },
);
