/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatInTimeZone } from 'date-fns-tz';
import { t } from 'i18next';
import _ from 'lodash';
import { memo, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { NoChargers, maintenance } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import {
  CHARGER_STATUS,
  OCPP_STATUS,
  SERVER_SIDE_CHARGER_STATUS,
} from '../../constant/Text.constant';
import { useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import {
  ChargerOptions,
  useFetchChargersQuery,
} from '../../services/charger.api';
import { Charger } from '../../stores/types';
import { FilteredCharger } from '../../stores/types/chargers.interface';
import { getLocale } from '../../utils/Date.Util';
import { CheckBox, ColorType, Grid, Icon, Label, LabelType } from '../_ui';
import { GridColumnType } from '../_ui/grid/enums/Grid-Column-Type.enum';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import ChargerStateOfCharge from './ChargerStateOfCharge.component';
import { ChargerStatus } from './ChargerStatus.component';
import { getRowStatus } from './utils';

type ChargerGridInput = {
  status: (string | null)[] | null | undefined;
  maintenanceFlag: boolean | null | undefined;
  locationIds: (string | null)[] | null | undefined;
  searches: (string | null)[] | null | undefined;
  page: number;
  handleLoadPage: (page: number) => void;
  selectedChargers: Charger[];
  setSelectedChargers: Function;
};
const ROW_PER_PAGE = NUMBER.TWENTY;
const ROW_MAX_SIZE = 1000;

export const ChargerGrid = memo(
  ({
    status,
    maintenanceFlag,
    locationIds,
    searches,
    page,
    handleLoadPage,
    selectedChargers,
    setSelectedChargers,
  }: ChargerGridInput) => {
    const auth = useAuth();

    const { allowStateOfChargeChargersView } = useAllowedFeatures();

    const { navigateWithCompanyId } = useNavigateWithSearchParam();
    const { isMultiViewActive, companies, companyId, userScope, companyScope } =
      useCompany();
    const rowClick = (rowData: any) => {
      navigateWithCompanyId(
        `../${RoutePath.CHARGERS}/${rowData.id}`,
        companyId,
      );
    };

    const [isLocalTimezone, setIsLocalTimezone] = useState(true);
    const fetchOption = useMemo(() => {
      const serverStatus = new Set();
      status?.forEach((_status) => {
        switch (_status) {
          case OCPP_STATUS.CHARGING:
          case OCPP_STATUS.SUSPENDED_EV:
          case OCPP_STATUS.SUSPENDED_EVSE:
            // This is weired but true.
            serverStatus.add(SERVER_SIDE_CHARGER_STATUS.ONLINE.toLowerCase());
            serverStatus.add(SERVER_SIDE_CHARGER_STATUS.CHARGING.toLowerCase());
            break;
          case CHARGER_STATUS.OUT_OF_ORDER:
            // This is weired but true, too.
            serverStatus.add(SERVER_SIDE_CHARGER_STATUS.ONLINE.toLowerCase());
            serverStatus.add(
              SERVER_SIDE_CHARGER_STATUS.OUT_OF_ORDER.toLowerCase(),
            );
            break;
          case CHARGER_STATUS.AVAILABLE:
            serverStatus.add(SERVER_SIDE_CHARGER_STATUS.ONLINE.toLowerCase());
            break;
          default:
            serverStatus.add(_status);
        }
      });
      return {
        scope: userScope,
        'filter_like[name]': searches?.join(','),
        // TODO: Pagination
        limit: ROW_MAX_SIZE, // limit: ROW_PER_PAGE,
        offset: 0, // offset: ROW_PER_PAGE * (page - 1),
        'filter_eq[maintenanceFlag]': maintenanceFlag || undefined,
        'filter_in[status]': Array.from(serverStatus).join(','),
        'filter_in[locationId]': locationIds?.join(','),
      } as ChargerOptions;
    }, [status, maintenanceFlag, locationIds, userScope, searches, page]);

    const { chargerData, isChargerLoading, chargerDataCount } =
      useFetchChargersQuery(fetchOption, {
        pollingInterval: 15000,
        selectFromResult: (endPoint) => {
          return {
            chargerData: endPoint.data?.entities || [],
            isChargerLoading: endPoint.isFetching && !endPoint?.data,
            chargerDataCount: endPoint.data?.totalCount || 0,
          };
        },
      });

    // TODO: Pagination
    const totalCount = useMemo(() => {
      if (status && status?.length > 0) {
        return (
          [...chargerData].filter(
            (charger) => !status || status?.includes(charger.status),
          ).length || 0
        );
      }
      return chargerDataCount > ROW_MAX_SIZE ? ROW_MAX_SIZE : chargerDataCount;
    }, [chargerData, status, chargerDataCount]);

    const chargers = useMemo(() => {
      // TODO: Pagination
      // filter again by charger status
      const newChargers = [...chargerData].filter(
        (charger) =>
          !status || status.length == 0 || status?.includes(charger.status),
      );

      // order1: sort all chargers by locations name
      newChargers.sort((a, b) => {
        const locationA = a.location?.name.toLowerCase() || '';
        const locationB = b.location?.name.toLowerCase() || '';
        if (locationA < locationB) {
          return -1;
        }
        if (locationA > locationB) {
          return 1;
        }
        // location is same
        const nameA = a.name || '';
        const nameB = b.name || '';
        if (nameA < nameB) {
          return -1;
        }
        return 1;
      });

      // order 2: out of order -> offline -> others
      const outOfOrderChargers: Charger[] = [];
      const offlineChargers: Charger[] = [];
      const otherChargers: Charger[] = [];

      newChargers.forEach((charger) => {
        if (charger.status === CHARGER_STATUS.OUT_OF_ORDER) {
          outOfOrderChargers.push(charger);
        } else if (charger.status === CHARGER_STATUS.OFFLINE) {
          offlineChargers.push(charger);
        } else {
          otherChargers.push(charger);
        }
      });

      return outOfOrderChargers.concat(offlineChargers, otherChargers);
    }, [chargerData, status]);

    const gridData = useMemo(() => {
      const startIndex = (page - 1) * ROW_PER_PAGE;
      const result = chargers
        // TODO: Pagination
        .slice(startIndex, startIndex + ROW_PER_PAGE)
        .map((charger: Charger) => {
          const companyObj = companies?.filter(
            (item: any) => item.id === charger.ownerId,
          );
          return {
            id: charger.id,
            charger: charger.name,
            location: charger.location?.name,
            company: companyObj?.length ? companyObj[0].name : '',
            status: charger.status,
            lastUsed: charger.lastUsed,
            pricing: charger.isFree
              ? t('chargers_price_free')
              : t('chargers_price_paid'),
            access: t((charger.access || '').toLowerCase()),
            note: charger?.internalNote,
            maintenanceFlag: charger.maintenanceFlag,
            ports: charger.ports,
            bg:
              charger.status === CHARGER_STATUS.OUT_OF_ORDER
                ? 'bg-negative0'
                : charger.status === CHARGER_STATUS.OFFLINE
                ? 'bg-warning0'
                : 'bg-white',
            timeZone: charger.location?.timeZone,
          };
        });
      return result;
    }, [companies, chargers, page]);

    const handleCheckboxSelection = (
      checked: boolean,
      row: FilteredCharger,
    ) => {
      let selectedChargersShallowCopy: Array<Charger> = [...selectedChargers];
      if (checked) {
        const selectedCharger = chargers.find(
          (ele: Charger) => ele.id === row.id,
        );
        if (selectedCharger) selectedChargersShallowCopy.push(selectedCharger);
      } else {
        selectedChargersShallowCopy = selectedChargers.filter(
          (ele: Charger) => ele.id !== row.id,
        );
      }
      setSelectedChargers(selectedChargersShallowCopy);
    };

    const handleAllSelected = (selected: boolean) => {
      if (selected) {
        setSelectedChargers(_.uniqBy([...selectedChargers, ...chargers], 'id'));
      } else {
        setSelectedChargers(_.differenceBy(selectedChargers, chargers, 'id'));
      }
    };

    const isAllCurrentPageSelected = useMemo(() => {
      if (chargers.length === 0) {
        return false;
      }
      return chargers.every((ele: Charger) => {
        return (
          selectedChargers.findIndex(
            (charger: Charger) => charger.id === ele.id,
          ) > -1
        );
      });
    }, [chargers, selectedChargers]);

    const getColumnTitle = () => {
      let columnData = [
        {
          key: 'checkbox',
          title: (
            <CheckBox
              selected={isAllCurrentPageSelected}
              onChange={handleAllSelected}
            />
          ),
          component: (row: FilteredCharger) => (
            <CheckBox
              selected={
                selectedChargers.findIndex(
                  (ele: Charger) => ele.id === row.id,
                ) > -1
              }
              onChange={(checked: boolean) => {
                handleCheckboxSelection(checked, row);
              }}
            />
          ),
          onCellClick: (e: any) => {},
        },
        {
          key: 'charger',
          title: t('charger'),
          class: 'w-[120px]',
          component: (row: any) => (
            <Label
              className='whitespace-nowrap w-[120px] truncate'
              text={row.charger}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={chargers.length === 0}
            />
          ),
        },
        {
          key: 'location',
          title: t('location'),
          class: 'w-[180px]',
          component: (row: any) => (
            <Label
              className='whitespace-nowrap w-[180px] truncate'
              text={row.location}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={chargers.length === 0}
            />
          ),
        },
        {
          key: 'status',
          title: t('status'),
          class: 'w-[120px]',
          component: (row: any) => {
            const { timeStamp, status: rowStatus, ports } = getRowStatus(row);
            return (
              <div className='whitespace-nowrap flex'>
                <ChargerStatus
                  status={rowStatus}
                  ports={ports}
                  timeStamp={timeStamp}
                  isLoading={chargers.length === 0}
                />
                {row.maintenanceFlag && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon
                        className='ml-[8px]'
                        src={maintenance}
                        size={IconSize.SIZE_20x20}
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <span
                        className='text-sm text-white font-medium'
                        style={{ fontFamily: 'Inter' }}
                      >
                        <Trans
                          i18nKey='maintenance_flag_tip'
                          components={{
                            br: <br />,
                          }}
                        />
                      </span>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
            );
          },
        },
        {
          key: 'stateOfCharge',
          title: t('chargers_grid_stateOfCharge'),
          class: 'min-w-[180px]',
          hidden: !allowStateOfChargeChargersView,
          component: (row: any) => {
            return (
              <ChargerStateOfCharge
                ports={row.ports}
                isLoading={chargers.length === 0}
              />
            );
          },
        },
        {
          key: 'lastUsed',
          title: t('chargers_grid_lastUsed'),
          type: GridColumnType.TIMEZONE,
          component: (row: any) => {
            return (
              <Label
                className='whitespace-nowrap w-40'
                text={
                  row.lastUsed && row.timeZone
                    ? formatInTimeZone(
                        row.lastUsed,
                        isLocalTimezone
                          ? Intl.DateTimeFormat().resolvedOptions().timeZone
                          : row.timeZone,
                        'LLL dd, h:mm a',
                        getLocale(),
                      )
                    : ''
                }
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={chargers.length === 0}
              />
            );
          },
        },
        {
          key: 'pricing',
          title: t('pricing'),
          hidden: allowStateOfChargeChargersView,
          class: 'w-[132px]',
        },
        {
          key: 'access',
          title: t('access'),
          hidden: allowStateOfChargeChargersView,
          class: 'w-[95px]',
        },
        {
          key: 'note',
          title: t('chargers_grid_note'),
          component: (row: any) => {
            return (
              <div className='overflow-hidden text-ellipsis items-center w-[220px]'>
                <Label
                  text={row.note}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  isLoading={chargers.length === 0}
                />
              </div>
            );
          },
        },
      ];
      // add company column and remove below columns
      // checkbox, pricing, access
      if (isMultiViewActive) {
        columnData = columnData.filter(
          (_item: any, indexNo: number) => [0, 6, 7].indexOf(indexNo) === -1,
        );
        // add new company column
        const companyColumn = {
          key: 'company',
          title: t('company'),
          class: 'w-[156px]',
          component: (row: any) => (
            <Label
              className='whitespace-nowrap w-[156px] truncate'
              text={row.company}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={chargers.length === 0}
            />
          ),
        };
        // insert company column on index 2
        const companyIndex = 2;
        columnData = [
          ...columnData.slice(0, companyIndex),
          companyColumn,
          ...columnData.slice(companyIndex, columnData.length),
        ];
      }
      return columnData;
    };
    const handleToggleChange = (selected: boolean) => {
      setIsLocalTimezone(selected);
    };
    const renderNoFilterChargerState = (data: any) => {
      if (!isChargerLoading && data?.length === NUMBER.ZERO) {
        return (
          <div className='flex flex-col items-center gap-2 mt-[16px] mb-[28px] '>
            <Icon src={NoChargers} size={IconSize.SIZE_48x48} />
            <Label
              text={t('charger_not_found')}
              type={LabelType.H4}
              color={ColorType.GREY5}
            />
            <Label
              text={t('charger_not_found_message')}
              type={LabelType.BODY2}
              color={ColorType.GREY5}
            />
          </div>
        );
      }
      return null;
    };
    return (
      <div className='overflow-hidden mt-6'>
        <Grid
          onRowClick={rowClick}
          pageIndex={page}
          loadPage={handleLoadPage}
          columns={getColumnTitle()}
          data={gridData}
          primaryKey='id'
          totalPage={Math.ceil(totalCount / ROW_PER_PAGE)}
          isLoading={isChargerLoading}
          dataTestId='charger-table'
          setIsLocalTimezone={handleToggleChange}
          isLocalTimezone={isLocalTimezone}
          switchClass='w-5 h-2.5'
          switchBtnClass='w-2 h-2 top-px left-px'
          translateClass='translate-x-2.5'
        />
        {renderNoFilterChargerState(chargers)}
      </div>
    );
  },
);
