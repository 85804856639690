import { createSelector } from 'reselect';
import { AppState } from '../types/App-State';
import { Theme } from '../types/theme.interface';
import { envApi } from '../../services/env.api';
import chargelab from '../../assets/images/chargelab-logo.svg';
import { ColorType, getHexColorByType } from '../../components/_ui';

const initStore = {
  networkLogo: chargelab,
  chartColor: '',
  navigationSelectedColor: '',
  btnHoverColor: '',
  secondaryBtnBgColor: '',
} as Theme;

export const getCurrentTheme = createSelector(
  [envApi.endpoints.fetchThemeSettings.select(undefined)],
  (endpoint) => endpoint.data || initStore,
);
