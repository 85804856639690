import React, { ReactNode, useState } from 'react';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../index';

interface ToggleItemProps {
  option: string;
  isSelected?: boolean;
  onClick?: (option: string) => void;
  label?: string;
  className?: string;
  dataTestId?: string;
}

const ToggleItem: React.FC<ToggleItemProps> = ({
  option,
  isSelected,
  onClick,
  label,
  className,
  dataTestId = '',
}) => {
  const handleOnClick = () => {
    onClick && onClick(option);
  };

  return (
    <div
      key={option}
      className={`p-2 cursor-pointer text-center  ${
        isSelected ? 'hover:bg-grey2 bg-grey1' : 'hover:bg-grey1'
      } ${className}`}
      onClick={handleOnClick}
      data-testid={dataTestId}
    >
      <Label
        text={label || option}
        type={LabelType.LABEL_S}
        color={isSelected ? ColorType.BLACK : ColorType.GREY6}
      />
    </div>
  );
};

export default ToggleItem;
