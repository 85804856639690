import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '../Divider.component';
import { LabelType } from '../Label.component';
import CheckBox from './CheckBox.component';
import { CheckBoxData } from './types/CheckBox-Column.interface';

export enum GroupDirection {
  Horizontal,
  Vertical,
}

interface CheckBoxGroupProps {
  direction?: GroupDirection;
  defaultItems: CheckBoxData[];
  singleSelection?: boolean;
  onChange: Function;
  handleAllSelect?: Function;
  filterStr?: string;
  label?: string;
  translationOn?: boolean;
  itemHeight?: string;
  hoverEffect?: boolean;
  selectedLabelColor?: string;
  padding?: string;
  secondaryLabel?: string;
  labelType?: LabelType;
  itemWidth?: string;
  dataTestId?: string;
}

const CheckBoxGroup = ({
  defaultItems,
  direction = GroupDirection.Horizontal,
  singleSelection = false,
  onChange,
  handleAllSelect,
  selectedLabelColor,
  filterStr = '',
  label = 'label',
  secondaryLabel = 'secondaryLabel',
  labelType = LabelType.BODY3,
  translationOn = false,
  itemHeight = '40px',
  itemWidth,
  hoverEffect = false,
  padding = 'pl-2 pr-2',
  dataTestId,
}: CheckBoxGroupProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(defaultItems);
  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  const changeHandler = (selected: boolean, i: number) => {
    const newItems = items.map((item, index) => {
      if (singleSelection) {
        // For a single selection, we can't cancel the radio, otherwise it will cause problems on date dropdown
        // TODO: product team has changed there decision, instead of radio, the new data picker is more like a dropdown
        // so we should update this part code in future.
        return { ...item, selected: index === i };
      }
      return {
        ...item,
        selected: index === i ? selected : item.selected,
      };
    });

    setItems(newItems);
    if (onChange) onChange(newItems);
    if (handleAllSelect) handleAllSelect({ ...items[i], selected });
  };

  return (
    <div
      className={`flex ${
        direction === GroupDirection.Vertical ? 'flex-col' : 'flex-row gap-4'
      }`}
    >
      {items.map((rowData: any, index) => {
        const item = rowData[label] as string;
        const secItem = rowData[secondaryLabel] as string;
        if (
          filterStr &&
          !item.toLowerCase().includes(filterStr.toLowerCase())
        ) {
          return null;
        }
        return (
          <>
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${item}-${index}`}
              className={`flex ${hoverEffect && 'hover:bg-grey1'}  ${
                direction === GroupDirection.Vertical && padding
              } 
            `}
              style={{ height: itemHeight, width: itemWidth }}
            >
              <CheckBox
                index={index}
                // eslint-disable-next-line react/no-array-index-key
                key={`${item}-${index}`}
                label={item}
                secondaryLabel={secItem}
                selected={rowData.selected || false}
                singleSelection={singleSelection}
                onChange={changeHandler}
                translationOn={translationOn}
                isDisabled={rowData.disabled}
                pillLabel={rowData.pillLabel}
                pillIcon={rowData.pillIcon}
                selectedLabelColor={selectedLabelColor}
                labelType={labelType}
                dataTestId={dataTestId}
              />
            </div>
            {/* Added divider for all company select */}
            {rowData.id === 'all' && <Divider key={`${rowData.id}-divider`} />}
          </>
        );
      })}
    </div>
  );
};

CheckBoxGroup.defaultProps = {
  direction: GroupDirection.Horizontal,
  singleSelection: false,
};

export default memo<CheckBoxGroupProps>(CheckBoxGroup);
