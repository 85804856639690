import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pill } from '../_ui/Pill.component';
import { Label, LabelType } from '../_ui/Label.component';
import {
  CHARGER_STATUS,
  OCPP_STATUS,
  OUT_OF_ORDER_PORT_OCPP_STATUS,
} from '../../constant/Text.constant';
import { ColorType } from '../_ui';
import StatusChip from './StatusChip.component';
import { copyChargingToInuse, getValidRawStatus } from './utils';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { handleTimeCalculation } from '../Session/utils';
import { ChargerPort } from '../../stores/types/sessions.interface';
import { convertToDate, getDifferenceInMinutes } from '../../utils/Date.Util';

interface InputProps {
  status?: string;
  ports?: ChargerPort[];
  isLoading?: boolean;
  timeStamp?: number | string;
}

export const ChargerStatus = memo(
  ({ status = '', ports = [], isLoading = false, timeStamp }: InputProps) => {
    const timeStampText = timeStamp
      ? `(~${handleTimeCalculation(timeStamp)})`
      : '';
    const { t } = useTranslation();
    const getStatusProperty = (chargerStatus: string) => {
      let color;
      let tooltip;
      let secondaryColor = ColorType.POSITIVE0;
      let secondaryTextColor;
      switch (chargerStatus) {
        case CHARGER_STATUS.COMING_SOON:
          color = ColorType.GREY4;
          secondaryColor = ColorType.GREY0;
          secondaryTextColor = ColorType.GREY5;
          tooltip = 'coming_soon_tooltip';
          break;
        case CHARGER_STATUS.AVAILABLE:
          color = ColorType.POSITIVE1;
          secondaryColor = ColorType.POSITIVE0;
          secondaryTextColor = ColorType.POSITIVE2;
          tooltip = 'available_status_tooltip';
          break;
        case OCPP_STATUS.CHARGING:
          color = ColorType.ACCENT1;
          secondaryColor = ColorType.ACCENT_SUB;
          tooltip = 'charging_status_tooltip';
          break;
        case CHARGER_STATUS.SCHEDULED:
          color = ColorType.PURPLE;
          tooltip = '';
          break;
        case CHARGER_STATUS.OFFLINE:
          color = ColorType.WARNING1;
          secondaryColor = ColorType.WARNING0;
          secondaryTextColor = ColorType.WARNING2;
          tooltip = 'offline_status_tooltip';
          break;
        case CHARGER_STATUS.OUT_OF_ORDER:
          color = ColorType.NEGATIVE1;
          secondaryColor = ColorType.NEGATIVE0;
          tooltip = 'outoforder_status_tooltip';
          break;
        case OCPP_STATUS.SUSPENDED_EV:
          color = ColorType.ACCENT1;
          secondaryColor = ColorType.ACCENT_SUB;
          tooltip = 'suspendedev_status_tooltip';
          break;
        case OCPP_STATUS.SUSPENDED_EVSE:
          color = ColorType.ACCENT1;
          secondaryColor = ColorType.ACCENT_SUB;
          tooltip = 'suspendedevse_status_tooltip';
          break;
        case OUT_OF_ORDER_PORT_OCPP_STATUS.UNAVAILABLE:
          color = ColorType.NEGATIVE1;
          tooltip = 'outoforder_status_tooltip';
          break;
        case OUT_OF_ORDER_PORT_OCPP_STATUS.FAULTED:
          color = ColorType.NEGATIVE1;
          tooltip = 'outoforder_status_tooltip';
          break;
        default:
          color = ColorType.POSITIVE1;
          secondaryColor = ColorType.POSITIVE0;
          tooltip = '';
          break;
      }
      return { color, tooltip, secondaryColor, secondaryTextColor };
    };

    const { color, tooltip, secondaryColor, secondaryTextColor } =
      getStatusProperty(status);

    const inUseArray = [
      OCPP_STATUS.CHARGING,
      OCPP_STATUS.SUSPENDED_EV,
      OCPP_STATUS.SUSPENDED_EVSE,
    ];

    let secondaryText = t(status);
    if (ports.length === 1) {
      secondaryText = t('in_use');
    } else if (ports.length > 1) {
      secondaryText = `${ports.length} ${t('ports')}`;
    }
    const renderStatus =
      inUseArray.includes(status) || ports.length > 1 ? (
        <StatusChip
          isLoading={isLoading}
          primaryText={inUseArray.includes(status) ? t('in_use') : t(status)}
          secondaryText={secondaryText}
          primaryTextColor={ColorType.WHITE}
          primaryBgColor={color}
          secondaryTextColor={secondaryTextColor || color}
          secondaryBgColor={secondaryColor}
        />
      ) : (
        <Pill
          label={t(copyChargingToInuse(status))}
          bgColor={color}
          isLoading={isLoading}
          labelColor={ColorType.WHITE}
        />
      );

    return tooltip || ports.length > 1 ? (
      <Tooltip
        placement='top'
        tipOffset={{
          mainAxis: 10,
          crossAxis: -10,
        }}
      >
        <TooltipTrigger>{renderStatus}</TooltipTrigger>
        <TooltipContent>
          {ports.length > 1 ? (
            ports.map((port, i) => {
              const timeStampData = `(~${handleTimeCalculation(
                getDifferenceInMinutes(
                  new Date(),
                  convertToDate(
                    port?.rawChargerStatus?.rawChargerStatusChangedTime,
                  ),
                ),
              )})`;

              const portStatus =
                port.status.toLowerCase() === OCPP_STATUS.CHARGING
                  ? getValidRawStatus(
                      port?.rawChargerStatus?.rawChargerStatus?.toLowerCase() ||
                        port.status.toLowerCase(),
                    )
                  : port.status.toLowerCase();

              const { tooltip: portTooltip } = getStatusProperty(portStatus);
              return (
                <Label
                  text={`${t('port')} ${i + 1}:  ${t(portTooltip, {
                    timeStampText:
                      portStatus !== CHARGER_STATUS.AVAILABLE
                        ? timeStampData
                        : '',
                  })}`}
                  type={LabelType.LABEL_S_MEDIUM}
                  color={ColorType.WHITE}
                  className={`${i > 0 ? 'mt-2' : ''}`}
                />
              );
            })
          ) : (
            <Label
              text={t(tooltip, { timeStampText })}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.WHITE}
            />
          )}
        </TooltipContent>
      </Tooltip>
    ) : (
      renderStatus
    );
  },
);
