const checkIfInValidNumber = (val: number | string) =>
  val === '' || val === null || val === undefined || Number.isNaN(Number(val));

export const isValidLat = (val: number | string) => {
  if (checkIfInValidNumber(val)) {
    return false;
  }
  return Number(val) >= -90 && Number(val) <= 90;
};

export const isValidLong = (val: number | string) => {
  if (checkIfInValidNumber(val)) {
    return false;
  }
  return Number(val) >= -180 && Number(val) <= 180;
};
