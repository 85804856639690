import { NotificationCheckboxData } from '../stores/types/notification.interface';

export const sortChargers = (chargers: NotificationCheckboxData[]) => {
  const sortedArray = [...chargers];
  sortedArray?.length &&
    sortedArray.sort(
      (
        prevCharger: NotificationCheckboxData,
        nextCharger: NotificationCheckboxData,
      ) => {
        const nameA = prevCharger.label.toLowerCase() || '';
        const nameB = nextCharger.label.toLowerCase() || '';
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      },
    );
  return sortedArray;
};
