/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react';
import { Spinner } from '../../_ui';

type Props = {
  src: string;
  title: string;
  width: number | string;
  height: number;
  loadingHeight: number;
};
export const Iframe = ({ src, title, width, height, loadingHeight }: Props) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedSrc, setLoadedSrc] = useState('');
  useEffect(() => {
    if (src != loadedSrc) {
      setIsLoading(true);
    }

    const handleIframeLoad = () => {
      setLoadedSrc(src);
      setIsLoading(false);
    };
    const element = ref.current;
    if (element) {
      element.addEventListener('load', handleIframeLoad);
    }
    return () => {
      if (element) {
        element.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [src]);
  return (
    <div style={{ width: width, height: isLoading ? loadingHeight : height }}>
      {isLoading && (
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner />
        </div>
      )}
      <iframe
        ref={ref}
        src={src}
        title={title}
        style={{
          width: '100%',
          height: isLoading ? '0px' : '100%',
          display: 'block',
        }}
      />
    </div>
  );
};
export default Iframe;