import React from 'react';
import { ColorType, Icon, Label, LabelType } from '../_ui';
import { IconSize } from '../../constant/IconSize.constant';

interface Iprops {
  icon?: string;
  leftText: string;
  rightText: string;
  renderIcon?: any;
}
const ConnectorRowItem = ({
  icon,
  leftText,
  rightText,
  renderIcon,
}: Iprops) => {
  return (
    <div className='flex flex-row justify-between items-center'>
      <div className='flex items-center mr-1.5'>
        {icon && (
          <Icon
            size={IconSize.SIZE_16x16}
            src={icon}
            color={ColorType.GREY6}
            className='mr-[4px]'
          />
        )}
        {renderIcon && renderIcon}
        <Label
          text={leftText}
          type={LabelType.LABEL_XS}
          color={ColorType.GREY6}
          className='whitespace-nowrap'
        />
      </div>
      <Label
        className='ml-1 whitespace-nowrap'
        text={rightText}
        type={LabelType.BODY4}
        color={ColorType.GREY6}
      />
    </div>
  );
};

export default ConnectorRowItem;
