import { Auth } from 'aws-amplify';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth, USER_ROLE } from '../../../hooks';
import { useAllowedFeatures } from '../../../hooks/useFeaturePersonalization';
import {
  getSparkEnvironment,
  getSparkUrl,
} from '../../../stores/selectors/deployment.selector';
import { Sessions } from '../../Session';
import SupportNotesButton from '../../SupportNotes/Support-Notes-Button.component';
import SupportNotes from '../../SupportNotes/Support-Notes.component';
import { Card, Tab, Tabs } from '../../_ui';
import ChargerMessage from '../charger-message/ChargerMessage.component';
import ExportOcppMessages from '../charger-message/ExportOcppMessages.component';
import Iframe from './Iframe';

interface IProps {
  chargerId: string;
  chargerName: string | undefined;
  chargerTimeZone: string;
  companyId: string;
}

enum HistoryTab {
  SESSION_HISTORY = 0,
  CHARGER_MESSAGE = 1,
  CHARGER_SIGNALS = 2,
  SUPPORT_NOTES = 3,
}

const ChargerHistory = ({
  chargerId,
  chargerName,
  chargerTimeZone,
  companyId,
}: IProps) => {
  const [tab, setTab] = useState(HistoryTab.SESSION_HISTORY);
  const [messagesFilter, setMessagesFilter] = useState({
    count: 0,
    filters: {},
  });
  const { t } = useTranslation();

  const auth = useAuth();
  const [searchHeight, setSearchHeight] = useState(400);
  const [signalsFrameUrl, setSignalsFrameUrl] = useState('');
  const { allowSparkSignals } = useAllowedFeatures();
  const sparkUrl: string = useSelector(getSparkUrl);
  const sparkEnvironment: string = useSelector(getSparkEnvironment);

  async function updateSignalsFrameUrl(searchParams: any) {
    const cognitoUserSession = await Auth.currentSession();
    const cognitoToken = cognitoUserSession.getAccessToken().getJwtToken();

    const url = new URL('/integration/signals/charger', sparkUrl);
    url.searchParams.append('deviceId', chargerId);
    url.searchParams.append('authToken', cognitoToken);
    url.searchParams.append('tz', chargerTimeZone);
    url.searchParams.append(
      'cb',
      String(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)),
    );

    Object.entries(searchParams).forEach(([key, value]) =>
      url.searchParams.append(key, String(value)),
    );

    setSignalsFrameUrl(url.href);
  }

  useLayoutEffect(() => {
    if (signalsFrameUrl == '') {
      updateSignalsFrameUrl({
        env: sparkEnvironment,
        start_time: 'now-15m',
        end_time: 'now',
      });
    }
  });

  useLayoutEffect(() => {
    const listener = async (event: any) => {
      console.log('Received event in listener: ', event);
      if (!event.origin.endsWith('spark.chargelab.io')) {
        console.log('Unexpected event origin: ', event.origin);
        return;
      }

      const { data } = event;
      const { type } = data;
      if (type === 'reload') {
        const { searchParams } = data;
        await updateSignalsFrameUrl(searchParams);
      } else if (type === 'height') {
        let height = Number(event.data.height);
        if (height == 0) {
          console.log('Height was 0! Setting to 400.');
          height = 400;
        }

        console.log('Setting iframe height to: ', height);
        setSearchHeight(height);
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  });

  const hasSparkSignals = useMemo(() => {
    return auth?.role === USER_ROLE.SUPPORT || allowSparkSignals;
  }, [auth?.role, allowSparkSignals]);

  const hasSupportNote = useMemo(() => {
    return auth?.role === USER_ROLE.SUPPORT;
  }, [auth?.role]);

  const renderRightTopButton = () => {
    if (tab === HistoryTab.CHARGER_MESSAGE) {
      return (
        <ExportOcppMessages
          chargerName={chargerName}
          messagesFilter={messagesFilter}
          companyId={companyId}
        />
      );
    }
    if (tab === HistoryTab.SUPPORT_NOTES) {
      return <SupportNotesButton chargerId={chargerId} />;
    }
  };
  return (
    <div className='flex flex-grow self-baseline w-[479px]'>
      <Card>
        <div className=' flex flex-row justify-between'>
          <Tabs onTabChange={setTab} rootClass='w-[720px]'>
            <Tab label={t('session_recent_sessions')} />
            <Tab label={t('charger_messages')} />
            {hasSparkSignals ? <Tab label={t('charger_signals')} /> : <div />}
            {hasSupportNote ? <Tab label={t('support_notes')} /> : <div />}
          </Tabs>
          {renderRightTopButton()}
        </div>
        {tab === HistoryTab.SESSION_HISTORY && (
          <Sessions
            enableFilterCharger={false}
            enableExportCSV={false}
            dataMap={[
              'createTime|startTime',
              'status',
              'port.connectorType|port.physicalLocation',
              'consumedEnergyKwh',
              'billedTotalAmount',
            ]}
            chargerId={chargerId}
            showTitle={false}
            cardPadding='pt-0 pl-0 pb-0 pr-0'
            companyId={companyId}
          />
        )}
        {tab === HistoryTab.CHARGER_MESSAGE && (
          <ChargerMessage
            setMessagesFilter={setMessagesFilter}
            chargerId={chargerId}
            chargerTimeZone={chargerTimeZone}
            companyId={companyId}
          />
        )}
        {tab === HistoryTab.CHARGER_SIGNALS && (
          <Iframe
            src={signalsFrameUrl}
            title='Charger Signals'
            width='100%'
            height={searchHeight}
            loadingHeight={400}
          />
        )}
        {tab === HistoryTab.SUPPORT_NOTES && (
          <SupportNotes chargerId={chargerId} />
        )}
      </Card>
    </div>
  );
};

export default ChargerHistory;
