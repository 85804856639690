import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useGlobalModalContext } from '../../_ui/modal/GlobalModal.component';
import { CheckBoxData } from '../../_ui';
import { useLazyCheckIfUserEmailExistsQuery } from '../../../services/access.api';
import { validateDomainName, validateEmail } from '../../_ui/modal/utils';
import {
  useAddDiscountMutation,
  useLazyFetchDiscountsQuery,
  useUpdateDiscountMutation,
  usePreExistingDiscountValidatonMutation,
} from '../../../services/discount.api';
import { DiscountFormType } from '../../../stores/types/discount.interface';
import {
  ChargerSelection,
  ChargerSelectionList,
} from '../../../stores/types/chargers.interface';
import { TEXT } from '../../../constant/Text.constant';
import { DiscountFormFields } from './DiscountFormFields';
import { NUMBER } from '../../../constant/Number.constant';
import {
  removeElementsFromArray,
  roundToTwoDecimalPlaces,
} from '../../Pricing/utils';

const { FIFTY, HUNDRED } = NUMBER;

interface EditDiscountDetails {
  appliedAt: string[];
  discount: number;
  email: string;
  id: string;
  type: string;
}

interface DiscountFormProp {
  chargersList: any;
  isEditMode?: boolean;
  editDiscountDetails?: EditDiscountDetails;
}

export const DiscountForm = ({
  chargersList,
  isEditMode = false,
  editDiscountDetails = {
    appliedAt: [],
    discount: HUNDRED,
    email: '',
    id: '',
    type: '',
  },
}: DiscountFormProp) => {
  const { t } = useTranslation();
  const {
    INDIVIDUAL,
    CUSTOM,
    PERCENTAGE,
    CHARGER,
    FULFILLED,
    DOMAIN_NAME,
    FREE,
    ALL,
  } = TEXT;

  const editDiscountDetailDiscount = roundToTwoDecimalPlaces(
    Math.abs(Number(editDiscountDetails.discount)),
  );

  const isCustomDiscountType =
    isEditMode &&
    editDiscountDetailDiscount !== HUNDRED &&
    editDiscountDetailDiscount !== FIFTY;

  const [triggerAddDiscount, addDiscountQueryResult] = useAddDiscountMutation();
  const [triggerEditDiscount, editDiscountQueryResult] =
    useUpdateDiscountMutation();

  const [triggerValidateDiscount, { data: existingDiscountRes }] =
    usePreExistingDiscountValidatonMutation();

  const [triggerCheckIfUserEmailExist, { isError: emailDoesNotExist }] =
    useLazyCheckIfUserEmailExistsQuery();

  const [discountType, setDiscountType] = useState<string>(
    isCustomDiscountType ? CUSTOM : '',
  );

  const [isValidDiscount, setIsValidDiscount] = useState(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectorType =
    editDiscountDetails.type === 'Company' ? DOMAIN_NAME : INDIVIDUAL;

  const [showPreExistDiscountMessage, setShowPreExistDiscountMessage] =
    useState<boolean>(false);

  const editResourcesList = editDiscountDetails.appliedAt.map(
    (ele: string) => ({
      id: ele,
      resourceType: CHARGER,
    }),
  );

  const defaultState = {
    adjustmentType: PERCENTAGE,
  };

  const isCompanyType: Boolean = selectorType.toUpperCase() === DOMAIN_NAME;

  const isCompanyEditMOde: Boolean =
    isEditMode && selectorType.toUpperCase() === DOMAIN_NAME;

  const isDriverEditMOde: Boolean =
    isEditMode && selectorType.toUpperCase() === INDIVIDUAL;

  const driverDefaultState: DiscountFormType = {
    ...defaultState,
    adjustmentPercentage: isDriverEditMOde
      ? editDiscountDetailDiscount
      : HUNDRED,
    userGroupSelector: {
      type: INDIVIDUAL,
      value: isDriverEditMOde ? editDiscountDetails.email : '',
    },
    resources: isEditMode ? editResourcesList : [],
  };

  const companyDefaultState: DiscountFormType = {
    ...defaultState,
    adjustmentPercentage: isCompanyEditMOde
      ? editDiscountDetailDiscount
      : HUNDRED,
    userGroupSelector: {
      type: DOMAIN_NAME,
      value: isCompanyEditMOde ? editDiscountDetails.email : '',
    },
    resources: isEditMode ? editResourcesList : [],
  };

  const [driverDiscountDetails, setDriverDiscountDetails] =
    useState<DiscountFormType>(driverDefaultState);

  const [companyDiscountDetails, setCompanyDiscountDetails] =
    useState<DiscountFormType>(companyDefaultState);

  const [discountDetails, setDiscountDetails] = useState<DiscountFormType>(
    isCompanyType ? companyDefaultState : driverDefaultState,
  );

  const [chargerDropdownList, setChargerDropdownList] = useState(chargersList);

  const [discountOptions, setDiscountOptions] = useState<CheckBoxData[]>([
    {
      id: FREE,
      label: 'Free',
      value: `${HUNDRED}`,
      selected: !isEditMode ? true : editDiscountDetailDiscount === HUNDRED,
    },
    {
      id: 'FIFTY',
      label: '50% off',
      value: `${FIFTY}`,
      selected: editDiscountDetailDiscount === FIFTY,
    },
    {
      id: CUSTOM,
      label: 'Custom',
      value: isEditMode ? `${editDiscountDetailDiscount}` : '',
      selected: isCustomDiscountType,
    },
  ]);

  /**
   * It takes a boolean value and returns an array of objects
   * @param {boolean} isEditDiscountDetails - boolean - This is a boolean value that is true when the
   * user is editing a discount.
   */
  const getInitialSelectedChargers = (resourcesList: any | undefined) => {
    const data = chargersList.map((ele: ChargerSelectionList) => {
      const children: any[] = ele.children.map((charger: ChargerSelection) => {
        return {
          id: charger.id,
          label: charger.label,
          selected:
            isEditMode && resourcesList
              ? resourcesList.findIndex(
                  (element: any) => element.id === charger.id,
                ) > -1
              : false,
        };
      });
      return {
        id: ele.id,
        label: ele.label,
        selected: children
          ? children?.every((child: any) => child.selected)
          : false,
        children: children || [],
      };
    });
    const isAllChargerSelected = data.every(
      (obj: { selected: boolean }) => obj.selected,
    );
    const updatedChargers = data.map((obj: { id: string }) =>
      obj.id === ALL ? { ...obj, selected: false } : obj,
    );
    return isAllChargerSelected ? data : updatedChargers;
  };

  /**
   * It checks if the discount details entered by the user already exists in the existing discount list
   */
  const checkPreExistDiscount = (resources: any, entities: any[]) => {
    let isResourceExist = false;
    const existingResources = entities
      ?.map((ele: { resources: [] }) => ele.resources)
      .flatMap((res: any) => res.map((ele: any) => ele.id));
    const resourcesIds = resources.map((ele: any) => ele.id);
    if (isEditMode) {
      isResourceExist = resourcesIds.some((i: string) =>
        editDiscountDetails.appliedAt.includes(i),
      );
    }
    const updatedResourceId = isResourceExist
      ? removeElementsFromArray(resourcesIds, editDiscountDetails.appliedAt)
      : resourcesIds;
    const isPreExistDiscount = existingResources?.some((ele: any) => {
      return updatedResourceId.find((id: string) => id === ele);
    });

    if (isPreExistDiscount && resources.length > 0) {
      setShowPreExistDiscountMessage(true);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      setChargerDropdownList(
        getInitialSelectedChargers(discountDetails.resources),
      );
      const payload = {
        type: discountDetails.userGroupSelector.type,
        value: discountDetails.userGroupSelector.value,
      };
      triggerValidateDiscount(payload);
    }
  }, [isEditMode]);

  const handleChargersChange = (items: ChargerSelectionList[]) => {
    setShowPreExistDiscountMessage(false);
    let allChargers: ChargerSelection[] = [];
    items.forEach((item: ChargerSelectionList) => {
      allChargers = allChargers.concat(item.children);
    });
    const selectedChargers = allChargers.filter(
      (item: ChargerSelection) => item.selected,
    );
    const resources = selectedChargers.map((ele: ChargerSelection) => ({
      id: ele.id,
      resourceType: CHARGER,
    }));
    const { entities = [] } = existingDiscountRes || {};
    checkPreExistDiscount(resources, entities);
    if (discountDetails.userGroupSelector.type === DOMAIN_NAME) {
      setCompanyDiscountDetails({
        ...companyDiscountDetails,
        resources,
      });
    } else {
      setDriverDiscountDetails({
        ...driverDiscountDetails,
        resources,
      });
    }
    setDiscountDetails({
      ...discountDetails,
      resources,
    });
  };

  const checkIsFormValid = () => {
    const isValidWoCustom =
      !isLoading &&
      !emailDoesNotExist &&
      isValidDiscount &&
      discountDetails.userGroupSelector.value &&
      discountDetails.resources?.length;
    if (discountType === CUSTOM) {
      return isValidWoCustom && discountDetails.adjustmentPercentage;
    }
    return isValidWoCustom;
  };

  const handleSaveClick = () => {
    setIsLoading(true);
    // PM-1665 Adding @ to company domain email before calling API
    const payload = {
      ...discountDetails,
      userGroupSelector: {
        ...discountDetails.userGroupSelector,
        value:
          discountDetails.userGroupSelector.type === TEXT.DOMAIN_NAME
            ? `@${discountDetails.userGroupSelector.value}`
            : discountDetails.userGroupSelector.value,
      },
    };
    payload.adjustmentPercentage = `-${payload.adjustmentPercentage}`;
    if (isEditMode) {
      payload.id = editDiscountDetails?.id;
      triggerEditDiscount(payload).catch(() => {
        setIsLoading(false);
      });
    } else {
      triggerAddDiscount(payload).catch(() => {
        setIsLoading(false);
      });
    }
  };

  const handleSwitchChanges = (selected: string) => {
    let userGroupSelectorType: string = INDIVIDUAL;
    setShowPreExistDiscountMessage(false);
    if (selected === t('by_company')) userGroupSelectorType = DOMAIN_NAME;
    else userGroupSelectorType = INDIVIDUAL;
    const isCompany: Boolean = userGroupSelectorType === DOMAIN_NAME;
    const updatedUserGroupSelectorValue = isCompany
      ? companyDiscountDetails.userGroupSelector.value
      : driverDiscountDetails.userGroupSelector.value;
    if (isCompany) {
      setCompanyDiscountDetails({
        ...companyDiscountDetails,
        userGroupSelector: {
          type: userGroupSelectorType,
          value: companyDiscountDetails.userGroupSelector.value,
        },
      });
    } else {
      setDriverDiscountDetails({
        ...driverDiscountDetails,
        userGroupSelector: {
          type: userGroupSelectorType,
          value: driverDiscountDetails.userGroupSelector.value,
        },
      });
    }
    setDiscountDetails({
      ...discountDetails,
      userGroupSelector: {
        type: userGroupSelectorType,
        value: updatedUserGroupSelectorValue,
      },
    });
    setChargerDropdownList(
      getInitialSelectedChargers(discountDetails.resources),
    );
  };

  const handleDiscountSelection = (value: CheckBoxData[]) => {
    setIsValidDiscount(true);
    setDiscountOptions(value);
    const selectedDiscount = value.find((ele: CheckBoxData) => ele.selected);
    if (selectedDiscount) {
      setDiscountDetails({
        ...discountDetails,
        adjustmentType: PERCENTAGE,
        adjustmentPercentage: selectedDiscount?.value,
      });
      setDiscountType(selectedDiscount?.id || '');
    }
  };

  const validateEmailOrDomain = async (value: any) => {
    setShowPreExistDiscountMessage(false);
    const { type } = discountDetails.userGroupSelector;
    const isCompany = type === DOMAIN_NAME;
    const isEmail = validateEmail(value);
    const isDomain = validateDomainName(value);
    const payload = { type, value };
    let shouldTriggerValidateDiscount = false;
    if (isCompany && isDomain) {
      shouldTriggerValidateDiscount = true;
    } else if (isEmail) {
      await triggerCheckIfUserEmailExist(value);
      shouldTriggerValidateDiscount = true;
    }
    if (shouldTriggerValidateDiscount) {
      const res: any = await triggerValidateDiscount(payload);
      checkPreExistDiscount(discountDetails?.resources, res.data.entities);
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (value) validateEmailOrDomain(value);
    const userGroupSelector = {
      ...discountDetails.userGroupSelector,
      value,
    };
    if (discountDetails.userGroupSelector.type === DOMAIN_NAME) {
      setCompanyDiscountDetails({
        ...companyDiscountDetails,
        userGroupSelector,
      });
    } else {
      setDriverDiscountDetails({
        ...driverDiscountDetails,
        userGroupSelector,
      });
    }
    setDiscountDetails({
      ...discountDetails,
      userGroupSelector,
    });
  };

  return (
    <DiscountFormFields
      discountDetails={discountDetails}
      handleSwitchChanges={handleSwitchChanges}
      discountType={discountType}
      checkIsFormValid={checkIsFormValid}
      emailDoesNotExist={emailDoesNotExist}
      handleChange={handleChange}
      handleChargersChange={handleChargersChange}
      chargerDropdownList={chargerDropdownList}
      showPreExistDiscountMessage={showPreExistDiscountMessage}
      setDiscountDetails={setDiscountDetails}
      handleDiscountSelection={handleDiscountSelection}
      discountOptions={discountOptions}
      handleSaveClick={handleSaveClick}
      setIsValidDiscount={setIsValidDiscount}
      isValidDiscount={isValidDiscount}
      editDiscountQueryResult={editDiscountQueryResult}
      addDiscountQueryResult={addDiscountQueryResult}
      isEditMode={isEditMode}
    />
  );
};
