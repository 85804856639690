import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from './GlobalModal.component';
import {
  Button,
  ButtonType,
  Label,
  LabelType,
  Card,
  Icon,
  ColorType,
} from '..';
import { getConnectorIcon } from '../../Charger/utils';
import { ButtonSize } from '../Button.component';
import { MODAL_LAYER_INDEX } from './utils';
import { IconSize } from '../../../constant/IconSize.constant';
import { ExitDeleteRemoveClose } from '../../../assets/icons';

Modal.setAppElement('#root');

export const PortModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { t } = useTranslation();
  const { modalProps } = store;
  const {
    title,
    width = '360px',
    height = '286px',
    ports,
    button = {
      label: '',
      type: ButtonType.PRIMARY,
      size: ButtonSize.SMALL_FULL,
    },
  } = modalProps;

  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleClose = () => {
    hideModal();
  };

  const renderHeader = () => {
    return (
      <div className='flex items-center justify-center sticky top-0 h-16'>
        <Icon
          src={ExitDeleteRemoveClose}
          className='w-[28px] h-[28px] hover:bg-grey2 rounded-full flex items-center justify-center absolute left-2 cursor-pointer'
          onClick={handleClose}
        />
        <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
      </div>
    );
  };

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleButtonClick = () => {
    handleClose();
    button.onClick && button.onClick(ports[selectedIndex]);
  };
  const renderBody = () => {
    return (
      <div className='flex flex-row gap-3 mx-5'>
        {ports.map((port: any, index: number) => {
          const isSelected = index === selectedIndex;
          return (
            <div
              key={port.id}
              onClick={() => handleClick(index)}
              className='cursor-pointer bg'
              style={{ width: '154px' }}
            >
              <Card
                bg={isSelected ? 'bg-brand0' : 'bg-white'}
                className={`border-2 h-[8.875rem] flex flex-col items-center ${
                  isSelected ? 'border-brand2' : 'border-grey2'
                } p-0`}
              >
                <div className='mt-3'>
                  <Label
                    text={t(port.physicalLocation.toLowerCase())}
                    type={LabelType.LABEL_M}
                    className={isSelected ? 'text-brand2' : 'text-grey3'}
                  />
                </div>
                <div className='mt-2'>
                  {port.connectorTypes.map((type: any) => (
                    <div key={type} className='w-12 h-12 flex items-center'>
                      <Icon
                        size={IconSize.SIZE_48x48}
                        src={getConnectorIcon(type)}
                        color={isSelected ? ColorType.BLACK : ColorType.GREY6}
                      />
                    </div>
                  ))}
                </div>
                <div className='mt-3.5'>
                  <Label
                    text={port.connectorTypes[0]}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                    className={isSelected ? '' : 'text-grey3'}
                  />
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='mx-5 my-5'>
        <Button
          label={button.label}
          type={button.type}
          size={button.size}
          className='h-10'
          onClick={handleButtonClick}
        />
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <div className='flex flex-col px-4'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
