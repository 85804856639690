import React, { memo } from 'react';
import { CheckBoxGroup, GroupDirection, LabelType } from '..';
import { CheckBoxData } from './types/CheckBox-Column.interface';

interface RadioGroupProps {
  direction?: GroupDirection;
  defaultItems: CheckBoxData[];
  onChange: Function;
  translationOn?: boolean;
  itemHeight?: string;
  selectedLabelColor?: string;
  padding?: string;
  labelType?: LabelType;
  itemWidth?: string;
  dataTestId?: string;
}

const RadioGroup = ({
  direction = GroupDirection.Horizontal,
  defaultItems,
  onChange,
  selectedLabelColor,
  translationOn = false,
  itemHeight = '40px',
  itemWidth,
  labelType = LabelType.BODY3,
  padding,
  dataTestId = '',
}: RadioGroupProps) => (
  <CheckBoxGroup
    defaultItems={defaultItems}
    direction={direction}
    singleSelection
    onChange={onChange}
    translationOn={translationOn}
    itemHeight={itemHeight}
    selectedLabelColor={selectedLabelColor}
    padding={padding}
    labelType={labelType}
    itemWidth={itemWidth}
    dataTestId={dataTestId}
  />
);

RadioGroup.defaultProps = {
  direction: GroupDirection.Horizontal,
};

export default memo<RadioGroupProps>(RadioGroup);
