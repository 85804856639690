import { memo } from 'react';
import { Label, LabelType } from '../Label.component';
import { EventContent } from './Calendar.component';
import { ColorType } from '../index';

interface Props {
  event: EventContent;
  onClick?: Function;
}

export const CalendarItem = memo(({ event, onClick }: Props) => {
  const handleItemClick = () => {
    onClick && onClick(event);
  };
  return (
    <div
      className='rounded-lg w-full h-full flex flex-col bg-white p-[8px] hover:bg-grey1 hover:cursor-pointer'
      onClick={handleItemClick}
    >
      <Label
        text={event.title}
        type={LabelType.LABEL_XS}
        color={ColorType.BLACK}
      />
      <Label
        text={event.message}
        type={LabelType.BODY4}
        color={ColorType.GREY6}
      />
    </div>
  );
});
