import React, { useState } from 'react';

export interface TabProps {
  label: string; // title of tab
}

export interface TabsProps {
  children: any;
  onTabChange?: Function; // Callback function triggered when a tab is changed.
  rootClass?: string; // Additional CSS class for the root container of Tabs.
  tabClass?: string; // Additional CSS class for each individual tab.
}

/**
 * Tabs Component
 * @param {TabsProps} props - The properties for the Tabs component.
 * @returns {JSX.Element} - The rendered Tabs component.
 *
 * @usage
 * const MyTabs = () => {
 *   // Callback function for tab change
 *   const handleTabChange = (index: number) => {
 *     console.log(`Selected tab index: ${index}`);
 *   };
 *
 *   return (
 *     <Tabs onTabChange={handleTabChange} >
 *       <Tab label="Tab 1" />
 *       <Tab>
 *         {renderCustomTab()}
 *       </Tab>
 *     </Tabs>
 *   );
 * };
 */
const Tabs: React.FC<TabsProps> = ({
  children,
  onTabChange,
  rootClass = 'w-full',
  tabClass = '',
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
    onTabChange && onTabChange(index);
  };

  return (
    <div className={`flex justify-around ${rootClass}`}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child as any, {
          index,
          selected: index === selectedTabIndex,
          onClick: handleTabChange,
          className: tabClass,
        });
      })}
    </div>
  );
};

export default Tabs;
