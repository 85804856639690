import { ColorType, Label, LabelType } from '../../_ui';

interface Props {
  value?: number;
}

export const Load = ({ value }: Props) => {
  return (
    <div className='flex flex-row items-baseline'>
      <Label
        text={value}
        type={LabelType.LABEL_M_MEDIUM}
        color={ColorType.BLACK}
      />
      <Label
        className='ml-1'
        text='LOADS'
        type={LabelType.LABEL_XXS}
        color={ColorType.GREY5}
      />
    </div>
  );
};
