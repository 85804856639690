import countries from './country.json';

const getAllCountries = () => {
  return countries;
};

const getCountryByCode = (isoCode: string) => {
  return countries.filter((country) => country.isoCode === isoCode);
};

export default {
  getAllCountries,
  getCountryByCode,
};
