import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  Breaker as BreakerObj,
  Circuit,
  DropdownOption,
} from '../../stores/types/pm.interface';

import {
  AddPlus2,
  ChargerEV,
  ChevronDown,
  ChevronUp,
  More,
  breaker,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { USER_ROLE, useAuth } from '../../hooks';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import {
  useDeleteBreakerMutation,
  useDeleteCircuitMutation,
} from '../../services/pm.api';
import { i18n } from '../../services/translation/i18n';
import { SCOPE_TYPE } from '../../services/utils';
import { Charger as ChargerObj } from '../../stores/types';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  useGlobalModalContext,
} from '../_ui';
import { BreakerEditor } from './BreakerEditor.component';
import LimitExceededWarning from './LimitExceededWarning/LimitExceededWarning.component';
import { Limit } from './LocationDetailsHeader/Limit';
import { Load } from './LocationDetailsHeader/Load';
import { Phase } from './LocationDetailsHeader/Phase';
import { Rating } from './LocationDetailsHeader/Rating';
import { Volt } from './LocationDetailsHeader/Volt';
import { MainBreakerDetailsModal } from './MainBreakerDetailsModal';
import { SubBreakerDetailsModal } from './SubBreakerDetailsModal';
import { LoadStepOne } from './SubBreakerLoad/LoadStepOne';
import { LoadStepTwo } from './SubBreakerLoad/LoadStepTwo';
import { SubBreakerModal } from './SubBreakerModal';
import { checkIfBreakerWarning } from './utils';

interface Props {
  children?: any;
  data: {
    breakerData: BreakerObj;
    parentBreakerData?: BreakerObj; // Should be mandatory field for sub-breaker
    circuitData: Circuit;
    parentBreakerOptions: DropdownOption[];
  };
  style?: any;
  index?: any;
  className?: string;
  isLocationExpanded: boolean;
  notPowerManagedChargers: ChargerObj[];
  showWarning: Function;
  locationId: string;
}

export const Breaker = ({
  data,
  children,
  style,
  className,
  index,
  isLocationExpanded,
  notPowerManagedChargers,
  showWarning,
  locationId,
}: Props) => {
  const [isCardExpanded, setIsCardExpanded] = useState<any>(
    isLocationExpanded ? { [index]: isLocationExpanded } : {},
  );
  const [isDisableButton, setIsDisableButton] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const [deleteSubBreaker] = useDeleteBreakerMutation();
  const [deleteMainBreaker] = useDeleteCircuitMutation();
  const [companyId] = useQueryParam('companyId', StringParam);
  const { allowPowermanEdit } = useAllowedFeatures();

  const handleEditBreaker = (breakerModelData: {
    breaker: BreakerObj;
    circuit: Circuit;
  }) => {
    hideModal();

    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('pm_edit_main_breaker'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <BreakerEditor
          locationID={breakerModelData.circuit.locationId}
          breaker={breakerModelData.breaker}
          circuit={breakerModelData.circuit}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };
  const handleViewBreakerDetailsBtn = (breakerModelData: {
    breaker: BreakerObj;
    circuit: Circuit;
  }) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('main_brekaer_modal_title'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => <MainBreakerDetailsModal data={breakerModelData} />,
    });
  };

  const handleAddSubBreaker = (isEdit = false) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: isEdit ? t('edit_sub_breaker_label') : t('add_sub_breaker_label'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <SubBreakerModal
          data={{
            breaker: data.breakerData,
            circuit: data.circuitData,
            parentBreaker: data?.parentBreakerData,
            parentBreakerOptions: data.parentBreakerOptions,
          }}
          companyId={companyId}
          isEdit={isEdit}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleViewSubBreakerDetailsBtn = (subBreakerModalData: {
    breaker: BreakerObj;
    parentBreaker?: BreakerObj;
    circuit: Circuit;
  }) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('sub_brekaer_modal_title'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => <SubBreakerDetailsModal data={subBreakerModalData} />,
    });
  };

  const handleDeleteBreaker = (modalData: {
    breakerData: BreakerObj;
    parentBreakerData?: BreakerObj;
    circuitData: Circuit;
  }) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: modalData.parentBreakerData
        ? `${t('delete_sub_breaker_label')}?`
        : `${t('delete_main_breaker_label')}?`,
      width: '400px',
      height: 'max-content',
      message: modalData.parentBreakerData
        ? t('delete_sub_breaker_menu_desc')
        : t('delete_main_breaker_menu_desc'),
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          dataTestId: 'cancelConfirm',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          dataTestId: 'deleteConfirm',
          onClick: () => {
            if (!modalData.parentBreakerData) {
              deleteMainBreaker({
                circuitId: modalData.circuitData.id,
                scope: SCOPE_TYPE.COMPANY,
                companyId: companyId || '',
              });
            } else {
              deleteSubBreaker({
                breakerId: modalData.breakerData.id,
                scope: SCOPE_TYPE.COMPANY,
                companyId: companyId || '',
              });
            }
          },
        },
      ],
    });
  };

  const renderDotsMenu = () => {
    return (
      <Menu
        contentClassName='w-[204px]'
        rootClassName='mt-2'
        placement='bottom-end'
        icon={More}
        dataTestId='menuWrapper'
      >
        <MenuItem
          dataTestId='viewSubBreaker'
          label={
            data.parentBreakerData
              ? t('sub_breaker_popup_menu_label_1')
              : t('main_breaker_popup_menu_label_1')
          }
          onClick={
            data.parentBreakerData
              ? () =>
                  handleViewSubBreakerDetailsBtn({
                    breaker: data.breakerData,
                    parentBreaker: data.parentBreakerData,
                    circuit: data.circuitData,
                  })
              : () =>
                  handleViewBreakerDetailsBtn({
                    breaker: data.breakerData,
                    circuit: data.circuitData,
                  })
          }
        />
        {(auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
          <>
            <MenuItem
              dataTestId={
                data.parentBreakerData ? 'editSubbreaker' : 'editMainBreaker'
              }
              label={
                data.parentBreakerData
                  ? t('edit_sub_breaker')
                  : t('edit_main_breaker')
              }
              onClick={
                data.parentBreakerData
                  ? () => {
                      showWarning(locationId, (actionValue: boolean) => {
                        if (actionValue) {
                          handleAddSubBreaker(true);
                        }
                      });
                    }
                  : () => {
                      showWarning(locationId, (actionValue: boolean) => {
                        if (actionValue) {
                          handleEditBreaker({
                            breaker: data.breakerData,
                            circuit: data.circuitData,
                          });
                        }
                      });
                    }
              }
            />
            <MenuItem
              dataTestId='deleteSubBreaker'
              label={
                data.parentBreakerData
                  ? t('delete_sub_breaker_label')
                  : t('delete_main_breaker_label')
              }
              labelColor={ColorType.NEGATIVE1}
              onClick={() => {
                showWarning(locationId, (actionValue: boolean) => {
                  if (actionValue) {
                    handleDeleteBreaker(data);
                  }
                });
              }}
              disabled={
                !!data.breakerData.subBreakers.length ||
                !!data.breakerData.unmanagedLoads.length
              }
            />
          </>
        )}
      </Menu>
    );
  };

  const renderBreakerTitle = () => {
    return (
      <div
        className='flex flex-row items-center gap-x-2'
        data-testid={data.breakerData.name}
      >
        <Icon src={breaker} size={IconSize.SIZE_20x20} />
        <Label
          text={data.breakerData.name}
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          dataTestId='mainBreakerTitle'
        />
        <LimitExceededWarning
          isWarning={checkIfBreakerWarning([data?.breakerData])}
          label={t('limit_exceeded')}
          error={t('amperage_limit_of_breaker_exceeded')}
          breakerName={data.breakerData.name}
        />
        {/* Hides chev icon in main breaker as per design */}
        {/* Hides chev icon in sub breaker when no load is present */}
        {data.parentBreakerData && data?.breakerData.noOfLoads > 0 && (
          <div
            className='cursor-pointer'
            onClick={() => {
              setIsCardExpanded({
                ...isCardExpanded,
                [index]: !isCardExpanded[index],
              });
            }}
          >
            <Icon
              src={isCardExpanded[index] ? ChevronUp : ChevronDown}
              key={
                isCardExpanded[index]
                  ? `expanded-${data.breakerData.id}`
                  : `collapsed-${data.breakerData.id}`
              }
            />
          </div>
        )}
      </div>
    );
  };

  const renderBreakerDetails = () => {
    return (
      <div className='flex items-center gap-x-4 px-8'>
        <Rating value={data.breakerData.breakerRating} />
        <Limit value={data.breakerData.continuousLoadLimit} />
        <Load value={data.breakerData.noOfLoads} />
        {!data.parentBreakerData && data.circuitData.electricalSupply && (
          <Phase value={data.circuitData.electricalSupply} isElectricSupply />
        )}
        {!data.parentBreakerData && data.circuitData.lineVoltage && (
          <Volt value={data.circuitData.lineVoltage} />
        )}
      </div>
    );
  };

  const handleAddBreakerLoad = () => {
    showModal(MODAL_TYPES.WIZARD_MODAL, {
      width: '540px',
      height: 'max-content',
      stepTitles: [
        i18n.t('add_load_modal_title', { name: data.breakerData.name }),
        i18n.t('add_load_modal_title', { name: data.breakerData.name }),
      ],
      stepComponents: [
        <LoadStepOne
          data={{
            breaker: data.breakerData,
            circuit: data.circuitData,
          }}
          companyId={companyId}
        />,
        <LoadStepTwo
          notPowerManagedChargers={notPowerManagedChargers}
          data={{
            breaker: data.breakerData,
            circuit: data.circuitData,
          }}
          companyId={companyId}
        />,
      ],
      shouldCloseOnOverlayClick: false,
    });
  };

  useEffect(() => {
    function findSubBreakerLevel(
      breakers: BreakerObj[],
      targetId: string,
      parentId: null | string = null,
      level = 1,
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const breakerData of breakers) {
        if (breakerData.id === targetId) {
          return level;
        }
        if (breakerData.subBreakers.length > 0) {
          const childLevel: number = findSubBreakerLevel(
            breakerData.subBreakers,
            targetId,
            breakerData.id,
            level + 1,
          );
          if (childLevel !== -1) {
            return childLevel;
          }
        }
      }
      return -1;
    }

    const breakerLevel = findSubBreakerLevel(
      data.circuitData?.mainBreakers || [],
      data.breakerData.id,
    );
    if (breakerLevel !== -1 && breakerLevel >= 6) {
      setIsDisableButton(true);
    }
  }, [data]);

  return (
    <div key={data.breakerData.id} style={style} className={className}>
      {!data.breakerData.dedicatedBreaker && (
        <div
          className='flex flex-row justify-between items-center'
          data-testid='breakerWrapper'
        >
          <div className='flex flex-col gap-y-[2px]'>
            {renderBreakerTitle()}
            {renderBreakerDetails()}
          </div>
          <div className='flex justify-between gap-4'>
            {(auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
              <div className='flex flex-row justify-between gap-2'>
                <Button
                  label={t('add_sub_breaker_btn')}
                  labelType={LabelType.LABEL_S}
                  labelColor={ColorType.GREY6}
                  size={ButtonSize.SMALL}
                  type={ButtonType.OUTLINE}
                  labelIcon={breaker}
                  labelIconSize={IconSize.SIZE_20x20}
                  labelIconColor={ColorType.GREY6}
                  icon={AddPlus2}
                  iconSize={IconSize.SIZE_20x20}
                  iconColor={ColorType.GREY5}
                  className='gap-1'
                  onClick={() => {
                    showWarning(locationId, (actionValue: boolean) => {
                      if (actionValue) {
                        handleAddSubBreaker();
                      }
                    });
                  }}
                  disabled={
                    data.breakerData.subBreakers.length >= 100 ||
                    isDisableButton
                  }
                  dataTestId='addSubBreakerBtn'
                />
                <Button
                  label={t('add_load_btn')}
                  labelType={LabelType.LABEL_S}
                  labelColor={ColorType.GREY6}
                  size={ButtonSize.SMALL}
                  type={ButtonType.OUTLINE}
                  labelIcon={ChargerEV}
                  labelIconSize={IconSize.SIZE_20x20}
                  labelIconColor={ColorType.GREY6}
                  icon={AddPlus2}
                  iconSize={IconSize.SIZE_20x20}
                  iconColor={ColorType.GREY5}
                  className='gap-1'
                  onClick={() => {
                    showWarning(locationId, (actionValue: boolean) => {
                      if (actionValue) {
                        handleAddBreakerLoad();
                      }
                    });
                  }}
                  disabled={data.breakerData.noOfLoads >= 50}
                  dataTestId='addLoadsBtn'
                />
              </div>
            )}
            {renderDotsMenu()}
          </div>
        </div>
      )}
      {isCardExpanded[index] ? children : null}
    </div>
  );
};
