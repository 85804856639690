import { memo, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Company, User, Invite } from '../../stores/types';
// Components
import {
  Label,
  Card,
  LabelType,
  Dropdown,
  Grid,
  Pill,
  DropdownType,
  Icon,
  Button,
  ButtonType,
  ButtonSize,
  Skeleton,
  Menu,
  MenuItem,
  ColorType,
} from '../_ui';
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from '../_ui/modal/GlobalModal.component';
import { useAuth, USER_ROLE } from '../../hooks';
import { TeamUserEditor } from './TeamUserEditor.component';
import { CancelInvites } from './CancelInvites.component';
import {
  useCreateInvitesMutation,
  useDeleteUserMutation,
} from '../../services/usergroups.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { IconSize } from '../../constant/IconSize.constant';
import { AddPlus2, CheckDone, Info, More } from '../../assets/icons';

interface TeamInfoProp {
  users: User[] | undefined;
  invites: Invite[] | undefined;
  company: Company | undefined;
  isUserLoading: boolean;
  isInviteLoading: boolean;
  triggerFetchInvites: Function;
}
const ROW_PER_PAGE = 10;
const SECONDS_1_DAY = 60 * 60 * 24;
export const TeamInfo = memo(
  ({
    users,
    invites,
    company,
    isUserLoading = false,
    isInviteLoading = false,
    triggerFetchInvites,
  }: TeamInfoProp) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const { showModal } = useGlobalModalContext();
    const [createInvite] = useCreateInvitesMutation();
    const [deleteUser] = useDeleteUserMutation();
    const theme = useSelector(getCurrentTheme);

    const hanldeAddUser = () => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('company_invite_user'),
        overflow: 'auto',
        width: '540px',
        height: 'fit-content',
        onRenderBody: () => (
          <TeamUserEditor triggerFetchInvites={triggerFetchInvites} />
        ),
        shouldCloseOnOverlayClick: false,
      });
    };

    const renderHeader = () => {
      return (
        <div className='flex flex-row w-full justify-between items-center'>
          <div className='flex flex-row items-baseline gap-2'>
            <Label
              text={t('company_team')}
              type={LabelType.H4}
              color={ColorType.BLACK}
            />
            {isUserLoading && (
              <Skeleton
                height='h-5'
                width='w-60'
                innerClassName='items-center'
              />
            )}
            {!isUserLoading && (
              <div className='text-sm font-normal font-inter text-grey5'>
                {(users?.length || 0) < 10 ? (
                  <Trans
                    i18nKey='company_team_showing_users_below_10'
                    values={{
                      number: users?.length || 0,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey='company_team_showing_users'
                    values={{
                      page: (currentPage - 1) * ROW_PER_PAGE + 1,
                      number:
                        currentPage * ROW_PER_PAGE > (users?.length || 0)
                          ? users?.length || 0
                          : ROW_PER_PAGE,
                      total: users?.length || 0,
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <Button
            icon={AddPlus2}
            onClick={hanldeAddUser}
            label={t('company_invite_user')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            dataTestId='inviteUserButton'
          />
        </div>
      );
    };

    const onDeleteUser = (user: User) => {
      if (company?.adminUserGroupId && user.email) {
        deleteUser({ email: user.email, uuid: company?.adminUserGroupId });
      }
    };
    const handleDeleteUser = (user: User) => {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: t('company_remove_user_title'),
        width: '400px',
        height: '176px',
        message: (
          <Trans
            i18nKey='company_remove_user_content'
            values={{ name: user.name }}
          />
        ),
        iconColor: ColorType.NEGATIVE1,
        buttons: [
          {
            label: t('cancel'),
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
          },
          {
            label: t('company_remove_user'),
            type: ButtonType.DESTRUCTIVE,
            size: ButtonSize.SMALL,
            onClick: () => onDeleteUser(user),
          },
        ],
      });
    };

    const getColumnTitle = () => {
      return [
        {
          key: 'name',
          title: t('company_team_name'),
          // Please don't remove this, for support user in future
          // component: (row: any) => (
          //   <div className='whitespace-nowrap w-[160px]'>
          //     <Pill
          //       label={row.name}
          //       labelType={LabelType.LABEL_S_G6}
          //       bgColor={PILL_BG_COLOR.LIGHT}
          //     />
          //   </div>
          // ),
        },
        {
          key: 'phone',
          title: t('company_team_phone'),
          class: 'w-[200px]',
        },
        {
          key: 'email',
          title: t('company_team_email'),
        },
        {
          key: 'button',
          title: '',
          component: (row: any) => (
            <div className='flex flex-row gap-[16px] justify-end pr-2'>
              <Menu placement='bottom-end' icon={More}>
                <MenuItem
                  label={`${t('company_remove_user')} ${row.name || ''}`}
                  labelType={LabelType.BODY3}
                  labelColor={ColorType.NEGATIVE1}
                  onClick={() => handleDeleteUser(row)}
                />
              </Menu>
            </div>
          ),
        },
      ];
    };
    const data = useMemo(() => {
      if (users && users.length > 0) {
        const startIndex = (currentPage - 1) * ROW_PER_PAGE;
        return users.slice(startIndex, startIndex + ROW_PER_PAGE);
      }
      return [];
    }, [currentPage, users]);

    const handleCancelInvite = (row: Invite) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('company_cancel_invite'),
        width: '380px',
        height: '276px',
        onRenderBody: () => <CancelInvites invite={row} company={company} />,
        shouldCloseOnOverlayClick: false,
      });
    };

    const handlResendInvite = (invite: Invite) => {
      if (
        company?.adminUserGroupId &&
        invite.timeSinceLastInviteSecond >= SECONDS_1_DAY
      ) {
        createInvite({ email: invite.email, uuid: company.adminUserGroupId });
      }
    };

    const getInvitesColumnTitle = () => {
      return [
        {
          key: 'email',
          title: t('company_email'),
          component: (row: any) => (
            <Label
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              text={t(row.email)}
              isLoading={isInviteLoading}
              skeletonHeight='h-4'
              skeletonWidth='w-14'
              dataTestId={row.email}
            />
          ),
        },
        {
          key: 'status',
          title: t('company_invite_status'),
          class: 'w-[118px]',
          component: (row: any) => (
            <Label
              text={t(row.status?.toLowerCase())}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isInviteLoading}
            />
          ),
        },
        {
          key: 'dateRequested',
          title: t('company_invite_date_requested'),
          class: 'w-[129px]',
        },
        {
          key: 'button',
          title: '',
          component: (row: any) => (
            <div className='flex flex-row gap-[16px] justify-end'>
              <Button
                onClick={() => handleCancelInvite(row)}
                label={t('company_cancel_invite')}
                type={ButtonType.OUTLINE}
                size={ButtonSize.SMALL}
                dataTestId={`cancel-${row.email}`}
              />
              <Button
                icon={
                  row.timeSinceLastInviteSecond < SECONDS_1_DAY
                    ? CheckDone
                    : undefined
                }
                onClick={() => handlResendInvite(row)}
                label={
                  row.timeSinceLastInviteSecond < SECONDS_1_DAY
                    ? t('company_invite_sent')
                    : t('company_resend_invite')
                }
                type={ButtonType.OUTLINE}
                disabled={row.timeSinceLastInviteSecond < SECONDS_1_DAY}
                size={ButtonSize.SMALL}
                dataTestId={`resend-${row.email}`}
              />
            </div>
          ),
        },
      ];
    };

    const renderUserGrid = () => {
      return (
        <Grid
          pageIndex={currentPage}
          loadPage={(page: number) => setCurrentPage(page)}
          columns={getColumnTitle()}
          data={data}
          primaryKey='email'
          totalPage={Math.ceil((users?.length || 0) / ROW_PER_PAGE)}
          isLoading={isUserLoading}
        />
      );
    };

    const isAnyResendAvailable = useMemo(() => {
      return invites?.some(
        (invite) => invite.timeSinceLastInviteSecond >= SECONDS_1_DAY,
      );
    }, [invites]);

    const handleResendAllInvites = () => {
      if (company?.adminUserGroupId && invites && invites.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const invite of invites) {
          if (invite.timeSinceLastInviteSecond >= SECONDS_1_DAY) {
            createInvite({
              email: invite.email,
              uuid: company.adminUserGroupId,
            });
          }
        }
      }
    };

    const renderPendingInvites = () => (
      <Card className='border-2 border-grey2 mt-[24px]'>
        <div className='flex flex-col'>
          <div className='flex flex-row justify-between w-full'>
            <div className='flex flex-row items-center gap-[10px]'>
              <Icon
                size={IconSize.SIZE_24x24}
                src={Info}
                color={theme.navigationSelectedColor}
              />
              <div className='font-inter text-black text-xl font-semibold'>
                <Trans
                  i18nKey={
                    (invites?.length || 0) > 1
                      ? 'company_pending_invites_plural'
                      : 'company_pending_invites'
                  }
                  values={{
                    number: invites?.length || 0,
                  }}
                />
              </div>
            </div>
            <Button
              onClick={handleResendAllInvites}
              label={t('company_resend_all_invites')}
              type={ButtonType.SECONDARY}
              disabled={!isAnyResendAvailable}
              size={ButtonSize.SMALL}
            />
          </div>

          <div className='mt-[20px]'>
            <Grid
              pageIndex={1}
              columns={getInvitesColumnTitle()}
              data={invites}
              primaryKey='email'
              totalPage={1}
              hidePager
              hasBottomBorder={false}
              isLoading={isInviteLoading}
              skeletonRowCount={1}
            />
          </div>
        </div>
      </Card>
    );
    return (
      <div className='flex flex-col' data-testid='teamInfo'>
        {renderHeader()}
        {invites && invites?.length > 0 && renderPendingInvites()}
        <div className='mt-[24px]'>{renderUserGrid()}</div>
      </div>
    );
  },
);
