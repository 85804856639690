import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Label,
  LabelType,
  Snackbar,
  useGlobalModalContext,
} from '../../_ui';
import { useMoveChargerMutation } from '../../../services/charger.api';
import {
  AlertPosition,
  AlertType,
} from '../../_ui/snack-bar/Snack-Bar.component';
import { CheckMark } from '../../../assets/icons';

export const ChargerConfirmation = (props: any) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { wizardState, onClose } = props;
  const [triggerMoveChargerApi] = useMoveChargerMutation();

  const handleMoveAction = () => {
    const toastData = {
      chargerName: wizardState.chargerInfo?.name,
      companyName: wizardState?.selCompany?.label,
    };
    const apiData = {
      ...wizardState,
      companyId: wizardState.selCompany?.id,
      locationId: wizardState.selLocation?.id,
      priceModelId: wizardState.selPriceModel?.id,
    };
    delete apiData.chargerInfo;
    delete apiData.companyInfo;
    delete apiData.selCompany;
    delete apiData.selLocation;
    delete apiData.selPriceModel;
    delete apiData.pricingModels;
    triggerMoveChargerApi({ payload: apiData, toast: toastData });
    hideModal();
    onClose && onClose();
  };

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex'>
        <Label
          text={t('charger_move_action_alert')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text='Charger ID'
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={wizardState.chargerInfo?.name}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('chargers_destination_company')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={wizardState.selCompany?.label}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('chargers_destination_location')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={wizardState.selLocation?.label}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('charger_auto_start')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={t('disabled')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('chargers_map_visibility')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={
            wizardState.hidden
              ? t('charger_map_visibility_false')
              : t('chargers_map_visibility')
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      {wizardState.directions && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('chargers_directions')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.directions}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      {wizardState.note && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('charger_internal_note')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.note}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      {wizardState.installer && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('chargers_installer')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.installer}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      {wizardState.installerContact && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('charger_installer_email')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.installerContact}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      {wizardState.installationDate && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('chargers_installation_date')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.installationDate}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      <div className='flex flex-row gap-9'>
        <Label
          text={t('charger_deployment_status')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={
            wizardState.deployed
              ? t('charger_deployment_status_true')
              : t('charger_deployment_status_false')
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      {wizardState.deploymentDate && (
        <div className='flex flex-row gap-9'>
          <Label
            text={t('charger_deployment_date')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[144px]'
          />
          <Label
            text={wizardState.deploymentDate}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      <div className='flex flex-row gap-9'>
        <Label
          text={t('pricing_rules')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={`${wizardState.selPriceModel?.label} (${wizardState.selLocation?.label})`}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('discounts')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={t('none')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row gap-9'>
        <Label
          text={t('access')}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='w-[144px]'
        />
        <Label
          text={t('public')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-col mb-5'>
        <Button
          label={t('charger_move_action')}
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL_FULL}
          onClick={handleMoveAction}
        />
      </div>
    </div>
  );
};
