import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { header } from './utils';
import { header } from './http/http.service';
import { Depolyment } from '../stores/types';
import { Environment } from '../stores/types/deployment.interface';

const API_KEY = 'deployment';

export const deploymentApi = createApi({
  reducerPath: API_KEY,
  baseQuery: fetchBaseQuery({
    headers: header,
    baseUrl: `/${API_KEY}`,
  }),
  endpoints: (builder) => ({
    fetchDeployment: builder.query<Depolyment, void>({
      query: () => ({
        url: `api?hostname=${window.location.hostname}`,
        method: 'GET',
      }),
    }),
    fetchEnvironment: builder.query<Environment, void>({
      query: () => ({
        url: 'environment',
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchDeploymentQuery, useFetchEnvironmentQuery } =
  deploymentApi;
