import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { PayterProvider } from '../../hooks/usePayter';
import PayoutHistory from './PayoutHistory/PayoutHistory.component';

import Payter from './Payter.component';

export const Payouts = () => {
  const { allowPayterView } = useAllowedFeatures();

  return allowPayterView ? (
    <PayterProvider>
      <Payter />
    </PayterProvider>
  ) : (
    <PayoutHistory />
  );
};
