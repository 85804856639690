import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLink, QuestionHelp } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { useAuth } from '../../../hooks';
import { useCompany } from '../../../hooks/useCompany';
import { Button, ButtonType } from '../Button.component';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { MODAL_TYPES, useGlobalModalContext } from '../modal';
import { ColorType } from '../shared/Colors.g';

const HELP_CENTER_URL = 'https://chargelab.zendesk.com/hc/en-us/';
const CONTACT_SUPPORT_URL =
  'https://chargelab.zendesk.com/hc/en-us/requests/new?ticket_form_id=23731149080859';

const HelpCenter = () => {
  const { showModal, hideModal } = useGlobalModalContext();
  const { t } = useTranslation();

  const { companies: companylist, companyId, isMultiViewActive } = useCompany();

  const auth = useAuth();

  const repleceSpaceWithPlus = (name: string) => {
    return name.split(' ').join('+');
  };

  const urlParams = useMemo(() => {
    let str = `${CONTACT_SUPPORT_URL}&tf_anonymous_requester_email=${auth.user.attributes.email}`;

    if (isMultiViewActive) {
      str += '&tf_1260827747190=Multiple&tf_28410218697115=Multiple';
    } else {
      const companyDetails = companylist?.find((ele) => ele?.id === companyId);
      const channelPartnerDetails = companylist?.find(
        (ele) => ele?.id === companyDetails?.businessInfo?.channelPartnerId,
      );
      str += `&tf_1260827747190=${repleceSpaceWithPlus(
        companyDetails?.name || '',
      )}&tf_28410218697115=${repleceSpaceWithPlus(
        channelPartnerDetails?.name || '',
      )}`;
    }
    return str;
  }, [companyId, companylist, auth]);

  const onCLick = (link: string) => {
    window.open(link);
    hideModal();
  };

  const openHelpModel = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('get_help'),
      overflow: 'auto',
      width: '540px',
      height: 'fit-content',
      onRenderBody: () => (
        <>
          <div className='mb-2'>
            <Label
              text={t('help_resources')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
          </div>
          <div className='mb-2'>
            <Label
              text={t('help_resources_desc1')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='h-[40px]'>
            <Button
              label={t('visit_help_center')}
              type={ButtonType.OUTLINE}
              icon={ExternalLink}
              iconRightAlign
              iconColor={ColorType.GREY5}
              labelContainerCss='grow-0'
              onClick={() => onCLick(HELP_CENTER_URL)}
            />
          </div>
          <div className='mt-6 mb-2'>
            <Label
              text={t('support')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
          </div>
          <div className='mb-2'>
            <Label
              text={t('help_resources_desc2')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='h-[40px] mb-5'>
            <Button
              label={t('contact_support')}
              type={ButtonType.OUTLINE}
              icon={ExternalLink}
              iconRightAlign
              iconColor={ColorType.GREY6}
              labelContainerCss='grow-0'
              onClick={() => onCLick(urlParams)}
            />
          </div>
        </>
      ),
      onClose: hideModal,
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <div
      className='flex items-center gap-2
          hover:bg-grey2 py-1 px-1 xl:px-2 rounded-full xl:rounded-lg flex-row justify-center cursor-pointer'
      onClick={openHelpModel}
    >
      <Label
        className='hidden xl:block'
        text={t('help_text')}
        type={LabelType.LABEL_S}
        color={ColorType.GREY6}
      />
      <Icon
        src={QuestionHelp}
        size={IconSize.SIZE_24x24}
        onClick={openHelpModel}
      />
    </div>
  );
};

export default HelpCenter;
