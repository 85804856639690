import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargerEV } from '../../../assets/icons';
import { NUMBER } from '../../../constant/Number.constant';
import { useCompany } from '../../../hooks/useCompany';
import { useFetchLocationsQuery } from '../../../services/location.api';
import { Charger } from '../../../stores/types/pm.interface';
import { ColorType, Label, LabelType, Pill } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

interface IProps {
  connectedChargerIds: string[];
  isTerminalDataLoading: boolean;
  isChargerDetails?: boolean;
}

const ConnectedChargers = ({
  connectedChargerIds,
  isTerminalDataLoading,
  isChargerDetails,
}: IProps) => {
  const { t } = useTranslation();
  const [showAllCharger, setShowAllChargers] = useState(false);

  const { userScope } = useCompany();
  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint?.data ? endPoint.data.entities : [],
      }),
    },
  );

  const allChargers = useMemo(() => {
    const chargerArray: any[] = [];
    locations.forEach((location) => {
      location.chargers?.forEach((charger) => {
        chargerArray.push(charger);
      });
    });
    return chargerArray;
  }, [locations]);

  const connectedChargersList = useMemo(() => {
    const chargerArray = allChargers.filter((charger: any) =>
      connectedChargerIds?.includes(charger.id),
    );
    if (!showAllCharger) {
      return chargerArray.slice(0, NUMBER.FOUR);
    }
    return chargerArray;
  }, [allChargers, showAllCharger, connectedChargerIds]);

  const renderPayoutViewChargers = () =>
    connectedChargersList?.length ? (
      <>
        <div className='flex flex-wrap gap-1'>
          {connectedChargersList.map((child: any) => (
            <Pill
              label={child.displayName}
              key={child.displayName}
              iconLeft={ChargerEV}
            />
          ))}
        </div>
        {connectedChargersList.length > NUMBER.FOUR && (
          <Label
            text={
              showAllCharger
                ? t('view_fewer_chargers_button')
                : t('view_more_chargers_button', {
                    number: connectedChargersList.length - NUMBER.FOUR,
                  })
            }
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BRAND2}
            className='mt-2 pl-2 pr-2'
            onClick={() => setShowAllChargers((state) => !state)}
          />
        )}
      </>
    ) : (
      <Label
        isLoading={isTerminalDataLoading}
        text={t('none_linked')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />
    );

  const renderChargerViewTerminal = () => {
    return (
      connectedChargerIds?.length > 0 && (
        <div className='flex flex-wrap gap-1 items-center'>
          {connectedChargersList.slice(0, 1).map((child: Charger) => {
            return (
              <Tooltip placement='top' key={child.displayName}>
                <TooltipTrigger>
                  <Pill
                    label={child.displayName}
                    iconLeft={ChargerEV}
                    labelClass='w-[45px] truncate'
                  />
                </TooltipTrigger>
                <TooltipContent style={{ maxWidth: 'fit-content' }}>
                  <Label
                    text={child.displayName}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            );
          })}
          {connectedChargersList.length > 1 && (
            <Label
              text={`+ ${connectedChargersList.length - 1} ${t('more')}`}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.GREY5}
            />
          )}
        </div>
      )
    );
  };

  return isChargerDetails
    ? renderChargerViewTerminal()
    : renderPayoutViewChargers();
};

export default ConnectedChargers;
