import { memo, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Company, User, Invite, Location } from '../../stores/types';
// Components
import {
  Label,
  Card,
  LabelType,
  Dropdown,
  Grid,
  Pill,
  DropdownType,
  Icon,
  Button,
  ButtonType,
  ButtonSize,
  Skeleton,
  Menu,
  MenuItem,
  ColorType,
} from '../_ui';
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from '../_ui/modal/GlobalModal.component';
import { useAuth, USER_ROLE } from '../../hooks';
import { LocationEditor } from './LocationEditor.component';
import { IconSize } from '../../constant/IconSize.constant';
import { AddPlus2, More } from '../../assets/icons';

interface Props {
  locations: Location[] | undefined;
  isLocationLoading?: boolean;
  company: Company | undefined;
}

const ROW_PER_PAGE = 10;

export const LocationInfo = memo(
  ({ locations, isLocationLoading = false, company }: Props) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const { showModal, hideModal } = useGlobalModalContext();

    const handleAddLocationClick = () => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('company_add_location'),
        width: '540px',
        height: 'max-content',
        onRenderBody: () => <LocationEditor company={company} />,
        shouldCloseOnOverlayClick: false,
      });
    };

    const renderHeader = () => {
      return (
        <div className='flex flex-row w-full justify-between items-center'>
          <div className='flex flex-row items-baseline gap-2'>
            <Label
              text={t('company_locations')}
              type={LabelType.H4}
              color={ColorType.BLACK}
            />
            {isLocationLoading && (
              <Skeleton
                height='h-5'
                width='w-60'
                innerClassName='items-center'
              />
            )}
            {!isLocationLoading && (
              <div className='text-sm font-normal font-inter text-grey5'>
                {(locations?.length || 0) < 10 ? (
                  <Trans
                    i18nKey='company_number_locations'
                    values={{
                      number: locations?.length || 0,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey='company_number_locations_gt_10'
                    values={{
                      page: (currentPage - 1) * ROW_PER_PAGE + 1,
                      number:
                        currentPage * ROW_PER_PAGE > (locations?.length || 0)
                          ? locations?.length || 0
                          : ROW_PER_PAGE,
                      total: locations?.length || 0,
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <Button
            icon={AddPlus2}
            label={t('company_add_location')}
            onClick={handleAddLocationClick}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            dataTestId='addNewLocation'
          />
        </div>
      );
    };

    const handleEditClick = (row: Location) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('location_edit'),
        width: '540px',
        height: 'max-content',
        onRenderBody: () => (
          <LocationEditor defaultLocation={row} company={company} />
        ),
        shouldCloseOnOverlayClick: false,
      });
    };

    const getColumnTitle = () => {
      return [
        {
          key: 'name',
          title: t('company_location_name'),
        },
        {
          key: 'email',
          title: t('company_team_number_chargers'),
          component: (row: any) => (
            <Label
              className='whitespace-nowrap'
              text={`${row.chargers?.length || 0} ${t(
                'company_locations_chargers',
              )}`}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLocationLoading}
              skeletonHeight='h-5'
            />
          ),
        },
        {
          key: 'address',
          title: t('company_location_address'),
          component: (row: any) => (
            <Label
              className='whitespace-nowrap'
              text={`${row.streetAddress}, ${row.city}, ${row.stateOrRegion}, ${row.zipOrPostalCode}`}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLocationLoading}
              skeletonHeight='h-5'
            />
          ),
        },
        {
          key: 'button',
          title: t(''),
          component: (row: Location) => (
            <div className='flex flex-row gap-[16px] justify-end pr-2'>
              <Menu
                placement='bottom-end'
                icon={More}
                dataTestId={`locationMenuBar-${row.name}`}
              >
                <MenuItem
                  label={t('location_edit')}
                  onClick={() => handleEditClick(row)}
                  dataTestId='locationItem'
                />
              </Menu>
            </div>
          ),
        },
      ];
    };

    const data = useMemo(() => {
      if (locations && locations.length > 0) {
        const startIndex = (currentPage - 1) * ROW_PER_PAGE;
        return locations.slice(startIndex, startIndex + ROW_PER_PAGE);
      }
      return [];
    }, [currentPage, locations]);

    const renderLocationGrid = () => {
      return (
        <Grid
          pageIndex={currentPage}
          loadPage={(page: number) => setCurrentPage(page)}
          columns={getColumnTitle()}
          data={data}
          primaryKey='id'
          totalPage={Math.ceil((locations?.length || 0) / ROW_PER_PAGE)}
          rowCount={ROW_PER_PAGE}
          isLoading={isLocationLoading}
        />
      );
    };

    return (
      <div className='flex flex-col'>
        {renderHeader()}
        <div className='mt-[24px]'>{renderLocationGrid()}</div>
      </div>
    );
  },
);
