import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../../assets/icons';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { ColorType, Pill } from '../../_ui';

interface IProps {
  users: Array<any>;
  removeUsers: (user: string) => void;
  showMore: boolean;
  setShowMore: Function;
}

const UserLists = ({ users, removeUsers, showMore, setShowMore }: IProps) => {
  const theme = useSelector(getCurrentTheme);

  const usersData = useMemo(() => {
    if (users.length > 10 && !showMore) {
      return users.slice(0, 10);
    }
    return users;
  }, [showMore, users]);

  return (
    <>
      <div className='flex flex-wrap  mt-[16px]'>
        {usersData.map((user) => (
          <div key={user} className='mt-[4px] mr-[8px]'>
            <Pill
              label={user}
              bgColor={ColorType.GREY2}
              hasCloseButton
              iconLeft={User}
              onClick={() => removeUsers(user)}
              translationOn
              dataTestId={user}
            />
          </div>
        ))}
      </div>
      {users.length > 10 && (
        // I removed this from button for removing textLeft params,
        // this button doesn't belong to any existing button design,
        // maybe we should create a LinkButton component for it.
        <button
          className='mt-[12px] mb-[12px] bg-transparent text-sm font-normal'
          style={{ color: theme.navigationSelectedColor }}
          onClick={() => setShowMore(!showMore)}
        >
          {String(showMore ? t('see_less') : t('see_more'))}
        </button>
      )}
    </>
  );
};

export default UserLists;
