import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Card,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui';
import { IconSize } from '../../constant/IconSize.constant';
import {
  AddPlus2,
  ChevronDown,
  ChevronUp,
  ErrorWarningCircle,
} from '../../assets/icons';
import { BreakerEditor } from './BreakerEditor.component';
import { USER_ROLE, useAuth } from '../../hooks';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';

interface Props {
  cardTitle?: string;
  children?: any;
  CollapsedHeaderDetails?: any;
  LocationDetails?: any;
  index?: any;
  isError?: boolean;
  locationCount: number;
  isPowerManaged: boolean;
  handleLocationExpansion?: Function;
  locationId: string;
  mainBreakerCount: number;
  showWarning: Function;
}

export const LocationCard = ({
  cardTitle,
  children,
  CollapsedHeaderDetails,
  LocationDetails,
  index,
  isError,
  locationCount,
  isPowerManaged,
  handleLocationExpansion,
  locationId,
  mainBreakerCount,
  showWarning,
}: Props) => {
  const auth = useAuth();
  const MAX_MAIN_BREAKER_COUNT = 8;

  const LOCATION_COUNT_TO_KEEP_CARD_EXPANDED = 5;
  const [isCardExpanded, setIsCardExpanded] = useState<any>({});
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const { allowPowermanEdit } = useAllowedFeatures();

  // Helps in auto-expand power managed locations
  useEffect(() => {
    if (isPowerManaged) {
      setIsCardExpanded({
        [index]: locationCount <= LOCATION_COUNT_TO_KEEP_CARD_EXPANDED,
      });
    }
  }, [locationCount]);

  // Helps to auto-expand breaker card when location card is expanded
  useEffect(() => {
    if (isCardExpanded) {
      handleLocationExpansion && handleLocationExpansion(isCardExpanded);
    }
  }, [isCardExpanded]);

  const toggleCard = () => {
    setIsCardExpanded(() => ({
      ...isCardExpanded,
      [index]: !isCardExpanded[index],
    }));
  };

  const handleAddMainBreaker = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('pm_add_main_breaker'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <BreakerEditor locationID={locationId} />,
      shouldCloseOnOverlayClick: false,
    });
  };
  return (
    <Card className={`${isCardExpanded[index] ? 'mb-6' : 'mb-2'}`}>
      <div
        data-testid='locationCard'
        className={`flex bg-white ${isCardExpanded[index] ? 'flex-col' : ''} ${
          isCardExpanded[index] ? '' : 'items-center'
        } gap-x-6`}
      >
        <div className='flex justify-between'>
          <div className='flex flex-col'>
            <div
              className={`flex flex-row items-center cursor-pointer self-start ${
                !isCardExpanded[index] ? 'w-60' : ''
              } gap-x-2`}
              onClick={toggleCard}
            >
              {isError && (
                <Icon src={ErrorWarningCircle} size={IconSize.SIZE_20x20} />
              )}
              <Label
                text={cardTitle}
                type={LabelType.H5}
                color={ColorType.BLACK}
                className='truncate'
              />
              <Icon
                className='flex items-center'
                src={isCardExpanded[index] ? ChevronUp : ChevronDown}
                key={
                  isCardExpanded[index]
                    ? `expanded-${index}`
                    : `collapsed-${index}`
                }
              />
            </div>

            {isCardExpanded[index] && LocationDetails}
          </div>
          {isCardExpanded[index] && (auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
            <Button
              type={ButtonType.TERTIARY}
              icon={AddPlus2}
              label={t('pm_add_main_breaker')}
              onClick={() => {
                showWarning(locationId, (actionValue: boolean) => {
                  if (actionValue) {
                    handleAddMainBreaker();
                  }
                });
              }}
              disabled={mainBreakerCount >= MAX_MAIN_BREAKER_COUNT}
              dataTestId='mainBreakerBtn'
            />
          )}
        </div>

        {isCardExpanded[index] ? (
          children
        ) : (
          <div className='flex justify-between w-full items-center'>
            <div className='flex flex-col gap-y-2'>
              {CollapsedHeaderDetails}
            </div>
            {!isCardExpanded[index] && (auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
              <Button
                className=' self-start'
                type={ButtonType.TERTIARY}
                icon={AddPlus2}
                label={t('pm_add_main_breaker')}
                onClick={() => {
                  showWarning(locationId, (actionValue: boolean) => {
                    if (actionValue) {
                      handleAddMainBreaker();
                    }
                  });
                }}
                disabled={mainBreakerCount >= MAX_MAIN_BREAKER_COUNT}
                dataTestId='mainBreakerBtn'
              />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
