import { useMemo } from 'react';
import {
  ColorType,
  Icon,
  Label,
  LabelType,
  getHexColorByType,
} from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { IconSize } from '../../../constant/IconSize.constant';
import { ErrorWarningtraiangle, Info, More } from '../../../assets/icons';
import { PM_WARNING_TYPE } from '../../../constant/Text.constant';

interface IProps {
  label: string;
  hoverTooltipContent: string;
  clickTooltipContent: string;
  warningType?: string;
  tooltipDateTime?: string;
}

const PowerManagementWarning = ({
  hoverTooltipContent,
  clickTooltipContent,
  label,
  warningType = PM_WARNING_TYPE.INFO,
  tooltipDateTime,
}: IProps) => {
  const warningStyle = useMemo(() => {
    if (warningType === PM_WARNING_TYPE.INFO) {
      return {
        icon: Info,
        iconColor: ColorType.GREY6,
        contentStyle: {
          border: '1px solid #4E5968', // Grey 6 color tailwind config
          background: getHexColorByType(ColorType.GREY2),
          borderRadius: '16px',
        },
      };
    }
    if (warningType === PM_WARNING_TYPE.WARNING) {
      return {
        icon: ErrorWarningtraiangle,
        iconColor: ColorType.WARNING2,
        contentStyle: {
          border: '1px solid #FF8F00', // Warning 2 color tailwind config
          background: getHexColorByType(ColorType.WARNING0),
          borderRadius: '16px',
        },
      };
    }
    // warningType === PM_WARNING_TYPE.ERROR
    return {
      icon: Info,
      iconColor: ColorType.NEGATIVE1,
      contentStyle: {
        border: '1px solid #E53935', // Negative 1 color tailwind config
        background: getHexColorByType(ColorType.NEGATIVE0),
        borderRadius: '16px',
      },
    };
  }, [warningType]);

  const renderTriggerContent = () => (
    <TooltipTrigger className='flex flex-row gap-1 items-center'>
      <Icon
        src={warningStyle.icon}
        size={IconSize.SIZE_16x16}
        color={warningStyle.iconColor}
      />
      <Label
        text={label}
        type={LabelType.LABEL_S_MEDIUM}
        color={warningStyle.iconColor}
      />
    </TooltipTrigger>
  );

  const renderTriggerIcon = () => (
    <TooltipTrigger>
      <Icon
        src={More}
        size={IconSize.SIZE_16x16}
        color={warningStyle.iconColor}
      />
    </TooltipTrigger>
  );

  const getTooltipContent = (textContent: string) => {
    return (
      <div className='flex flex-col gap-2'>
        {tooltipDateTime && (
          <Label
            text={tooltipDateTime}
            type={LabelType.LABEL_S}
            color={ColorType.WHITE}
          />
        )}
        <Label
          text={textContent}
          type={LabelType.BODY3}
          color={ColorType.WHITE}
        />
      </div>
    );
  };

  return (
    <div
      style={warningStyle.contentStyle}
      className='flex items-center box-border px-1 py-0.5 gap-1'
    >
      <Tooltip
        placement='top'
        tipOffset={{
          mainAxis: 10,
          crossAxis: 0,
        }}
      >
        {renderTriggerContent()}
        <TooltipContent>
          {getTooltipContent(hoverTooltipContent)}
        </TooltipContent>
      </Tooltip>
      <Tooltip
        placement='top'
        tipOffset={{
          mainAxis: 10,
          crossAxis: 0,
        }}
        enablePopover
      >
        {renderTriggerIcon()}
        <TooltipContent>
          {getTooltipContent(clickTooltipContent)}
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default PowerManagementWarning;
