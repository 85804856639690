import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorType, Icon, Label, LabelType } from '../../_ui';
import { IconSize } from '../../../constant/IconSize.constant';
import { TableNoData } from '../../../assets/icons';

const NoChargerMessage = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col justify-center h-52'>
      <div className='flex justify-center'>
        <Icon className='grey5' src={TableNoData} size={IconSize.SIZE_36x36} />
      </div>
      <div className='flex justify-center my-2'>
        <Label
          text={t('charger_messages_no_data')}
          type={LabelType.H4}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex justify-center'>
        <Label
          text={t('try_adjust_search_filter_settings')}
          type={LabelType.BODY2}
          color={ColorType.GREY5}
        />
      </div>
    </div>
  );
};

export default NoChargerMessage;
