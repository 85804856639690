import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface InputProps {
  onChange?: Function;
  segmentedItemWidth?: string;
  segmentedDefault?: string;
  className?: string;
  options?: any[];
}

export const Segment = memo(
  ({
    onChange,
    segmentedItemWidth = 'w-32',
    segmentedDefault = '',
    className = '',
    options = [],
  }: InputProps) => {
    const [selected, setSelected] = useState(segmentedDefault);
    const { t } = useTranslation();

    useEffect(() => {
      setSelected(segmentedDefault);
    }, [segmentedDefault]);

    const itemSelected = useCallback(
      (option: any) => {
        setSelected(option);
        onChange && onChange(option);
      },
      [onChange],
    );

    return (
      <div
        className={`flex items-center bg-grey1 h-11 cursor-pointer rounded-3xl ${className}`}
      >
        {options.map((op, index) => {
          const isSelected = selected === op || (!selected && index === 0);
          return (
            <div
              key={op}
              onClick={() => itemSelected(op)}
              className={`h-9 flex items-center mx-1 place-content-center ${segmentedItemWidth} ${
                isSelected
                  ? 'bg-white box-shadow-switch'
                  : 'hover:bg-grey2 rounded-full'
              }`}
              data-testid={op.replace(' ', '-')}
            >
              <span
                className={`text-sm font-semibold font-inter ${
                  isSelected ? 'text-black' : 'text-grey5'
                }`}
              >
                {t(op)}
              </span>
            </div>
          );
        })}
      </div>
    );
  },
);
