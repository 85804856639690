import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  DateTimePicker,
  Dropdown,
  FormInput,
  Icon,
  Label,
  LabelType,
  Switch,
  getHexColorByType,
} from '../../_ui';
import { InputType, ValidationType } from '../../_ui/Input.component';
import { CustomInputType } from '../../_ui/date-picker/Date-Picker.component';
import { formatDate } from '../../../utils/Date.Util';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { IconSize } from '../../../constant/IconSize.constant';
import { Info } from '../../../assets/icons';

export const ChargerSettings = (props: any) => {
  const { t } = useTranslation();
  const { nextStep, wizardState, setWizardState } = props;

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex'>
        <Label
          text={`${t('charger_new_settings_descriptions')}`}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          htmlText={` ${wizardState.selCompany?.label}:`}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <Label
          text={t('pricing_rules')}
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
        />
        <Dropdown
          headerWidth='100%'
          items={wizardState.pricingModels}
          onItemClick={(selectedItem: any) => {
            setWizardState({
              ...wizardState,
              selPriceModel: selectedItem,
            });
          }}
          placeholder={t('select_pricing_rule')}
          placeholderLabelType={LabelType.BODY3}
          labelType={LabelType.BODY3}
          labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
          contentDivWidth={490}
          chevdownIconHighlightColor={ColorType.GREY6}
          headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] m-0'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row gap-1'>
          <Label
            text={t('chargers_map_visibility')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={t('chargers_map_visibility_hint')}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        </div>

        <CheckBox
          label={t('chargers_visibility_user_access')}
          key='visible'
          index={0}
          selected={!wizardState.hidden}
          onChange={(isChecked: boolean) => {
            setWizardState({
              ...wizardState,
              hidden: !isChecked,
            });
          }}
        />
      </div>
      <div className='flex'>
        <FormInput
          defaultValue={wizardState.directions}
          label={t('chargers_directions')}
          secondLabel={`(${t('chargers_optional')})`}
          labelType={LabelType.LABEL_M}
          secondLabelType={LabelType.LABEL_M_MEDIUM}
          secondLabelColor={ColorType.GREY6}
          placeholder={t('chargers_directions')}
          inputType={InputType.TEXT}
          width='100%'
          onChange={(event: any) => {
            setWizardState({
              ...wizardState,
              directions: event.target.value,
            });
          }}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <FormInput
          defaultValue={wizardState.note}
          label={t('charger_internal_note')}
          labelType={LabelType.LABEL_M}
          secondLabel={`(${t('chargers_optional')})`}
          secondLabelType={LabelType.LABEL_M_MEDIUM}
          secondLabelColor={ColorType.GREY6}
          placeholder={t('charger_internal_note')}
          onChange={(event: any) => {
            setWizardState({
              ...wizardState,
              note: event.target.value,
            });
          }}
          width='100%'
        />
      </div>
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          <FormInput
            width='242px'
            label={t('chargers_installer')}
            defaultValue={wizardState.installer}
            secondLabel={`(${t('chargers_optional')})`}
            labelType={LabelType.LABEL_M}
            secondLabelType={LabelType.LABEL_M_MEDIUM}
            secondLabelColor={ColorType.GREY6}
            placeholder={t('chargers_installer')}
            onChange={(event: any) => {
              setWizardState({
                ...wizardState,
                installer: event.target.value,
              });
            }}
          />
        </div>
        <div className='flex flex-col ml-4'>
          <div className='flex flex-row pb-2'>
            <Label
              text={t('chargers_installation_date')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Label
              text={`(${t('chargers_optional')})`}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY6}
            />
          </div>
          <DateTimePicker
            defaultSelectedDate={wizardState?.installationDate}
            onChange={(date) => {
              setWizardState({
                ...wizardState,
                installationDate: formatDate(date, 'yyyy-MM-dd'),
              });
            }}
            format='MM/dd/yyyy'
            customInputType={CustomInputType.FORMINPUTDATE}
            showDefaultDate={false}
            customFormInputDivWidth='w-[242px]'
          />
        </div>
      </div>
      <div className='flex'>
        <FormInput
          defaultValue={wizardState.installerContact}
          label={t('chargers_installer_contact')}
          secondLabel={`(${t('chargers_optional')})`}
          labelType={LabelType.LABEL_M}
          secondLabelType={LabelType.LABEL_M_MEDIUM}
          secondLabelColor={ColorType.GREY6}
          placeholder={t('chargers_installer_contact_placeholder')}
          inputType={InputType.TEXT}
          validationType={ValidationType.EMAIL}
          showErrorIcon
          errorLabel={t('chargers_email_validation_message')}
          width='100%'
          onChange={(event: any) => {
            setWizardState({
              ...wizardState,
              installerContact: event.target.value,
            });
          }}
        />
      </div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-col gap-2'>
          <Label
            text={t('edit_bulk_charger_deployment_status')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <Switch
            value={wizardState.deployed}
            enableLabel={t('edit_bulk_charger_deployed')}
            disableLabel={t('charger_deployment_status_false')}
            onChange={(value: boolean) => {
              setWizardState({
                ...wizardState,
                deployed: value,
              });
            }}
            className='h-[40px]'
          />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row gap-1'>
            <Label
              text={t('edit_bulk_charger_deployment_date')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Label
              text={`(${t('chargers_optional')})`}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY6}
            />
          </div>
          <DateTimePicker
            defaultSelectedDate={wizardState?.deploymentDate}
            onChange={(date) => {
              setWizardState({
                ...wizardState,
                deploymentDate: formatDate(date, 'yyyy-MM-dd'),
              });
            }}
            format='MM/dd/yyyy'
            customInputType={CustomInputType.FORMINPUTDATE}
            showDefaultDate={false}
            customFormInputDivWidth='w-[240px]'
            customFormInputErrorLabel={t('chargers_date_validation_message')}
          />
        </div>
      </div>
      <div className='flex'>
        <Label
          text={t('charger_move_default_description')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='border-y border-grey2' />
      <div className='flex flex-col'>
        <div className='flex flex-row gap-8 pb-4'>
          <Label
            text={t('charger_auto_start')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[72px]'
          />
          <Label
            text={t('disabled')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-8 pb-4'>
          <Label
            text={t('discounts')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[72px]'
          />
          <Label
            text={t('none')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-row gap-8 pb-4'>
          <Label
            text={t('access')}
            type={LabelType.LABEL_S}
            color={ColorType.BLACK}
            className='w-[72px]'
          />
          <Label
            text={t('public')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      </div>
      <div className='flex flex-col mb-5'>
        <Button
          label={t('next')}
          type={ButtonType.PRIMARY}
          disabled={!wizardState.selPriceModel}
          size={ButtonSize.SMALL_FULL}
          onClick={() => nextStep()}
        />
      </div>
    </div>
  );
};
