import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddPlus2 } from '../../assets/icons';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { useShowBankingInfoModal } from '../../hooks/useShowBankingInfoModal';
import { RoutePath } from '../../routes';
import {
  ChargerOptions,
  useFetchChargersQuery,
} from '../../services/charger.api';
import { useFetchPricingModelsQuery } from '../../services/pricing.api';
import { Charger } from '../../stores/types';
import {
  ChargerSelection,
  ChargersGroupByLocation,
} from '../../stores/types/chargers.interface';
import { Price } from '../../stores/types/price.interface';
import { DiscountForm } from '../Discounts/DiscountForm/DiscountForm.component';
import { Discounts } from '../Discounts/Discounts.component';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  MODAL_TYPES,
  Tab,
  Tabs,
  useGlobalModalContext,
} from '../_ui';
import { RuleComponent } from './Rule.component';

enum TABS {
  PRICING_RULES = 0,
  DISCOUNT,
}

export const PricingRules = () => {
  const { t } = useTranslation();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();
  const { showModal } = useGlobalModalContext();
  const { showBankInfoModal, displayBankInfoModal } = useShowBankingInfoModal();

  const [selectedTab, setSelectedTab] = useState<number>(TABS.PRICING_RULES);
  const { companyId } = useCompany();

  const fetchOption = useMemo(() => {
    return {
      includeAcl: true,
    } as ChargerOptions;
  }, []);

  const { chargers } = useFetchChargersQuery(fetchOption, {
    selectFromResult: ({ data, isSuccess }) => {
      return {
        chargers: data ? data.entities : [],
        isChargerFetchingSuccess: isSuccess,
      };
    },
  });

  const { userScope } = useCompany();
  const { pricingData, totalNonSyntheticChargers } = useFetchPricingModelsQuery(
    { companyId: companyId!, scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        pricingData: endPoint.data ? endPoint.data : [],
        totalNonSyntheticChargers: endPoint.data
          ? endPoint.data
              .filter((pricing) => pricing.synthetic === false)
              .reduce(
                (previous, current) => previous + current.chargers.length,
                0,
              )
          : 0,
        isPricingLoading: endPoint.isFetching,
      }),
    },
  );

  const chargersGroupByLocation: ChargersGroupByLocation = useMemo(() => {
    return _.groupBy(chargers, 'location.name');
  }, [chargers]);

  const chargersList = useMemo(() => {
    const data = Object.entries(chargersGroupByLocation).map(
      (key: Array<any>) => {
        const children: ChargerSelection = key[1].map((charger: Charger) => {
          return {
            id: charger.id,
            label: charger.name,
            selected: false,
          };
        });

        return {
          id: key[0],
          label: key[0],
          selected: false,
          children: children || [],
        };
      },
    );
    return data.filter((charger: any) => charger.children.length);
  }, [chargersGroupByLocation]);

  const allChargerList = useMemo(() => {
    const list = _.cloneDeep(chargersList);
    const all = {
      id: 'all',
      label: 'All chargers',
      selected: false,
      children: [],
    };
    list.unshift(all as any);
    return list;
  }, [chargersList]);

  const sortedPricing = useMemo(
    () => [...pricingData].sort((item) => (item.defaultPrice ? -1 : 1)),
    [pricingData],
  );

  const renderTopRightButtons = useMemo(() => {
    const renderEditPricingRuleComponent = () => {
      navigateWithCompanyId(`../${RoutePath.PRICING_EDIT}`, companyId);
    };

    const handleAddClick = () => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('discount_add_modal_title'),
        width: '540px',
        height: 'max-content',
        onRenderBody: () => <DiscountForm chargersList={allChargerList} />,
        shouldCloseOnOverlayClick: false,
      });
    };
    const pricingRuleButtons = [
      {
        id: 'addRule',
        label: t('pricing_add_rule'),
        type: ButtonType.TERTIARY,
        size: ButtonSize.SMALL,
        onClick: renderEditPricingRuleComponent,
      },
    ];

    const discountButtons = [
      {
        id: 'addDiscount',
        label: t('discount_add_modal_title'),
        type: ButtonType.TERTIARY,
        size: ButtonSize.SMALL,
        onClick: handleAddClick,
      },
    ];
    const buttons =
      selectedTab === TABS.PRICING_RULES ? pricingRuleButtons : discountButtons;
    return buttons.map((button) => (
      <Button
        key={button.id}
        icon={AddPlus2}
        label={button.label}
        type={button.type}
        size={button.size}
        onClick={button.onClick}
        dataTestId={button.id}
      />
    ));
  }, [
    allChargerList,
    companyId,
    navigateWithCompanyId,
    selectedTab,
    showModal,
    t,
  ]);

  /**
   * Show bank info modal if company's banking info missing & have at least one non-free pricing rule.
   */
  useEffect(() => {
    if (showBankInfoModal) {
      displayBankInfoModal();
    }
  }, [showBankInfoModal]);

  return (
    <Card dataTestId='pricingRules'>
      <div className=' flex flex-row justify-between pb-4'>
        <Tabs onTabChange={setSelectedTab} rootClass='w-[420px]'>
          <Tab label={t('pricing_rules')} />
          <Tab label={t('discounts')} dataTestId='discountTab' />
        </Tabs>
        {renderTopRightButtons}
      </div>

      {selectedTab === TABS.PRICING_RULES ? (
        sortedPricing?.length === 0 ? (
          <RuleComponent
            pricingData={{} as Price}
            totalNonSyntheticChargers={0}
            isPricingLoading
            totalDataLength={sortedPricing.length}
          />
        ) : (
          sortedPricing?.map((pricingResponse: Price) => (
            <RuleComponent
              key={pricingResponse.id}
              pricingData={pricingResponse}
              totalNonSyntheticChargers={totalNonSyntheticChargers}
              totalDataLength={sortedPricing.length}
            />
          ))
        )
      ) : (
        <Discounts
          chargersList={chargersList}
          allChargerList={allChargerList}
        />
      )}
    </Card>
  );
};
