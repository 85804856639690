import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { LabelType } from '../../_ui';

interface IPROPS {
  text: string;
}
const RenderLearnMoreText = ({ text }: IPROPS) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <div className={`${LabelType.BODY3} text-grey6`}>
      <Trans i18nKey={text}>
        <a
          target='_blank'
          href='https://chargelab.zendesk.com/hc/en-us/sections/26591210082971-Payter-Information'
          rel='noreferrer'
          className={LabelType.BODY3}
          style={{ color: theme.brand_2 }}
        >
          This text will be replaced by actual link text during translation
        </a>
      </Trans>
    </div>
  );
};

export default RenderLearnMoreText;
