import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Label,
  LabelType,
} from '../_ui';

export const NoRoutePermission = memo(() => {
  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation();
  const { navigateWithCompanyId, navigate } = useNavigateWithSearchParam();
  const auth = useAuth();
  const handleBtnClick = () => {
    navigateWithCompanyId(
      '/',
      auth.user.attributes.profile?.lastActiveCompanyId,
      { replace: true },
    );
  };
  useEffect(() => {
    if (!auth.user) {
      navigate('/login', { replace: true });
    }
  }, [auth]);

  return (
    <div className='h-screen bg-[#E5E5E5]'>
      <div className='flex flex-col items-center'>
        <div className='pt-10 pb-10'>
          <img src={theme.networkLogo} alt='' />
        </div>
        <div className='w-[440px] h-80'>
          <Card className='px-10 py-12'>
            <div className='pb-10'>
              <Label
                type={LabelType.H3}
                color={ColorType.BLACK}
                text={t('no_route_access_title')}
                className='mb-1'
              />
              <Label
                type={LabelType.BODY2}
                text={t('no_route_access_message')}
              />
            </div>
            <div className='w-full h-[48px]'>
              <Button
                label={t('no_route_access_btn')}
                type={ButtonType.PRIMARY}
                onClick={() => handleBtnClick()}
                size={ButtonSize.MEDIUM_FULL}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
});
