import { createSelector } from '@reduxjs/toolkit';
import { envApi } from '../../services/env.api';

export const selectReceiptSettingsResult = createSelector(
  [envApi.endpoints.fetchReceiptSettings.select(undefined)],
  (endpoint) => endpoint.data || {},
);

export const getWhiteLabellingCompanyName = createSelector(
  [selectReceiptSettingsResult],
  (data) => data.companyName || '',
);
