import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, ColorType, Label, LabelType, Spinner } from '../../_ui';
import { useFetchOcppMessagesQuery } from '../../../services/ocpp-messages.api';

const ChargerMessageDetaits = () => {
  const { chargerId, receivedTime } = useParams();

  const obj = {
    chargerId,
    'filter[receivedTimeUtc][eq]': receivedTime,
    sort: '-receivedTimeUtc',
    limit: 1,
    offset: 0,
  };

  const { data: messages, isLoading } = useFetchOcppMessagesQuery(obj);

  return (
    <Card>
      {isLoading ? (
        <Spinner />
      ) : (
        <Label
          text={messages?.entities?.[0]?.message}
          type={LabelType.BODY4}
          color={ColorType.BLACK}
        />
      )}
    </Card>
  );
};

export default ChargerMessageDetaits;
