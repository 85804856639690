import { useTranslation } from 'react-i18next';
import { Card } from './Card.component';
import { Label, LabelType } from './Label.component';
import { ColorType } from './index';

const AcceptCompanyAccess = () => {
  const { t } = useTranslation();
  return (
    <Card className='pl-[40px] pr-[40px] pt-[48px] pb-[48px]'>
      <Label
        className=''
        text={t('dont_have_company_access')}
        type={LabelType.H3}
        color={ColorType.BLACK}
        dataTestId='dont-have-company-access'
      />
      <Label text={t('check_myaccount_for_access')} type={LabelType.BODY2} dataTestId='check-myaccount-for-access' />

    </Card>
  );
};

export default AcceptCompanyAccess;
