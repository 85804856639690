import { Invite, Invites } from '../stores/types';
import { API_KEYS, Api } from './base.api';

export const inviteApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDelegationInvites: builder.query<Invite[], void>({
      query: () => ({
        url: `/internal/core/v2/${API_KEYS.DELEGATION_INVITES}?self=true&filter_eq[status]=Pending`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.DELEGATION_INVITES, id: 'LIST' },
      ],
    }),
    sendDelegationInvites: builder.mutation<any, Invites>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.DELEGATION_INVITES}`,
        body: payload,
        method: 'POST',
        toast: {
          successTrans: 'successfull_invited',
          values: {
            users: payload.emails?.length,
            companies: payload.companyIds?.length,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DELEGATION_INVITES, id: 'LIST' },
        { type: API_KEYS.DELEGATION_INVITES, id: result?.id },
      ],
    }),
    deleteDelegationInvites: builder.mutation<any, Invites>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.DELEGATION_INVITES}?self=true`,
        body: payload,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DELEGATION_INVITES, id: 'LIST' },
        { type: API_KEYS.DELEGATION_INVITES, id: result?.id },
      ],
    }),
    updateDelegationInvites: builder.mutation<any, Invites>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.DELEGATION_INVITES}:accept`,
        body: payload,
        method: 'PATCH',
        toast: {
          successTrans: 'successfull_joined',
          values: {
            companies: payload.inviteIds?.length,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DELEGATION_INVITES, id: 'LIST' },
        { type: API_KEYS.DELEGATION_INVITES, id: result?.id },
        { type: API_KEYS.COMPANIES },
      ],
    }),
  }),
});

export const {
  useFetchDelegationInvitesQuery,
  useSendDelegationInvitesMutation,
  useUpdateDelegationInvitesMutation,
  useDeleteDelegationInvitesMutation,
} = inviteApi;
