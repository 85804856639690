import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPlus2, Edit } from '../../assets/icons';
import { useCompany } from '../../hooks/useCompany';
import { useFetchChargerDetailQuery } from '../../services/charger.api';
import { useFetchSupportNoteQuery } from '../../services/supportnote.api';
import {
  Button,
  ButtonSize,
  ButtonType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui';
import SupportNotesEditor from './Support-Notes-Editor.component';

interface IProps {
  chargerId: string;
}

const SupportNotesButton = React.memo(({ chargerId }: IProps) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { userScope } = useCompany();
  const {
    data: note,
    isFetching,
    isSuccess,
  } = useFetchSupportNoteQuery(chargerId);
  const { data: charger } = useFetchChargerDetailQuery({
    id: chargerId!,
    scope: userScope!,
  });

  const isAdd = useMemo(() => {
    return !note;
  }, [note]);

  const icon = useMemo(() => {
    return isAdd ? AddPlus2 : Edit;
  }, [isAdd]);
  const label = useMemo(() => {
    return isAdd ? 'support_notes_add' : 'support_notes_edit';
  }, [isAdd]);
  const title = useMemo(() => {
    return `${
      isAdd ? t('support_notes_add_for') : t('support_notes_edit_for')
    } ${charger?.name}`;
  }, [isAdd, charger?.name]);

  const onAddNote = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: title,
      width: '540px',
      height: '604px',
      onRenderBody: () => <SupportNotesEditor chargerId={chargerId} />,
      shouldCloseOnOverlayClick: false,
    });
  };
  const onEditNote = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: title,
      width: '540px',
      height: '604px',
      onRenderBody: () => <SupportNotesEditor note={note} />,
      shouldCloseOnOverlayClick: false,
    });
  };
  if (isSuccess) {
    return (
      <Button
        icon={icon}
        size={ButtonSize.SMALL}
        label={t(label)}
        type={ButtonType.TERTIARY}
        onClick={isAdd ? onAddNote : onEditNote}
        isLoading={isFetching}
      />
    );
  }

  return <div />;
});

export default SupportNotesButton;
