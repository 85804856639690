import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { PRICING_SCHEDULED_TYPE } from '../../../constant/Text.constant';
import {
  DurationType,
  Price,
  PricingType,
} from '../../../stores/types/price.interface';
import { ColorType, Label, LabelType } from '../../_ui';

export const TieredPricing = (
  pricingData: Price,
  renderPriceType: Function,
) => {
  const { TIERED } = PRICING_SCHEDULED_TYPE;

  const { scheduleType, tieredPriceConditions } = pricingData;

  return (
    scheduleType === TIERED && (
      <>
        <div className='py-2'>
          <Label
            text={t('pricing_tiered')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
          />
        </div>
        {tieredPriceConditions.map((tieredItem: any, index: number) => (
          <div className='flex justify-between items-center'>
            {index === 0 && tieredItem.duration !== '' && (
              <div className='text-sm font-normal text-grey6'>
                <Trans
                  i18nKey={
                    tieredItem.durationUnit === DurationType.KWH
                      ? 'tiered_pricing_for_first_kwh'
                      : 'tiered_pricing_for_first_hours'
                  }
                  values={{
                    duration: tieredItem.duration,
                  }}
                />
              </div>
            )}
            {index !== 0 && tieredItem.duration !== '' && (
              <div className='text-sm font-normal text-grey6'>
                <Trans
                  i18nKey={
                    tieredItem.durationUnit === DurationType.KWH
                      ? 'tiered_pricing_for_next_kwh'
                      : 'tiered_pricing_for_next_hours'
                  }
                  values={{
                    duration: tieredItem.duration,
                  }}
                />
              </div>
            )}
            {index === tieredPriceConditions.length - 1 &&
              tieredItem.duration === '' && (
                <Label
                  text={t('pricing_afterward')}
                  type={LabelType.BODY3}
                  color={ColorType.GREY6}
                />
              )}
            <div className='flex justify-between'>
              {renderPriceType(tieredItem)}
            </div>
          </div>
        ))}
      </>
    )
  );
};
