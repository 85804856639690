import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NUMBER } from '../../../constant/Number.constant';
import { Breaker } from '../../../stores/types/pm.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  Label,
  LabelType,
  Select,
  SelectItem,
} from '../../_ui';
import { ELECTRIC_SUPPLY, arrayRange, defaultLoadConfig } from '../utils';

export const LoadStepOne = (props: any) => {
  const { t } = useTranslation();
  const [noOfLoadsOptions, setNoOfLoadsOptions] = useState(
    arrayRange(NUMBER.ONE, NUMBER.MAX_LOAD_NUM),
  );
  const [noOfLoads, setNoOfLoads] = useState(0);
  const [dedicatedBreakerData, setDedicatedBreakerData] = useState();
  const { data, nextStep, wizardState, setWizardState } = props;

  const updateNoOfNodeOptions = (breakerObject: Breaker) => {
    let totalLoads =
      breakerObject.chargers.length + breakerObject.unmanagedLoads.length;
    // find total load count for dedicated breakers
    if (dedicatedBreakerData && dedicatedBreakerData[breakerObject.id]) {
      totalLoads += +dedicatedBreakerData[breakerObject.id];
    }

    if (totalLoads > 0) {
      const rangeData = arrayRange(
        NUMBER.ONE,
        NUMBER.MAX_LOAD_NUM - totalLoads,
      );
      setNoOfLoadsOptions(() => rangeData);
    } else {
      const allRange = arrayRange(NUMBER.ONE, NUMBER.MAX_LOAD_NUM);
      setNoOfLoadsOptions(() => allRange);
    }
  };

  const makeParentBreakerOptions = (
    parentBreakers: Breaker[],
    dropdownOptions: any,
    dedicatedBreakers: any,
  ) => {
    if (!Array.isArray(parentBreakers)) {
      return { dedicatedBreakers, dropdownOptions };
    }
    parentBreakers.forEach((breakerData: Breaker) => {
      // skip dedicated breaker from the dropdown options
      if (!breakerData.dedicatedBreaker) {
        dropdownOptions.push({
          id: breakerData.id,
          label: breakerData.name,
          selected:
            breakerData.id === wizardState.parentBreakerIdName?.parentBreakerId,
          chargers: breakerData.chargers,
          unmanagedLoads: breakerData.unmanagedLoads,
        });
      } else if (breakerData.parentBreakerId) {
        // for dedicated breaker count only
        if (!dedicatedBreakers[breakerData.parentBreakerId]) {
          dedicatedBreakers[breakerData.parentBreakerId] = NUMBER.ONE;
        } else {
          dedicatedBreakers[breakerData.parentBreakerId] += NUMBER.ONE;
        }
      }
      if (breakerData.subBreakers.length > 0) {
        makeParentBreakerOptions(
          breakerData.subBreakers,
          dropdownOptions,
          dedicatedBreakers,
        );
      }
    });
    return { dedicatedBreakers, dropdownOptions };
  };

  const mainBreakerOptions = useMemo(() => {
    const { dedicatedBreakers, dropdownOptions } = makeParentBreakerOptions(
      data.circuit?.mainBreakers ?? [],
      [],
      {},
    );
    setDedicatedBreakerData(dedicatedBreakers);
    return dropdownOptions;
  }, [data.breaker.id, wizardState.parentBreakerIdName]);

  const handleInputChange = (key: string, value: any) => {
    setWizardState((oldData: any) => ({ ...oldData, [key]: value }));
  };

  useEffect(() => {
    if (noOfLoadsOptions[noOfLoads]) {
      const makeDefaultLoadConfig = {
        ...defaultLoadConfig,
        wiringPhase:
          data.circuit.electricalSupply === ELECTRIC_SUPPLY.SPLIT_PHASE
            ? 'L_L' // Default wiring phase for phase to phase
            : '',
      };
      setWizardState((oldData: any) => ({
        ...oldData,
        loadData: new Array(noOfLoadsOptions[noOfLoads]).fill({
          ...makeDefaultLoadConfig,
        }),
        activeIndex: 0,
      }));
    }
  }, [noOfLoads]);

  useEffect(() => {
    setWizardState((oldData: any) => ({
      ...oldData,
      parentBreakerIdName: {
        parentBreakerId: data.breaker.id,
        name: data.breaker.name,
      },
    }));
    updateNoOfNodeOptions(data.breaker);
  }, [data.breaker.id]);

  return (
    <div className='divide-y divide-grey3'>
      <div className='flex flex-col pb-4 gap-y-2'>
        <Label
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          text={t('sub_breaker_label_1')}
        />
        <Dropdown
          items={mainBreakerOptions}
          onItemClick={(selectedItem: any) => {
            handleInputChange('parentBreakerIdName', {
              parentBreakerId: selectedItem.id,
              name: selectedItem.label,
            });
            updateNoOfNodeOptions(selectedItem);
          }}
          headerWidth='100%'
          contentDivHeight={150}
          placeholderLabelType={LabelType.BODY3}
          labelType={LabelType.BODY3}
          labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
          chevdownIconHighlightColor={ColorType.GREY6}
          headerHighLightClassName='bg-grey1 border-grey3 rounded'
        />
      </div>
      <div className='flex flex-col gap-4 pt-4'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Label
              type={LabelType.LABEL_M}
              text={t('number_of_loads_heading_label')}
            />
            <Label
              type={LabelType.BODY3}
              text={t('number_of_loads_sub_heading_label')}
              color={ColorType.GREY6}
            />
          </div>
          <Select
            defaultIndex={noOfLoads}
            defaultLabel=''
            contentHeight={150}
            placement='top-start'
            onItemSelected={(index: number) => {
              setNoOfLoads(index);
            }}
            buttonProps={{
              type: ButtonType.TERTIARY,
              iconRightAlign: true,
            }}
          >
            {noOfLoadsOptions.map((number: number) => {
              return <SelectItem key={number} label={String(number)} />;
            })}
          </Select>
        </div>
        <div className='flex flex-row mb-5'>
          <Button
            label={t('add_load_continue_label')}
            type={ButtonType.SECONDARY}
            size={ButtonSize.SMALL_FULL}
            onClick={() => nextStep()}
            disabled={!(wizardState?.parentBreakerIdName && noOfLoads >= 0)}
            dataTestId='addLoadDetails'
          />
        </div>
      </div>
    </div>
  );
};
