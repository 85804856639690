import { t } from 'i18next';
import _, { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AddPlus2, Info } from '../../assets/icons';
import { useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useFetchCompanyDetailQuery } from '../../services/company.api';
import { useFetchLocationsQuery } from '../../services/location.api';
import {
  useCreatePricingModelMutation,
  useEditPricingModelMutation,
  useFetchPricingModelsQuery,
  useValidateIdleFeeMutation,
} from '../../services/pricing.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  DayPrice,
  Price,
  RuleTypes,
  ScheduleType,
} from '../../stores/types/price.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  CheckBoxData,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Label,
  LabelType,
  MODAL_TYPES,
  Pill,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { checkIdleFeeError, validateCurrency } from '../_ui/modal/utils';
import PriceForm from './PriceForm/PriceForm.component';
import { tierPriceItem } from './PriceForm/utils';
import {
  capitalizeFirstLetter,
  getScheduleLabel,
  isChargerFromDefaultRule,
} from './utils';

export const EditPricingRules = () => {
  const auth = useAuth();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();
  const theme = useSelector(getCurrentTheme);
  const { companyId, userScope } = useCompany();

  const { showModal, hideModal } = useGlobalModalContext();
  const [showDefaultChargeDropdown, setShowDefaultChargerDropdown] =
    useState(false);

  const { data: company } = useFetchCompanyDetailQuery({
    id: auth.user.attributes.profile?.lastActiveCompanyId,
    scope: userScope!,
  });
  const { defaultPricingRule } = useFetchPricingModelsQuery(
    { companyId: companyId!, scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        defaultPricingRule: endPoint.data
          ? endPoint.data.find((priceRule) => priceRule.defaultPrice)
          : undefined,
      }),
    },
  );

  const currentLocation = useLocation();
  const { state } = currentLocation;
  const price = (state as any)?.price as Price;

  const [name, setName] = useState(price?.displayName || '');
  const [chargers, setChargers] = useState<string[]>([]);

  const isDefaultPricingRule = useMemo(() => {
    return defaultPricingRule && price && price?.id === defaultPricingRule?.id;
  }, [defaultPricingRule, price]);

  useEffect(() => {
    // For default pricing rule, the init chargers array is empty
    if (price?.chargers && !isDefaultPricingRule) {
      setChargers(price.chargers.map((charger) => charger.chargerId));
    }
  }, [price, isDefaultPricingRule, defaultPricingRule]);
  const [dayPrices, setDayPrices] = useState(price?.dayPrices || []);

  const getScheduleType = (type: ScheduleType | RuleTypes) => {
    let priceResult;
    let scheduleResult;
    switch (type) {
      case RuleTypes.FREE:
        priceResult = RuleTypes.FREE;
        break;
      case RuleTypes.TIERED:
        priceResult = RuleTypes.TIERED;
        break;
      default:
        priceResult = RuleTypes.TIME_OF_USE;
        scheduleResult = type;
    }
    return { priceResult, scheduleResult };
  };

  const { priceResult, scheduleResult } = getScheduleType(
    price?.scheduleType || RuleTypes.FREE,
  );

  const [scheduleType, setScheduleType] = useState(
    scheduleResult || ScheduleType.PER_DAY,
  );
  const [priceType, setPriceType] = useState(priceResult || RuleTypes.FREE);

  const priceTiered = price?.tieredPriceConditions?.map((item) => {
    return {
      ...item,
      id: uniqueId(),
      price: Number(item.price?.substring(1)).toFixed(2),
    };
  });

  const [tierPrices, setTierPrices] = useState(
    priceTiered?.length ? priceTiered : [tierPriceItem(), tierPriceItem()],
  );

  const [triggerCreatePricing, createPricingQueryResult] =
    useCreatePricingModelMutation();
  const [triggerEditPricing, editPricingQueryResult] =
    useEditPricingModelMutation();
  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint?.data ? endPoint.data.entities : [],
      }),
    },
  );
  const [triggerIdleRateValidate, validateResponse] =
    useValidateIdleFeeMutation();

  const [idleFeeDetails, setIdleFeeDetails] = useState({
    enable: !!price?.idleRate,
    idleRate: price?.idleRate ? Number(price?.idleRate).toFixed(2) : '',
  });

  const chargersDrodownList = useMemo(() => {
    return locations
      .map((location) => {
        const children = location.chargers
          ?.filter((charger: any) => {
            // we only display undefault chargers for default pricing rule.
            if (!isDefaultPricingRule) return true;
            return !defaultPricingRule?.chargers.some(
              (defaultCharger) => defaultCharger.chargerId === charger.id,
            );
          })
          .map((charger: any) => {
            return {
              id: charger.id,
              label: charger.displayName,
              selected: chargers.includes(charger.id),
            };
          });

        if (children?.length === 0) {
          return null;
        }
        return {
          id: location.id,
          label: location.name,
          children: children || [],
          selected: children?.length
            ? children?.every((child) => child.selected)
            : false,
        };
      })
      .filter((location) => location);
  }, [chargers, locations, isDefaultPricingRule, defaultPricingRule]);

  const scheduleItems = useMemo(() => {
    return Object.keys(ScheduleType).map((key) => {
      return {
        id: key,
        label: getScheduleLabel(key),
        selected: key === scheduleType,
        disabled:
          editPricingQueryResult.isLoading ||
          createPricingQueryResult.isLoading,
      };
    });
  }, [
    scheduleType,
    editPricingQueryResult.isLoading,
    createPricingQueryResult.isLoading,
  ]);

  const handleCalendarChange = (data: DayPrice[]) => {
    setDayPrices(data);
  };

  const handleScheduleTypeChange = (items: CheckBoxData[]) => {
    items.forEach((item) => {
      if (item && item.selected && item?.id) {
        setScheduleType(item.id as ScheduleType);
      }
    });
  };

  const handleChargersChange = (items: any) => {
    const newChargers: string[] = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        if (child.selected) {
          newChargers.push(child.id);
        }
      });
    });
    setChargers(newChargers);
  };

  const onValidation = () => {
    if (_.isEmpty(_.trim(name))) {
      return false;
    }
    // Disabling save button until user enters the duration & price for tiered pricing rule
    if (priceType === RuleTypes.TIERED) {
      const hasInvalidValue = tierPrices?.some((item, index) => {
        // check price
        if (_.isEmpty(item.price) || !validateCurrency(item.price, false)) {
          return true;
        }
        // check duration
        if (
          index !== tierPrices.length - 1 &&
          (_.isEmpty(item.duration) || Number(item.duration) === 0)
        ) {
          return true;
        }
        return false;
      });
      if (hasInvalidValue) {
        return false;
      }
    }

    // disable when idle pricing enable
    if (
      idleFeeDetails.enable &&
      (checkIdleFeeError(idleFeeDetails, company?.currency) ||
        !idleFeeDetails.idleRate ||
        validateResponse.isError ||
        !validateCurrency(idleFeeDetails.idleRate))
    ) {
      return false;
    }

    return true;
  };

  const schedule = () => {
    let result;
    if (priceType === RuleTypes.TIME_OF_USE) {
      result = scheduleType;
    } else {
      result = priceType;
    }
    return result;
  };

  // tieredPrices modify request data / updates start boundary

  const tieredPrices = tierPrices.map((item, i) => {
    const response = {
      ...item,
      price: `$${item.price}`,
    };

    delete response.id;
    return response;
  });

  const savePricing = (data: any) => {
    const request = { ...data };
    if (!chargers?.length) {
      delete request.chargerIds;
    }
    if (priceType !== RuleTypes.TIERED) {
      request.tieredPriceConditions = [];
    } else {
      request.dayPrices = [];
    }
    if (price?.id) {
      triggerEditPricing(request);
    } else {
      delete request.id;
      triggerCreatePricing(request);
    }
  };

  const handleSave = () => {
    // Format these info for server need
    const dayPriceWithDollar = dayPrices?.map((dayPrice) => {
      const day = capitalizeFirstLetter(dayPrice.day);
      return {
        day,
        pricePeriods: dayPrice.pricePeriods.map((pp) => ({
          ...pp,
          price: pp.price,
        })),
      };
    });

    savePricing({
      id: price?.id,
      displayName: name,
      // for default pricing rule, we should concat the existing chargers and new selected chargers.
      chargerIds: isDefaultPricingRule
        ? defaultPricingRule?.chargers
            .map((charger) => charger.chargerId)
            .concat(chargers)
        : chargers,
      scheduleType: schedule(),
      tieredPriceConditions: tieredPrices,
      dayPrices: dayPriceWithDollar,
      idleRate: idleFeeDetails.enable ? idleFeeDetails.idleRate : 0,
    });
  };

  const handleLeave = () => {
    navigateWithCompanyId(`../${RoutePath.PRICING}`, companyId, {
      replace: true,
    });
  };

  const handleCancel = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('pricing_unsaved_chargers'),
      message: t('pricing_unsaved_message'),
      buttons: [
        {
          label: t('pricing_go_back'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('pricing_leave_without_saving'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: handleLeave,
        },
      ],
    });
  };

  const renderChargersOverlapWarn = () => {
    if (isDefaultPricingRule && chargers.length > 0) {
      return (
        <div className='mt-[18px]'>
          <Label
            icon={Info}
            text={t('edit_default_pricing_rule_warn')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </div>
      );
    }
    if (!isChargerFromDefaultRule(defaultPricingRule, chargers, price)) {
      return (
        <div className='mt-[18px]'>
          <Label
            icon={Info}
            text={t('pricing_chargers_overlap_warn')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
          />
        </div>
      );
    }
    return null;
  };

  const handleAddCharger = () => {
    setShowDefaultChargerDropdown(true);
  };

  const renderAddChargersButton = () => {
    if (
      !isDefaultPricingRule ||
      (isDefaultPricingRule && showDefaultChargeDropdown)
    ) {
      return null;
    }
    return (
      <div>
        <Label
          text={t('chargers_add_label')}
          svgIcon={AddPlus2}
          svgColor={theme.navigationSelectedColor}
          type={LabelType.LABEL_S}
          color={theme.navigationSelectedColor as ColorType}
          onClick={handleAddCharger}
        />
      </div>
    );
  };

  const renderChargersDropdown = () => {
    if (isDefaultPricingRule && !showDefaultChargeDropdown) {
      return null;
    }
    return (
      <Dropdown
        placeholder={t('pricing_chargers_holder')}
        placeholderLabelType={LabelType.DROPDOWN_HEADER}
        headerWidth={450}
        items={chargersDrodownList}
        type={DropdownType.CHECKBOX_TREE}
        showPillHeader
        onItemClick={(items: any) => handleChargersChange(items)}
        showFooter
        dataTestId='selectChargers'
      />
    );
  };

  const renderDefaultChargersInfo = () => {
    if (!isDefaultPricingRule) {
      return null;
    }
    return (
      <div className='flex flex-row items-center mt-[16px]'>
        <Label
          className='w-[128px]'
          text={t('pricing_chargers')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
        />
        <div className='inline-flex flex-wrap gap-1 overflow-x-hidden w-[900px]'>
          {defaultPricingRule?.chargers.map((charger) => {
            return <Pill key={charger.chargerId} label={charger.chargerName} />;
          })}
        </div>
      </div>
    );
  };

  const renderChargersDropdownInfo = () => {
    if (defaultPricingRule === undefined) {
      return null;
    }
    return (
      <div className='flex flex-row items-center mt-[16px]'>
        <Label
          className='w-[128px]'
          text={
            isDefaultPricingRule
              ? showDefaultChargeDropdown
                ? t('pricing_rule_chargers_to_add')
                : ''
              : t('pricing_chargers')
          }
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
        />
        <div className='flex flex-col'>
          {renderAddChargersButton()}
          {renderChargersDropdown()}
        </div>
      </div>
    );
  };

  const renderPricingSchedule = () => {
    return (
      <Form
        onSubmit={handleSave}
        queryResult={
          price?.id ? editPricingQueryResult : createPricingQueryResult
        }
        onQuerySuccess={() => {
          navigateWithCompanyId(`../${RoutePath.PRICING}`, companyId, {
            replace: true,
          });
        }}
        onQueryFailed={() => {
          navigateWithCompanyId(`../${RoutePath.PRICING}`, companyId, {
            replace: true,
          });
        }}
      >
        <div className='flex flex-col gap-[18px] mt-4'>
          <div className='flex flex-col'>
            <div className='flex flex-row h-[40px] items-center'>
              <Label
                className='w-[128px]'
                text={t('pricing_rule_name')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
              <FormInput
                placeholder={t('pricing_rule_name_holder')}
                onChange={(event: any) => setName(event.target.value)}
                defaultValue={name.trimStart()}
                width='335px'
                disabled={isDefaultPricingRule}
                dataTestId='pricingRuleName'
              />
            </div>
            {renderDefaultChargersInfo()}
            {renderChargersDropdownInfo()}

            {renderChargersOverlapWarn()}
            <PriceForm
              priceType={priceType}
              setPriceType={setPriceType}
              scheduleItems={scheduleItems}
              scheduleType={scheduleType}
              dayPrices={price?.dayPrices}
              tierPrices={tierPrices}
              idleFeeDetails={idleFeeDetails}
              setIdleFeeDetails={setIdleFeeDetails}
              setTierPrices={setTierPrices}
              handleCalendarChange={handleCalendarChange}
              handleScheduleTypeChange={handleScheduleTypeChange}
              disabled={
                editPricingQueryResult.isLoading ||
                createPricingQueryResult.isLoading
              }
              companyInfo={company}
              triggerIdleRateCheck={triggerIdleRateValidate}
              isError={validateResponse.isError}
            />
          </div>
          <div className='flex flex-row-reverse mt-[20px] gap-2'>
            <Button
              label={t('save')}
              size={ButtonSize.SMALL}
              type={ButtonType.PRIMARY}
              disabled={!onValidation()}
              isSumbit
              dataTestId='pricingRuleSave'
            />
            <Button
              label={t('cancel')}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              onClick={handleCancel}
            />
          </div>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <Card title={price ? t('pricing_edit_rule') : t('pricing_new_rule')}>
        {renderPricingSchedule()}
      </Card>
    </div>
  );
};
