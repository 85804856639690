import { useCallback, useEffect, useRef, useState } from 'react';
import { CheckBox } from '..';
import { useCompany } from '../../../hooks/useCompany';
import { GridBody } from './Grid-Body.component';
import { GridHeader } from './Grid-Header.component';
import { GridPager } from './Grid-Pager.component';
import './Grid.css';
import { GridColumnType } from './enums/Grid-Column-Type.enum';
import { GridColumn } from './types/Grid-Column.interface';

export interface InputProps {
  columns: GridColumn[];
  data?: any[];
  primaryKey: string;
  totalPage?: number;
  loadPage?: (page: number, filter?: any) => void;
  pageIndex?: number;
  onRowClick?: (rowData: any) => void;
  local?: boolean;
  checkable?: boolean;
  radioBtn?: boolean;
  onCheckboxChanges?: Function;
  onRadioBtnChange?: Function;
  rowCount?: number;
  hidePager?: boolean;
  hasBottomBorder?: boolean;
  skeletonRowCount?: number;
  isLoading?: boolean;
  hasRowBottomBorder?: boolean;
  scrollTableClass?: string;
  verticalAlign?: string;
  dataTestId?: string;
  showPageSearch?: boolean;
  setIsLocalTimezone?: Function;
  isLocalTimezone?: boolean;
  switchClass?: string;
  switchBtnClass?: string;
  translateClass?: string;
}

export const Grid = ({
  columns,
  data,
  primaryKey,
  totalPage,
  loadPage,
  pageIndex = 1,
  local = false,
  onRowClick,
  checkable = false,
  radioBtn = false,
  onCheckboxChanges,
  onRadioBtnChange,
  rowCount,
  hidePager = false,
  hasBottomBorder = true,
  skeletonRowCount = 8,
  isLoading = false,
  hasRowBottomBorder = false,
  scrollTableClass,
  verticalAlign,
  dataTestId = '',
  showPageSearch = true,
  setIsLocalTimezone,
  isLocalTimezone = false,
  switchClass,
  switchBtnClass,
  translateClass,
}: InputProps) => {
  const checkRef = useRef();
  const [currentPage, setCurrentPage] = useState<number>(pageIndex);
  const [checkboxStatus, setCheckboxStatus] = useState(
    new Array<{ id: number; selected: boolean }>((data?.length || 0) + 1),
  );
  const [headerCheckboxSelected, setHeaderCheckboxSelected] = useState(false);
  const [initCheckbox, setInitCheckbox] = useState(false);
  const [selectedRadioBtnId, setSelectedRadioBtnId] = useState(-1);
  const columnsToDisplay = columns.filter(
    (column) => !(column.hidden || false),
  );

  const { companyId } = useCompany();

  useEffect(() => {
    setSelectedRadioBtnId(-1);
  }, [companyId]);

  const loadMoreData = useCallback(
    (page: number) => {
      if (page >= 0 && page <= (totalPage || 1)) {
        setCurrentPage(page);

        if (loadPage) {
          loadPage(page);
        }
      }
    },
    [loadPage, totalPage],
  );

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (
      checkable &&
      data &&
      data.length > 0 &&
      checkboxStatus &&
      !initCheckbox
    ) {
      const initCheckboxArray = new Array(data?.length);
      for (let i = 0; i <= (data?.length || 0); i += 1) {
        initCheckboxArray[i] = { id: i, selected: false };
      }
      setCheckboxStatus(initCheckboxArray);
      setInitCheckbox(true);
    }
  }, [checkable, data]);

  const isAnyCheckBoxSelected = (
    checkboxStatusArray: { id: number; selected: boolean }[],
  ) => {
    return checkboxStatusArray.some((item) => item.selected === true);
  };
  const allCheckBoxSelected = (
    checkboxStatusArray: { id: number; selected: boolean }[],
  ) => {
    return checkboxStatusArray.every((item) => item.selected === true);
  };
  const allCheckBoxUnselected = (
    checkboxStatusArray: { id: number; selected: boolean }[],
  ) => {
    return checkboxStatusArray.every((item) => item.selected === false);
  };

  const updateCheckboxStatus = (selected: boolean, index: number) => {
    const headerCheckboxRef: any = checkRef.current;
    headerCheckboxRef.indeterminate = false;
    index = index === -1 ? index + 1 : index;
    let localCopyCheckboxStatus = checkboxStatus;
    localCopyCheckboxStatus[index] = { id: index, selected };
    if (index === 0) {
      // header checkbox has been clicked
      // if condition handles case : when all or no row chechbox are selected
      if (
        allCheckBoxSelected(
          localCopyCheckboxStatus.slice(1, localCopyCheckboxStatus.length),
        ) ||
        allCheckBoxUnselected(
          localCopyCheckboxStatus.slice(1, localCopyCheckboxStatus.length),
        )
      ) {
        localCopyCheckboxStatus = checkboxStatus.map((checkbox) => {
          return { id: checkbox.id, selected };
        });
        setHeaderCheckboxSelected(true);
      } else if (
        isAnyCheckBoxSelected(
          localCopyCheckboxStatus.slice(1, localCopyCheckboxStatus.length),
        )
      ) {
        // else if condition handles case : when one or more row chechbox is selected
        localCopyCheckboxStatus = checkboxStatus.map((checkbox) => {
          return { id: checkbox.id, selected: true };
        });
        setHeaderCheckboxSelected(true);
      }
    } else if (
      isAnyCheckBoxSelected(
        localCopyCheckboxStatus.slice(1, localCopyCheckboxStatus.length),
      )
    ) {
      // any row chechbox is clicked
      if (
        allCheckBoxSelected(
          localCopyCheckboxStatus.slice(1, localCopyCheckboxStatus.length),
        )
      ) {
        localCopyCheckboxStatus[0] = { id: 0, selected: true };
        setHeaderCheckboxSelected(true);
        // headerCheckboxRef.checked = true;
      } else {
        localCopyCheckboxStatus[0] = { id: 0, selected: false };
        setHeaderCheckboxSelected(false);
        headerCheckboxRef.indeterminate = true;
      }
    }
    setCheckboxStatus(localCopyCheckboxStatus);
    onCheckboxChanges && onCheckboxChanges(localCopyCheckboxStatus);
  };

  const updateRadioBtnStatus = (selected: boolean, index: number) => {
    setSelectedRadioBtnId(index);
    onRadioBtnChange && onRadioBtnChange(index);
  };

  const renderColumns = () => {
    if (checkable && data) {
      return [
        {
          key: '',
          title: '',
          type: GridColumnType.CHECKBOX,
          component: (row: any) => (
            <CheckBox
              index={row.id + 1}
              name={row.id + 1}
              onChange={updateCheckboxStatus}
              selected={checkboxStatus[row.id + 1]?.selected}
            />
          ),
        },
        ...columnsToDisplay,
      ];
    }
    if (radioBtn && data) {
      return [
        {
          key: '',
          title: '',
          type: GridColumnType.RADIOBTN,
          component: (row: any) => (
            <div className='w-3'>
              <CheckBox
                index={row.id}
                name={row.id}
                onChange={updateRadioBtnStatus}
                selected={row.id === selectedRadioBtnId}
                singleSelection
                // dataTestId={dataTestId}
              />
            </div>
          ),
        },
        ...columnsToDisplay,
      ];
    }
    return columnsToDisplay;
  };

  const renderGridTable = () => (
    <table className='table-auto min-w-full' data-testid={dataTestId}>
      <GridHeader
        columns={renderColumns()}
        onChange={updateCheckboxStatus}
        checkRef={checkRef}
        selected={headerCheckboxSelected}
        setIsLocalTimezone={setIsLocalTimezone}
        isLocalTimezone={isLocalTimezone}
        switchClass={switchClass}
        switchBtnClass={switchBtnClass}
        translateClass={translateClass}
      />

      <GridBody
        onRowClick={onRowClick}
        columns={renderColumns()}
        data={data}
        local={local}
        pageIndex={pageIndex}
        primaryKey={primaryKey}
        rowCount={rowCount}
        hasBottomBorder={hasBottomBorder}
        skeletonRowCount={skeletonRowCount}
        isLoading={isLoading}
        hasRowBottomBorder={hasRowBottomBorder}
        verticalAlign={verticalAlign}
        dataTestId={dataTestId}
      />
    </table>
  );

  return (
    <div className='flex flex-col' data-testid={dataTestId}>
      {scrollTableClass ? (
        <div className={scrollTableClass}>{renderGridTable()}</div>
      ) : (
        renderGridTable()
      )}
      {/* If total page = 1 -> hide pagination  */}
      {!hidePager &&
        totalPage !== undefined &&
        totalPage > 1 &&
        data &&
        data.length > 0 && (
          <GridPager
            totalPage={totalPage}
            currentPage={currentPage}
            loadPage={loadMoreData}
            showPageSearch={showPageSearch}
          />
        )}
    </div>
  );
};
