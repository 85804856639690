export const copyToClipboard = (text: string, setShowTooltip: any) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      setShowTooltip(text);
      setTimeout(() => {
        setShowTooltip(''); // false
      }, 1000);
    })
    .catch((error) => {
      console.error('Failed to copy text to clipboard:', error);
    });
};
