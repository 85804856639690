import { TerminalObj } from '../../../stores/types/payter.interface';

export const getWebLanguage = (lang: string) => {
  if (lang.toLowerCase() === 'en') {
    return 'en-us';
  }
  return lang.toLowerCase();
};

export const getApisLanguage = (lang: string) => {
  if (lang.toLowerCase() === 'en-us') {
    return 'EN';
  }
  return lang.toUpperCase();
};

export const sortTerminals = (terminals: TerminalObj[]) => {
  return [...terminals].sort((terminal: TerminalObj) => {
    return terminal.id ? 1 : -1;
  });
};
