import { addMonths, differenceInMonths } from 'date-fns';
import _ from 'lodash';
import { Statistics } from '../../stores/types';
import { formatDate } from '../../utils/Date.Util';
import { convertToThousandSeperator } from '../../utils/Number.Util';

export const getFormattedSimpleStats = (
  stats: Statistics[] | undefined,
  dateRange: Date[],
  selCurrency: string,
  selChart: string,
) => {
  if (!stats || stats.length === 0) return [];
  const diffMonths = Math.abs(differenceInMonths(dateRange[1], dateRange[0]));
  const initStats = [];
  // prepare base stats for chart
  for (let i = 0; i <= diffMonths; i += 1) {
    initStats.push({
      date: formatDate(addMonths(dateRange[0], i), 'yyyy-LL'),
      transactions: 0,
      revenue: 0,
      energyDeliveredKWh: 0,
    });
  }

  const result = stats
    .filter((item) => {
      if (selChart === 'revenue') {
        return item.revenueCurrency === selCurrency;
      }
      return true;
    })
    .reduce((formattedStats, currentStat) => {
      const stat = formattedStats.find(
        (formattedStat: any) => formattedStat.date === currentStat.date,
      );
      if (stat) {
        stat.transactions += currentStat.transactions;
        stat.revenue += currentStat.revenue;
        stat.energyDeliveredKWh += currentStat.energyDeliveredKWh;
      } else {
        formattedStats.push({
          date: formatDate(new Date(currentStat.date), 'yyyy-LL'),
          transactions: currentStat.transactions || 0,
          revenue: currentStat.revenue || 0,
          energyDeliveredKWh: currentStat.energyDeliveredKWh || 0,
        });
      }
      return formattedStats;
    }, initStats);
  return result.sort((a: any, b: any) =>
    new Date(a.date) < new Date(b.date) ? -1 : 1,
  );
};

export const getStatCurrencies = (stats: Statistics[] | undefined) => {
  if (!stats || stats.length === 0) return [];
  const data = _.uniq(
    stats.map((item: Statistics) => item.revenueCurrency || 'CAD'),
  );

  // moving the CAD currency into first position
  data.forEach((item: string, ind: number) => {
    if (item === 'CAD') {
      data.splice(ind, 1);
      data.unshift(item);
    }
  });

  return data;
};

export type SummaryContentItem = {
  prefix?: string; // $ string that display in the front
  val?: string; // orginal number
  formattedVal?: string; // formatted number
  abbr?: string; // K, M, B
  unit?: string; // kWh/GWh/xxx
  suffixes?: string; // string that display in the end CAD/USD
  formattedUnit?: string;
};

export const formatSessions = (num: number) => {
  if (num < 100000) {
    return {
      val: convertToThousandSeperator(num),
      formattedVal: convertToThousandSeperator(num),
    };
  }
  if (num >= 100000 && num < 1000000) {
    return {
      val: convertToThousandSeperator(num),
      formattedVal: `${convertToThousandSeperator(num / 1000, 1)}`,
      abbr: 'K',
    };
  }
  if (num >= 1000000) {
    return {
      val: convertToThousandSeperator(num),
      formattedVal: `${convertToThousandSeperator(num / 1000000, 1)}`,
      abbr: 'M',
    };
  }
};

export const formatEnergy = (num: number) => {
  if (num < 1000) {
    return {
      val: convertToThousandSeperator(num, 1),
      unit: 'kWh',
      formattedVal: convertToThousandSeperator(num, 1),
      formattedUnit: 'kWh',
    };
  }
  if (num >= 1000 && num < 1000000) {
    return {
      val: convertToThousandSeperator(num, 1),
      unit: 'kWh',
      formattedVal: convertToThousandSeperator(num / 1000, 1),
      formattedUnit: 'MWh',
    };
  }
  if (num >= 1000000) {
    return {
      val: convertToThousandSeperator(num, 1),
      unit: 'kWh',
      formattedVal: convertToThousandSeperator(num / 1000000, 1),
      formattedUnit: 'GWh',
    };
  }
};

export const formatFees = (num: number, currency: string) => {
  if (num < 10000) {
    return {
      prefix: '$',
      val: `${convertToThousandSeperator(num, 2)}`,
      formattedVal: `${convertToThousandSeperator(num, 2)}`,
      abbr: '',
      suffixes: currency,
    };
  }
  if (num >= 10000 && num < 1000000) {
    return {
      prefix: '$',
      val: `${convertToThousandSeperator(num, 2)}`,
      formattedVal: `${convertToThousandSeperator(num / 1000, 1)}`,
      abbr: 'K',
      suffixes: currency,
    };
  }
  if (num >= 1000000) {
    return {
      prefix: '$',
      val: `${convertToThousandSeperator(num, 2)}`,
      formattedVal: `${convertToThousandSeperator(num / 1000000, 1)}`,
      abbr: 'M',
      suffixes: currency,
    };
  }
};
