import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ChargerCheckboxTree, CheckBox, ColorType, FormInput } from '..';
import { ChevronLeft, Search } from '../../../assets/icons';
import {
  Notification,
  NotificationCheckboxData,
  Resource,
} from '../../../stores/types/notification.interface';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { MODAL_TYPES, useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const NotificationChargerModal = () => {
  const { t } = useTranslation();
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const {
    title = t('notification_modal_charger'),
    message = t('notification_modal_charger_message'),
    // width = '600px',
    notification,
    generatedData,
    isMultiCompany,
    totalChargers,
  } = modalProps;
  const [newNodes, setNewNodes] =
    useState<NotificationCheckboxData[]>(generatedData);
  const [notificationData, setNotificationData] =
    useState<Notification[]>(notification);
  const [searchStr, setSearchStr] = useState<string>('');
  const [filterChargerCount, setFilterChargerCount] = useState(0);

  const handleBack = () => {
    showModal(MODAL_TYPES.NOTIFICATION_MODAL, { notification });
  };

  const handleClose = () => {
    hideModal();
  };

  const handleConfirm = () => {
    const companyList: Resource[] = [];
    const locationList: Resource[] = [];
    const chargerList: Resource[] = [];
    const newResources = {
      companies: companyList,
      locations: locationList,
      chargers: chargerList,
    };
    newNodes?.forEach((node: NotificationCheckboxData) => {
      if (node.selected) {
        isMultiCompany
          ? newResources.companies.push({ id: node.id })
          : newResources.locations.push({ id: node.id });
      } else if (node.children) {
        node.children.forEach((child) => {
          if (child.selected) {
            isMultiCompany
              ? newResources.locations.push({ id: child.id })
              : newResources.chargers.push({ id: child.id });
          } else if (child.children) {
            child.children.forEach((subChild) => {
              if (subChild.selected) {
                newResources.chargers.push({ id: subChild.id });
              }
            });
          }
        });
      }
    });
    const udpatedNotification = notificationData?.map((notif) => {
      return {
        ...notif,
        resources: newResources,
      };
    });
    setNotificationData(udpatedNotification);
    showModal(MODAL_TYPES.NOTIFICATION_MODAL, {
      data: newNodes,
      notification: udpatedNotification,
    });
  };

  const onCheckboxChange = (
    nodes: any,
    existingNotification: Notification[],
  ) => {
    setNewNodes(nodes);
    setNotificationData(existingNotification);
  };

  const handleAllChargerCheckbox = (select: boolean, index: number) => {
    const updatedNodes = newNodes?.map((node) => {
      const updateChildren = node?.children?.map((child) => {
        const grandChildren = child?.children?.map((grandChild) => {
          return {
            ...grandChild,
            selected: select,
          };
        });
        return {
          ...child,
          children: grandChildren,
          selected: select,
        };
      });
      return {
        ...node,
        children: updateChildren,
        selected: select,
      };
    });
    setNewNodes(updatedNodes);
  };

  const handleSearch = (event: any) => {
    setSearchStr(event.target.value);
  };

  const handleCancelClick = () => {
    setSearchStr('');
  };

  useEffect(() => {
    if (searchStr) {
      const count = newNodes.reduce((previous, current) => {
        const currentValue =
          current &&
          current.noOfChargers &&
          current.label.toLowerCase().includes(searchStr.toLowerCase())
            ? current.noOfChargers
            : 0;
        return previous + currentValue;
      }, 0);
      setFilterChargerCount(count);
    }
  }, [searchStr]);

  const renderHeader = () => {
    return (
      <div className='flex items-center justify-center sticky top-0 h-16 bg-white z-50'>
        <Icon
          src={ChevronLeft}
          className='w-[24px] h-[24px] hover:bg-grey2 rounded-full flex items-center justify-center absolute left-[0px] cursor-pointer'
          onClick={handleBack}
        />
        <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className='pt-2'>
        <Label text={message} type={LabelType.BODY3} />
        <div className='pt-4'>
          <div className=''>
            <div>
              <div className=''>
                <FormInput
                  defaultValue={searchStr}
                  placeholder={
                    isMultiCompany
                      ? t('notification_modal_charger_placeholder_multicompany')
                      : t('notification_modal_charger_placeholder_single')
                  }
                  icon={Search}
                  onChange={handleSearch}
                  iconClassName='pl-3'
                  inputIconPadding='pl-10'
                  width='490px'
                  showFoundResult={searchStr !== ''}
                  /* eslint-disable react/jsx-wrap-multilines */
                  foundResult={
                    <Trans
                      i18nKey='notification_charger_search_result'
                      values={{
                        found: filterChargerCount,
                        total: totalChargers,
                      }}
                    />
                  }
                  onCancelClick={handleCancelClick}
                  dataTestId='searchByCompany'
                />
              </div>
              <div className='pt-2'>
                <div className='pt-[12px] pb-[12px] pr-[8px] pl-[8px] border-b border-grey3'>
                  <CheckBox
                    name='all_chargers'
                    label={t('notification_modal_charger_checkbox')}
                    translationOn
                    selected={
                      newNodes &&
                      newNodes?.every((node) => node.selected === true)
                    }
                    onChange={handleAllChargerCheckbox}
                  />
                </div>
                <ChargerCheckboxTree
                  defaultNodes={newNodes}
                  isMultipleCompany={isMultiCompany}
                  filterStr={searchStr}
                  checkedNotification={notification || []}
                  onChange={onCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='pt-6 pb-5 bottom-0 sticky bg-white'>
        <div className='w-auto'>
          <Button
            label={t('confirm')}
            type={ButtonType.PRIMARY}
            size={ButtonSize.MEDIUM_FULL}
            onClick={handleConfirm}
            dataTestId='confirmSelectCompany'
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: '47%',
          left: '50%',
          width: '540px',
          // height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 8,
          overflow: 'auto',
          position: 'relative',
          // minWidth: '540px',
          maxHeight: '600px',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <div data-testid='notification-modal' className='flex flex-col px-5'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
