import { IState } from './interface';
import states from './state.json';

const sortStates = (statesList: IState[]) => {
  return statesList.sort((prev, next) => {
    const prevState = prev.name.toUpperCase();
    const nextState = next.name.toUpperCase();

    if (prevState < nextState) {
      return -1; // prevState comes before nextState
    }
    if (prevState > nextState) {
      return 1; // prevState comes after nextState
    }
    return 0; // names are equal
  });
};

const getStatesOfCountry = (countryCode: string) => {
  const filteredStates = states.filter(
    (state) => state.countryCode === countryCode,
  );
  return sortStates(filteredStates);
};

const getAllStates = () => {
  return [...states];
};

const getStateByCodeAndCountry = (isoCode: string) => {
  const filteredStates = states.filter((state) => state.isoCode === isoCode);
  return sortStates(filteredStates);
};
export default { getStatesOfCountry, getAllStates, getStateByCodeAndCountry };
