import { Notification } from '../stores/types/notification.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const notificationApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotifications: builder.query<Notification[], SCOPE_TYPE>({
      query: (scope) => ({
        url: `/internal/core/v2/${API_KEYS.NOTIFICATIONS}`,
        params: { scope },
        method: 'GET',
        needCompanyId: false,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.NOTIFICATIONS, id: 'id' },
      ],
    }),
    editNotification: builder.mutation<
      any,
      { notification: Notification; scope: SCOPE_TYPE }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.NOTIFICATIONS}/${payload.notification.id}`,
        params: { scope: payload.scope },
        body: payload.notification,
        method: 'PUT',
        needCompanyId: false,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.NOTIFICATIONS, id: 'id' },
      ],
    }),
  }),
});

export const {
  useFetchNotificationsQuery,
  useLazyFetchNotificationsQuery,
  useEditNotificationMutation,
} = notificationApi;
