import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { ErrorWarningtraiangle } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';

export interface WarningModalProp {
  locationName: string;
  callbackFunction: Function;
}
export const ActionWarningModal = ({
  callbackFunction,
  locationName = '',
}: WarningModalProp) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  return (
    <div className='flex flex-col gap-4 pb-5'>
      <div className='flex flex-row gap-3 p-5 bg-warning0 border-2 border-warning1 rounded-[8px]'>
        <Icon
          src={ErrorWarningtraiangle}
          color={ColorType.WARNING1}
          size={IconSize.SIZE_24x24}
        />
        <div className='flex flex-col gap-2'>
          <Label
            type={LabelType.LABEL_M}
            text={t('pm_menu_action_warning_header')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            text={t('pm_menu_action_warning_desc')}
          />
        </div>
      </div>
      <Label
        type={LabelType.BODY3}
        text={t('pm_menu_action_warning_content_1')}
      />
      <Label
        type={LabelType.BODY3}
        text={t('pm_menu_action_warning_content_2')}
      />
      <div className='flex flex-row gap-1'>
        <Label
          type={LabelType.BODY3}
          text={t('pm_menu_action_warning_content_3')}
        />
        <Label
          type={LabelType.LABEL_S}
          color={ColorType.BRAND2}
          text={t('pm_menu_action_warning_content_4')}
        />
      </div>
      <div className='text-sm font-normal font-inter text-black'>
        <Trans
          i18nKey='pm_menu_action_warning_content_5'
          values={{ locationName }}
        />
      </div>
      <div className='flex flex-row gap-2'>
        <Button
          label={t('pm_menu_action_button_1')}
          type={ButtonType.SECONDARY}
          size={ButtonSize.SMALL_FULL}
          onClick={() => {
            hideModal();
            callbackFunction(false);
          }}
        />
        <Button
          label={t('pm_menu_action_button_2')}
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL_FULL}
          onClick={() => {
            hideModal();
            localStorage.removeItem('showEditWarning');
            callbackFunction(true);
          }}
        />
      </div>
    </div>
  );
};
