import React from 'react';
import { ColorType, Label, LabelType } from '../../_ui';

interface IProps {
  name: string;
  collection: string;
  currency: string;
  backGroundColor?: string;
}

const ReportItem = ({
  name,
  collection,
  currency,
  backGroundColor = 'bg-grey0',
}: IProps) => {
  return (
    <div
      className={`block h-[120px] w-full ${backGroundColor}  p-4 rounded-2xl`}
    >
      <Label
        type={LabelType.LABEL_M_MEDIUM}
        color={ColorType.GREY5}
        text={name}
      />
      <div className='flex justify-between mt-7'>
        <Label type={LabelType.H2} color={ColorType.BLACK} text={collection} />
        <Label
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY5}
          text={currency}
          className='flex items-end'
        />
      </div>
    </div>
  );
};

export default ReportItem;
