/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
import { parsePhoneNumber } from 'libphonenumber-js';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input-visio';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { USER_ROLE, useAuth, useKeyPress } from '../../hooks';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useLazyFetchCompaniesQuery } from '../../services/company.api';
import { useLoginAnalyticsMutation } from '../../services/google.api';
import { useAddUserMutation } from '../../services/usergroups.api';
import { SCOPE_TYPE } from '../../services/utils';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { ColorType, Label, LabelType, Snackbar } from '../_ui';
import { validateEmail } from '../_ui/modal/utils';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';

export const LoginVerifyCodeStep = memo((props: any) => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const isEnterPress = useKeyPress('Enter');
  // state to check if the resend code was clicked
  const [resendOtpClicked, setResendOtpClicked] = useState<boolean>(false);
  const location = useLocation();
  const [addUser, { isSuccess: isAddingUserSuccess }] = useAddUserMutation();
  const auth = useAuth();
  const { loading, setLoading } = auth;
  const { navigateWithCompanyId, navigate } = useNavigateWithSearchParam();
  const [
    triggerFetchCompanies,
    {
      data: companies,
      isSuccess: isFetchCompaniesSuccess,
      isError: isFetchCompaniesError,
      isLoading: isCompaniesLoading,
    },
  ] = useLazyFetchCompaniesQuery();
  const [triggerLoginGA] = useLoginAnalyticsMutation();
  // the timer on the OTP page
  const [otpTimer, setOtpTimer] = useState<string>('');

  // the one time code sent to email / phone
  const [otp, setOtp] = useState<string>('');

  const otpInputRef = useRef(null);

  const otpChanged = async (evt: any) => {
    setOtp(evt);
  };

  const showSnackbar = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM,
      alertType: AlertType.ERROR,
      duration: 3000,
    });
  };

  useEffect(() => {
    if (isAddingUserSuccess && props.isActive && auth.user) {
      triggerFetchCompanies(
        auth.role == USER_ROLE.SUPPORT ? SCOPE_TYPE.ALL : SCOPE_TYPE.COMPANY,
      );
    }
  }, [isAddingUserSuccess, auth.user]);

  useEffect(() => {
    if (auth.user?.username && props.isActive) {
      // for new invite users
      props.onLoading(true);
      if (props.inviteId && props.uuid) {
        addUser({ inviteId: props.inviteId, uuid: props.uuid });
      } else {
        triggerFetchCompanies(
          auth.role == USER_ROLE.SUPPORT ? SCOPE_TYPE.ALL : SCOPE_TYPE.COMPANY,
        );
      }
      triggerLoginGA();
    }
  }, [auth.user]);

  useEffect(() => {
    if (isFetchCompaniesError && props.isActive) {
      props.onLoading(false);
      setLoading(false);
      setOtp('');
      props.nextStep();
    }
  }, [isFetchCompaniesError]);

  useEffect(() => {
    if (isFetchCompaniesSuccess && props.isActive) {
      props.onLoading(false);
      setLoading(false);
      localStorage.setItem('inviteModal', 'true');
      // Power management edit warning per login session
      localStorage.setItem('showEditWarning', 'true');
      if (companies && companies.length > 0 && auth.user) {
        const udateCompanyAndNav = async () => {
          const companyId = auth.user.attributes.profile?.lastActiveCompanyId;
          if (
            companyId === '' ||
            !companies.some((comp) => comp.id === companyId)
          ) {
            await auth.updateCompanyId(companies[0].id);
          }
          navigateWithCompanyId('/', companyId || companies[0]?.id, {
            replace: true,
          });
        };
        udateCompanyAndNav();
      } else {
        // users does not in any companies
        setOtp('');
        navigate(`/${RoutePath.NO_ACCESS}`, { replace: true });
      }
    }
  }, [isFetchCompaniesSuccess]);

  useEffect(() => {
    if (auth.error && props.isActive) {
      setLoading(false);
      setOtp('');
      // @ts-ignore
      otpInputRef.current.focusInput(0);
      showSnackbar(t('login_code_invalid'));
    }
  }, [auth.error]);

  const codeVerification = (code: string) => {
    auth.verifyCode(code);
  };

  const goNext = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    codeVerification(otp);
  };

  useEffect(() => {
    if (isEnterPress && props.isActive) {
      goNext();
    }
  }, [isEnterPress]);

  // timer function used in resend OTP state reset
  const timer = (remaining: number) => {
    const m = Math.floor(remaining / 60);
    const s = remaining % 60;

    const minuteString: string = m < 10 ? `${m}` : `${m}`;
    const secondsString: string = s < 10 ? `0${s}` : `${s}`;
    setOtpTimer(`${minuteString}:${secondsString}`);
    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(() => {
        timer(remaining);
      }, 1000);
    }
  };

  // this is used to monitor if the resend OTP link was clicked
  useEffect(() => {
    if (!props.isActive) {
      return;
    }
    (async () => {
      if (resendOtpClicked) {
        // 60 seconds to reset the resend OTP link
        timer(60);
        setTimeout(() => {
          setResendOtpClicked(false);
        }, 60000);
      }
    })();
  }, [resendOtpClicked]);

  const resendsCode = () => {
    setLoading(true);
    setResendOtpClicked(true);
    setOtp('');
    // @ts-ignore
    otpInputRef.current.focusInput(0);
    auth.resendCode();
  };

  // Outputs in this format: +1 (647) 594-2080, else +1 6475942080
  const getFormattedName = () => {
    if (auth.cognitoUser) {
      if (validateEmail(auth.cognitoUser.username)) {
        return auth.cognitoUser.username;
      }
      const phoneNumber = parsePhoneNumber(auth.cognitoUser.username);
      return `+${
        phoneNumber.countryCallingCode
      } ${phoneNumber.formatNational()}`;
    }
    return '';
  };

  // Copied the original Button.component.tsx and overridden the height from h-10(40px) to h-12(48px) based on figma
  const buttonDisabled =
    'flex bg-grey0 rounded justify-center h-12 items-center';
  const buttonPrimary =
    'flex bg-brand2 rounded justify-center h-12 items-center hover:bg-brand3';

  const isDisabled = otp.length < 5 || loading || isCompaniesLoading;
  return (
    <div>
      <div className='mt-[0.5rem] pb-10'>
        <Label
          type={LabelType.H3}
          color={ColorType.BLACK}
          text={t('login_enter_code_placeholder')}
        />
        <div className='text-base text-grey6 font-normal mt-1'>
          <p>{t('login_sent_code_prefix')}</p>
          <span
            style={{ color: theme.navigationSelectedColor }}
            className='overflow-hidden text-ellipsis w-full inline-block'
          >
            {` ${getFormattedName()}.`}
          </span>
        </div>
      </div>
      <div className='mb-10 flex justify-around'>
        <OtpInput
          ref={otpInputRef}
          value={otp}
          onChange={otpChanged}
          numInputs={5}
          shouldAutoFocus
          inputStyle={{
            width: '3.5rem',
            height: '3.5rem',
            borderRadius: 8,
            border: '2px solid #D1D6DB',
            fontSize: '1.5rem',
            fontWeight: 500,
            marginRight: '.5rem',
          }}
          focusStyle={{
            border: '2px solid #000000',
            borderRadius: '8px',
            boxShadow: '0 0 #000000',
            outline: '2px solid transparent !important',
            outlineOffset: '2px',
          }}
          data-testid='otp'
        />
      </div>
      <button
        disabled={isDisabled}
        className={`${
          isDisabled ? buttonDisabled : buttonPrimary
        } w-full pt-3 pb-3 mb-4 h-12`}
        onClick={goNext}
        style={
          isDisabled ? {} : { backgroundColor: theme.navigationSelectedColor }
        }
        data-testid='login-submit-button'
      >
        <Label
          type={LabelType.LABEL_M}
          color={isDisabled ? ColorType.GREY3 : ColorType.WHITE}
          text={t('submit')}
        />
      </button>
      <div className='text-sm text-grey6 font-normal flex'>
        {!resendOtpClicked ? (
          <>
            <div className='pr-2'>Didn&apos;t receive a code?</div>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link to={`/login${location.search || ''}`} onClick={resendsCode}>
                <span
                  className=' font-semibold underline'
                  style={{
                    color: theme.navigationSelectedColor,
                  }}
                >
                  {t('login_send_new_code')}
                </span>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className='pr-2'>
              {t('login_code_disabled_for')}
              <span>{` ${otpTimer}`}</span>
            </div>
            <div>
              <span
                className='font-semibold underline '
                style={{
                  color: theme.navigationSelectedColor,
                }}
              >
                {t('login_code_sent')}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
