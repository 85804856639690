import { ColorType, Label, LabelType } from '../../_ui';

interface IProps {
  title: string;
  desc: string;
  desc2?: string;
}
const PriceFormStepsHead = ({ title, desc, desc2 }: IProps) => (
  <div className='flex flex-col gap-2 mt-[32px]'>
    <Label text={title} type={LabelType.LABEL_M} color={ColorType.BLACK} />
    <Label text={desc} type={LabelType.BODY3} color={ColorType.GREY6} />
    <Label text={desc2} type={LabelType.BODY3} color={ColorType.GREY6} />
  </div>
);

export default PriceFormStepsHead;
