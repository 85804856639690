import React, { memo, useCallback, useEffect } from 'react';
import { convertToLocaleCurrency } from '../../../utils/Currency.Util';
import { formatDate, formatDateTime } from '../../../utils/Date.Util';
import { Label, LabelType } from '../Label.component';
import { GridColumnType } from './enums/Grid-Column-Type.enum';
import { InputProps } from './Grid.component';
import { GridColumn } from './types/Grid-Column.interface';
import { ColorType } from '../index';

export const GridBody = memo(
  ({
    columns,
    data,
    primaryKey,
    onRowClick,
    pageIndex,
    local,
    rowCount = 20,
    hasBottomBorder = true,
    skeletonRowCount = 8,
    isLoading = false,
    hasRowBottomBorder = false,
    verticalAlign = 'align-inherit',
    dataTestId = '',
  }: InputProps) => {
    const formatData = (col: GridColumn, dataRow: any) => {
      let currentData = '';

      if (col.key.indexOf('.') !== -1) {
        currentData = dataRow;
        col.key.split('.').forEach((key: any) => {
          currentData = currentData[key];
        });
      } else if (col.key.indexOf('|') !== -1) {
        const keys = col.key.split('|');
        currentData = dataRow[keys[0]];

        if (!currentData) {
          currentData = dataRow[keys[1]];
        }
      } else {
        currentData = dataRow[col.key];
      }

      if (col.type === GridColumnType.DATE) {
        if (currentData) {
          return formatDate(new Date(currentData), col.format);
        }
      } else if (col.type === GridColumnType.DATETIME) {
        if (currentData) {
          return formatDateTime(
            new Date(currentData.split('[')[0]),
            col.format,
          );
        }
      } else if (col.type === GridColumnType.CURRENCY) {
        if (currentData) {
          return convertToLocaleCurrency(+currentData);
        }
      }

      return currentData;
    };

    const rowClicked = useCallback(
      (row: any) => {
        onRowClick && onRowClick(row);
      },
      [onRowClick],
    );

    const renderRows = (col: any, dataRow: any) => {
      if (col.component) {
        const Component = col.component;
        return <Component {...dataRow} />;
      }
      return (
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={isLoading ? '' : formatData(col, dataRow)}
          isLoading={isLoading}
          skeletonHeight='h-4'
          skeletonWidth='w-14'
        />
      );
    };
    const handleCellClick = (event: any, col: GridColumn) => {
      if (col.onCellClick) {
        event.stopPropagation();
      }
    };

    if (isLoading) {
      return (
        <tbody
          className={
            hasBottomBorder ? 'table-item border-b border-grey2' : 'table-item'
          }
        >
          {Array(skeletonRowCount)
            .fill(0)
            .map((dataRow: any) => (
              <tr className={`row ${dataRow.bg ? dataRow.bg : 'bg-white'}`}>
                {columns.map((col: GridColumn) => (
                  <td
                    className={`pl-3 py-2.5 ${verticalAlign}`}
                    key={col.key}
                    onClick={(event: any) => {
                      handleCellClick(event, col);
                    }}
                  >
                    {renderRows(col, dataRow)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      );
    }
    return (
      <tbody
        className={
          hasBottomBorder ? 'table-item border-b border-grey2' : 'table-item'
        }
      >
        {(local
          ? (data || []).filter(
              (d: any, index) =>
                index >= ((pageIndex || 1) - 1) * rowCount &&
                index < ((pageIndex || 1) - 1) * rowCount + rowCount,
            )
          : data || []
        ).map((dataRow: any) => (
          <tr
            onClick={() => rowClicked(dataRow)}
            key={dataRow[primaryKey]}
            className={`row-item row ${dataRow.bg ? dataRow.bg : 'bg-white'} ${
              hasRowBottomBorder && 'border-b border-grey2'
            } ${onRowClick ? 'hover:bg-grey1 cursor-pointer' : ''}`}
            data-testid={dataTestId}
          >
            {columns.map((col: GridColumn) => (
              <td
                className={`pl-3 py-2.5 ${verticalAlign}`}
                key={col.key}
                onClick={(event: any) => {
                  handleCellClick(event, col);
                }}
              >
                {renderRows(col, dataRow)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  },
);
