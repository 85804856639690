import React from 'react';
import { Label, LabelType } from '../_ui/Label.component';
import { Skeleton } from '../_ui';

interface IProps {
  primaryText: string;
  primaryTextColor: string;
  secondaryText: string;
  secondaryTextColor: string;
  primaryBgColor: string;
  secondaryBgColor: string;
  isLoading: boolean;
}
const StatusChip = ({
  primaryText,
  primaryTextColor,
  secondaryText,
  secondaryTextColor,
  primaryBgColor,
  secondaryBgColor,
  isLoading,
}: IProps) => {
  return isLoading ? (
    <Skeleton innerClassName='rounded-3xl' height='h-6' width='w-14' isPill />
  ) : (
    <div
      className={`border border-${primaryBgColor} bg-${primaryBgColor} rounded-3xl box-border flex flex-row items-center h-6 whitespace-nowrap w-max overflow-hidden`}
    >
      <div className='h-6 px-2 rounded-l-3xl py-0.5 gap-1'>
        <Label
          type={LabelType.LABEL_S_MEDIUM}
          text={primaryText}
          color={primaryTextColor}
        />
      </div>
      <div
        className={`h-6  px-2 rounded-r-3xl py-0.5 gap-1 bg-${secondaryBgColor}`}
      >
        <Label
          type={LabelType.LABEL_S_MEDIUM}
          text={secondaryText}
          color={secondaryTextColor}
        />
      </div>
    </div>
  );
};

export default StatusChip;
