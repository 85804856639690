import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { envApi } from '../services/env.api';
import { deploymentApi } from '../services/deployment.api';
import { Api } from '../services/base.api';
import { rtkQueryLogger } from './rtkQueryLogger';
import { timeZoneApi } from '../services/timezone.api';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      Api.middleware,
      envApi.middleware,
      deploymentApi.middleware,
      rtkQueryLogger,
      timeZoneApi.middleware,
    ]),
});

export { store };
