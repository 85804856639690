import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DayPrice,
  PricePeriod,
  PricingType,
  RuleTypes,
  ScheduleType,
} from '../../stores/types/price.interface';
import { convertToLocaleCurrency } from '../../utils/Currency.Util';
import {
  ButtonSize,
  ButtonType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui';
import { Calendar, EventContent } from '../_ui/calendar/Calendar.component';
import {
  compare24HourTime,
  compareStartTime,
  convertTo24Hour,
  has15MinsForNewPricePeriod,
  is24Hours,
  isPeriod24hour,
  isTimeOverlaps,
} from './utils';
import { PricingPeriodEditor } from './PricingPeriodEditor.component';

interface Props {
  dayPrices: DayPrice[] | undefined;
  onChange?: Function;
  scheduleType: ScheduleType | RuleTypes;
  disabled?: boolean;
}

export const PricingCalendar = memo(
  ({ scheduleType, dayPrices, onChange, disabled = false }: Props) => {
    const { showModal, hideModal } = useGlobalModalContext();

    const { t } = useTranslation();
    const [typeDayPrices, setTypeDayPrices] = useState(
      _.cloneDeep({ [scheduleType]: dayPrices }) || {},
    );

    const handleSave = (
      day: string,
      index: number,
      newPricePeriod: PricePeriod,
      saveToDays: any[],
    ) => {
      const nextDayPrices = [...(typeDayPrices[scheduleType] || [])];

      // Generate all pricing periods
      saveToDays.forEach(({ key, selected, isDisabled }) => {
        if (!selected) {
          // if day is not selected skip
          return;
        }
        // Get exisitng dayPrices for the day
        const nextDayPrice = nextDayPrices.find(
          (dayPrice) => dayPrice.day.toLowerCase() === key.toLowerCase(),
        );

        // if day price is not empty, we need to check overlaps
        if (nextDayPrice) {
          const editExistingItem =
            day.toLowerCase() === key.toLowerCase() && index !== -1;
          if (editExistingItem) {
            // we are changing the existing price period
            nextDayPrice.pricePeriods[index] = newPricePeriod;
          }
          // calculate how many existing price period overlap with new price period
          const overlapIndexs: any = [];
          nextDayPrice.pricePeriods.forEach((pricePeriod, i) => {
            // skip for checking the editing one
            if (editExistingItem && index === i) {
              return;
            }
            if (
              isTimeOverlaps(
                pricePeriod.startTime,
                pricePeriod.endTime,
                newPricePeriod.startTime,
                newPricePeriod.endTime,
              )
            ) {
              overlapIndexs.push(i);
            }
          });
          if (overlapIndexs.length === 0) {
            if (!editExistingItem) {
              nextDayPrice.pricePeriods.push(newPricePeriod);
            }
          } else {
            overlapIndexs.forEach((overlapIndex: any) => {
              // overlap
              const overlapPricePeriod =
                nextDayPrice.pricePeriods[overlapIndex];
              const overlapTime = convertTo24Hour(
                overlapPricePeriod.startTime,
                overlapPricePeriod.endTime,
              );
              const newTime = convertTo24Hour(
                newPricePeriod.startTime,
                newPricePeriod.endTime,
              );
              if (
                compare24HourTime(
                  overlapTime.startHour,
                  overlapTime.startMin,
                  newTime.startHour,
                  newTime.startMin,
                ) >= 0 &&
                compare24HourTime(
                  overlapTime.endHour,
                  overlapTime.endMin,
                  newTime.endHour,
                  newTime.endMin,
                ) <= 0
              ) {
                // case 1: same time period
                // case 2: new period is bigger than overlaped period ()-overlap []-new  [.(...).]
                // Replace the price period
                nextDayPrice.pricePeriods[overlapIndex] =
                  _.cloneDeep(newPricePeriod);
              } else if (
                compare24HourTime(
                  overlapTime.startHour,
                  overlapTime.startMin,
                  newTime.startHour,
                  newTime.startMin,
                ) === -1 &&
                compare24HourTime(
                  overlapTime.endHour,
                  overlapTime.endMin,
                  newTime.endHour,
                  newTime.endMin,
                ) === 1
              ) {
                // case 3:  in middle ()-old []-new  (...[...]...)
                // copy current to next
                nextDayPrice.pricePeriods.splice(
                  overlapIndex + 1,
                  0,
                  _.cloneDeep(nextDayPrice.pricePeriods[overlapIndex]),
                );
                nextDayPrice.pricePeriods[overlapIndex].endTime =
                  newPricePeriod.startTime;
                nextDayPrice.pricePeriods[overlapIndex + 1].startTime =
                  newPricePeriod.endTime;
                nextDayPrice.pricePeriods.splice(
                  overlapIndex + 1,
                  0,
                  _.cloneDeep(newPricePeriod),
                );
              } else if (
                compare24HourTime(
                  overlapTime.startHour,
                  overlapTime.endHour,
                  newTime.startHour,
                  newTime.startMin,
                ) === -1 &&
                compare24HourTime(
                  overlapTime.endHour,
                  overlapTime.endMin,
                  newTime.endHour,
                  newTime.endMin,
                ) <= 0
              ) {
                // case 4: overlap right part ()-old []-new  (..[...)..]
                nextDayPrice.pricePeriods[overlapIndex].endTime =
                  newPricePeriod.startTime;
                nextDayPrice.pricePeriods.splice(
                  overlapIndex + 1,
                  0,
                  _.cloneDeep(newPricePeriod),
                );
              } else if (
                compare24HourTime(
                  overlapTime.startHour,
                  overlapTime.endHour,
                  newTime.startHour,
                  newTime.startMin,
                ) === 1 &&
                compare24HourTime(
                  overlapTime.endHour,
                  overlapTime.endMin,
                  newTime.endHour,
                  newTime.endMin,
                ) > 0
              ) {
                // case 5: overlap left part ()-old []-new  [...(...]...)
                nextDayPrice.pricePeriods[overlapIndex].startTime =
                  newPricePeriod.endTime;
                nextDayPrice.pricePeriods.splice(
                  overlapIndex,
                  0,
                  _.cloneDeep(newPricePeriod),
                );
              }
            });
          }

          // remove dupilicate price periods
          nextDayPrice.pricePeriods = _.uniqWith(
            nextDayPrice.pricePeriods,
            _.isEqual,
          );
        } else {
          // if existing day price is empty, add new price periods directly
          nextDayPrices.push({
            day: key,
            pricePeriods: [_.cloneDeep(newPricePeriod)],
          });
        }
      });

      // sort before save
      nextDayPrices.forEach((nextDayPrice1) => {
        nextDayPrice1.pricePeriods.sort((a, b) =>
          compareStartTime(a.startTime, b.startTime),
        );
      });
      setTypeDayPrices({
        ...typeDayPrices,
        [scheduleType]: nextDayPrices,
      });
      onChange && onChange(nextDayPrices);
    };

    useEffect(() => {
      onChange && onChange(typeDayPrices[scheduleType]);
    }, [scheduleType]);

    const handleHeaderClick = (key: string, index: string) => {
      if (!disabled) {
        showModal(MODAL_TYPES.INFO_MODAL, {
          title: t('pricing_new_period'),
          width: '656px',
          height: 'max-content',
          onRenderBody: () => (
            <PricingPeriodEditor
              day={key}
              scheduleType={scheduleType}
              onSave={handleSave}
              dayPrices={typeDayPrices[scheduleType]}
            />
          ),
        });
      }
    };

    const handleConfirmDelete = (day: string, index: number) => {
      const dayPriceToDelete = typeDayPrices[scheduleType]?.find(
        (dayPrice) => dayPrice.day.toLowerCase() === day.toLowerCase(),
      );
      dayPriceToDelete?.pricePeriods.splice(index, 1);
      setTypeDayPrices({
        ...typeDayPrices,
        [scheduleType]: typeDayPrices[scheduleType],
      });
    };

    const handleDeletePircingPeriod = (day: string, index: number) => {
      hideModal();
      showModal(MODAL_TYPES.ALERT_MODAL, {
        height: 'max-content',
        title: t('pricing_delete_warn_title'),
        message: t('pricing_delete_warn_message'),
        buttons: [
          {
            label: t('cancel'),
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
          },
          {
            label: t('delete'),
            type: ButtonType.DESTRUCTIVE,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
            onClick: () => handleConfirmDelete(day, index),
          },
        ],
      });
    };

    const handleItemClick = (event: EventContent) => {
      if (!disabled) {
        const day = event.key.split('-')[0];
        const index = Number(event.key.split('-')[1]);
        const pricePeriods = typeDayPrices[scheduleType]?.find(
          (dayPrice) => dayPrice.day === day,
        )?.pricePeriods;

        if (pricePeriods && pricePeriods?.length > 0) {
          showModal(MODAL_TYPES.INFO_MODAL, {
            title: t('pricing_edit_period'),
            width: '656px',
            height: 'max-content',
            showHeaderButton: true,
            headerButtonLabel: t('delete'),
            onHeaderButtonOnlick: () => handleDeletePircingPeriod(day, index),
            onRenderBody: () => (
              <PricingPeriodEditor
                day={day}
                index={index}
                scheduleType={scheduleType}
                pricePeriod={pricePeriods[index]}
                onSave={handleSave}
                dayPrices={typeDayPrices[scheduleType]}
              />
            ),
          });
        }
      }
    };

    const data = useMemo(() => {
      const oldDayPrices = typeDayPrices[scheduleType] || [];
      return oldDayPrices?.map((dayPrice) => {
        return {
          key: dayPrice.day,
          events: dayPrice.pricePeriods.map((pricePeriod, index) => {
            let pricingTypeStr = '';
            switch (pricePeriod.pricingType) {
              case PricingType.BILLED_BY_TIME_PLUGGED_IN:
                pricingTypeStr = t('pricing_hour_plugged');
                break;
              case PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME:
                pricingTypeStr = t('pricing_hour_charging');
                break;
              case PricingType.BILLED_BY_KWH:
                pricingTypeStr = t('pricing_kwh');
                break;
              default:
                break;
            }
            return {
              key: `${dayPrice.day}-${index}`,
              title: `$${Number(pricePeriod.price.slice(1)).toFixed(
                2,
              )}/${pricingTypeStr}`,
              message: `${String(pricePeriod.startTime)} - ${String(
                pricePeriod.endTime,
              )}`,
              start: pricePeriod.startTime,
              end: pricePeriod.endTime,
              isBigItem: isPeriod24hour([pricePeriod]),
            };
          }),
        };
      });
    }, [typeDayPrices, scheduleType]);

    const headers = useMemo(() => {
      const headerDayPrices = typeDayPrices[scheduleType] || [];
      let result;
      switch (scheduleType) {
        case ScheduleType.SAME_EVERYDAY:
          result = [{ key: 'daily', label: t('everyday') }];
          break;
        case ScheduleType.WEEKDAYS_WEEKEND:
          result = [
            { key: 'weekday', label: t('weekdays') },
            { key: 'weekend', label: t('weekends') },
          ];
          break;
        case ScheduleType.PER_DAY:
        default:
          result = [
            { key: 'monday', label: t('monday') },
            { key: 'tuesday', label: t('tuesday') },
            { key: 'wednesday', label: t('wednesday') },
            { key: 'thursday', label: t('thursday') },
            { key: 'friday', label: t('friday') },
            { key: 'saturday', label: t('saturday') },
            { key: 'sunday', label: t('sunday') },
          ];
      }
      return result.map((dataKeyAndLabel) => {
        const dayPrice = headerDayPrices.find(
          (headerDayPrice) =>
            headerDayPrice.day.toLowerCase() ===
            dataKeyAndLabel.key.toLowerCase(),
        );
        return {
          ...dataKeyAndLabel,
          disabled: !has15MinsForNewPricePeriod(dayPrice?.pricePeriods),
          placeHolder: t('free'),
        };
      });
    }, [scheduleType, typeDayPrices]);

    return (
      <Calendar
        headers={headers}
        data={data}
        onHeaderClick={handleHeaderClick}
        onItemClick={handleItemClick}
      />
    );
  },
);
