import { add, addDays, subDays, subWeeks } from 'date-fns';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight, Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { useAuth } from '../../../hooks';
import { formatDate } from '../../../utils/Date.Util';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../shared/Colors.g';
import './Date-Picker.css';

export interface InputProps {
  defaultRange?: (Date | null)[];
  format?: string;
  onChange?: (date: any) => void;
  inputBG?: string;
  defaulttext?: string;
  inline?: boolean;
  monthsShown?: number;
  onClickOutSide?: Function;
  placeholder?: string;
  includeDays?: number;
}

export const DateRangePicker = memo(
  ({
    defaultRange = [subWeeks(new Date(), 1), new Date()],
    onChange,
    format = 'MMM yyyy',
    inputBG = 'bg-grey1',
    defaulttext = 'Choose a range',
    inline = false,
    monthsShown = 1,
    onClickOutSide,
    placeholder,
    includeDays,
  }: InputProps) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState(defaultRange);
    const hasStartAndEndDate = (dates: any) => dates.length === 2;
    const DateRangeCustomInput = forwardRef(
      ({ value, onClick }: any, ref: any) => {
        const dates = value.split('-');
        let result = defaulttext;

        if (hasStartAndEndDate(dates)) {
          result = `${formatDate(new Date(dates[0]), format)} - ${formatDate(
            new Date(dates[1]),
            format,
          )}`;
        }

        return (
          <button className={`date-range-selector ${inputBG}`}>
            <div className='block'>
              <div
                className={`inline-block align-middle ${
                  defaulttext && !defaultRange
                    ? 'text-grey4 text-xs'
                    : 'text-grey6 text-sm'
                }`}
                onClick={onClick}
                ref={ref}
              >
                {result}
              </div>
            </div>
          </button>
        );
      },
    );

    const onDateRangeChange = useCallback(
      (newDateRange: any) => {
        const newDateRangeWithTime: any = [...newDateRange];
        if (newDateRange && newDateRange[1]) {
          const enddateWithTime = add(newDateRange[1], {
            hours: 23,
            minutes: 59,
            seconds: 59,
          });
          newDateRangeWithTime[1] = enddateWithTime;
        }
        setDateRange(newDateRangeWithTime);
        onChange && onChange(newDateRangeWithTime);
      },
      [setDateRange, onChange],
    );

    const renderArrow = (arraw: any) => {
      return <Icon src={arraw} size={IconSize.SIZE_20x20} />;
    };

    const handleClickOutside = () => {
      onClickOutSide && onClickOutSide();
    };

    const customDateInterval = useMemo(() => {
      const [startDate, endDate] = dateRange;
      if (includeDays && startDate && !endDate) {
        return {
          includeDateIntervals: [
            {
              start: subDays(startDate, NUMBER.THREE_HUNDRED),
              end: addDays(startDate, includeDays - NUMBER.ONE),
            },
          ],
        };
      }
      return {};
    }, [includeDays, dateRange]);

    const DateContanier = ({
      className,
      children,
    }: {
      className: string;
      children: any;
    }) => {
      return includeDays ? (
        <div>
          <CalendarContainer
            className={`react-datepicker_container ${className}`}
          >
            {children}
          </CalendarContainer>
          <div className='bg-white datepicker-footer px-4'>
            <div className='flex items-center border-t border-t-grey3 py-4'>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
              <Label
                className='ml-2'
                text={t('maximun_range_selected', {
                  number: includeDays,
                })}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.GREY5}
              />
            </div>
          </div>
        </div>
      ) : (
        <CalendarContainer className={className}>{children}</CalendarContainer>
      );
    };
    return (
      <div>
        <DatePicker
          locale={auth.user.attributes.profile?.sitehostDashboardLanguage}
          selectsRange
          onChange={(newDateRange: any) => onDateRangeChange(newDateRange)}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          customInput={<DateRangeCustomInput />}
          useWeekdaysShort
          previousMonthButtonLabel={renderArrow(ArrowLeft)}
          nextMonthButtonLabel={renderArrow(ArrowRight)}
          inline={inline}
          monthsShown={monthsShown}
          onClickOutside={handleClickOutside}
          placeholderText={placeholder}
          {...customDateInterval}
          calendarContainer={DateContanier}
        />
      </div>
    );
  },
);
