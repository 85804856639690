import { Auth } from 'aws-amplify';

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
  getApiUrlPrefix,
  getLegacyApiUrlPrefix,
  getMeasurementId,
} from '../stores/selectors/deployment.selector';

export enum SCOPE_TYPE {
  NONE = 'none',
  COMPANY = 'company',
  ALL = 'all',
}

export const getPath = (path: string, params?: any) => {
  if (params) {
    Object.entries(params)?.forEach(([key, value]) => {
      path += `&${key}=${value}`;
    });
  }
  return path.replaceAll('[', '%5B').replaceAll(']', '%5D');
};

/**
 * Extracts the filename from a Content-Disposition header
 * @param contentDisposition The Content-Disposition header
 * @returns The filename if it can be extracted, null otherwise
 */
export const extractFilename = (contentDisposition: string) => {
  if (!contentDisposition) {
    return null;
  }
  const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
  if (filenameMatch && filenameMatch[1]) {
    const filename = filenameMatch[1];
    return filename;
  }
  return null;
};

/**
 * Downloads a blob as a file with the given filename and extension.
 * @param data The blob to be downloaded
 * @param fileName The filename to use for the downloaded file
 * @param extension The file extension to use, if any and if not included in the filename
 */
export const downloadBlob = (
  data: Blob,
  fileName: string,
  extension?: string,
) => {
  const fileExtension = extension ? `.${extension}` : '';
  const a = document.createElement('a');
  a.download = `${fileName}${fileExtension}`;
  a.href = window.URL.createObjectURL(data);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const getToken = async () => {
  const cognitoUserSession = await Auth.currentSession();
  const token = cognitoUserSession.getAccessToken().getJwtToken();
  return token;
};

const getAcceptHeader = (args: any) => {
  if (args.isCSV) {
    return 'text/csv';
  }
  if (args.isPDF) {
    return 'application/pdf';
  }
  return 'application/json';
};

const rawBaseQuery = async (
  baseUrl: string,
  args: any,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  const baseResult = await fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
      headers.set('Accept', getAcceptHeader(args));
      headers.set(
        'Content-Type',
        args.isPDF ? 'application/pdf' : 'application/json',
      );
      headers.set('Access-Control-Allow-Origin', '*');
      // TODO: move token to state
      // const token = (getState() as RootState).auth.token
      const token = await getToken();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    baseUrl,
  })(args, api, extraOptions);

  return {
    ...baseResult,
    meta: baseResult.meta && {
      ...baseResult.meta,
      toast: args.toast,
      hideToast: args.hideToast,
    },
  };
};

// clean params -> delete undefined values
const cleanParams = (params: any) => {
  const result = { ...params };
  Object.keys(result).forEach((key) => {
    const val = result[key];
    if (
      val === undefined ||
      val === '' ||
      (Array.isArray(val) && val.length === 0)
    ) {
      delete result[key];
    }
  });
  return result;
};

/**
 * flatten param
 * Input:
 * {
 * filter: {
 *   locationId: 'locationQuery',
 * },
 * }
 * Output:
 * {
 *   filter[locationId]=locationQuery
 * }
 */
const flattenParams = (params: any, prefix = '') => {
  return Object.keys(params).reduce((acc: any, k) => {
    const prop = prefix ? `${prefix}[${k}]` : k;
    if (Array.isArray(params[k])) {
      acc[prop] = params[k].join(',');
    } else if (typeof params[k] === 'object') {
      Object.assign(acc, flattenParams(params[k], prop));
    } else {
      acc[prop] = params[k];
    }
    return acc;
  }, {});
};

const setupExtraParams = (params: any, args: any, api: BaseQueryApi) => {
  // for new multi-company view, scope should be setup in endpoint file, and it will be 'company' by default
  if (params.scope === SCOPE_TYPE.NONE) {
    /**
     * If scope is NONE, we need to remove it from the params
     */
    delete params.scope;
  } else if (!params.scope) {
    // if there is no specific scope type, will set scope to 'company'
    params = {
      ...params,
      scope: SCOPE_TYPE.COMPANY,
    };
  }

  if (!params.companyId && args.needCompanyId !== false) {
    const companyId = new URLSearchParams(window.location.search).get(
      'companyId',
    );
    if (companyId?.toUpperCase() !== 'ALL') {
      params = {
        ...params,
        companyId,
      };
    }
  }

  if (args.useLegacyBaseUrl) {
    // [BF-2483] Used in payout reports, we need to use the window.location.hostname so that the proper white label
    // theme color and logo will reflect the PDF reports
    params = {
      ...params,
      domainName: window.location.hostname,
    };
  }

  if (args.measurementUrl) {
    params = {
      ...params,
      measurement_id: getMeasurementId(api.getState() as any),
    };
  }
  return params;
};

export const dynamicBaseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  let params = cleanParams(args.params);
  params = flattenParams(params);
  params = setupExtraParams(params, args, api);

  let adjustedArgs = {
    ...args,
    params,
  };

  adjustedArgs = {
    ...adjustedArgs,
    url: args.measurementUrl
      ? `${adjustedArgs.url}?measurement_id=${getMeasurementId(
          api.getState() as any,
        )}`
      : adjustedArgs.url,
  };

  const baseUrl = args.measurementUrl
    ? '/'
    : args.useLegacyBaseUrl
    ? // legacy-vx-xxx.dev.chargelab.io
      `${getLegacyApiUrlPrefix(api.getState() as any)}/legacy/client-dashboard/`
    : // api-vx-xxx.dev.chargelab.io
      getApiUrlPrefix(api.getState() as any);

  return rawBaseQuery(baseUrl, adjustedArgs, api, extraOptions);
};

export const providesListTag = <
  R extends { id: string | number }[],
  T extends string,
>(
  resultsWithIds: R | undefined,
  tagType: T,
) => {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
};
