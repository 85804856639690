import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks';
import { useUpsertSupportNoteMutation } from '../../services/supportnote.api';
import { SupportNote } from '../../stores/types/supportnote.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  FormInput,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { ValidationType } from '../_ui/Input.component';
import InputArea from '../_ui/InputArea.component';

interface IProps {
  note?: SupportNote;
  chargerId?: string;
}

const MAX_LEN = 1000;

const SupportNotesEditor = React.memo(({ note, chargerId }: IProps) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const auth = useAuth();
  const [content, setContent] = useState(note?.note);
  const [link, setLink] = useState(note?.link);
  const numberLabel = useMemo(() => {
    return `${content?.length || 0} / ${MAX_LEN}`;
  }, [content]);

  const [triggerUpsertNote, upsertNoteResult] = useUpsertSupportNoteMutation();

  const onSaveClick = () => {
    const newNote = {
      ...note!,
      deviceId: (note?.deviceId || chargerId)!,
      note: content || '',
      link: link || '',
    };
    delete newNote.updatedAt;
    triggerUpsertNote(newNote);
  };

  return (
    <Form
      onSubmit={onSaveClick}
      queryResult={upsertNoteResult}
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <div className='w-[500px] h-[520px] flex-col justify-start items-start gap-4 inline-flex'>
        <div className='self-stretch h-[520px] flex-col justify-start items-start gap-4 flex'>
          <div className='self-stretch h-[360px] flex-col justify-start items-start gap-2 flex'>
            <Label
              text={t('support_notes_note')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <div className='flex self-stretch justify-start items-start w-full'>
              <InputArea
                height='300px'
                placeholder={t('support_notes_placeholder')}
                defaultValue={content}
                onChange={(newValue) => setContent(newValue)}
                maxLength={MAX_LEN}
              />
            </div>
            <Label
              text={numberLabel}
              type={LabelType.LABEL_S}
              color={ColorType.GREY5}
            />
          </div>
          <div className='self-stretch h-20 flex-col justify-start items-start gap-2 flex'>
            <Label
              text={t('support_notes_link')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <div className='flex self-stretch justify-start items-center w-full'>
              <FormInput
                validationType={ValidationType.LINK}
                placeholder={t('support_notes_link_placeholder')}
                defaultValue={link}
                width='100%'
                onChange={(event: any) => setLink(event.target.value)}
              />
            </div>
          </div>
          <div className='self-stretch justify-center items-center gap-2.5 inline-flex'>
            <Button
              size={ButtonSize.MEDIUM_FULL}
              label={t('save')}
              type={ButtonType.PRIMARY}
              isSumbit
            />
          </div>
        </div>
      </div>
    </Form>
  );
});

export default SupportNotesEditor;
