/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ChargerEV,
  CompanyBuilding,
  ErrorWarningCircle,
  Success,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { useCompany } from '../../hooks/useCompany';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { useFetchLocationsQuery } from '../../services/location.api';
import {
  useGenerateNyserdaEnergyUseReportAsyncMutation,
  useGenerateNyserdaStatusReportAsyncMutation,
  useGenerateReportSyncMutation,
  useGenerateSessionIntervalsReportAsyncMutation,
  useGenerateSessionsReportAsyncMutation,
  useGenerateStatisticsReportAsyncMutation,
} from '../../services/report.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  ChargerSelection,
  ChargerSelectionList,
} from '../../stores/types/chargers.interface';
import {
  Category,
  IRange,
  ReportType,
  Reports,
} from '../../stores/types/report.interface';
import {
  convertToUTCEndOfDay,
  convertToUTCStartOfDay,
} from '../../utils/Date.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  CustomDatePicker,
  Divider,
  Dropdown,
  DropdownType,
  Icon,
  Label,
  LabelType,
  Pill,
  Snackbar,
} from '../_ui';
import { Form, FormQueryResult } from '../_ui/Form.component';
import { DATE_RANGE } from '../_ui/date-picker/Custom-Date-Picker.component';
import { MonthPicker } from '../_ui/date-picker/Multiple-Month-Picker.component';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { ReportContent } from './ReportContent.component';

export const Report = () => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  const { LAST_WEEK, LAST_MONTH, CUSTOM_RANGE } = DATE_RANGE;
  const { allowNyserdaReports } = useAllowedFeatures();

  const [generateReportSync, generateReportSyncResult] =
    useGenerateReportSyncMutation();
  const [generateStatisticsReportAsync, generateStatisticsReportAsyncResult] =
    useGenerateStatisticsReportAsyncMutation();
  const [generateSessionsReportAsync, generateSessionsReportAsyncResult] =
    useGenerateSessionsReportAsyncMutation();
  const [
    generateSessionIntervalsReportAsync,
    generateSessionIntervalsReportAsyncResult,
  ] = useGenerateSessionIntervalsReportAsyncMutation();
  const [
    generateNyserdaEnergyUseReportAsync,
    generateNyserdaEnergyUseReportAsyncResult,
  ] = useGenerateNyserdaEnergyUseReportAsyncMutation();
  const [
    generateNyserdaStatusReportAsync,
    generateNyserdaStatusReportAsyncResult,
  ] = useGenerateNyserdaStatusReportAsyncMutation();

  const [chargers, setChargers] = useState<string[]>([]);
  const [categoryType, setCategoryType] = useState<Category | null>(null);
  const [reportType, setReportType] = useState<any>('');
  const [dateRange, setDateRange] = useState<IRange>();
  const [reportData, setReportData] = useState({
    companyIds: [],
    chargerIds: [],
    months: [],
  } as Reports);

  const [chargerDropdownList, setChargerDropdownList] = useState<any>();
  const { isMultiViewActive, companies, userScope, companyId } = useCompany();
  const [selectedCompanies, setSelectedCompanies] = useState<any>([]);

  const { locations, refetch: refetchLocation } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint?.data ? endPoint.data.entities : [],
      }),
    },
  );
  const chargerPillsToShow: any[] = [];

  const chargersList = useMemo(() => {
    return locations
      .map((location) => {
        const children = location.chargers?.map((charger: any) => {
          return {
            id: charger.id,
            label: charger.displayName,
            selected: chargers.includes(charger.id),
          };
        });

        if (children?.length === 0) {
          return null;
        }
        return {
          id: location.id,
          label: location.name,
          children: children || [],
          selected: children?.length
            ? children?.every((child) => child.selected)
            : false,
        };
      })
      .filter((location) => location);
  }, [chargers, locations]);

  const allChargerList = useMemo(() => {
    let allChargersFlag = false;
    if (chargerDropdownList?.length > 0) {
      allChargersFlag = chargerDropdownList[0].selected;
    }

    const list = _.cloneDeep(chargersList);
    const all = {
      id: 'all',
      label: t('all_chargers'),
      selected: allChargersFlag,
      children: [],
    };
    list.unshift(all as any);
    return list;
  }, [chargersList]);

  useEffect(() => {
    setChargerDropdownList(allChargerList);
  }, [allChargerList]);

  const unselectAllAfterQuerySuccess = () => {
    const newLocations = allChargerList.map((location) => {
      return {
        id: location?.id,
        label: location?.label,
        children: location?.children?.length
          ? location?.children?.map((child) => {
              return {
                id: child.id,
                label: child.label,
                selected: false,
              };
            })
          : [],
        selected: false,
      };
    });
    setChargerDropdownList(newLocations);
  };

  const resetStates = () => {
    setChargers([]);
    setSelectedCompanies([]);
    setReportType(null);
    setCategoryType(null);
    setDateRange(undefined);
    setReportData({
      chargerIds: [],
      months: [],
    });
    unselectAllAfterQuerySuccess();
  };

  useEffect(() => {
    resetStates();
    refetchLocation();
  }, [companyId]);

  const categories = [
    {
      id: Category.CHARGERS,
      label: t('chargers'),
      secondaryLabel: t('charger_info'),
      selected: categoryType === Category.CHARGERS,
    },
    {
      id: Category.SESSIONS,
      label: t('overview_sessions'),
      secondaryLabel: t('session_info'),
      selected: categoryType === Category.SESSIONS,
    },
  ];

  const categoryOptions = () => {
    // At the momemt NYSERDA reports are only visible for support users.
    if (allowNyserdaReports) {
      return [
        ...categories,
        {
          id: Category.FINANCIAL_PROGRAMS,
          label: t('financial_programs'),
          secondaryLabel: t('session_info'),
          selected: categoryType === Category.FINANCIAL_PROGRAMS,
        },
      ];
    }
    return [...categories];
  };
  const reportTypeOptions = (type: string) => {
    if (type === Category.SESSIONS) {
      return [
        {
          id: ReportType.FULL_SESSION,
          label: t('full_session'),
          secondaryLabel: t('full_session_info'),
          selected: reportType === ReportType.FULL_SESSION,
        },
        {
          id: ReportType.INTERVALS,
          label: t('interval'),
          secondaryLabel: t('interval_info'),
          selected: reportType === ReportType.INTERVALS,
        },
      ];
    }
    if (type === Category.FINANCIAL_PROGRAMS) {
      return [
        {
          id: ReportType.NYSERDA_ENERGY_USE,
          label: t('nyserda_energy_use'),
          selected: reportType === ReportType.NYSERDA_ENERGY_USE,
        },
        {
          id: ReportType.NYSERDA_STATUS_HISTORY,
          label: t('nyserda_status_history'),
          selected: reportType === ReportType.NYSERDA_STATUS_HISTORY,
        },
      ];
    }
    return [
      {
        id: ReportType.CHARGER_STATS,
        label: t('charger_stats'),
        secondaryLabel: t('charger_stats_info'),
        selected: reportType === ReportType.CHARGER_STATS,
      },
    ];
  };
  const handleCategoryType = (category: { id: Category }) => {
    setReportType(null);
    setCategoryType(category?.id as Category);
  };

  const handleReportType = (report: { id: ReportType }) => {
    setReportType(report?.id as ReportType);
    setReportData({
      ...reportData,
      report: report?.id,
    });
  };

  const renderCardTitle = () => {
    return (
      <div className='flex flex-row items-baseline gap-2'>
        <Label
          type={LabelType.H4}
          color={ColorType.BLACK2}
          text={t('run_report')}
        />
      </div>
    );
  };
  const handleChargersChange = (items: any) => {
    setChargerDropdownList(items);
    const newChargers: string[] = [];
    let allChargers: ChargerSelection[] = [];
    items.forEach((item: ChargerSelectionList) => {
      allChargers = allChargers.concat(item.children);
    });
    const selectedChargers = allChargers.filter(
      (item: ChargerSelection) => item.selected,
    );
    selectedChargers.forEach((item: any) => {
      if (item.selected) {
        newChargers.push(item.id);
      }
    });
    setChargers(newChargers);
    setReportData({
      ...reportData,
      chargerIds: !items[0].selected ? newChargers : ['all'],
    });
  };

  const getQueryResult: FormQueryResult & { reset: () => void } =
    useMemo(() => {
      if (isMultiViewActive) {
        switch (reportType) {
          case ReportType.CHARGER_STATS:
            return generateStatisticsReportAsyncResult;
          case ReportType.FULL_SESSION:
            return generateSessionsReportAsyncResult;
          case ReportType.INTERVALS:
            return generateSessionIntervalsReportAsyncResult;
          case ReportType.NYSERDA_ENERGY_USE:
            return generateNyserdaEnergyUseReportAsyncResult;
          case ReportType.NYSERDA_STATUS_HISTORY:
            return generateNyserdaStatusReportAsyncResult;
          default:
            return {
              isSuccess: false,
              isError: false,
              isLoading: false,
              reset: () => {},
            };
        }
      } else {
        return generateReportSyncResult;
      }
    }, [
      isMultiViewActive,
      generateStatisticsReportAsyncResult,
      generateSessionsReportAsyncResult,
      generateSessionIntervalsReportAsyncResult,
      generateNyserdaEnergyUseReportAsyncResult,
      generateNyserdaStatusReportAsyncResult,
      generateReportSyncResult,
    ]);

  const handleSaveClick = () => {
    const requestedAt = new Date();
    // Note that this must be kept in sync with queryResultToMonitor above
    if (isMultiViewActive) {
      switch (reportType) {
        case ReportType.CHARGER_STATS:
          generateStatisticsReportAsync({
            scope: userScope!,
            companyIds: reportData.companyIds!,
            months: reportData.months,
            requestedAt,
          });
          break;
        case ReportType.FULL_SESSION:
          generateSessionsReportAsync({
            scope: userScope!,
            companyIds: reportData.companyIds!,
            fromSessionCompletedDate: reportData.fromSessionCompletedDate!,
            toSessionCompletedDate: reportData.toSessionCompletedDate!,
            requestedAt,
          });
          break;
        case ReportType.INTERVALS:
          generateSessionIntervalsReportAsync({
            scope: userScope!,
            companyIds: reportData.companyIds!,
            fromSessionCompletedDate: reportData.fromSessionCompletedDate!,
            toSessionCompletedDate: reportData.toSessionCompletedDate!,
            requestedAt,
          });
          break;
        case ReportType.NYSERDA_ENERGY_USE:
          generateNyserdaEnergyUseReportAsync({
            scope: userScope!,
            companyIds: reportData.companyIds!,
            reportStartDate: reportData.fromSessionCompletedDate!,
            reportEndDate: reportData.toSessionCompletedDate!,
            requestedAt,
          });
          break;
        case ReportType.NYSERDA_STATUS_HISTORY:
          generateNyserdaStatusReportAsync({
            scope: userScope!,
            companyIds: reportData.companyIds!,
            reportStartDate: reportData.fromSessionCompletedDate!,
            reportEndDate: reportData.toSessionCompletedDate!,
            requestedAt,
          });
          break;
        default:
          break;
      }
    } else {
      generateReportSync({
        scope: userScope!,
        reportInfo: reportData,
        requestedAt,
      });
    }
  };

  const allChargerSelected = useMemo(() => {
    return chargerDropdownList?.length && chargerDropdownList[0].selected;
  }, [chargerDropdownList]);

  const handleFilterItemClick = (clickedItem: any) => {
    const newList = chargerDropdownList.map((item: any) => {
      let isAllChildrenChecked = true;
      const newChildren = item.children?.map((child: any) => {
        const newSelected =
          child.id === clickedItem.id ? false : child.selected;
        if (!newSelected) isAllChildrenChecked = false;
        return {
          ...child,
          selected: newSelected,
        };
      });
      return {
        ...item,
        selected: item.id === 'all' ? item.selected : isAllChildrenChecked,
        children: newChildren,
      };
    });
    setChargerDropdownList(newList);
  };

  const unselectAllChargers = () => {
    const newList = chargerDropdownList.map((item: any) => {
      const newChildren = item.children?.map((child: any) => {
        return {
          ...child,
          selected: false,
        };
      });
      return {
        ...item,
        selected: false,
        children: newChildren,
      };
    });
    setChargerDropdownList(newList);
  };

  const renderSelectedChargers = () => {
    if (allChargerSelected) {
      return (
        <Pill
          iconLeft={ChargerEV}
          key='all_chargers'
          label={t('all_chargers')}
          hasCloseButton
          onClick={unselectAllChargers}
          translationOn
        />
      );
    }
    chargerDropdownList?.forEach((ele: any) => {
      ele.children?.forEach((child: any) => {
        if (child.selected) {
          chargerPillsToShow.push({
            id: child.id,
            label: child.label,
          });
        }
      });
    });
    return chargerPillsToShow.map((item) => {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Pill
          iconLeft={ChargerEV}
          key={item.id}
          label={item.label}
          hasCloseButton
          onClick={() => handleFilterItemClick(item)}
          translationOn
        />
      );
    });
  };

  const renderCategory = () => {
    return (
      <div className='flex flex-col gap-[8px]'>
        <Label text={t('category')} type={LabelType.LABEL_M} />
        <Dropdown
          placeholder={t('select_category')}
          placeholderLabelType={LabelType.BODY3_G4}
          headerWidth={340}
          items={categoryOptions()}
          type={DropdownType.SELECT}
          onItemClick={(item: any, event: any) => handleCategoryType(item)}
          contentDivWidth={340}
          labelType={LabelType.BODY3}
          labelTypeHighlighted={LabelType.BODY3}
          chevdownIconHighlightColor={ColorType.GREY6}
          headerHighLightClassName='bg-grey1 border-grey3 rounded'
        />
      </div>
    );
  };
  const renderReportType = () => {
    return (
      <div className='flex flex-col gap-[8px]'>
        <Label text={t('report_type')} type={LabelType.LABEL_M} />
        {!categoryType && (
          <Label
            text={t('report_type_placeholder')}
            type={LabelType.BODY3_G4}
          />
        )}
        {categoryType && (
          <Dropdown
            placeholder={t('select_report_type')}
            placeholderLabelType={LabelType.BODY3_G4}
            headerWidth={340}
            items={reportTypeOptions(categoryType)}
            type={DropdownType.SELECT}
            onItemClick={(item: any, event: any) => handleReportType(item)}
            contentDivWidth={340}
            labelType={LabelType.BODY3}
            labelTypeHighlighted={LabelType.BODY3}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded'
          />
        )}
      </div>
    );
  };

  const renderNoSelectedChargerOrCompany = () => {
    return (
      <div className='flex flex-col items-center gap-2 mt-[16px] mb-[28px] text-center'>
        <Icon src={ChargerEV} size={IconSize.SIZE_48x48} />
        <Label
          text={t('no_chargers_selected')}
          type={LabelType.H4}
          color={ColorType.GREY5}
        />
        <Label
          text={t('reports_chargers_info')}
          type={LabelType.BODY2}
          color={ColorType.GREY5}
        />
      </div>
    );
  };

  const selectDate = (option: string, date: Date[]) => {
    setDateRange({
      option,
      date,
    });
    setReportData({
      ...reportData,
      fromSessionCompletedDate: convertToUTCStartOfDay(date[0]),
      toSessionCompletedDate: convertToUTCEndOfDay(date[1]),
    });
  };
  const dateChanged = (newMonths: Array<Date>) => {
    setReportData({
      ...reportData,
      months: newMonths,
    });
  };
  const renderTimeRange = () => {
    return (
      <div className='flex flex-col gap-[8px]'>
        <Label text={t('time_range')} type={LabelType.LABEL_M} />
        {!reportType && (
          <Label text={t('time_range_placeholder')} type={LabelType.BODY3_G4} />
        )}
        <div className='flex'>
          {reportType && reportType !== ReportType.CHARGER_STATS && (
            <CustomDatePicker
              optionList={[LAST_WEEK, LAST_MONTH, CUSTOM_RANGE]}
              format='MMM d,yyyy'
              onChange={selectDate}
              selectedOption={dateRange?.option ? dateRange?.option : ''}
              showHighligtHeader={false}
              placeholder='Select time range'
            />
          )}
          {reportType && reportType === ReportType.CHARGER_STATS && (
            <MonthPicker
              months={reportData.months}
              onChange={dateChanged}
              maxDate={today}
            />
          )}
        </div>
        {reportType === ReportType.CHARGER_STATS && (
          <div>
            <Label
              text={t('date_picker_text1')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={t('date_picker_text2')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
        )}
      </div>
    );
  };
  const renderReportContents = () => {
    return (
      <div className='flex flex-col gap-[8px]'>
        <Label text={t('report_contents')} type={LabelType.LABEL_M} />
        {!reportType && (
          <Label
            text={t('report_contents_placeholder')}
            type={LabelType.BODY3_G4}
          />
        )}
        {reportType && <ReportContent data={reportType} />}
      </div>
    );
  };

  const onSaveValidation = () => {
    if (categoryType === Category.CHARGERS) {
      if (isMultiViewActive) {
        return (
          selectedCompanies?.length > 0 &&
          reportData?.months?.length > 0 &&
          reportType
        );
      }

      return (
        chargers?.length > 0 && reportData?.months?.length > 0 && reportType
      );
    }

    if (isMultiViewActive) {
      return (
        selectedCompanies?.length > 0 &&
        categoryType &&
        reportType &&
        dateRange?.option
      );
    }

    return (
      chargers?.length > 0 && categoryType && reportType && dateRange?.option
    );
  };

  const renderButtons = () => {
    return (
      <div className='flex flex-row-reverse gap-2'>
        <Button
          label={t('Run')}
          type={ButtonType.PRIMARY}
          disabled={!onSaveValidation()}
          size={ButtonSize.SMALL}
          isSumbit
        />
      </div>
    );
  };
  const displayToast = (type: number) => {
    Snackbar.show({
      message: (
        <>
          {type === 1 && !isMultiViewActive && (
            <>
              <Label
                text={t('report_request_submitted')}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.BLACK}
              />
              <Label
                text={t('report_download')}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.BLACK}
              />
            </>
          )}
          {type === 1 && isMultiViewActive && (
            <Label
              text={t('emailed_report_request')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BLACK}
            />
          )}
          {type === 2 && (
            <Label
              text={t('failed_report_message')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BLACK}
            />
          )}
        </>
      ),
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: type === 1 ? Success : ErrorWarningCircle,
      height: 'h-auto',
    });
  };

  const handleQuerySuccess = () => {
    resetStates();
    displayToast(1);
  };

  const companyList = useMemo(() => {
    if (companies) {
      const dropdownOptions = companies.map((company) => {
        return {
          id: company.id,
          key: company.id,
          label: company.name,
          selected: selectedCompanies.indexOf(company.id) !== -1,
          disabled:
            selectedCompanies.length >= NUMBER.MAX_COMPANIES &&
            selectedCompanies.indexOf(company.id) === -1,
        };
      });

      dropdownOptions.unshift({
        id: 'all',
        key: 'all',
        label: t('all_companies'),
        selected: dropdownOptions.every((dataItem: any) => dataItem.selected),
        disabled: false,
      });
      return dropdownOptions;
    }

    return [];
  }, [companies, selectedCompanies]);

  const onCompanySelected = (items: any[]) => {
    const filterCompanies = items
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    setSelectedCompanies(filterCompanies);
  };

  const handleAllSelect = (item: any) => {
    if (item.id === 'all' && item.selected) {
      const allCompanyIds = companies?.map((company: any) => company.id);
      setSelectedCompanies(allCompanyIds);
    } else if (item.id === 'all' && !item.selected) {
      setSelectedCompanies([]);
    }
  };

  const onCompanyPillSelected = (id: string) => {
    const newSelectedCompanies = selectedCompanies!.filter(
      (ids: any) => ids !== id,
    ) as string[];
    setSelectedCompanies(newSelectedCompanies);
  };

  const onClearCompanies = () => {
    setSelectedCompanies([]);
  };

  const renderSelectedCompanies = (isAllSelected: boolean) => {
    if (isAllSelected) {
      return (
        <Pill
          iconLeft={CompanyBuilding}
          key='all'
          label={t('all_companies')}
          hasCloseButton
          onClick={() => {
            setSelectedCompanies([]);
          }}
          translationOn
        />
      );
    }
    return (
      <div className='flex flex-row gap-2 flex-wrap mt-1'>
        {companyList
          ?.filter((company) => company.selected)
          .map((pill) => {
            return (
              <Pill
                iconLeft={CompanyBuilding}
                key={pill.id}
                label={pill.label}
                hasCloseButton
                onClick={() => onCompanyPillSelected(pill.id)}
                translationOn
              />
            );
          })}
      </div>
    );
  };

  const noCompaniesSelected = () => {
    return (
      <div className='flex flex-col gap-2 items-center my-4'>
        <Icon src={CompanyBuilding} size={IconSize.SIZE_48x48} />
        <Label
          text={t('no_companies_selected')}
          type={LabelType.H4}
          color={ColorType.GREY5}
        />
        <Label
          text={t('no_companies_sub_label')}
          type={LabelType.BODY2}
          color={ColorType.GREY5}
          className='text-center'
        />
      </div>
    );
  };

  const renderAllCompaniesDropdown = () => {
    const isAllSelected = companyList.every((item: any) => item.selected);
    if (companyList && companyList.length > 0) {
      return (
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-2'>
            <Label
              text={t('companies')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Dropdown
              placeholder={t('select_companies')}
              placeholderLabelType={LabelType.LABEL_S_GREY4}
              type={DropdownType.CHECKBOX}
              headerWidth='100%'
              items={companyList}
              onItemClick={onCompanySelected}
              handleAllSelect={handleAllSelect}
              contentDivWidth={300}
              contentDivHeight={336}
              headerClassName='bg-white border border-grey3 rounded pr-2'
              disabled={selectedCompanies.length >= NUMBER.MAX_COMPANIES}
              showFooter
            />
          </div>
          {!isAllSelected && (
            <div className='flex flex-row justify-between'>
              <Label
                type={LabelType.BODY3}
                color={ColorType.GREY5}
                text={`${selectedCompanies.length}/${NUMBER.MAX_COMPANIES} ${t(
                  'selected',
                )}`}
              />
              <Label
                type={LabelType.DROPDOWN_ITEM_SELECTED}
                text={t('clear')}
                style={{ color: theme.navigationSelectedColor }}
                onClick={onClearCompanies}
              />
            </div>
          )}
          {renderSelectedCompanies(isAllSelected)}
          {selectedCompanies.length === 0 && noCompaniesSelected()}
        </div>
      );
    }
    return null;
  };

  const renderChargerDropdown = () => {
    return (
      <>
        <div className='flex flex-col gap-2'>
          <Label text={t('pricing_chargers')} type={LabelType.LABEL_M} />
          <Dropdown
            placeholder={t('pricing_chargers_holder')}
            placeholderLabelType={LabelType.BODY3_G4}
            headerWidth='100%'
            items={chargerDropdownList}
            type={DropdownType.CHECKBOX_TREE}
            onItemClick={(items: any) => handleChargersChange(items)}
            showFooter
            contentDivWidth={340}
            contentDivHeight={336}
          />
          <div className='flex flex-row items-baseline flex-wrap gap-2'>
            {renderSelectedChargers()}
          </div>
        </div>
        {chargerPillsToShow?.length === 0 &&
          !allChargerSelected &&
          renderNoSelectedChargerOrCompany()}
      </>
    );
  };

  useEffect(() => {
    setReportData({
      ...reportData,
      companyIds: companyList.every((item: any) => item.selected)
        ? ['all']
        : selectedCompanies,
    });
  }, [selectedCompanies]);

  return (
    <div>
      <Card OnRenderTitle={renderCardTitle} className='gap-6'>
        <Form
          onSubmit={handleSaveClick}
          queryResult={getQueryResult}
          onQuerySuccess={() => {
            handleQuerySuccess();
          }}
          onQueryFailed={() => {
            displayToast(2);
          }}
        >
          <div className='flex flex-col gap-6'>
            <div className='flex flex-row gap-6'>
              <div className='flex flex-col gap-6 w-[364px] border-r border-grey3 pr-6'>
                {isMultiViewActive
                  ? renderAllCompaniesDropdown()
                  : renderChargerDropdown()}
              </div>
              <div className='flex flex-col gap-6'>
                {renderCategory()}
                {renderReportType()}
                <div className='w-[340px] z-40'>{renderTimeRange()}</div>
              </div>
            </div>
            <Divider />
            {renderReportContents()}
            {renderButtons()}
          </div>
        </Form>
      </Card>
    </div>
  );
};
