import { Voltage } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';

interface Props {
  value: number;
}

export const Volt = ({ value }: Props) => {
  return (
    <div className='flex flex-row items-end gap-x-1'>
      <Icon src={Voltage} size={IconSize.SIZE_20x20} className='mr-1' />
      <Label
        text={`${value} V`}
        type={LabelType.LABEL_XS_MEDIUM}
        color={ColorType.GREY5}
      />
    </div>
  );
};
