import { useTranslation } from 'react-i18next';
import { Info } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { Breaker, Circuit } from '../../stores/types/pm.interface';
import { ColorType, Icon } from '../_ui';
import { Label, LabelType } from '../_ui/Label.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { transformElectricSupply } from './utils';

export interface MainBreakerDetailsProp {
  data: {
    breaker: Breaker;
    circuit: Circuit;
  };
}
export const MainBreakerDetailsModal = ({ data }: MainBreakerDetailsProp) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-y-4 px-2 pb-5'>
      <div className='flex flex-col gap-y-2'>
        <Label
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          text={t('main_breaker_modal_field_1')}
        />
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={data.breaker.name}
        />
      </div>
      <div className='flex flex-col gap-y-2'>
        <div className='flex flex-row items-center gap-x-1'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('main_breaker_modal_field_2')}
          />
          <Tooltip placement='bottom-start'>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_24x24} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={t('main_breaker_modal_tooltip')}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        </div>
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={transformElectricSupply(data?.circuit?.electricalSupply || '')}
        />
      </div>
      <div className='flex flex-col gap-y-2'>
        <div className='flex flex-row'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('main_breaker_modal_field_3')}
          />
          <div>&nbsp;</div>
          <Label
            type={LabelType.LABEL_M_MEDIUM}
            color={ColorType.GREY6}
            text={t('main_breaker_modal_field_4')}
          />
        </div>
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={`${data.circuit.lineVoltage} volts`}
        />
      </div>
      <div className='flex flex-col gap-y-2'>
        <Label
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          text={t('main_breaker_modal_field_5')}
        />
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={`${data.breaker.breakerRating} amps`}
        />
      </div>
      <div className='flex flex-col gap-y-2'>
        <div className='flex flex-row'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('main_breaker_modal_field_6')}
          />
          <div>&nbsp;</div>
          <Label
            type={LabelType.LABEL_M_MEDIUM}
            color={ColorType.GREY6}
            text={t('main_breaker_modal_field_7')}
          />
        </div>
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={`${data.breaker.continuousLoadLimit} amps`}
        />
      </div>
    </div>
  );
};
