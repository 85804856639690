import { useState, useMemo, useEffect, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from '../_ui/modal/GlobalModal.component';
import { Company, Invite } from '../../stores/types';
import {
  Label,
  Card,
  LabelType,
  Dropdown,
  Grid,
  Pill,
  DropdownType,
  Icon,
  Button,
  ButtonType,
  ButtonSize,
  FormInput,
} from '../_ui';

import {
  useCreateInvitesMutation,
  useDeleteInvitesMutation,
} from '../../services/usergroups.api';

interface Prop {
  invite: Invite;
  company: Company | undefined;
}

export const CancelInvites = memo(({ invite, company }: Prop) => {
  const { t } = useTranslation();
  const { hideModal, showModal } = useGlobalModalContext();
  const [deleteInvites] = useDeleteInvitesMutation();

  const handleCancelInvite = () => {
    if (company?.adminUserGroupId && invite.inviteId) {
      deleteInvites({
        inviteId: invite.inviteId,
        uuid: company?.adminUserGroupId,
      });
      hideModal();
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='text-black whitespace-pre-line text-sm leading-5 font-normal'>
        <Trans
          i18nKey='company_cancel_invite_content'
          values={{ email: invite.email }}
          components={{
            br: <br />,
            bold: <strong />,
          }}
        />
      </div>
      <div className='mt-4 flex flex-row items-end gap-4 justify-end'>
        <Button
          onClick={hideModal}
          label={t('cancel')}
          size={ButtonSize.SMALL}
          type={ButtonType.TERTIARY}
          dataTestId='closeCancleInviteModel'
        />
        <Button
          onClick={handleCancelInvite}
          label={t('confirm')}
          size={ButtonSize.SMALL}
          type={ButtonType.DESTRUCTIVE}
          dataTestId='confirmCancleInviteModel'
        />
      </div>
    </div>
  );
});
