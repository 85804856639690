import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ColorType, Label, LabelType } from '../_ui';

interface QRCodeProps {
  chargerName: string;
  webAppUrl: string;
}

export const ViewQRCode = ({ chargerName, webAppUrl }: QRCodeProps) => {
  const { t } = useTranslation();

  const handleDownloadClick = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg as Node);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const padding = 100; // Define the padding size in pixels

      // Calculate the dimensions of the canvas
      const canvasWidth = img.width + padding * 2;
      const canvasHeight = img.height + padding * 2 + 300; // Increase the height by 60 pixels for the additional text and background

      // Set the canvas size
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      if (ctx) {
        // Draw the white background
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);

        // Draw the QR code image with padding
        ctx.drawImage(img, padding, padding);

        // Add white background for the additional text
        ctx.fillStyle = 'white';
        ctx.fillRect(0, img.height + padding * 2, canvasWidth, 60);

        // Add text below the QR code
        ctx.fillStyle = 'black';
        ctx.font = 'bold 128px Inter';
        ctx.textAlign = 'center';
        ctx.fillText(
          chargerName,
          canvasWidth / 2,
          img.height + padding * 2 + 160,
        );
      }

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `Charger_QR_Code_${chargerName}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col h-auto my-0 mx-auto w-[160px] justify-items-center relative pb-5'>
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            width: '100%',
          }}
        >
          <QRCode
            id='QRCode'
            size={1000}
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
            viewBox='0 0 200 200'
            value={`${webAppUrl}/${chargerName}`}
          />
        </div>
      </div>
      <div className='flex flex-col h-auto my-0 w-[500px] px-5'>
        <Label
          text={chargerName}
          type={LabelType.H1}
          color={ColorType.BLACK}
          className='mx-auto text-center justify-items-center relative break-words w-[460px]'
        />
      </div>
      <div className='flex flex-col px-5 py-6 gap-y-4'>
        <Label
          text={t('view_qr_code_msg_1')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
        <Label
          text={t('view_qr_code_msg_2')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
        <Button
          label={t('download')}
          size={ButtonSize.SMALL}
          onClick={handleDownloadClick}
        />
      </div>
    </div>
  );
};
