import { UpdateAccessRuleRequest } from '../stores/types/access/editAccessRuleRequest.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE, providesListTag } from './utils';

export enum ACCESS_EDIT_TYPE {
  ADD,
  EDIT,
  DELETE,
}

export const accessApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    updateAccess: builder.mutation<
      any,
      {
        body: UpdateAccessRuleRequest;
        scope: SCOPE_TYPE;
        updateType: ACCESS_EDIT_TYPE;
      }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS_BY_BATCH}/acl?bulk=true`,
        params: { scope: payload.scope },
        body: payload.body,
        method: 'PUT',
        toast: {
          successTrans:
            payload.updateType === ACCESS_EDIT_TYPE.ADD
              ? 'access_add_success_message'
              : payload.updateType === ACCESS_EDIT_TYPE.EDIT
              ? 'access_update_success_message'
              : 'access_delete_group_success_message',
        },
      }),
      invalidatesTags: (result, error, arg) =>
        providesListTag(
          arg.body.batch[0].chargerIds
            ? arg.body.batch[0].chargerIds.map((chargerId) => ({
                id: chargerId,
              }))
            : [],
          API_KEYS.CHARGERS,
        ),
    }),
    checkIfUserEmailExists: builder.query<any, any>({
      query: (email: string) => ({
        url: `/internal/core/v2/${API_KEYS.USERS}/${email}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useUpdateAccessMutation,
  useCheckIfUserEmailExistsQuery,
  useLazyCheckIfUserEmailExistsQuery,
} = accessApi;
