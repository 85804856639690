import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { useAuth } from '../../hooks';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { RegisterCreateAccount } from './RegisterCreateAccount.component';

export const Register = () => {
  const theme = useSelector(getCurrentTheme);
  const auth = useAuth();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();
  const location = useLocation();
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

  useEffect(() => {
    if (auth.user) {
      navigateWithCompanyId(
        `../${RoutePath.OVERVIEW}`,
        auth.user?.attributes?.profile?.lastActiveCompanyId,
        { replace: true },
      );
    }
  }, [auth]);

  const handleRegisterSuccess = (value: boolean) => {
    setIsRegisterSuccess(value);
  };

  return (
    <div className='h-screen bg-[#E5E5E5]'>
      <div className='flex flex-col items-center'>
        <div className='pt-10 pb-10'>
          <img
            src={theme.networkLogo}
            alt=''
            style={{ width: 'auto', height: '54px' }}
          />
        </div>
        <div className='w-[440px] bg-white rounded py-[48px] px-[40px] border-grey0 items-center'>
          <StepWizard
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
              intro: '',
            }}
          >
            <RegisterCreateAccount registerSuccess={handleRegisterSuccess} />
          </StepWizard>
        </div>
        {!isRegisterSuccess && (
          <div
            className='w-[440px] px-4 py-2 text-grey5 font-normal'
            style={{ fontSize: '12px', lineHeight: '20px' }}
          >
            <Trans
              i18nKey='terms_of_service_register_msg_3'
              values={{ whiteLabelCompanyName }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
