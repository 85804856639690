import React, { memo } from 'react';
import { Label, LabelType } from '../_ui/Label.component';
import { ColorType, Icon } from '../_ui';
import { ChargerPort } from '../../stores/types/sessions.interface';
import { IconSize } from '../../constant/IconSize.constant';
import {
  Battery25,
  Battery50,
  Battery75,
  Battery100,
} from '../../assets/icons';
import { isNullOrUndefined } from '../../utils/TypeScript.util';

interface IProps {
  ports?: ChargerPort[];
  isLoading?: boolean;
}
export const ChargerStateOfCharge = memo(({ ports, isLoading }: IProps) => {
  const getBatterySvg = (stateOfCharge: number) => {
    if (stateOfCharge < 25) {
      return Battery25;
    }
    if (stateOfCharge < 80) {
      return Battery50;
    }
    if (stateOfCharge < 100) {
      return Battery75;
    }
    return Battery100;
  };
  const renderStateOfChargeIcon = (stateOfCharge: number) => {
    return (
      <Icon src={getBatterySvg(stateOfCharge)} size={IconSize.SIZE_20x20} />
    );
  };
  const renderStateOfChargeLabel = (stateOfCharge: number) => {
    return (
      <Label
        className='whitespace-nowrap'
        text={stateOfCharge.toFixed(1)}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />
    );
  };
  const renderPercentageLabel = () => {
    return (
      <Label
        className='whitespace-nowrap'
        text='%'
        type={LabelType.BODY3}
        color={ColorType.GREY5}
      />
    );
  };
  const renderPortSeparatorLabel = () => {
    return (
      <Label
        className='whitespace-nowrap'
        text='|'
        type={LabelType.BODY3}
        color={ColorType.GREY3}
      />
    );
  };
  const renderChargerStateOfChargeData = (chargerPorts: ChargerPort[]) => {
    const portsWithSoC = chargerPorts?.filter(
      (port) => !isNullOrUndefined(port.stateOfCharge),
    );
    if (isNullOrUndefined(portsWithSoC)) return null;
    const numberOfPorts = portsWithSoC.length;
    return (
      <div className='flex flex-row items-center h-6 whitespace-nowrap w-max overflow-hidden gap-1'>
        {portsWithSoC.map((port, index) => {
          if (isNullOrUndefined(port.stateOfCharge)) return null;
          return (
            <div key={port.id} className='flex flex-row gap-1'>
              {renderStateOfChargeIcon(port.stateOfCharge)}
              {renderStateOfChargeLabel(port.stateOfCharge)}
              {renderPercentageLabel()}
              {index !== numberOfPorts - 1 && renderPortSeparatorLabel()}
            </div>
          );
        })}
      </div>
    );
  };
  return isLoading || isNullOrUndefined(ports)
    ? null
    : renderChargerStateOfChargeData(ports);
});

export default ChargerStateOfCharge;
