import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { header } from './utils';
import ReactGA from 'react-ga4';
import { header } from './http/http.service';
import { SCOPE_TYPE } from './utils';
import { API_KEYS, Api } from './base.api';
import {
  GA_ACTION,
  GA_CATEGORY,
  GA_LABEL,
  GA_LOCAL_STORAGE_KEY_CLIENT_ID,
  GA_LOCAL_STORAGE_KEY_SESSION_ID,
  pushEventToGA,
} from '../utils/GoogleAnalytics';

const formatBody = (payload: Record<string, any>) => {
  const clientId = localStorage.getItem(GA_LOCAL_STORAGE_KEY_CLIENT_ID);
  const sessionId = localStorage.getItem(GA_LOCAL_STORAGE_KEY_SESSION_ID);
  return {
    client_id: clientId,
    events: [
      {
        name: payload.name,
        param: {
          ...payload.params,
          session_id: sessionId,
          engagement_time_msec: Date.now(),
        },
      },
    ],
  };
};

export const googleApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    googleAnalytics: builder.mutation<void, Record<string, any>>({
      query: (payload) => ({
        url: API_KEYS.GOOGLE_ANALYTICS,
        body: formatBody(payload),
        method: 'POST',
        measurementUrl: true,
        needCompanyId: false,
        params: { scope: SCOPE_TYPE.NONE },
      }),
    }),
    clickAnalytics: builder.mutation<void, string>({
      query: (label) => ({
        url: API_KEYS.GOOGLE_ANALYTICS,
        body: formatBody({
          name: 'click',
          params: {
            label,
          },
        }),
        method: 'POST',
        measurementUrl: true,
        needCompanyId: false,
        params: { scope: SCOPE_TYPE.NONE },
        responseHandler: (response) =>
          response.text().then(() => {
            pushEventToGA(GA_CATEGORY.TOP_NAV, GA_ACTION.CLICK, label);
          }),
      }),
    }),
    loginAnalytics: builder.mutation<void, void>({
      query: () => ({
        url: API_KEYS.GOOGLE_ANALYTICS,
        body: formatBody({
          name: 'login',
          params: {
            method: 'dashboard',
          },
        }),
        method: 'POST',
        measurementUrl: true,
        needCompanyId: false,
        params: { scope: SCOPE_TYPE.NONE },
        responseHandler: (response) =>
          response.text().then(() => {
            pushEventToGA(
              GA_CATEGORY.AUTH,
              GA_ACTION.CLICK,
              GA_LABEL.SIGNUP_SUCCESS,
              'dashboard',
            );
          }),
      }),
    }),
    signUpAnalytics: builder.mutation<void, void>({
      query: () => ({
        url: API_KEYS.GOOGLE_ANALYTICS,
        body: formatBody({
          name: 'sign_up',
          params: {
            method: 'dashboard',
          },
        }),
        method: 'POST',
        measurementUrl: true,
        needCompanyId: false,
        params: { scope: SCOPE_TYPE.NONE },
        responseHandler: (response) =>
          response.text().then(() => {
            pushEventToGA(
              GA_CATEGORY.AUTH,
              GA_ACTION.CLICK,
              GA_LABEL.SIGNUP_SUCCESS,
              'dashboard',
            );
          }),
      }),
    }),
    logoutAnalytics: builder.mutation<void, void>({
      query: () => ({
        url: API_KEYS.GOOGLE_ANALYTICS,
        body: formatBody({
          name: 'logout',
          params: {
            method: 'dashboard',
          },
        }),
        method: 'POST',
        measurementUrl: true,
        needCompanyId: false,
        params: { scope: SCOPE_TYPE.NONE },
        responseHandler: (response) =>
          response.text().then(() => {
            pushEventToGA(
              GA_CATEGORY.AUTH,
              GA_ACTION.CLICK,
              GA_LABEL.LOGIN_SUCCESS,
              'dashboard',
            );
          }),
      }),
    }),
  }),
});

export const {
  useGoogleAnalyticsMutation,
  useLoginAnalyticsMutation,
  useSignUpAnalyticsMutation,
  useLogoutAnalyticsMutation,
  useClickAnalyticsMutation,
} = googleApi;
