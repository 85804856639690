import { PayterProvider } from '../../../hooks/usePayter';
import { Charger } from '../../../stores/types';
import ViewTerminal from './ViewTerminal.component';

interface IProps {
  charger: Charger;
  editEnabled: boolean;
  handleInputChange: (key: string, val: string) => void;
}

const ChargerTerminal = ({
  charger,
  editEnabled,
  handleInputChange,
}: IProps) => {
  return (
    <PayterProvider>
      <ViewTerminal
        charger={charger}
        editEnabled={editEnabled}
        handleInputChange={handleInputChange}
      />
    </PayterProvider>
  );
};

export default ChargerTerminal;
