import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { useCompany } from '../../hooks/useCompany';
import { useUpdateBulkChargersMutation } from '../../services/charger.api';
import { useFetchPricingModelsQuery } from '../../services/pricing.api';
import { Charger } from '../../stores/types';
import { ChargerSharedDetails } from '../../stores/types/chargers.interface';
import { Price, PricingDropdown } from '../../stores/types/price.interface';
import { formatDate } from '../../utils/Date.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  DateTimePicker,
  Dropdown,
  FormInput,
  Icon,
  Label,
  LabelType,
  Pill,
  Switch,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { CustomInputType } from '../_ui/date-picker/Date-Picker.component';

export const ChargerBulkEditor = (props: any) => {
  const { selectedChargers = [], hideModal, setSelectedChargers } = props;
  const { t } = useTranslation();
  const { companyId } = useCompany();
  const [bulkChargersDetails, setBulkChargersDetails] =
    useState<ChargerSharedDetails>({
      deploymentStatus: false,
      hiddenOnMap: false,
      priceModelId: '',
    } as ChargerSharedDetails);

  const { userScope } = useCompany();

  const [showHide, setShowHide] = useState(false);
  const [updateBulkChargers, updateBulkChargerQueryResult] =
    useUpdateBulkChargersMutation();
  // this will become true if users edit deployment status or deployment date
  const [shouldUpdateDeployedStatus, setShouldUpdateDeployedStatus] =
    useState(false);

  const isPricingRuleSame = useMemo(() => {
    return selectedChargers.every(
      (item: Charger) => item.priceModelId === selectedChargers[0].priceModelId,
    );
  }, [selectedChargers]);

  // Checking if selected charger(s) have same deployment status
  const isDeploymentStatusSame = useMemo(() => {
    return selectedChargers.every(
      (item: Charger) =>
        item.deploymentInfo?.deployed ===
        selectedChargers[0].deploymentInfo?.deployed,
    );
  }, [selectedChargers]);

  useEffect(() => {
    // Checking if selected charger(s) have same pricing rule
    if (selectedChargers?.length > 0) {
      let updatedBulkChargerDetails = { ...bulkChargersDetails };

      // Setting priceModelId to bulkChargersDetails if all pricing rule are same
      if (isPricingRuleSame) {
        updatedBulkChargerDetails = {
          ...updatedBulkChargerDetails,
          priceModelId: selectedChargers[0].priceModelId,
        };
      }

      updatedBulkChargerDetails = {
        ...updatedBulkChargerDetails,
        deploymentStatus:
          selectedChargers[0].deploymentInfo && isDeploymentStatusSame
            ? selectedChargers[0].deploymentInfo.deployed
            : false,
      };
      setBulkChargersDetails(updatedBulkChargerDetails);
    }
  }, [selectedChargers, isPricingRuleSame, isDeploymentStatusSame]);

  const { pricing } = useFetchPricingModelsQuery(
    { companyId: companyId!, scope: userScope! },
    {
      selectFromResult: (endPoint: any) => ({
        pricing: endPoint.data ? endPoint.data : [],
      }),
    },
  );

  const pricingDropdown = useMemo(() => {
    return pricing.map((pricingObj: Price) => ({
      id: pricingObj.id,
      label: pricingObj.displayName,
      selected: isPricingRuleSame
        ? pricingObj.id === selectedChargers[0].priceModelId
        : '',
    }));
  }, [pricing, selectedChargers, isPricingRuleSame]);

  const handleInputChange = (key: string, value: string) => {
    setBulkChargersDetails({ ...bulkChargersDetails, [key]: value });
  };

  const handleToggleChange = (key: string, value: boolean) => {
    setBulkChargersDetails({
      ...bulkChargersDetails,
      [key]: value,
    });

    // we should send deployment status in request
    if (key === 'deploymentStatus') {
      setShouldUpdateDeployedStatus(true);
    }
  };

  // Removed button validation based on comments on BF-1226
  // const onButtonValidation = () => {
  //   if (
  //     bulkChargersDetails.deploymentStatus &&
  //     !bulkChargersDetails.deploymentDate
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleEditBulkChargers = () => {
    const payload = selectedChargers.map((ele: Charger) => {
      let result: any = {
        deviceId: ele.id,
        chargerModelId: ele.chargerModelId,
        hiddenOnMap: bulkChargersDetails.hiddenOnMap,
        directions: bulkChargersDetails.directions,
        internalNote: bulkChargersDetails.internalNote,
        installer: bulkChargersDetails.installer,
        installationDate: bulkChargersDetails.installationDate,
        installerContactEmail: bulkChargersDetails.installerContactEmail,
        displayId: ele.name,
      };

      if (bulkChargersDetails.priceModelId !== '') {
        result = { ...result, priceModelId: bulkChargersDetails.priceModelId };
      }

      if (shouldUpdateDeployedStatus) {
        result = {
          ...result,
          deploymentStatus: bulkChargersDetails.deploymentStatus,
        };
      }

      return result;
    });
    updateBulkChargers({
      bulkChargers: payload,
      bulkOperation: payload.length > 1,
      autoPopulate: true,
    });
    setSelectedChargers([]);
  };

  const selectedChargersCount = selectedChargers.length;

  const countOfChargerToDisplay = showHide ? selectedChargersCount : NUMBER.TEN;

  const mapVisibilityLabel = () => (
    <Label
      text={t('chargers_map_visibility_hint')}
      type={LabelType.BODY3}
      color={ColorType.WHITE}
    />
  );

  const renderDisabledSwitchLabel = () => {
    if (!bulkChargersDetails.deploymentStatus) {
      if (!isDeploymentStatusSame && !shouldUpdateDeployedStatus) {
        return t('edit_bulk_charger_some_deployed');
      }
      return t('edit_bulk_charger_not_yet_deployed');
    }
  };

  return (
    <Form
      onSubmit={handleEditBulkChargers}
      queryResult={updateBulkChargerQueryResult}
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <div className='divide-y divide-grey2'>
        <div className='pb-4'>
          <div className='flex items-baseline'>
            <Label
              className='pb-4'
              text={t('setting_applied')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            <Label
              className='pb-4 pl-1'
              text={t('setting_applied_to_all_chargers')}
              type={LabelType.LABEL_S}
              color={ColorType.BLACK}
            />
          </div>
          <div className='flex-wrap' style={{ display: 'flex' }}>
            {selectedChargers
              .slice(0, countOfChargerToDisplay)
              .map((ele: Charger) => (
                <div className='pr-2 pb-2' key={ele.id}>
                  <Pill label={ele.name} labelColor={ColorType.GREY6} />
                </div>
              ))}
          </div>
          {selectedChargersCount > NUMBER.TEN && (
            <div
              className='text-sm font-medium whitespace-pre text-brand2 cursor-pointer'
              onClick={() => setShowHide(!showHide)}
            >
              {`View ${showHide ? 'less' : 'more'} (showing ${
                !showHide ? NUMBER.TEN : selectedChargersCount
              } of ${selectedChargersCount})`}
            </div>
          )}
        </div>
        <div className='pt-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-y-2'>
              <Label
                text={t('chargers_pricing_rule')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Dropdown
                headerWidth='500px'
                items={pricingDropdown}
                placeholder={t('select_pricing_rule')}
                onItemClick={(selectedItem: PricingDropdown) =>
                  handleInputChange('priceModelId', selectedItem.id)
                }
                labelType={LabelType.BODY3_G4}
                contentDivWidth={500}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <div className='flex flex-row gap-1 items-center'>
                <Label
                  text={t('chargers_map_visibility')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <Tooltip>
                  <TooltipTrigger>
                    <Icon src={Info} size={IconSize.SIZE_20x20} />
                  </TooltipTrigger>
                  <TooltipContent>{mapVisibilityLabel()}</TooltipContent>
                </Tooltip>
              </div>
              <CheckBox
                label={t('chargers_visibility_user_access')}
                key='visible'
                index={0}
                selected={!bulkChargersDetails.hiddenOnMap}
                onChange={(value: boolean) =>
                  handleToggleChange('hiddenOnMap', !value)
                }
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <FormInput
                defaultValue={bulkChargersDetails?.directions}
                label={t('chargers_directions')}
                secondLabel={`(${t('chargers_optional')})`}
                secondLabelType={LabelType.LABEL_M_MEDIUM}
                secondLabelColor={ColorType.GREY6}
                placeholder={t('chargers_directions')}
                inputType={InputType.TEXT}
                props={{ style: { width: '500px' } }}
                onChange={(event: any) =>
                  handleInputChange('directions', event.target.value)
                }
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <FormInput
                defaultValue={bulkChargersDetails?.internalNote}
                label={t('chargers_internal_note')}
                secondLabel={`(${t('chargers_optional')})`}
                secondLabelType={LabelType.LABEL_M_MEDIUM}
                secondLabelColor={ColorType.GREY6}
                placeholder={t('chargers_internal_note_placeholder')}
                inputType={InputType.TEXT}
                props={{ style: { width: '500px' } }}
                onChange={(event: any) =>
                  handleInputChange('internalNote', event.target.value)
                }
              />
            </div>
            <div className='flex flex-row gap-4 justify-between'>
              <div className='flex flex-col gap-y-2 w-full'>
                <FormInput
                  defaultValue={bulkChargersDetails?.installer}
                  width='100%'
                  label={t('chargers_installer')}
                  secondLabel={`(${t('chargers_optional')})`}
                  secondLabelType={LabelType.LABEL_M_MEDIUM}
                  secondLabelColor={ColorType.GREY6}
                  placeholder={t('chargers_installer')}
                  inputType={InputType.TEXT}
                  onChange={(event: any) =>
                    handleInputChange('installer', event.target.value)
                  }
                />
              </div>
              <div className='flex flex-col gap-y-2'>
                <div className='flex flex-row gap-1'>
                  <Label
                    text={t('chargers_installation_date')}
                    type={LabelType.LABEL_M}
                    color={ColorType.BLACK}
                  />
                  <Label
                    text={`(${t('chargers_optional')})`}
                    type={LabelType.LABEL_M_MEDIUM}
                    color={ColorType.GREY6}
                  />
                </div>
                <DateTimePicker
                  onChange={(date) =>
                    handleInputChange(
                      'installationDate',
                      formatDate(date, 'yyyy-MM-dd'),
                    )
                  }
                  format='MM/dd/yyyy'
                  customInputType={CustomInputType.FORMINPUTDATE}
                  showDefaultDate={false}
                  customFormInputDivWidth='w-[240px]'
                  customFormInputErrorLabel={t(
                    'chargers_date_validation_message',
                  )}
                />
              </div>
            </div>
            <div className='flex flex-col gap-y-2'>
              <FormInput
                defaultValue={bulkChargersDetails?.installerContactEmail}
                label={t('edit_bulk_charger_installer_email')}
                secondLabel={`(${t('chargers_optional')})`}
                secondLabelType={LabelType.LABEL_M_MEDIUM}
                secondLabelColor={ColorType.GREY6}
                placeholder={t('chargers_installer_contact_placeholder')}
                inputType={InputType.TEXT}
                validationType={ValidationType.EMAIL}
                showErrorIcon
                errorLabel={t('chargers_email_validation_message')}
                props={{ style: { width: '500px' } }}
                onChange={(event: any) =>
                  handleInputChange('installerContactEmail', event.target.value)
                }
              />
            </div>
            <div className='flex flex-row gap-4 justify-between'>
              <div className='flex flex-col gap-y-2'>
                <div className='flex flex-row gap-1'>
                  <Label
                    text={t('edit_bulk_charger_deployment_status')}
                    type={LabelType.LABEL_M}
                    color={ColorType.BLACK}
                  />
                </div>
                <Switch
                  value={bulkChargersDetails.deploymentStatus}
                  enableLabel={t('edit_bulk_charger_deployed')}
                  disableLabel={renderDisabledSwitchLabel()}
                  onChange={(value: boolean) => {
                    handleToggleChange('deploymentStatus', value);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col pt-[16px] pb-[20px] sticky bottom-0 bg-white'>
              <Button
                label={t('chargers_add_save')}
                type={ButtonType.PRIMARY}
                size={ButtonSize.SMALL_FULL}
                isSumbit
                dataTestId='charger-edit-save'
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
