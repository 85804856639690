import { t } from 'i18next';
import {
  PRICING_SCHEDULED_TYPE,
  WEEKDAYS,
  WEEKDAY_WEEKEND,
} from '../../../constant/Text.constant';
import { Price } from '../../../stores/types/price.interface';
import { ColorType, Label, LabelType } from '../../_ui';
import {
  clubDayPricing,
  convertDaysArrayToString,
  formatTime,
  is24Hour,
} from '../utils';

export const TimeOfUsePricing = (
  pricingData: Price,
  renderPriceType: Function,
) => {
  const { scheduleType, dayPrices } = pricingData;
  const { PER_DAY, SAME_EVERYDAY, WEEKDAYS_WEEKEND } = PRICING_SCHEDULED_TYPE;

  const getTime = (pricePeriod: any) => {
    let text = '';
    if (is24Hour(pricePeriod.startTime, pricePeriod.endTime)) {
      text = `${formatTime(pricePeriod.startTime)}-${formatTime(
        pricePeriod.endTime,
      )} (24h)`;
    } else {
      text = `${formatTime(pricePeriod.startTime)}-${formatTime(
        pricePeriod.endTime,
      )}`;
    }
    return text;
  };

  if (
    scheduleType !== PER_DAY &&
    scheduleType !== SAME_EVERYDAY &&
    scheduleType !== WEEKDAYS_WEEKEND
  ) {
    return null;
  }

  const renderTouData = (day: Array<string>, periods: Array<any>) => {
    return (
      <div className='py-2'>
        <Label
          text={day.join(', ')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.BLACK}
        />
        {periods?.map((pricePeriod) => {
          const { startTime, endTime } = pricePeriod;
          return (
            <div
              key={`${formatTime(startTime)}-${formatTime(endTime)}`}
              className='flex justify-between'
            >
              <Label
                text={getTime(pricePeriod)}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
              {renderPriceType(pricePeriod)}
            </div>
          );
        })}
      </div>
    );
  };

  let dayType = {};
  if (scheduleType === PER_DAY) dayType = WEEKDAYS;
  else if (scheduleType === WEEKDAYS_WEEKEND) dayType = WEEKDAY_WEEKEND;

  const data: { [x: string]: any } = {};
  clubDayPricing(dayPrices, dayType).forEach((item: any) => {
    // TODO: Improve this
    // I have to added this map, previous comparsion doesn't work because the active value is different.
    const mappedPricePeriods = Array.isArray(item?.pricePeriods)
      ? item?.pricePeriods?.map((pricePeriod: any) => {
          return {
            startTime: pricePeriod.startTime,
            endTime: pricePeriod.endTime,
            price: pricePeriod.price,
            pricingType: pricePeriod.pricingType,
          };
        })
      : item?.pricePeriods;
    const priceKey = JSON.stringify(mappedPricePeriods);
    if (data[priceKey]) {
      data[priceKey] = [...data[priceKey], item.day];
    } else {
      data[priceKey] = [item.day];
    }
  });

  const dataKey = Object.keys(data);
  return scheduleType === PER_DAY || scheduleType === WEEKDAYS_WEEKEND
    ? dataKey?.map((day: string) => {
        const renderDays = convertDaysArrayToString(data[day], scheduleType);
        return JSON.parse(day) !== 'Free' ? (
          renderTouData(renderDays, JSON.parse(day))
        ) : (
          <div className='py-2' key={day}>
            <Label
              text={renderDays?.join(', ')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BLACK}
            />
            <div className='flex justify-between'>
              <Label
                text={t('free')}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.BLACK}
              />
            </div>
          </div>
        );
      })
    : dayPrices?.map((day: any) => renderTouData([day.day], day?.pricePeriods));
};
