import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { header } from './http/http.service';
import { Location } from '../stores/types';

const API_KEY = 'maps/api/timezone/json';

export const timeZoneApi = createApi({
  reducerPath: API_KEY,
  baseQuery: fetchBaseQuery({
    headers: header,
    baseUrl: `/${API_KEY}`,
  }),
  endpoints: (builder) => ({
    fetchtTimezone: builder.query<any, any>({
      query: (location: Location) => {
        return {
          url: '',
          method: 'GET',
          params: location,
        };
      },
    }),
  }),
});

export const { useLazyFetchtTimezoneQuery } = timeZoneApi;
