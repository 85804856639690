import { memo } from 'react';
import { Button, ButtonSize, ButtonType, ColorType, Scrollable } from '.';
import { Label, LabelType } from './Label.component';

interface InputProps {
  title?: string;
  OnRenderTitle?: any;
  titleType?: LabelType;
  titleColor?: ColorType;
  className?: string;
  children?: any;
  bg?: string;
  headerButtonLabel?: string;
  headerButtonOnClick?: Function;
  headerButtonIcon?: any;
  headerButtonClassname?: string;
  dataTestId?: string;
}

export const Card = memo(
  ({
    title = '',
    OnRenderTitle,
    titleType = LabelType.H4,
    titleColor = ColorType.BLACK,
    className = '',
    children,
    bg = 'bg-white',
    headerButtonLabel,
    headerButtonOnClick,
    headerButtonIcon,
    headerButtonClassname = 'top-[16px] right-[20px]',
    dataTestId = '',
  }: InputProps) => {
    const handleButtonClick = () => {
      headerButtonOnClick && headerButtonOnClick();
    };
    return (
      <div className={`relative w-full rounded-lg p-5 ${bg} ${className}`}>
        {OnRenderTitle ? (
          <div className='sticky top-0 bg-white z-1 mb-4'>
            {OnRenderTitle()}
          </div>
        ) : title ? (
          <div className='sticky top-0 bg-white z-1 mb-4'>
            <Label type={titleType} color={titleColor} text={title} />
          </div>
        ) : null}

        {headerButtonLabel && (
          <div className={`absolute ${headerButtonClassname}`}>
            <Button
              icon={headerButtonIcon}
              label={headerButtonLabel}
              onClick={handleButtonClick}
              size={ButtonSize.SMALL}
              type={ButtonType.TERTIARY}
              dataTestId={dataTestId}
            />
          </div>
        )}
        {children}
      </div>
    );
  },
);
