import { NavigateOptions, useNavigate } from 'react-router-dom';
import { addSearchParam } from '../utils/SearchParam.Utils';
import { URL_SEARCH_PARAM_KEY } from '../constant/UrlSearchParams.constant';

export const useNavigateWithSearchParam = () => {
  const reactRouterNavigate = useNavigate();

  // Wrapper around navigate hook of react-router-dom
  const navigate = (url: string, navigateOptions?: NavigateOptions) => {
    reactRouterNavigate(url, navigateOptions);
  };

  // Custom hook to add companyId param as search param to url
  const navigateWithCompanyId = (
    url: string,
    companyId: string | null | undefined,
    navigateOptions?: NavigateOptions,
  ) => {
    navigate(
      `${addSearchParam(
        url,
        URL_SEARCH_PARAM_KEY.COMPANY_ID,
        companyId || '',
      )}`,
      navigateOptions,
    );
  };

  return { navigateWithCompanyId, navigate };
};
