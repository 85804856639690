import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params';

/** Uses a dash to delimit entries. e.g. ['a', 'b'] => qp?=a-b */
export const DashArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, '-'),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, '-'),
};
export const CommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

export const URL_SEARCH_PARAM_KEY = {
  OVERVIEW_LOCATION_ID: 'locationIdKey',
  OVERVIEW_SUMMARY_DATE: 'dateKey',
  OVERVIEW_BARCHART_SWITCH_OPTION: 'barChartKey',
  OVERVIEW_BARCHART_DATE_START: 'barChartStartRangeKey',
  OVERVIEW_BARCHART_DATE_END: 'barChartEndRangeKey',
  OVERVIEW_SESSION_CHARGER_ID: 'chargerId',
  OVERVIEW_SESSION_DATE_START: 'dateRangeStart',
  OVERVIEW_SESSION_DATE_END: 'dateRangeEnd',
  OVERVIEW_CUSTOM_DATE_OPTION: 'dateOption',
  CHARGERS_FILTER_CHARGER_ID: 'filteredChargerId',
  CHARGERS_FILTER_STATUS: 'filterStatus',
  CHARGERS_CHARGER_DETAIL_ID: 'chargerId',
  COMPANY_ID: 'companyId',
  FILTER_COMPANIES: 'filterCompanies',
};
