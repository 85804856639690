import { t } from 'i18next';
import _ from 'lodash';
import { NUMBER } from '../../constant/Number.constant';
import { PRICING_SCHEDULED_TYPE, WEEKDAYS } from '../../constant/Text.constant';
import {
  DayPrice,
  Price,
  PricePeriod,
  RuleTypes,
  ScheduleType,
} from '../../stores/types/price.interface';

export const valdateTime = (timeStr: string) => {
  return /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(timeStr);
};

export const getScheduleLabel = (key: string, shortLabel: boolean = false) => {
  switch (key) {
    case ScheduleType.WEEKDAYS_WEEKEND:
      return shortLabel
        ? t('pricing_schedule_weekend_short')
        : t('pricing_schedule_weekend');
    case ScheduleType.SAME_EVERYDAY:
      return shortLabel
        ? t('pricing_schedule_same_everyday_short')
        : t('pricing_schedule_same_everyday');
    case ScheduleType.PER_DAY:
      return shortLabel
        ? t('pricing_schedule_perday_short')
        : t('pricing_schedule_perday');
    case RuleTypes.TIERED:
      return t('pricing_tiered');
    default:
      return t('pricing_scheudle_free');
  }
};

export const getPricingLabels = (type: ScheduleType | RuleTypes) => {
  let typeLabel;
  let scheduleLabel;
  switch (type) {
    case RuleTypes.FREE:
      typeLabel = t('free');
      break;
    case RuleTypes.TIERED:
      typeLabel = t('tiered');
      break;
    default:
      scheduleLabel = getScheduleLabel(type);
      typeLabel = t('time_of_use');
  }
  return { typeLabel, scheduleLabel };
};

export const getRuleLabel = (key: string) => {
  let label;
  switch (key) {
    case RuleTypes.FREE:
      label = t('free');
      break;
    case RuleTypes.TIME_OF_USE:
      label = t('time_of_use');
      break;
    case RuleTypes.TIERED:
      label = t('tiered');
      break;
    default:
      label = t('free');
      break;
  }
  return label;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const is24Hours = (
  startHour: any,
  startMin: any,
  startAmPm: string,
  endHour: any,
  endMin: any,
  endAmPm: string,
) => {
  if (
    startHour === '' ||
    startMin === '' ||
    endHour === '' ||
    endMin === '' ||
    startAmPm === '' ||
    endAmPm === ''
  ) {
    return false;
  }
  if (
    startAmPm.toLowerCase() === 'am' &&
    endAmPm.toLowerCase() === 'am' &&
    Number(startHour) === 12 &&
    Number(startMin) === 0 &&
    Number(endHour) === 12 &&
    Number(endMin) === 0
  ) {
    return true;
  }
};

export const convertTo24Hour = (startTime: string, endTime: string) => {
  const timeAAmPm = startTime.split(' ')[1];
  const timeBAmPm = endTime.split(' ')[1];
  const timeAHour = Number(startTime.split(' ')[0].split(':')[0]);
  const timeAMin = Number(startTime.split(' ')[0].split(':')[1]);
  const timeBHour = Number(endTime.split(' ')[0].split(':')[0]);
  const timeBMin = Number(endTime.split(' ')[0].split(':')[1]);
  if (
    is24Hours(timeAHour, timeAMin, timeAAmPm, timeBHour, timeBMin, timeBAmPm)
  ) {
    return { startHour: 0, startMin: 0, endHour: 24, endMin: 0 };
  }
  // convert time to 24 hours
  const timeAHour24 =
    timeAAmPm === 'AM' ? timeAHour % 12 : (timeAHour % 12) + 12;
  const timeAMin24 = timeAMin;
  const timeBHour24 =
    timeBAmPm === 'AM' && Number(timeBHour) === 12
      ? 24
      : timeBAmPm === 'AM'
      ? timeBHour % 12
      : (timeBHour % 12) + 12;
  const timeBMin24 = timeBMin;
  return {
    startHour: timeAHour24,
    startMin: timeAMin24,
    endHour: timeBHour24,
    endMin: timeBMin24,
  };
};

// calculate the min durication between 2 12-hour time
export const minDuration = (
  startHour: any,
  startMin: any,
  startAmPm: any,
  endHour: any,
  endMin: any,
  endAmPm: any,
) => {
  if (is24Hours(startHour, startMin, startAmPm, endHour, endMin, endAmPm)) {
    return 24 * 60;
  }
  // convert time to 24 hours
  const timeAHour24 =
    startAmPm.toLowerCase() === 'am' ? startHour % 12 : (startHour % 12) + 12;
  const timeAMin24 = startMin;

  const timeBHour24 =
    endAmPm.toLowerCase() === 'am'
      ? Number(endHour) === 12 && Number(endMin) === 0
        ? 24
        : endHour % 12
      : (endHour % 12) + 12;
  const timeBMin24 = endMin;

  const start = Number(timeAHour24) * 60 + Number(timeAMin24);
  const end = Number(timeBHour24) * 60 + Number(timeBMin24);
  return end - start;
};

export const minDuration24H = (
  startHour: any,
  startMin: any,
  endHour: any,
  endMin: any,
) => {
  const start = Number(startHour) * 60 + Number(startMin);
  const end = Number(endHour) * 60 + Number(endMin);
  return end - start;
};

export const has15MinsForNewPricePeriod = (
  pricePeriods: PricePeriod[] | undefined,
) => {
  let sum = 0;
  pricePeriods?.forEach((pricePeriod) => {
    const time = convertTo24Hour(pricePeriod.startTime, pricePeriod.endTime);
    sum += minDuration24H(
      time.startHour,
      time.startMin,
      time.endHour,
      time.endMin,
    );
  });
  return 24 * 60 - sum >= 15;
};

export const isPeriod24hour = (pricePeriods: PricePeriod[] | undefined) => {
  let sum = 0;
  pricePeriods?.forEach((pricePeriod) => {
    const time = convertTo24Hour(pricePeriod.startTime, pricePeriod.endTime);
    sum += minDuration24H(
      time.startHour,
      time.startMin,
      time.endHour,
      time.endMin,
    );
  });
  return sum === 24 * 60;
};

// 12 hours time compare, return -1 if timeA is smaller, return 1 if timeA is bigger, return 0 if same
export const compare24HourTime = (
  timeAHour24: any,
  timeAMin24: any,
  timeBHour24: any,
  timeBMin24: any,
) => {
  // hour
  if (timeAHour24 < timeBHour24) {
    return -1;
  }
  if (timeAHour24 > timeBHour24) {
    return 1;
  }

  // min
  if (timeAMin24 < timeBMin24) {
    return -1;
  }
  if (timeAMin24 > timeBMin24) {
    return 1;
  }

  // same
  return 0;
};

// calculate if 2 time periods overlap
export const isTimeOverlaps = (
  startTimeA: string,
  endTimeA: string,
  startTimeB: string,
  endTimeB: string,
) => {
  const timePeriodA = convertTo24Hour(startTimeA, endTimeA);
  const timePeriodB = convertTo24Hour(startTimeB, endTimeB);

  if (
    compare24HourTime(
      timePeriodA.endHour,
      timePeriodA.endMin,
      timePeriodB.startHour,
      timePeriodB.startMin,
    ) <= 0 ||
    compare24HourTime(
      timePeriodA.startHour,
      timePeriodA.startMin,
      timePeriodB.endHour,
      timePeriodB.endMin,
    ) >= 0
  ) {
    return false;
  }

  return true;
};

export const isTimeOverlapWithDayPrice = (
  pricePeriod: PricePeriod,
  dayPrices: DayPrice[],
  saveTo: any[],
  currentDay: string,
  currentIndex: number,
) => {
  return saveTo.some((day) => {
    const foundDayPrice = dayPrices.find(
      (dayPrice) =>
        dayPrice.day.toLowerCase() === day.key.toLowerCase() && day.selected,
    );
    if (foundDayPrice) {
      return foundDayPrice.pricePeriods.some((foundPricePeriod, index) => {
        // if overlap with current day and index, we ignore it
        if (
          foundDayPrice.day.toLowerCase() === currentDay.toLowerCase() &&
          index === currentIndex
        ) {
          return false;
        }
        return isTimeOverlaps(
          foundPricePeriod.startTime,
          foundPricePeriod.endTime,
          pricePeriod.startTime,
          pricePeriod.endTime,
        );
      });
    }
    return false;
  });
};

export const isChargerFromDefaultRule = (
  defaultRule: Price | undefined,
  chargerIds: string[],
  editingPrice: Price,
) => {
  if (!defaultRule || !defaultRule.chargers || chargerIds.length === 0) {
    return true;
  }

  const newChargers = chargerIds.filter(
    (chargerId) =>
      !editingPrice?.chargers.some(
        (charger) => charger.chargerId === chargerId,
      ),
  );
  return newChargers.every((chargerId) =>
    defaultRule.chargers.some((charger) => charger.chargerId === chargerId),
  );
};

export const compareStartTime = (timeA: string, timeB: string) => {
  const timeAAmPm = timeA.split(' ')[1];
  const timeBAmPm = timeB.split(' ')[1];
  const timeAHour = Number(timeA.split(' ')[0].split(':')[0]);
  const timeAMin = Number(timeA.split(' ')[0].split(':')[1]);
  const timeBHour = Number(timeB.split(' ')[0].split(':')[0]);
  const timeBMin = Number(timeB.split(' ')[0].split(':')[1]);

  const timeAHour24 =
    timeAAmPm === 'AM' ? timeAHour % 12 : (timeAHour % 12) + 12;
  const timeAMin24 = timeAMin;
  const timeBHour24 =
    timeBAmPm === 'AM' ? timeBHour % 12 : (timeBHour % 12) + 12;
  const timeBMin24 = timeBMin;

  return compare24HourTime(timeAHour24, timeAMin24, timeBHour24, timeBMin24);
};

export const is24Hour = (startHour: string, endHour: string) => {
  if (startHour === '' || endHour === '') {
    return false;
  }
  if (startHour === endHour) {
    return true;
  }
};

/**
 * It takes an array of numbers and returns an array of strings
 * @param {number[]} data - number[] - an array of numbers representing the days of the week (0-6)
 * @returns An array of strings.
 */
const consecutiveDays = (data: number[]) => {
  let length = 1;
  const sortedDays: string[] = [];
  if (data.length === NUMBER.ZERO) {
    return sortedDays;
  }
  for (let i = 1; i <= data.length; i += 1) {
    if (i === data.length || data[i].valueOf() - data[i - 1].valueOf() !== 1) {
      if (length === NUMBER.ONE) {
        sortedDays.push(WEEKDAYS[`${data[i - length]}`]);
      } else {
        sortedDays.push(
          `${WEEKDAYS[`${data[i - length]}`]}-${WEEKDAYS[`${data[i - 1]}`]}`,
        );
      }
      length = 1;
    } else {
      length += 1;
    }
  }

  return sortedDays;
};

export const convertDaysArrayToString = (days: any, scheduleType: any) => {
  if (scheduleType === PRICING_SCHEDULED_TYPE.WEEKDAYS_WEEKEND) {
    return [days.map((day: string) => day).join('/ ')];
  }
  const daysNumber = days.map((ele: string) =>
    Number(Object.keys(WEEKDAYS).find((key) => WEEKDAYS[key] === ele)),
  );
  const sortedConsecutiveDays = consecutiveDays(daysNumber);
  return sortedConsecutiveDays;
};

export const clubDayPricing = (days: DayPrice[] | undefined, dayType: {}) => {
  const dayPrices: { [x: string]: any } = {};
  if (days) {
    days.forEach((element: any) => {
      if (element.day) dayPrices[element.day] = element.pricePeriods;
    });
  }

  const data = Object.values(dayType).map((item: any) => {
    if (dayPrices[item]) {
      return { day: item, pricePeriods: dayPrices[item] };
    }
    return { day: item, pricePeriods: 'Free' };
  });
  return data;
};

// removed leading zeroes from time
export const formatTime = (time: string) => {
  return parseInt(time.slice(0, 2), 10) + time.slice(2, 9);
};

/**
 * It takes a number, checks if it has a decimal, and if it does, it returns the number rounded to two
 * decimal places
 * @param {number} num - number - the number to round
 * @returns A function that takes a number as an argument and returns a number.
 */
export const roundToTwoDecimalPlaces = (num: number) => {
  if (num % 1 !== 0) {
    return num.toFixed(2);
  }
  return num;
};

export const removeElementsFromArray = (
  arr: any[],
  elementsToRemove: string | any[],
) => {
  return arr.filter((element: any) => !elementsToRemove.includes(element));
};
