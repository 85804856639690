/* eslint-disable no-bitwise */

import React, { ReactNode } from 'react';
import { SelectItem } from './Select.component';

// generate hash value for label
export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
};

export const filterSelectedItemChildren = (children: any) => {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === SelectItem) {
      return child;
    }

    return null;
  });
};
