/* eslint-disable */
import {
  Middleware,
  MiddlewareAPI,
  isFulfilled,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { Trans } from 'react-i18next';
import { CheckMark, ErrorWarningCircle } from '../assets/icons';
import { ColorType, LabelType, Snackbar } from '../components/_ui';
import {
  AlertPosition,
  AlertType,
} from '../components/_ui/snack-bar/Snack-Bar.component';
import { InternalErrorCode } from './types/error.interface';
/**
 * Log a warning and show a toast!
 */

// These code is used by backend for validation checking
const excludesCodes = [409, 403];

interface Toast {
  successTrans?: string;
  failedTrans?: string;
  values?: any;
  position?: AlertPosition;
  alertType?: AlertType;
  duration?: number;
  successIcon: { icon: string; color: ColorType };
  failedIcon: { icon: string; color: ColorType };
  messageLabelType?: LabelType;
}

const countFailedEntities = (data: any) => {
  if (data) {
    return data.filter((item: any) => item.error === true).length;
  }
  return 0;
};

export const rtkQueryLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      // pending status -> next
      (!isRejectedWithValue(action) && !isFulfilled(action)) ||
      // excludesCode -> next
      excludesCodes.includes(action.payload?.originalStatus) ||
      excludesCodes.includes(action.payload?.status)
    ) {
      return next(action);
    }

    if (!action.meta?.baseQueryMeta.hideToast) {
      const isActionSuccess =
        !isRejectedWithValue(action) && isFulfilled(action);
      const toast = action.meta?.baseQueryMeta?.toast as Toast;
      const hasToast = isActionSuccess
        ? toast?.successTrans
        : toast?.failedTrans;
      if (hasToast) {
        const icon = isActionSuccess
          ? toast.successIcon?.icon || CheckMark
          : toast.failedIcon?.icon || ErrorWarningCircle;
        const iconColor = isActionSuccess
          ? toast.successIcon?.color || ColorType.POSITIVE1
          : toast.failedIcon?.color || ColorType.NEGATIVE1;
        Snackbar.show({
          message: (
            <span className='text-sm text-black font-medium'>
              <Trans
                i18nKey={
                  isActionSuccess ? toast.successTrans : toast.failedTrans
                }
                values={{
                  ...toast.values,
                  failedEntityCount:
                    action.meta?.arg?.originalArgs?.bulkOperation === true
                      ? countFailedEntities(action.payload?.data)
                      : 0,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            </span>
          ),
          height: 'h-[80px]',
          position: toast.position || AlertPosition.BOTTOM_LEFT,
          alertType: toast.alertType || AlertType.DEFAULT,
          duration: toast.duration || 5000,
          messageLabelType: toast.messageLabelType || LabelType.LABEL_S_MEDIUM,
          messageLabelColor: ColorType.BLACK,
          icon,
          iconColor,
        });
      } else if (!isActionSuccess) {
        // default toast for error message
        if (
          !Object.values(InternalErrorCode).includes(action.payload?.data?.code)
        ) {
          Snackbar.show({
            message: `${action.meta?.arg?.endpointName} Error: ${
              action.payload?.originalStatus || action.payload?.status
            } - ${action.payload?.data?.message}`,
            position: AlertPosition.BOTTOM,
            duration: 5000,
            alertType: AlertType.ERROR,
          });
        }
      }
    }

    return next(action);
  };
