import React, { useState } from 'react';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../shared/Colors.g';

interface InputProps {
  label?: string;
  index?: number;
  className?: string;
  selected?: boolean;
  onClick?: Function;
  children?: React.ReactNode;
  dataTestId?: string;
}

export const Tab = ({
  label,
  index,
  selected,
  onClick,
  children,
  className,
  dataTestId,
}: InputProps) => {
  const handleClick = () => {
    onClick && onClick(index);
  };

  //  h-[44px]
  return (
    <div
      className={`whitespace-nowrap overflow-hidden text-ellipsis items-center cursor-pointer px-4 py-2 flex-auto border-b-2 ${className} ${
        selected ? 'border-black' : 'border-grey2'
      }`}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      {React.isValidElement(children) ? (
        children
      ) : (
        <Label
          type={LabelType.LABEL_M}
          color={selected ? ColorType.BLACK : ColorType.GREY4}
          text={label}
          className='flex justify-center'
        />
      )}
    </div>
  );
};
