/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
// React & Redux
import { memo, useEffect, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

// API
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLazyFetchStatisticsV3Query } from '../../services/history.api';

// Utils
import {
  convertDateToString,
  convertMilliSecondToDate,
  formatDate,
} from '../../utils/Date.Util';

// Components
import {
  Card,
  ColorType,
  DateTimePicker,
  Icon,
  Label,
  LabelType,
} from '../_ui';

// constants
import { Bolt, ChargerEV, PayoutMoney } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { URL_SEARCH_PARAM_KEY } from '../../constant/UrlSearchParams.constant';
import { useCompany } from '../../hooks/useCompany';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { SimpleStatsRequest } from '../../stores/types/stats.interface';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import {
  SummaryContentItem,
  formatEnergy,
  formatFees,
  formatSessions,
} from './utils';

export interface SummaryProps {
  locationId?: string | undefined;
  companyIds: (string | null)[] | null | undefined;
}

export const Summary = memo(({ locationId, companyIds }: SummaryProps) => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const { userScope, companyId, isMultiViewActive } = useCompany();
  const today = new Date();
  const [summaryDate, setSummaryDate] = useQueryParam(
    URL_SEARCH_PARAM_KEY.OVERVIEW_SUMMARY_DATE,
    StringParam,
  );
  const [triggerFetchStatistics, { data, isFetching }] =
    useLazyFetchStatisticsV3Query({});
  const stat = useMemo(() => {
    if (data && data?.length > 0) {
      return {
        date: data[0].date,
        transactions: formatSessions(
          data.reduce((sum, item) => sum + item.transactions, 0),
        ),
        energyDeliveredKWh: formatEnergy(
          data.reduce((sum, item) => sum + item.energyDeliveredKWh, 0),
        ),
        revenue: data.map((s) => {
          return formatFees(s.revenue, s.revenueCurrency) as SummaryContentItem;
        }),
      };
    }
    return undefined;
  }, [data]);
  const isStatsLoading = useMemo(() => {
    return isFetching && !data;
  }, [data, isFetching]);

  // Refetch & get date for summary from url (if present) else default value (i.e. today) when url query changes
  const date = useMemo(() => {
    return summaryDate ? convertMilliSecondToDate(summaryDate) : today;
  }, [summaryDate]);

  useEffect(() => {
    const params = {
      fromDate: formatDate(date, 'yyyy-MM'),
      toDate: formatDate(date, 'yyyy-MM'),
      aggregateLocations: isMultiViewActive ? undefined : !locationId,
      scope: userScope,
      filter_in: {
        companyId: companyId !== 'ALL' ? [companyId!] : companyIds,
      },
      locationId: isMultiViewActive ? undefined : locationId,
    } as SimpleStatsRequest;
    triggerFetchStatistics(params);
  }, [
    companyId,
    date,
    isMultiViewActive,
    locationId,
    triggerFetchStatistics,
    userScope,
    companyIds,
  ]);

  const dateChanged = (newDate: any) => {
    setSummaryDate(convertDateToString(newDate));
  };

  const valLabelType = useMemo(() => {
    const count = stat?.revenue.length || 0;
    switch (count) {
      case 1:
        return LabelType.H1;
      case 2:
        return LabelType.H2;
      case 3:
      case 4:
        return LabelType.LABEL_S;
      default:
        return LabelType.H1;
    }
  }, [stat?.revenue]);

  const suffixesLabelType = useMemo(() => {
    const count = stat?.revenue.length || 0;
    switch (count) {
      case 1:
        return LabelType.H1;
      case 2:
        return LabelType.LABEL_S_MEDIUM;
      case 3:
      case 4:
        return LabelType.LABEL_S_MEDIUM;
      default:
        return LabelType.H1;
    }
  }, [stat?.revenue]);

  const showCurrencyLabel = useMemo(() => {
    return stat?.revenue && stat.revenue.length > 1;
  }, [stat?.revenue]);

  const renderSummaryItem = (
    icon: string,
    label: string,
    contentArr: SummaryContentItem | SummaryContentItem[] | undefined,
  ) => {
    if (contentArr === undefined) {
      // default value - display 0 when response is empty array
      contentArr = [
        {
          val: '0',
          formattedVal: '0',
        },
      ];
    }

    if (!Array.isArray(contentArr)) {
      contentArr = [contentArr];
    }

    return (
      <div className='flex flex-1 flex-col bg-grey0 p-4 justify-between content-between rounded-2xl h-[144px]'>
        <div className='flex flex-row gap-1'>
          <Icon
            src={icon}
            size={IconSize.SIZE_24x24}
            color={theme.navigationSelectedColor}
          />
          <Label
            type={LabelType.LABEL_M_MEDIUM}
            color={ColorType.GREY5}
            text={label}
          />
        </div>
        {contentArr?.map((content) => (
          <div className='flex flex-row justify-between items-baseline'>
            <div className='flex flex-row items-baseline gap-1'>
              {content?.abbr ||
              (content?.formattedUnit && content?.formattedUnit !== 'kWh') ? (
                <Tooltip
                  placement='top'
                  tipOffset={{ mainAxis: 0, crossAxis: 0 }}
                >
                  <TooltipTrigger>
                    <Label
                      type={
                        label === t('overview_fees_collected')
                          ? valLabelType
                          : LabelType.H1
                      }
                      color={ColorType.BLACK}
                      text={`${content?.prefix || ''}${content?.formattedVal} ${
                        content?.abbr || ''
                      }`}
                      isLoading={isStatsLoading}
                      skeletonHeight='h-10'
                      skeletonWidth='w-14'
                    />
                  </TooltipTrigger>
                  {!isStatsLoading && (
                    <TooltipContent>
                      <Label
                        text={`${content.prefix || ''}${content.val} ${
                          content.unit || ''
                        }`}
                        type={LabelType.LABEL_S_MEDIUM}
                        color={ColorType.WHITE}
                      />
                    </TooltipContent>
                  )}
                </Tooltip>
              ) : (
                <Label
                  type={
                    label === t('overview_fees_collected')
                      ? valLabelType
                      : LabelType.H1
                  }
                  color={ColorType.BLACK}
                  text={`${content?.prefix || ''}${content?.formattedVal} ${
                    content?.abbr || ''
                  }`}
                  isLoading={isStatsLoading}
                  skeletonHeight='h-10'
                  skeletonWidth='w-14'
                />
              )}

              {content.formattedUnit && (
                <Label
                  type={LabelType.LABEL_S_MEDIUM}
                  color={ColorType.BLACK}
                  text={content?.formattedUnit || ''}
                />
              )}
            </div>

            {content.suffixes && (
              <Label
                type={suffixesLabelType}
                color={ColorType.BLACK}
                text={showCurrencyLabel ? content?.suffixes || '' : ''}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card className='flex flex-col'>
      <div className='mb-4'>
        <DateTimePicker
          showMonthYearPicker
          onChange={dateChanged}
          defaultDate={date}
          maxDate={today}
        />
      </div>
      <div className='flex flex-row gap-2'>
        {renderSummaryItem(
          ChargerEV,
          t('overview_sessions'),
          stat?.transactions,
        )}
        {renderSummaryItem(
          Bolt,
          t('overivew_energy_used'),
          stat?.energyDeliveredKWh,
        )}
        {renderSummaryItem(
          PayoutMoney,
          t('overview_fees_collected'),
          stat?.revenue,
        )}
      </div>
    </Card>
  );
});
