import { ColorType, Label, LabelType } from '../../_ui';

interface Props {
  value?: number;
}

export const Limit = ({ value }: Props) => {
  return (
    <div className='flex flex-row items-baseline'>
      <Label
        text={value && Math.ceil(value)}
        type={LabelType.LABEL_M_MEDIUM}
        color={ColorType.BLACK}
      />
      <Label
        text='A'
        type={LabelType.LABEL_XS}
        color={ColorType.GREY5}
        className='ml-[2px]'
      />
      <Label
        className='ml-1'
        text='LIMIT'
        type={LabelType.LABEL_XXS}
        color={ColorType.GREY5}
      />
    </div>
  );
};
