export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  SIXTY: 60,
  HUNDRED: 100,
  TWO_HUNDRED_FIFTY: 250,
  THREE_HUNDRED: 300,
  ONE_THOUSAND: 1000,
  MINUS_ONE: -1,
  MAX_LOAD_NUM: 50,
  MAX_COMPANIES: 20,
  MAX_FILTERS: 20,
};
