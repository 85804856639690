import { useTranslation } from 'react-i18next';
import { Breaker, Circuit } from '../../stores/types/pm.interface';
import { Label, LabelType } from '../_ui/Label.component';
import { ColorType } from '../_ui';

export interface SubBreakerDetailsProp {
  data: {
    breaker: Breaker;
    parentBreaker?: Breaker;
    circuit: Circuit;
  };
}
export const SubBreakerDetailsModal = ({ data }: SubBreakerDetailsProp) => {
  const { t } = useTranslation();
  return (
    <div className='divide-y divide-grey2 px-1'>
      <div className='flex flex-col pb-4 gap-y-2 px-1'>
        <Label
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
          text={t('sub_breaker_modal_field_1')}
        />
        <Label
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          text={data.parentBreaker?.name}
        />
      </div>
      <div className='flex flex-col pt-4 gap-y-4 px-1 pb-5'>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('sub_breaker_modal_field_2')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={data.breaker.name}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('sub_breaker_modal_field_3')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={`${data.breaker.breakerRating} amps`}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className='flex flex-row'>
            <Label
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              text={t('sub_breaker_modal_field_4')}
            />
            <div>&nbsp;</div>
            <Label
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY6}
              text={t('sub_breaker_modal_field_5')}
            />
          </div>

          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={`${data.breaker.continuousLoadLimit} amps`}
          />
        </div>
      </div>
    </div>
  );
};
