import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { header } from './utils';
import { header } from './http/http.service';
import { Depolyment } from '../stores/types';
import { API_KEYS, Api } from './base.api';
import { WhiteLabel } from '../stores/types/whitelabel.interface';

export const whiteLabelsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchWhiteLabels: builder.query<WhiteLabel[], void>({
      query: () => ({
        url: `/internal/core/v2/${API_KEYS.WHITELABELS}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchWhiteLabelsQuery } = whiteLabelsApi;
