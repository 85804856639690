import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import StepWizard from 'react-step-wizard';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '..';
import { ChevronLeft, ExitDeleteRemoveClose } from '../../../assets/icons';
import { useElementSize, useWindowSize } from '../../../hooks';
import { useGlobalModalContext } from './GlobalModal.component';
import './modal.css';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');
const FIRST_STEP = 1;
let instance: any = null;
const WINDOW_H_PADDING = 80; // top 40 bottom 40
export const WizardModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const [overflow, setOverflow] = useState('auto');
  const { height } = useWindowSize();
  const maxModalHeight = height - WINDOW_H_PADDING;
  const [contentRef, contentSize] = useElementSize();
  const [headerRef, headerSize] = useElementSize();
  const modalRef = useRef(null);
  const { modalProps } = store;
  const {
    stepTitles = [],
    width = '540px',
    onRenderFooter,
    shouldCloseOnOverlayClick = true,
    onHeaderButtonOnlick = () => {},
    showHeaderButton = false,
    headerButtonType = ButtonType.DESTRUCTIVE,
    headerButtonSizie = ButtonSize.SMALL,
    headerButtonLabel = '',
    stepComponents = [],
    onClose = () => {},
    defaultOverflow = '',
  } = modalProps;

  const getInstance = (wizardInstance: any) => {
    instance = wizardInstance;
  };
  const handleClose = () => {
    hideModal();
  };

  const [step, setStep] = useState(FIRST_STEP);

  const handleStepChange = (stepChange: any) => {
    setStep(stepChange.activeStep);
    if (modalRef?.current) {
      // @ts-ignore
      modalRef.current?.scrollIntoView({ block: 'nearest' });
    }
  };

  useEffect(() => {
    // if (contentSize.height + headerSize.height >= maxModalHeight) {
    //   setOverflow('auto');
    // } else {
    //   setOverflow('hidden');
    // }
  }, [contentSize.height, maxModalHeight, headerSize.height]);

  const handleIconClick = () => {
    if (step === FIRST_STEP) {
      handleClose();
    } else {
      if (instance) {
        instance.previousStep();
      }
      setStep(step - 1);
    }
  };

  const renderHeader = () => {
    return (
      <div ref={headerRef} className='sticky top-0 z-50 bg-white'>
        <div className='flex items-center justify-center w-full h-16'>
          <div
            className='w-[24px] h-[24px] hover:bg-grey2 rounded-full flex items-center justify-center left-0 absolute cursor-pointer'
            onClick={handleIconClick}
          >
            <Icon
              src={step === FIRST_STEP ? ExitDeleteRemoveClose : ChevronLeft}
            />
          </div>
          <div className='pl-8 flex flex-row grow'>
            <Label
              text={stepTitles[step - 1]}
              type={LabelType.H5}
              color={ColorType.BLACK}
            />
            {/* \u00A0 is added to provide space */}
            <Label
              text={`\u00A0 (step ${step} of ${stepTitles.length})`}
              type={LabelType.H5}
              color={ColorType.BLACK}
              className='text-grey5 flex-none'
            />
          </div>
          {showHeaderButton && (
            <div className='absolute right-2'>
              <Button
                label={headerButtonLabel}
                type={headerButtonType}
                size={headerButtonSizie}
                onClick={onHeaderButtonOnlick}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const [wizardState, setWizardState] = useState<any>({});

  const renderBody = () => {
    return (
      <div className='' ref={contentRef}>
        <StepWizard
          transitions={{
            enterRight: '',
            enterLeft: '',
            exitRight: '',
            exitLeft: '',
            intro: '',
          }}
          onStepChange={handleStepChange}
          instance={getInstance}
        >
          {stepComponents.map((Component1: any) => {
            return React.cloneElement(Component1, {
              wizardState,
              setWizardState,
              onClose,
            });
          })}
        </StepWizard>
      </div>
    );
  };

  const renderFooter = () => {
    return <div>{onRenderFooter && onRenderFooter()}</div>;
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          overflow: defaultOverflow || overflow,
          top: '50%',
          left: '50%',
          width,
          height: 'max-content',
          maxHeight: maxModalHeight,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className='flex flex-col px-4' ref={modalRef}>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
