export enum ScheduleType {
  PER_DAY = 'PER_DAY',
  WEEKDAYS_WEEKEND = 'WEEKDAYS_WEEKEND',
  SAME_EVERYDAY = 'SAME_EVERYDAY',
}

export enum RuleTypes {
  FREE = 'FREE',
  TIME_OF_USE = 'TIME_OF_USE',
  TIERED = 'TIERED',
}

export enum PricingType {
  BILLED_BY_EFFECTIVE_CHARGING_TIME = 'BILLED_BY_EFFECTIVE_CHARGING_TIME',
  BILLED_BY_TIME_PLUGGED_IN = 'BILLED_BY_TIME_PLUGGED_IN',
  BILLED_BY_KWH = 'BILLED_BY_KWH',
}

export enum DurationType {
  HOUR = 'HOUR',
  KWH = 'KWH',
}

export interface ChargerWithLocation {
  chargerId: string;
  chargerName: string;
  locationId: string;
  locationName: string;
  totalChargersAtLocation: number;
  totalLocations: number;
}

export interface PricePeriod {
  price: string;
  pricingType: PricingType;
  startTime: string;
  endTime: string;
}

export interface DayPrice {
  day: string;
  pricePeriods: PricePeriod[];
}

export interface Price {
  id?: string;
  displayName: string;
  synthetic?: boolean;
  scheduleType: ScheduleType | RuleTypes;
  chargers: ChargerWithLocation[];
  dayPrices?: DayPrice[];
  defaultPrice?: boolean;
  tieredPriceConditions: Array<any>;
  idleRate?: string | number;
}

export interface PricingDropdown {
  id: string;
  label: string;
  selected: boolean;
}

export interface IdleFeesValidation {
  idleRate?: string | number;
}
