import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle, Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { useCompany } from '../../../hooks/useCompany';
import {
  useEditTerminalsByIdMutation,
  useLinkTerminalsMutation,
} from '../../../services/payouts.api';
import { i18n } from '../../../services/translation/i18n';
import { TerminalObj } from '../../../stores/types/payter.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '../../_ui';
import { Form } from '../../_ui/Form.component';
import SelectCharger from './SelectCharger.component';
import SelectLanguage from './SelectLanguage.component';
import { getApisLanguage } from './utils';

interface IPROPS {
  terminal: TerminalObj;
  hideModal: Function;
  terminalsList: TerminalObj[] | undefined;
  isEdit?: boolean;
}

const SetupTerminal = ({
  terminal,
  terminalsList,
  hideModal,
  isEdit = false,
}: IPROPS) => {
  const { t } = useTranslation();
  const { companyId } = useCompany();

  const { serialNumber, associatedChargers, defaultLanguage } = terminal;

  const [chargers, setChargers] = useState<string[]>(associatedChargers);
  const [language, setLanguage] = useState(defaultLanguage || i18n.language);

  const [isChargerChanged, setIsChargerChanged] = useState(false);
  const [isLocationDifferent, setIsLocationDifferent] = useState(false);

  const [editPaymentTerminal, editQueryResult] = useEditTerminalsByIdMutation();
  const [linkPaymentTerminal, linkQueryResult] = useLinkTerminalsMutation();

  useEffect(() => {
    if (
      editQueryResult.isSuccess ||
      editQueryResult.isError ||
      linkQueryResult.isSuccess ||
      linkQueryResult.isError
    ) {
      hideModal();
    }
  }, [editQueryResult, linkQueryResult, hideModal]);

  const updateTerminalData = () => {
    const dataObj = {
      body: {
        serialNumber: terminal.serialNumber,
        provider: terminal.provider,
        defaultLanguage: getApisLanguage(language),
        associatedChargers: chargers.map((charger: string) => charger),
        terminalId: terminal.id,
      },
      toast: {
        successTrans: 'successfully_edited_terminal',
        failedTrans: 'failed_to_edit_terminal',
      },
    };
    editPaymentTerminal(dataObj);
  };

  const linkTerminal = () => {
    const dataObj = {
      body: {
        serialNumber: terminal.serialNumber,
        paymentTerminalProvider: terminal.provider,
        companyId: companyId,
        defaultLanguage: getApisLanguage(language),
        associatedChargers: chargers.map((charger: string) => charger),
      },
      toast: {
        successTrans: 'successfully_setup_terminal',
        failedTrans: 'failed_to_setup_terminal',
      },
    };
    linkPaymentTerminal(dataObj);
  };

  const isDisabled = () => {
    return !chargers.length || !language;
  };

  const isChargersAlreadyLinked = useMemo(() => {
    let res = false;
    const filteredTerminals = terminalsList?.filter(
      (item: TerminalObj) => item.id !== terminal.id,
    );
    filteredTerminals?.forEach((terminalObj: TerminalObj) => {
      if (_.intersection(terminalObj.associatedChargers, chargers).length) {
        res = true;
      }
    });
    return res;
  }, [terminalsList, terminal, chargers]);

  const renderHeadline = (text: string) => (
    <div className='mb-2'>
      <Label text={text} type={LabelType.LABEL_M} color={ColorType.BLACK} />
    </div>
  );
  return (
    <Form
      onSubmit={isEdit ? updateTerminalData : linkTerminal}
      queryResult={isEdit ? editQueryResult : linkQueryResult}
    >
      {renderHeadline(t('terminal_serial_number'))}

      <div className='mb-4 pb-4 border-b border-b-grey3'>
        <Label
          text={serialNumber}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      {renderHeadline(t('terminal_language'))}
      <SelectLanguage language={language} setLanguage={setLanguage} />
      {renderHeadline(t('linked_charger'))}
      <div className='mb-2'>
        <Label
          text={t('link_termial_to_one_more_text')}
          type={LabelType.BODY3}
          color={ColorType.GREY6}
        />
      </div>
      <SelectCharger
        chargers={chargers}
        setChargers={setChargers}
        setIsChargerChanged={setIsChargerChanged}
        setIsLocationDifferent={setIsLocationDifferent}
      />
      {isChargerChanged && isChargersAlreadyLinked && (
        <div className='mt-4 w-full p-2 bg-grey0 border border-2 border-grey3 rounded-lg'>
          <div className='flex'>
            <Icon src={Info} size={IconSize.SIZE_24x24} />
            <Label
              className='ml-2'
              text={t('atleast_one_charger_linked_to_another_terminal')}
              type={LabelType.BODY4}
              color={ColorType.GREY6}
            />
          </div>
        </div>
      )}
      {isChargerChanged && isLocationDifferent && (
        <div className='mt-4 w-full p-2 bg-warning0 border border-2 border-warning2 rounded-lg'>
          <div className='flex'>
            <Icon
              src={ErrorWarningtraiangle}
              color={ColorType.WARNING2}
              size={IconSize.SIZE_24x24}
            />
            <Label
              className='ml-2'
              text={t('charger_selected_belong_to_different_location')}
              type={LabelType.BODY4}
              color={ColorType.GREY6}
            />
          </div>
        </div>
      )}
      <div className='flex flex-col mt-6 pb-[20px] sticky bottom-0 bg-white'>
        <Button
          label={t('save')}
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL_FULL}
          isSumbit
          disabled={isDisabled()}
        />
      </div>
    </Form>
  );
};

export default SetupTerminal;
