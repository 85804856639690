import { t } from 'i18next';
import { ColorType, Label, LabelType } from '../../../_ui';
import TieredPricingForm from './TieredPricingForm.component';

interface IProps {
  tierPrices: Array<any>;
  setTierPrices: Function;
  disabled?: boolean;
}

const TieredPricing = ({
  tierPrices,
  setTierPrices,
  disabled = false,
}: IProps) => {
  const headData = ['Rate', 'Unit', 'Duration'];

  return (
    <table className='table-auto w-[640px]'>
      <thead>
        <tr className='border-t border-b border-grey2 text-left'>
          {headData.map((item) => (
            <th key={item} className='pt-[12px] pb-[12px]'>
              <Label
                text={item}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
            </th>
          ))}
        </tr>
      </thead>
      <TieredPricingForm
        tierPrices={tierPrices}
        setTierPrices={setTierPrices}
        disabled={disabled}
      />
    </table>
  );
};

export default TieredPricing;
