import { t } from 'i18next';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Label, LabelType } from '../_ui';

export const NoMatch = memo(() => {
  const auth = useAuth();
  const theme = useSelector(getCurrentTheme);

  return (
    <div className='flex flex-row align-middle justify-center w-full bg-web_bg min-h-screen'>
      <div className='flex flex-col gap-8 justify-center items-center'>
        <div className='self-center'>
          <img
            src={theme.networkLogo}
            style={{ width: 'auto', height: '40px' }}
            alt=''
            className='cursor-pointer'
          />
        </div>
        <Label text={t('page_not_found')} type={LabelType.H1} />
        <Link
          to={
            auth.user
              ? `/?companyId=${auth.user.attributes.profile?.lastActiveCompanyId}`
              : '/'
          }
        >
          <Label
            text={t('return_to_home_page_link')}
            type={LabelType.LABEL_M}
            style={{
              color: theme.brand_2,
            }}
          />
        </Link>
      </div>
    </div>
  );
});
