// React
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { ChargerStatusChart, DataReport, Summary } from '.';
import { Sessions } from '../Session/Sessions.component';
import {
  ColorType,
  Dropdown,
  DropdownType,
  Label,
  LabelType,
  Pill,
  Select,
  SelectItem,
} from '../_ui';

// API
import { CompanyBuilding } from '../../assets/icons';
import { NUMBER } from '../../constant/Number.constant';
import {
  CommaArrayParam,
  URL_SEARCH_PARAM_KEY,
} from '../../constant/UrlSearchParams.constant';
import { useCompany } from '../../hooks/useCompany';
import { useShowBankingInfoModal } from '../../hooks/useShowBankingInfoModal';
import { useFetchLocationsV3Query } from '../../services/location.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';

const DEFAULT_ALL_LOCATION_INDEX = 0;
export const Overview = () => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const { isMultiViewActive, companyScope, companyId, companies } =
    useCompany();
  const { data: locationData } = useFetchLocationsV3Query({
    scope: companyScope,
  });
  const { showBankInfoModal, displayBankInfoModal } = useShowBankingInfoModal();

  const [locationId, setSelectedLocationId] = useQueryParam(
    URL_SEARCH_PARAM_KEY.OVERVIEW_LOCATION_ID,
    StringParam,
  );

  const locaitonList = useMemo(() => {
    const result = locationData
      ? locationData?.entities.map((location) => ({
          id: location.id,
          label: location.name,
        }))
      : [];
    result.unshift({ id: '', label: t('all_location') });
    return result;
  }, [locationData, t]);

  const locationChanged = (index: number) => {
    if (index === DEFAULT_ALL_LOCATION_INDEX) {
      setSelectedLocationId('');
    } else {
      const selectedLocation = locaitonList[index];
      selectedLocation && setSelectedLocationId(selectedLocation.id);
    }
  };

  const defaultIndex = useMemo(() => {
    if (!locationId) {
      return DEFAULT_ALL_LOCATION_INDEX;
    }
    return locaitonList?.findIndex((location) => location.id === locationId);
  }, [locaitonList, locationId]);

  /**
   * Show bank info modal if company's banking info missing & have at least one non-free pricing rule.
   */
  useEffect(() => {
    if (showBankInfoModal) {
      displayBankInfoModal();
    }
  }, [showBankInfoModal]);

  const renderAllLocationDropdown = () => {
    return (
      <Select
        defaultLabel={t('all_location')}
        contentWidth={206}
        contentHeight={480}
        placement='bottom-start'
        defaultIndex={defaultIndex}
        onItemSelected={locationChanged}
        buttonProps={{
          className: 'h-[44px]',
        }}
        dataTestId='select-location'
      >
        {locaitonList.map((location) => {
          return <SelectItem key={location.id} label={location.label} />;
        })}
      </Select>
    );
  };
  const [selectedCompanies, setSelectedCompanies] = useQueryParam(
    URL_SEARCH_PARAM_KEY.FILTER_COMPANIES,
    CommaArrayParam,
  );
  const companyList = useMemo(() => {
    return companies?.map((company) => {
      const selected =
        selectedCompanies?.find((cid) => cid === company.id) !== undefined;
      return {
        id: company.id,
        key: company.id,
        label: company.name,
        selected: selected,
        disabled:
          selectedCompanies &&
          selectedCompanies.length >= NUMBER.TWENTY &&
          !selected, // If number of selected companies are 20 or more the checkboxes will be disabled
      };
    });
  }, [companies, selectedCompanies]);

  const onCompanySelected = (items: any[]) => {
    const filterCompanies = items
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    setSelectedCompanies(filterCompanies);
  };

  const onCompanyPillSelected = (id: string) => {
    const newSelectedCompanies = selectedCompanies!.filter(
      (ids) => ids !== id,
    ) as string[];
    setSelectedCompanies(newSelectedCompanies);
  };

  const renderSelectedCompanies = () => {
    return (
      <div className='flex flex-row gap-2 flex-wrap items-start content-start'>
        {companyList
          ?.filter((company) => company.selected)
          .map((pill) => {
            return (
              <Pill
                iconLeft={CompanyBuilding}
                key={pill.id}
                label={pill.label}
                hasCloseButton
                onClick={() => onCompanyPillSelected(pill.id)}
                translationOn
              />
            );
          })}
      </div>
    );
  };

  const handleClearAllClick = () => {
    setSelectedCompanies([]);
  };
  const renderSelectedCompaniesCount = () => {
    if (companyList && companyList.length > 0 && selectedCompanies?.length) {
      return (
        <div className='flex flex-row gap-2 items-center h-[28px] min-w-[143px] ml-auto'>
          <Label
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            text={`${selectedCompanies?.length}/${NUMBER.TWENTY} ${t(
              'filters',
            )}`}
            className='flex items-center'
          />
          <button
            type='button'
            className='text-left'
            onClick={handleClearAllClick}
          >
            <Label
              type={LabelType.DROPDOWN_ITEM_SELECTED}
              color={ColorType.BLACK}
              text={t('session_clear_all')}
              style={{ color: theme.navigationSelectedColor }}
            />
          </button>
        </div>
      );
    }
  };

  const renderAllCompaniesDropdown = () => {
    if (companyList && companyList.length > 0) {
      return (
        <div className='flex flex-col gap-4'>
          <div className='flex'>
            <Dropdown
              placeholder={t('select_companies')}
              placeholderLabelType={LabelType.DROPDOWN_HEADER}
              type={DropdownType.CHECKBOX}
              headerWidth='auto'
              items={companyList}
              onItemClick={onCompanySelected}
              contentDivWidth={335}
              contentDivHeight={400}
              showFooter
              headerClassName='hover:bg-grey1 bg-white border border-grey3 rounded pr-2'
            />
          </div>
          <div className='flex flex-row gap-2'>
            {renderSelectedCompanies()}
            {renderSelectedCompaniesCount()}
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      {isMultiViewActive
        ? renderAllCompaniesDropdown()
        : renderAllLocationDropdown()}
      <div className='flex flex-row mt-6 gap-6'>
        <div className='inline-block w-[357px]'>
          <ChargerStatusChart
            locationId={locationId}
            companyIds={selectedCompanies}
          />
        </div>
        <div className='flex-grow'>
          <Summary
            locationId={locationId || ''}
            companyIds={selectedCompanies}
          />
        </div>
      </div>
      <div className='mt-6 block'>
        <DataReport
          locationId={locationId || ''}
          companyIds={selectedCompanies}
        />
      </div>
      {!isMultiViewActive && (
        <div className='mt-6 block'>
          <Sessions
            dataMap={[
              'startedVia',
              'port.charger.location.name',
              'port.charger.name',
              'createTime|startTime',
              'status',
              'consumedEnergyKwh',
              'billedTotalAmount',
            ]}
            locationId={locationId || ''}
            companyId={companyId!}
          />
        </div>
      )}
    </div>
  );
};
