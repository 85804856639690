import React, { useState } from 'react';
import { Card } from '../Card.component';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../index';
import { IconSize } from '../../../constant/IconSize.constant';
import { ChevronDown, ChevronUp } from '../../../assets/icons';

interface IProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
  open?: boolean;
}
const Accordion = ({ title, children, open = false }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  return (
    <Card className='p-0'>
      <div className='flex flex-col'>
        <div
          className='flex flex-row items-center cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        >
          <Label
            text={title}
            type={LabelType.LABEL_M_MEDIUM}
            color={ColorType.BLACK}
            className='pr-2'
          />
          <Icon
            src={isOpen ? ChevronUp : ChevronDown}
            key={isOpen ? 'expanded' : 'collapsed'}
          />
        </div>
        {isOpen && children}
      </div>
    </Card>
  );
};

export default Accordion;
