import React from 'react';
import { ColorType, Label, LabelType } from '../../_ui';
import notPowerManaged from './mockData/mockData';
import ViewChargerItem from './ViewChargerItem.component';
import { i18n } from '../../../services/translation/i18n';
import Accordion from '../../_ui/accordion/Accordion.component';
import { Charger } from '../../../stores/types';

interface IProps {
  notPowerManagedChargers?: Charger[];
}
const NotPowerManagedChargers = ({ notPowerManagedChargers }: IProps) => {
  // This function (renderDescription) shows helping text for approvad and not approvad modal for safe usage.

  const renderDescription = (desc: string) => (
    <Label
      className='mt-2'
      type={LabelType.BODY3}
      color={ColorType.GREY6}
      text={i18n.t(desc)}
    />
  );

  // This function (renderChargerItem)renders the chargers row [Display ID, Make and modal]

  const renderChargerItem = (chargers: any) =>
    chargers.map((chargerItem: any, i: number) => (
      <ViewChargerItem
        showTopBorder={i === 0}
        key={chargerItem.displayName}
        chargerName={chargerItem.displayName}
        chargerModel={chargerItem.model}
        chargerVendor={chargerItem.vendor}
      />
    ));

  const compatibleChargers = notPowerManagedChargers?.filter(
    (charger: Charger) => charger.powerManagementCompatible,
  );

  const inCompatibleChargers = notPowerManagedChargers?.filter(
    (charger: Charger) => !charger.powerManagementCompatible,
  );

  return (
    <div className='pt-4 pb-4'>
      {/* Shows List of compatible chargers */}
      <Accordion
        open
        title={i18n.t('charger_compatible_with_power', {
          number: compatibleChargers?.length,
        })}
      >
        {renderDescription(
          compatibleChargers && compatibleChargers.length > 0
            ? 'these_charger_modal_safe_approved'
            : 'no_chargers_compatible',
        )}
        <div className='py-4'>{renderChargerItem(compatibleChargers)}</div>
      </Accordion>

      {/* Shows List of incompatible chargers */}
      <Accordion
        open
        title={i18n.t('charger_incompatible_with_power', {
          number: inCompatibleChargers?.length,
        })}
      >
        {renderDescription(
          inCompatibleChargers && inCompatibleChargers.length > 0
            ? 'these_charger_modal_not_safe_approved'
            : 'no_chargers_incompatible',
        )}
        <div className='pt-4'>{renderChargerItem(inCompatibleChargers)}</div>
      </Accordion>
    </div>
  );
};

export default NotPowerManagedChargers;
