import React from 'react';
import { useTranslation } from 'react-i18next';
import { identity, pickBy } from 'lodash';
import {
  ColorType,
  Label,
  LabelType,
  MODAL_TYPES,
  Button,
  ButtonSize,
  ButtonType,
  useGlobalModalContext,
} from '../../_ui';
import { Info } from '../../../assets/icons';
import { useLazyExportOcppMessagesQuery } from '../../../services/ocpp-messages.api';
import { NUMBER } from '../../../constant/Number.constant';
import { SCOPE_TYPE } from '../../../services/utils';

interface IProps {
  chargerName: string | undefined;
  messagesFilter: {
    count: number;
    filters: Object;
  };
  companyId: string;
}
const ExportOcppMessages = ({
  chargerName,
  messagesFilter,
  companyId,
}: IProps) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const [triggerExportOcppMassagesCSV, { isLoading: isDownloadingMessage }] =
    useLazyExportOcppMessagesQuery();

  const downloadCsv = () => {
    const requestObj = pickBy(
      {
        ...messagesFilter.filters,
        limit: NUMBER.ONE_THOUSAND,
        lastUpdated: null,
        offset: null,
        chargerName,
        scope: SCOPE_TYPE.COMPANY,
        companyId,
      },
      identity,
    );
    triggerExportOcppMassagesCSV({ ...requestObj });
  };

  const renderModalBody = () => (
    <div className='ml-[36px] mt-2 text-grey6'>
      <Label
        type={LabelType.BODY3}
        text={t('export_csv_ocpp_desc1')}
        color={ColorType.GREY6}
      />
      <Label
        className='mt-2'
        type={LabelType.BODY3}
        text={t('export_csv_ocpp_desc2')}
        color={ColorType.GREY6}
      />
    </div>
  );

  const handleExportOCCPMessage = () => {
    if (messagesFilter?.count > NUMBER.ONE_THOUSAND) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: t('exporting_messages_title'),
        width: '400px',
        height: 'max-content',
        icon: Info,
        onRenderBody: renderModalBody,
        buttons: [
          {
            label: t('cancel'),
            onClick: null,
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
          },
          {
            label: t('export'),
            type: ButtonType.PRIMARY,
            size: ButtonSize.SMALL,
            className: 'min-w-fit w-20',
            onClick: downloadCsv,
          },
        ],
        shouldCloseOnOverlayClick: true,
      });
    } else {
      downloadCsv();
    }
  };
  return (
    <Button
      size={ButtonSize.SMALL}
      label={t('export_csv')}
      type={ButtonType.TERTIARY}
      onClick={handleExportOCCPMessage}
      isLoading={isDownloadingMessage}
      isSumbit
    />
  );
};

export default ExportOcppMessages;
