import React, { memo, useRef, useState, Children } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  Placement,
} from '@floating-ui/react';
import { Icon, IconProps } from '../Icon.component';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { LabelType } from '../Label.component';
import { CircleIconButton } from '../CircleIconButton.component';
/**
 * Composable Menu Component.
  The Menu component provides a dropdown-like behavior for items.
  The Menu conponent doesn't have a selected status/index.

  Example:
          <Menu
            placement='bottom-end'
            icon={dot}
            iconProps={{
              width: 24,
              height: 24,
            }}
          >
            <MenuItem label={t('edit_charger')} onClick={handleEditBtnClick} />
            <MenuItem
              label={t('view_charger_as_driver')}
              onClick={handleViewChargerClick}
            />
            <MenuItem
              label={t('view_qr_code')}
              onClick={handleViewQRCodeClick}
            />
          </Menu>
 */
interface MenuProps {
  label?: string; // The text label for the menu button.
  icon?: any; // An optional icon for the menu button. If the icon is set, the label will be ignored.
  rootClassName?: string; // Additional classnames for the root element of the component.
  contentClassName?: string; // Additional classnames for the content part.
  buttonProps?: {
    [key: string]: any;
  }; // Additional props that can be passed to the button component.
  iconProps?: IconProps; // Additional props that can be passed to the icon component.
  children: any; // The menu items. These should be valid React elements.
  placement?: Placement; // The position of the menu relative to the button.
  OnOpenChange?: Function; // Callback function that is called when the menu is opened or closed.
  mixWidth?: number; // min width of pop up content
  dataTestId?: string;
}

export const Menu = memo(
  ({
    label = 'menu',
    icon,
    rootClassName,
    contentClassName,
    buttonProps = {},
    iconProps = {},
    children,
    placement = 'bottom-start',
    OnOpenChange,
    mixWidth = 168,
    dataTestId,
  }: MenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleOpen = (val: boolean) => {
      setIsOpen(val);
      OnOpenChange && OnOpenChange(val);
    };

    const { refs, floatingStyles, context } = useFloating({
      placement,
      open: isOpen,
      onOpenChange: handleOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(5),
        size({
          apply({ elements, availableHeight, availableWidth }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
              minWidth: `${Math.min(mixWidth, availableWidth)}px`,
            });
          },
          padding: 10,
        }),
      ],
    });

    const listRef = useRef<Array<HTMLElement | null>>([]);
    const click = useClick(context, { event: 'mousedown' });
    const dismiss = useDismiss(context);
    const listNav = useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      loop: true,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([dismiss, listNav, click]);

    const handleSelect = (index: number) => {
      setIsOpen(false);
    };

    const renderButton = () => {
      const conbimedButtonProps = {
        labelType: LabelType.DROPDOWN_HEADER,
        type: ButtonType.OUTLINE,
        size: ButtonSize.SMALL,
        ...buttonProps,
      };
      return (
        <div ref={refs.setReference} {...getReferenceProps()}>
          {icon ? (
            <CircleIconButton
              src={icon}
              {...iconProps}
              dataTestId={dataTestId}
            />
          ) : (
            <Button
              label={label}
              {...conbimedButtonProps}
              dataTestId={dataTestId}
            />
          )}
        </div>
      );
    };

    const renderPopupItems = () => {
      return (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={`z-[100] p-2 overflow-y-auto bg-white outline-0 rounded-lg list-shadow hover:border-0 ${contentClassName}`}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
              data-testid={dataTestId}
            >
              {Children.map(children, (child, i) =>
                React.isValidElement(child) ? (
                  <div
                    key={child.key}
                    className='outline-0'
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    tabIndex={i === activeIndex ? 0 : -1}
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(i);
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                  >
                    {child}
                  </div>
                ) : null,
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      );
    };

    return (
      <div className={rootClassName}>
        {renderButton()}
        {isOpen && renderPopupItems()}
      </div>
    );
  },
);
