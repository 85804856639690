/* eslint-disable react/no-array-index-key */
import { memo, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import CheckBox from '../checkbox/CheckBox.component';
import { Label, LabelType } from '../Label.component';
import { useLazyFetchLocationsByCompanyIdQuery } from '../../../services/location.api';
import {
  Notification,
  NotificationCheckboxData,
} from '../../../stores/types/notification.interface';
import './NotificationCheckboxTree.component.css';
import { sortChargers } from '../../../utils/chargers.Util';
import { ChevronDown, ChevronUp, CompanyBuilding } from '../../../assets/icons';

interface ChargerCheckboxTreeProps {
  onChange?: Function;
  defaultNodes: NotificationCheckboxData[];
  filterStr?: string;
  translationOn?: boolean;
  parentStyle?: string;
  childStyle?: string;
  grandChildStyle?: string;
  isMultipleCompany: boolean;
  checkedNotification: Notification[];
}

const ChargerCheckboxTree = ({
  defaultNodes,
  onChange,
  filterStr = '',
  translationOn = false,
  parentStyle = 'flex h-5 gap-2 items-center justify-between',
  childStyle = 'flex h-5 pl-2 gap-2 items-center justify-between',
  grandChildStyle = 'flex h-5 pl-4',
  isMultipleCompany = false,
  checkedNotification = [],
}: ChargerCheckboxTreeProps) => {
  const [nodes, setNodes] = useState<NotificationCheckboxData[]>();
  const [searchStr, setSearchStr] = useState('');
  const [triggerFetchLocationByCompanyId, { data: locations }] =
    useLazyFetchLocationsByCompanyIdQuery();

  useEffect(() => {
    setNodes(defaultNodes);
  }, [defaultNodes]);

  useEffect(() => {
    setSearchStr(filterStr);
  }, [filterStr]);

  const handleParentChange = (selected: boolean, pIndex: number) => {
    const newNodes = nodes?.map((node, index) => {
      if (index === pIndex) {
        const newChildren = node.children?.map((child) => {
          const newSubChildren = child.children?.map((subChild) => {
            return {
              ...subChild,
              selected,
            };
          });
          return {
            ...child,
            selected,
            children: newSubChildren,
          };
        });
        return { ...node, selected, children: newChildren };
      }
      return node;
    });
    setNodes(newNodes);
    if (onChange) onChange(newNodes, checkedNotification);
  };

  const handleChildrenChange = (
    selected: boolean,
    cIndex: number,
    pIndex: number,
  ) => {
    const newNodes = nodes?.map((node, index) => {
      if (index === pIndex) {
        let isAllChildrenChecked = true;
        const newChildren = node.children?.map((child, childIndex) => {
          const newSelected = childIndex === cIndex ? selected : child.selected;
          if (!newSelected) isAllChildrenChecked = false;
          const newSubChildren = child.children?.map((subChild) => {
            return {
              ...subChild,
              selected: newSelected,
            };
          });
          return {
            ...child,
            selected: newSelected,
            children: newSubChildren,
          };
        });
        return {
          ...node,
          selected: isAllChildrenChecked,
          children: newChildren,
        };
      }
      return node;
    });
    setNodes(newNodes);
    if (onChange) onChange(newNodes, checkedNotification);
  };

  const handleGrandChildrenChange = (
    selected: boolean,
    gcIndex: number,
    cIndex: number,
    pIndex: number,
  ) => {
    const newNodes = nodes?.map((node, index) => {
      if (index === pIndex) {
        let isAllChildrenChecked = true;
        const newChildren = node.children?.map((child, childIndex) => {
          if (childIndex === cIndex) {
            let isAllSubChildrenChecked = true;
            const newSubChildren = child.children?.map(
              (subChild, subChildIndex) => {
                const newSelected =
                  subChildIndex === gcIndex ? selected : subChild.selected;
                if (!newSelected) {
                  isAllSubChildrenChecked = false;
                  isAllChildrenChecked = false;
                }
                return {
                  ...subChild,
                  selected: newSelected,
                };
              },
            );
            return {
              ...child,
              selected: isAllSubChildrenChecked,
              children: newSubChildren,
            };
          }
          isAllChildrenChecked = isAllChildrenChecked
            ? child.selected || false
            : isAllChildrenChecked;
          return child;
        });
        return {
          ...node,
          selected: isAllChildrenChecked,
          children: newChildren,
        };
      }
      return node;
    });
    setNodes(newNodes);
    if (onChange) onChange(newNodes, checkedNotification);
  };

  const createChargerNodes = (chargers: [], parentNode: any) => {
    const chargerNodes: NotificationCheckboxData[] = [];
    chargers.forEach((charger: any) => {
      chargerNodes.push({
        id: charger.id,
        label: charger.displayName,
        selected: parentNode?.selected
          ? true
          : checkedNotification &&
            checkedNotification?.[0]?.resources?.chargers?.some(
              (c) => c.id === charger.id,
            ),
        isOpen: false,
      });
    });
    return chargerNodes;
  };

  const createLocationNodes = (parentNode: any) => {
    const locationNodes: NotificationCheckboxData[] = [];
    locations?.entities.forEach((location) => {
      locationNodes.push({
        id: location.id,
        label: location.name,
        selected: parentNode.selected
          ? true
          : checkedNotification &&
            checkedNotification?.[0]?.resources?.locations?.some(
              (l) => l.id === location.id,
            ),
        isOpen: false,
        children:
          location.chargers && location.chargers?.length > 0
            ? sortChargers(createChargerNodes(location.chargers, parentNode))
            : [],
        noOfChargers: location.chargers?.length,
      });
    });
    return locationNodes;
  };

  useEffect(() => {
    if (locations) {
      const locationNode = createLocationNodes(locations.node);
      const newNode = nodes?.map((node) => {
        if (node.id === locations.node.id) {
          return {
            ...node,
            children: locationNode,
          };
        }
        return node;
      });
      setNodes(newNode);
    }
  }, [locations]);

  const handleParentClick = (
    incomingParentNode: NotificationCheckboxData,
    incomingParentIndex: number,
  ) => {
    const newNodes = nodes?.map((node, pIndex) => {
      if (pIndex === incomingParentIndex) {
        if (node.children?.length === 0) {
          triggerFetchLocationByCompanyId(node);
        }
        if (incomingParentNode.isOpen) {
          const newChildren = node.children?.map((child) => {
            return {
              ...child,
              isOpen: false,
            };
          });
          return {
            ...node,
            isOpen: !incomingParentNode.isOpen,
            children: newChildren,
          };
        }
        return {
          ...node,
          isOpen: !incomingParentNode.isOpen,
        };
      }
      return node;
    });
    setNodes(newNodes);
  };

  const handleChildClick = (
    incomingParentNode: NotificationCheckboxData,
    incomingParentIndex: number,
    incomingChildNode: NotificationCheckboxData,
    incomingChildIndex: number,
  ) => {
    const newNodes = nodes?.map((node, pIndex) => {
      if (pIndex === incomingParentIndex) {
        const newChildNodes = node.children?.map((child, cIndex) => {
          if (cIndex === incomingChildIndex) {
            return {
              ...child,
              isOpen: !incomingChildNode.isOpen,
            };
          }
          return child;
        });
        return {
          ...node,
          children: newChildNodes,
        };
      }
      return node;
    });
    setNodes(newNodes);
  };

  const renderMessage = (node: NotificationCheckboxData) => {
    const renderMsg = isMultipleCompany ? (
      <Trans
        i18nKey='notification_modal_location_checkbox_message'
        values={{
          locationCount: node.noOfLocations,
          chargerCount: node.noOfChargers,
        }}
      />
    ) : (
      <Trans
        i18nKey='notification_modal_charger_checkbox_message'
        values={{ chargers: node.noOfChargers }}
      />
    );
    return renderMsg;
  };

  const renderIcon = (node: NotificationCheckboxData) => {
    const iconVisible = isMultipleCompany
      ? node.noOfLocations !== undefined && node.noOfLocations > 0
      : node.noOfChargers !== undefined && node.noOfChargers > 0;
    return iconVisible;
  };

  const renderParentCheckbox = (node: any, pIndex: number) => {
    return (
      <div className={`${parentStyle} ${node.isOpen ? 'mb-[10px]' : ''}`}>
        <div>
          <CheckBox
            index={pIndex}
            label={node.label}
            selected={node.selected}
            onChange={handleParentChange}
            translationOn={translationOn}
            icon={isMultipleCompany ? CompanyBuilding : ''}
            labelType={LabelType.BODY3}
            isDisabled={
              isMultipleCompany
                ? node.noOfLocations === 0
                : node.noOfChargers === 0
            }
          />
        </div>
        <div className='top-8 flex'>
          <div className='text-sm text-grey5 font-normal pr-1'>
            {renderMessage(node)}
          </div>
          {renderIcon(node) && (
            <div onClick={() => handleParentClick(node, pIndex)}>
              <Label
                type={LabelType.BODY2}
                text=''
                icon={node.isOpen ? ChevronUp : ChevronDown}
                iconClass='w-[24px] h-[24px]'
                lazyIcon
                dataTestId='openLocation'
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderChildCheckbox = (
    node: any,
    child: any,
    cIndex: number,
    pIndex: number,
  ) => {
    return (
      <div className={`${childStyle} ${child.isOpen ? 'mb-[10px]' : ''}`}>
        <div>
          <CheckBox
            name={child.label}
            index={cIndex}
            label={child.label}
            selected={child.selected}
            onChange={(selected: boolean, childIndex: number) =>
              handleChildrenChange(selected, childIndex, pIndex)
            }
            translationOn={translationOn}
            isDisabled={child.noOfChargers === 0}
          />
        </div>
        <div className='top-8 flex'>
          {isMultipleCompany && (
            <div className='text-sm text-grey5 font-normal pr-1'>
              <Trans
                i18nKey='notification_modal_charger_checkbox_message'
                values={{ chargers: child.noOfChargers }}
              />
            </div>
          )}
          {child.noOfChargers > 0 && (
            <div onClick={() => handleChildClick(node, pIndex, child, cIndex)}>
              {isMultipleCompany && (
                <Label
                  type={LabelType.BODY2}
                  text=''
                  icon={child.isOpen ? ChevronUp : ChevronDown}
                  lazyIcon
                  iconClass='w-[24px] h-[24px]'
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='flex flex-col notification'>
      {nodes?.map((node, pIndex) => {
        const shouldDisplayParent = node.label
          .toLowerCase()
          .includes(filterStr.toLowerCase());
        if (!shouldDisplayParent) {
          return null;
        }
        return (
          <div
            key={`${node.label}-${pIndex}`}
            className={`pt-[10px] pr-[8px] pl-[8px] ${
              !node.isOpen ? 'pb-[10px]' : ''
            }`}
          >
            {renderParentCheckbox(node, pIndex)}
            {node.children?.map((child, cIndex) => {
              return (
                <div
                  key={`${child.label}-${cIndex}`}
                  style={{ display: node.isOpen ? 'block' : 'none' }}
                  className={`pt-[10px] pl-[8px] ${
                    !child.isOpen ? 'pb-[10px]' : ''
                  } items-center`}
                >
                  {node.isOpen &&
                    renderChildCheckbox(node, child, cIndex, pIndex)}
                  {child.children?.map((grandChild, gcIndex) => {
                    return (
                      <div
                        key={`${grandChild.label}-${gcIndex}`}
                        style={{ display: child.isOpen ? 'block' : 'none' }}
                        className='pt-[10px] pb-[10px] pr-[8px] pl-[8px] items-center'
                      >
                        <div className={grandChildStyle}>
                          {child.isOpen && (
                            <CheckBox
                              name={node.label}
                              index={gcIndex}
                              label={grandChild.label}
                              selected={grandChild.selected}
                              onChange={(
                                selected: boolean,
                                grandChildIndex: number,
                              ) =>
                                handleGrandChildrenChange(
                                  selected,
                                  grandChildIndex,
                                  cIndex,
                                  pIndex,
                                )
                              }
                              translationOn={translationOn}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

ChargerCheckboxTree.defaultProps = {
  onChange: () => null,
};

export default memo<ChargerCheckboxTreeProps>(ChargerCheckboxTree);
