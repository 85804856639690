import { Location, LocationObj } from '../stores/types';
import { NotificationCheckboxData } from '../stores/types/notification.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const locationApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchLocationsV3: builder.query<
      LocationObj,
      { scope?: SCOPE_TYPE; include?: String }
    >({
      query: (params) => ({
        url: `/internal/core/v3/${API_KEYS.LOCATION}`,
        params,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.LOCATIONV3, id: 'LISTV3' },
      ],
    }),
    fetchLocations: builder.query<
      LocationObj,
      { scope: SCOPE_TYPE; companyId?: String }
    >({
      query: (payload) => ({
        url: payload.companyId
          ? `/internal/core/v2/${API_KEYS.LOCATION}?include=all&companyId=${payload.companyId}`
          : `/internal/core/v2/${API_KEYS.LOCATION}?include=all`,
        params: { scope: payload.scope },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.LOCATION, id: 'LIST' },
      ],
    }),
    fetchNewLocationsByCompanyId: builder.query<LocationObj, any>({
      query: (param: any) => ({
        url: `/internal/core/v2/${API_KEYS.LOCATION}?include=all`,
        method: 'GET',
        params: { companyId: param.id },
      }),
    }),
    fetchLocationsByCompanyId: builder.query<
      {
        node: NotificationCheckboxData;
        totalCount: number;
        entities: Array<Location>;
      },
      any
    >({
      query: (param: any) => ({
        url: `/internal/core/v2/${API_KEYS.LOCATION}`,
        method: 'GET',
        params: { companyId: param.id },
      }),
      transformResponse: (response: LocationObj, meta, arg) => ({
        ...response,
        node: arg,
      }),
    }),
    addLocation: builder.mutation<any, Location>({
      query: (location: Location) => ({
        url: `/internal/core/v2/${API_KEYS.LOCATION}`,
        method: 'POST',
        body: location,
        toast: {
          successTrans: 'location_success_added',
          values: {
            name: location.name,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
    updateLocations: builder.mutation<any, Location>({
      query: (location: Location) => ({
        url: `/internal/core/v2/${API_KEYS.LOCATION}/${location.id}`,
        method: 'PATCH',
        body: location,
        toast: {
          successTrans: 'location_success_edited',
          values: {
            name: location.name,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchLocationsQuery,
  useFetchLocationsV3Query,
  useLazyFetchLocationsQuery,
  useLazyFetchLocationsV3Query,
  useLazyFetchLocationsByCompanyIdQuery,
  useLazyFetchNewLocationsByCompanyIdQuery,
  useAddLocationMutation,
  useUpdateLocationsMutation,
} = locationApi;
