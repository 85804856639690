import { uniqueId } from 'lodash';
import {
  DurationType,
  PricingType,
} from '../../../stores/types/price.interface';

export const tierPriceItem = () => ({
  id: uniqueId(),
  price: '',
  pricingType: PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME,
  duration: '',
  durationUnit: DurationType.HOUR,
});
