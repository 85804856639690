import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export const LANGUAGES = ['en-US', 'fr', 'es'];

export const i18n = i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next);
i18n.init(
  {
    // lng: 'en-US',
    // debug: true,
    initImmediate: false,
    fallbackLng: 'en-US',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // urlTemplate: 'AppScripts/i18n/{part}-{lang}.json' + '?cb=' + (new Date()).getTime()
      reloadInterval: 60 * 60 * 1000,
      requestOptions: {
        cache: 'reload',
      },
    },
    load: 'currentOnly',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'language',
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  },
  (error: any) => {
    if (error) {
      console.error('i18n init error:', error);
    }
  },
);
