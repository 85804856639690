import { providesListTag, SCOPE_TYPE } from './utils';
import { User, Invite } from '../stores/types';
import { Api, API_KEYS } from './base.api';

export const userGroupsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserGroups: builder.query<User[], any>({
      query: (uuid: string) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/users`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.USERGROUPS, id: 'LIST' },
      ],
      transformResponse: (response: User[]) => {
        const users = [...response];
        users.sort((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
        });
        return users;
      },
    }),
    fetchInvites: builder.query<Invite[], any>({
      query: (uuid: string) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/invites`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.INVITES, id: 'LIST' },
      ],
    }),
    createInvites: builder.mutation<any, { email: string; uuid: string }>({
      query: ({ email, uuid }) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/invites`,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.INVITES, id: 'LIST' },
      ],
    }),
    deleteInvites: builder.mutation<any, { inviteId: string; uuid: string }>({
      query: ({ inviteId, uuid }) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/invites`,
        method: 'DELETE',
        body: { inviteId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.INVITES, id: 'LIST' },
      ],
    }),
    addUser: builder.mutation<any, { inviteId: string; uuid: string }>({
      query: ({ inviteId, uuid }) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/users`,
        method: 'POST',
        body: { inviteId },
        params: {
          scope: SCOPE_TYPE.NONE,
        },
        needCompanyId: false,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.USERGROUPS, id: 'LIST' },
      ],
    }),
    deleteUser: builder.mutation<any, { email: string; uuid: string }>({
      query: ({ email, uuid }) => ({
        url: `/internal/core/v2/${API_KEYS.USERGROUPS}/${uuid}/users`,
        method: 'DELETE',
        body: { email },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.USERGROUPS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchUserGroupsQuery,
  useLazyFetchUserGroupsQuery,
  useCreateInvitesMutation,
  useLazyFetchInvitesQuery,
  useDeleteInvitesMutation,
  useDeleteUserMutation,
  useAddUserMutation,
} = userGroupsApi;
