import React, { useState, ReactNode } from 'react';

interface ToggleGroupProps {
  children: ReactNode;
  defaultOption: string;
  onOptionChange: (option: string) => void;
  className?: string;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({
  children,
  defaultOption,
  onOptionChange,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onOptionChange(option);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement, {
          isSelected:
            selectedOption === (child as React.ReactElement).props.option,
          onClick: handleOptionClick,
        }),
      )}
    </div>
  );
};

export default ToggleGroup;
