import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import {
  CSRCColumn1,
  CSRCColumn2,
  FSRCColumn1,
  FSRCColumn2,
  FSRCColumn3,
  INRCColumn1,
  INRCColumn2,
  INRCColumn3,
  INRCColumn4,
  NYSERDAEnergyUseColumn1,
  NYSERDAEnergyUseColumn2,
  NYSERDAStatucColumn,
  ReportType,
} from '../../stores/types/report.interface';
import { ColorType, Label, LabelType } from '../_ui';

interface InputProps {
  data: ReportType;
}

export const ReportContent = memo((data: InputProps) => {
  const { allowPayterView } = useAllowedFeatures();

  const { t } = useTranslation();
  const renderColumns = (dataArr: Array<string>) => {
    return dataArr.map((col: any) => (
      // eslint-disable-next-line react/jsx-key
      <Label text={t(col)} type={LabelType.BODY3} color={ColorType.GREY6} />
    ));
  };

  const CSRColumn2List = useMemo(() => {
    if (allowPayterView) {
      const csrColumns2 = [...CSRCColumn2];
      csrColumns2.splice(csrColumns2.indexOf('payment_collected'), 1);
      return [
        ...csrColumns2,
        'payter_total_revenue',
        'direct_revenue_collected',
        'payter_terminal_revenue_connected',
      ];
    }
    return CSRCColumn2;
  }, [allowPayterView]);

  const FSRCColumn3List = useMemo(() => {
    if (allowPayterView) {
      const fsrColumns3 = [...FSRCColumn3];
      return [...fsrColumns3, 'payment_terminal_id_if_applicable'];
    }
    return FSRCColumn3;
  }, [allowPayterView]);

  const INRCColumn4List = useMemo(() => {
    if (allowPayterView) {
      const inrColumns4 = [...INRCColumn4];
      return [...inrColumns4, 'payment_terminal_id_if_applicable'];
    }
    return INRCColumn4;
  }, [allowPayterView]);

  return (
    <div className='flex flex-row justify-between'>
      {data.data === ReportType.CHARGER_STATS && (
        <>
          <div className='flex flex-col gap-2 w-1/2'>
            {renderColumns(CSRCColumn1)}
          </div>
          <div className='flex flex-col gap-[8px] w-1/2'>
            {renderColumns(CSRColumn2List)}
          </div>
        </>
      )}
      {data.data === ReportType.FULL_SESSION && (
        <>
          <div className='flex flex-col gap-[8px] w-1/3'>
            {renderColumns(FSRCColumn1)}
          </div>
          <div className='flex flex-col gap-[8px] w-1/3'>
            {renderColumns(FSRCColumn2)}
          </div>
          <div className='flex flex-col gap-[8px] w-1/3'>
            {renderColumns(FSRCColumn3List)}
          </div>
        </>
      )}
      {data.data === ReportType.INTERVALS && (
        <>
          <div className='flex flex-col gap-[8px]'>
            {renderColumns(INRCColumn1)}
          </div>
          <div className='flex flex-col gap-[8px]'>
            {renderColumns(INRCColumn2)}
          </div>
          <div className='flex flex-col gap-[8px]'>
            {renderColumns(INRCColumn3)}
          </div>
          <div className='flex flex-col gap-[8px]'>
            {renderColumns(INRCColumn4List)}
          </div>
        </>
      )}
      {data.data === ReportType.NYSERDA_STATUS_HISTORY && (
        <div className='flex flex-col gap-[8px] w-1/3'>
          {renderColumns(NYSERDAStatucColumn)}
        </div>
      )}
      {data.data === ReportType.NYSERDA_ENERGY_USE && (
        <>
          <div className='flex flex-col gap-[8px] w-1/3'>
            {renderColumns(NYSERDAEnergyUseColumn1)}
          </div>
          <div className='flex flex-col gap-[8px] w-1/3'>
            {renderColumns(NYSERDAEnergyUseColumn2)}
          </div>
        </>
      )}
    </div>
  );
});
