import React, { forwardRef, memo, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';

interface ScrollableProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Scrollable = forwardRef(
  ({ children, style }: ScrollableProps, ref) => {
    if (style?.height) {
      return (
        <Scrollbars
          style={style}
          ref={ref as any}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {children}
        </Scrollbars>
      );
    }
    if (style?.maxHeight) {
      return (
        <Scrollbars
          style={style}
          ref={ref as any}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={style?.maxHeight}
        >
          {children}
        </Scrollbars>
      );
    }
    return (
      <Scrollbars
        style={style}
        ref={ref as any}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax='100vh'
      >
        {children}
      </Scrollbars>
    );
  },
);
