import { SupportNote } from '../stores/types/supportnote.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const supportnoteApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSupportNote: builder.query<SupportNote, string>({
      query: (id) => ({
        url: `/internal/core/v3/chargers/${id}/${API_KEYS.SUPPORT_NOTE}`,
        params: {
          scope: SCOPE_TYPE.NONE,
        },
        method: 'GET',
        needCompanyId: false,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.SUPPORT_NOTE, id: arg },
      ],
    }),
    upsertSupportNote: builder.mutation<any, SupportNote>({
      query: (note) => ({
        url: `/internal/core/v3/chargers/${note.deviceId}/${API_KEYS.SUPPORT_NOTE}`,
        params: {
          scope: SCOPE_TYPE.NONE,
        },
        method: 'PUT',
        body: note,
        needCompanyId: false,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.SUPPORT_NOTE, id: arg.deviceId },
      ],
    }),
  }),
});

export const { useFetchSupportNoteQuery, useUpsertSupportNoteMutation } =
  supportnoteApi;
