const colors = {
  brand0: '#E8F7FC',
  brand1: '#BBE7F6',
  brand2: '#18A0D7',
  brand3: '#117DB8',
  denim: '#117DB8',
  denim_hover: '#065C91',
  denim_light: '#DFF3F7',
  ink: '#1F2631',
  ink_hover: '#30363D',
  ink_light: '#F4F7F9',
  white: '#FFFFFF',
  black: '#202223',
  grey0: '#F9FAFB',
  grey1: '#F2F4F6',
  grey2: '#E5E8EB',
  grey3: '#D1D6DB',
  grey4: '#B0B8C1',
  grey5: '#6B7684',
  grey6: '#4E5968',
  negative0: '#FFEFED',
  negative1: '#E53935',
  negative2: '#C62828',
  warning0: '#FFFAF0',
  warning1: '#FFB300',
  warning2: '#FF8F00',
  positive0: '#F5FAF0',
  positive1: '#7CB342',
  positive2: '#558B2F',
  accent0: '#EFF3FE',
  accent1: '#039BE5',
  accent2: '#0277BD',
  accent_sub: '#EBF8FF',
  web_bg: '#F5F6FA',
  purple: '#7356BF',
  teal: '#00897B',
  toggle_green: '#4BD865',
  black2: '#000000',
};

export enum ColorType {
  BRAND0 = 'brand0', // #E8F7FC
  BRAND1 = 'brand1', // #BBE7F6
  BRAND2 = 'brand2', // #18A0D7
  BRAND3 = 'brand3', // #117DB8
  DENIM = 'denim', // #117DB8
  DENIM_HOVER = 'denim_hover', // #065C91
  DENIM_LIGHT = 'denim_light', // #DFF3F7
  INK = 'ink', // #1F2631
  INK_HOVER = 'ink_hover', // #30363D
  INK_LIGHT = 'ink_light', // #F4F7F9
  WHITE = 'white', // #FFFFFF
  BLACK = 'black', // #202223
  GREY0 = 'grey0', // #F9FAFB
  GREY1 = 'grey1', // #F2F4F6
  GREY2 = 'grey2', // #E5E8EB
  GREY3 = 'grey3', // #D1D6DB
  GREY4 = 'grey4', // #B0B8C1
  GREY5 = 'grey5', // #6B7684
  GREY6 = 'grey6', // #4E5968
  NEGATIVE0 = 'negative0', // #FFEFED
  NEGATIVE1 = 'negative1', // #E53935
  NEGATIVE2 = 'negative2', // #C62828
  WARNING0 = 'warning0', // #FFFAF0
  WARNING1 = 'warning1', // #FFB300
  WARNING2 = 'warning2', // #FF8F00
  POSITIVE0 = 'positive0', // #F5FAF0
  POSITIVE1 = 'positive1', // #7CB342
  POSITIVE2 = 'positive2', // #558B2F
  ACCENT0 = 'accent0', // #EFF3FE
  ACCENT1 = 'accent1', // #039BE5
  ACCENT2 = 'accent2', // #0277BD
  ACCENT_SUB = 'accent_sub', // #EBF8FF
  WEB_BG = 'web_bg', // #F5F6FA
  PURPLE = 'purple', // #7356BF
  TEAL = 'teal', // #00897B
  TOGGLE_GREEN = 'toggle_green', // #4BD865
  BLACK2 = 'black2', // #000000
}

export const getHexColorByType = (type: ColorType | undefined) => {
  return type ? colors[type] : '';
};
export const isHexColor = (color: string | undefined) => {
  const hexColorRegex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
  return color ? hexColorRegex.test(color) : false;
};
