import {
  FloatingFocusManager,
  FloatingList,
  FloatingPortal,
  Placement,
  autoUpdate,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
} from '@floating-ui/react';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import '../../../App.css';
import { CancelClose, ChevronDown, Search } from '../../../assets/icons';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { CircleIconButton } from '../CircleIconButton.component';
import { Icon, IconProps } from '../Icon.component';
import { FormInput } from '../Input.component';
import { Label, LabelType } from '../Label.component';
import { Scrollable } from '../Scrollable.component';
import { ColorType, getHexColorByType } from '../index';
import { filterSelectedItemChildren } from './Select.utils';
/**
 * Select and SelectItem Composable Components
 The Select component creates the dropdown container,
  and the SelectItem components are the individual items within the dropdown.
 */
interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | undefined;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number) => void;
  inputValue: string | null;
}

const SelectContext = createContext<SelectContextValue>(
  {} as SelectContextValue,
);

interface SelectProps {
  defaultLabel?: string; // Default label displayed on the button when no item is selected
  defaultIndex?: number; // Default index of the selected item in the dropdown list
  icon?: any; // Icon to be displayed on the dropdown button
  rootClassName?: string; // Additional class for the root div of the component
  contentClassName?: string; // Additional class for the dropdown content
  contentWidth?: number;
  contentHeight?: number;
  buttonProps?: { [key: string]: any }; // Additional properties for the dropdown button
  iconProps?: IconProps; // Properties for the icon inside the dropdown button
  children: any; // The items of the dropdown list
  placement?: Placement; // Positioning of the dropdown relative to the button
  OnOpenChange?: Function; // Callback function when dropdown open status changes
  onItemSelected?: Function; // Callback function when an item is selected
  disabled?: boolean; // Whether the dropdown is disabled or not
  renderFooter?: Function; // Function to render custom footer inside the dropdown
  usePortal?: boolean; // Portals the floating element into a given container element — by default, outside of the app root and into the body.
  dataTestId?: string;
}

const DISPLAY_SEARCH_BAR_COUNT = 5;

export const Select = memo(
  ({
    defaultLabel = 'Select',
    defaultIndex,
    icon,
    rootClassName,
    contentClassName,
    buttonProps = {},
    iconProps = {},
    children,
    placement = 'bottom-start',
    OnOpenChange,
    onItemSelected,
    disabled = false,
    renderFooter,
    usePortal = false,
    contentWidth,
    contentHeight,
    dataTestId = '',
  }: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
      defaultIndex,
    );

    const filteredChildren = filterSelectedItemChildren(children);
    const handleOpen = (val: boolean) => {
      setIsOpen(val);
      !val && setInputValue('');
      OnOpenChange && OnOpenChange(val);
    };

    const { refs, floatingStyles, context } = useFloating({
      placement,
      open: isOpen,
      onOpenChange: handleOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(5),
        size({
          apply({ rects, elements, availableHeight }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
              minWidth: `${rects.reference.width}px`,
            });
          },
          padding: 10,
        }),
        shift({ crossAxis: true }),
      ],
    });

    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);

    const handleSelect = useCallback(
      (index: number) => {
        setSelectedIndex(index);
        handleOpen(false);
        onItemSelected && onItemSelected(index);
      },
      [onItemSelected],
    );

    const listNav = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      selectedIndex,
      onNavigate: (index) => {
        setActiveIndex(index);
      },
      loop: true,
    });

    // const typeahead = useTypeahead(context, {
    //   listRef: labelsRef,
    //   activeIndex,
    //   selectedIndex,
    //   onMatch: handleTypeaheadMatch,
    //   // enabled: !isOpen,
    // });

    const click = useClick(context);
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([listNav, click, dismiss]);

    const selectContext = useMemo(
      () => ({
        activeIndex,
        selectedIndex,
        getItemProps,
        handleSelect,
        inputValue,
      }),
      [activeIndex, selectedIndex, getItemProps, handleSelect, inputValue],
    );

    const selectedLabel = useMemo(() => {
      // if the dropdown is not open yet
      if (!isOpen) {
        if (
          filteredChildren &&
          defaultIndex !== undefined &&
          filteredChildren[defaultIndex]
        ) {
          return filteredChildren[defaultIndex].props.label;
        }
        return defaultLabel;
      }
      return selectedIndex !== undefined
        ? labelsRef.current[selectedIndex]
        : defaultLabel;
    }, [selectedIndex, defaultLabel, filteredChildren, defaultIndex]);

    const renderButton = () => {
      const conbimedButtonProps = {
        labelType: LabelType.DROPDOWN_HEADER,
        type: ButtonType.OUTLINE,
        size: ButtonSize.SMALL_FULL,
        disabled,
        labelContainerCss: 'justify-start',
        iconRightAlign: true,
        label: selectedLabel || defaultLabel,
        ...buttonProps,
      };
      const combinedIconProps = {
        disabled,
        ...iconProps,
      };

      return (
        <div
          className='inline-block'
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {icon ? (
            <CircleIconButton
              src={icon}
              {...combinedIconProps}
              dataTestId={dataTestId}
            />
          ) : (
            <Button
              icon={ChevronDown}
              {...conbimedButtonProps}
              dataTestId={dataTestId}
            />
          )}
        </div>
      );
    };

    const handleSearch = (val: string) => {
      // clean avtive Index
      setActiveIndex(null);
      setInputValue(val);
    };

    const renderSearchBar = () => {
      return (
        <div className='flex flex-row h-[40px] mb-2'>
          <FormInput
            width='100%'
            placeholder='Search'
            icon={Search}
            onChange={(event: any) => handleSearch(event.target.value)}
            showOutline={false}
            suffixIcon={inputValue ? CancelClose : ''}
            defaultValue={inputValue}
            onClickSuffix={() => {
              handleSearch('');
            }}
            inputId='dropdown-search-input'
          />
        </div>
      );
    };

    const renderPortalPopup = (node: React.ReactNode) => {
      return <FloatingPortal>{node}</FloatingPortal>;
    };

    const renderPopupItems = () => {
      let style = {
        ...floatingStyles,
      };
      if (contentWidth) {
        style = {
          ...style,
          width: `${contentWidth}px`,
        };
      }
      if (contentHeight) {
        style = {
          ...style,
          maxHeight: `${contentHeight}px`,
        };
      }
      return (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            className={` z-50 flex flex-col p-2 bg-white outline-0 rounded-lg list-shadow hover:border-0 ${contentClassName}`}
            style={style}
            {...getFloatingProps()}
          >
            {labelsRef?.current?.length > DISPLAY_SEARCH_BAR_COUNT &&
              renderSearchBar()}
            <Scrollable style={{ maxHeight: contentHeight }}>
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </Scrollable>
            <div className='mt-auto'>{renderFooter && renderFooter()}</div>
          </div>
        </FloatingFocusManager>
      );
    };

    return (
      <div className={rootClassName}>
        {renderButton()}
        <SelectContext.Provider value={selectContext}>
          {isOpen &&
            (usePortal
              ? renderPortalPopup(renderPopupItems())
              : renderPopupItems())}
        </SelectContext.Provider>
      </div>
    );
  },
);

interface SelectItemProps {
  label?: string; // (Optional) The text displayed for the item.
  labelType?: LabelType; // (Optional) Label type for the label props.
  labelColor?: ColorType | string; // (Optional) Label color for the label props.
  iconBefore?: any; // (Optional) An icon or image displayed before the item text.
  iconAfter?: any; // (Optional) An icon or image displayed after the item text.
  disabled?: boolean; // is item disabled
  iconColor?: ColorType | string; // icon color
  dataTestId?: string;
}
export const SelectItem = (props: SelectItemProps) => {
  const {
    label,
    iconBefore,
    iconAfter,
    labelType,
    labelColor,
    disabled,
    iconColor,
    dataTestId,
  } = props;
  const { activeIndex, selectedIndex, getItemProps, handleSelect, inputValue } =
    useContext(SelectContext);
  const theme = useSelector(getCurrentTheme);
  const { ref, index } = useListItem({ label });
  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;
  if (inputValue && !label?.toLowerCase().includes(inputValue.toLowerCase())) {
    return null;
  }

  return (
    <button
      ref={ref}
      tabIndex={isActive ? 0 : -1}
      className='hover:bg-grey1 disabled:hover:bg-white outline-0 p-2 w-full h-[40px] text-left'
      style={{
        background:
          isSelected || isActive ? getHexColorByType(ColorType.GREY1) : '', // grey 1: #F2F4F6
      }}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
      disabled={disabled}
      data-testid={dataTestId}
    >
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-row items-center gap-2 truncate'>
          {iconBefore && (
            <Icon
              src={iconBefore}
              className='mr-2'
              color={
                isSelected
                  ? theme.navigationSelectedColor
                  : getHexColorByType(iconColor as ColorType) || ''
              }
            />
          )}
          <Label
            text={label}
            type={labelType || LabelType.LABEL_S_MEDIUM}
            style={
              isSelected
                ? { color: theme.navigationSelectedColor }
                : {
                    color: getHexColorByType(
                      (labelColor as ColorType) || ColorType.GREY6,
                    ),
                  }
            }
          />
        </div>
        {iconAfter && (
          <Icon src={iconAfter} color={iconColor || ''} className='mr-2' />
        )}
      </div>
    </button>
  );
};
