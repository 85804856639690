import { useMemo } from 'react';
import Modal from 'react-modal';
import { t } from 'i18next';
import { useGlobalModalContext } from './GlobalModal.component';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '..';
import { useWindowSize } from '../../../hooks';
import { MODAL_LAYER_INDEX } from './utils';
import { Success } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';

Modal.setAppElement('#root');

const WINDOW_H_PADDING = 80; // top 40 bottom 40

export const SuccessModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const windowSize = useWindowSize();
  const { modalProps } = store;
  const {
    title = '',
    width = '540px',
    height = 'max-content',
    overflow = 'auto',
    onRenderBody,
    onRenderFooter,
    shouldCloseOnOverlayClick = true,
    icon = Success,
  } = modalProps;

  const handleClose = () => {
    hideModal();
  };

  const renderHeader = () => {
    return (
      <div className='sticky top-0 z-50 bg-white'>
        <div className='flex flex-col gap-1 items-center'>
          <Icon src={icon} size={IconSize.SIZE_64x64} />
          <Label
            text={title}
            type={LabelType.H3}
            color={ColorType.BLACK}
            className='text-center'
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className='flex-grow my-6'>{onRenderBody && onRenderBody()}</div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='sticky top-0 flex justify-end'>
        <Button
          label={t('close')}
          type={ButtonType.TERTIARY}
          size={ButtonSize.SMALL}
          onClick={handleClose}
        />
      </div>
    );
  };

  const modalMaxHeight = useMemo(() => {
    if (windowSize.height === 0) {
      return 640;
    }
    return windowSize.height - WINDOW_H_PADDING;
  }, [windowSize.height]);

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          overflow,
          top: '50%',
          left: '50%',
          width,
          height,
          maxHeight: modalMaxHeight,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className='flex flex-col p-5'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
