import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { header } from './http/http.service';
import { ReceiptSettings, Theme } from '../stores/types';
import { ColorType, getHexColorByType } from '../components/_ui';

// More info about RTK Query
// https://redux-toolkit.js.org/rtk-query/overview

export const envApi = createApi({
  reducerPath: 'env',
  baseQuery: fetchBaseQuery({
    headers: header,
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    fetchThemeSettings: builder.query<Theme, void>({
      query: () =>
        `assets?resourceId=theme&domainName=${window.location.hostname}`,
      transformResponse: (response: {
        primary_color: string;
        secondary_color: string;
        brand_0: string;
        brand_1: string;
        brand_2: string;
        brand_3: string;
      }) => ({
        // logo will be determined based on the domain
        networkLogo: `/assets?resourceId=logo-svg&domainName=${window.location.hostname}`,
        chartColor: response.primary_color,
        navigationSelectedColor: response.primary_color,
        btnHoverColor: response.brand_3,
        secondaryBtnBgColor: response.secondary_color,
        brand_0: response.brand_0,
        brand_1: response.brand_1,
        brand_2: response.brand_2,
        brand_3: response.brand_3,
      }),
    }),
    fetchReceiptSettings: builder.query<ReceiptSettings, void>({
      query: () =>
        `assets?resourceId=receipt-settings&domainName=${window.location.hostname}`,
    }),
  }),
});

export const { useFetchThemeSettingsQuery, useFetchReceiptSettingsQuery } =
  envApi;

// Possible exports
// export const { endpoints, reducerPath, reducer, middleware } = envApi
