import { Vehicle } from '../stores/types/vehicles.interface';
import { API_KEYS, Api } from './base.api';
import { providesListTag } from './utils';

export const vehiclesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    // check if vehicle name dupilcated, 200 ok, 409 error
    validateVehicleName: builder.query<any, string>({
      query: (vehicleName: string) => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}`,
        method: 'HEAD',
        params: { 'filter_eq[name]': encodeURIComponent(vehicleName) },
      }),
    }),
    // check if vehicle name dupilcated, 200 ok, 409 error
    validateVehicleVin: builder.query<any, string>({
      query: (vehicleVin: string) => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}`,
        method: 'HEAD',
        params: { 'filter_eq[vin]': vehicleVin },
      }),
    }),
    fetchVehicles: builder.query<Vehicle[], void>({
      query: () => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}`,
        method: 'GET',
        params: { includeLastUsedInfo: true }, // this is required by backend, for getting energy used and last plugin date
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.map((res) => ({
            id: res.vehicleId,
          })),
          API_KEYS.VEHICLES,
        ),
    }),
    addVehicle: builder.mutation<any, Vehicle>({
      query: (vehicle: Vehicle) => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}`,
        method: 'POST',
        body: {
          name: vehicle.vehicleName,
          vin: vehicle.vin,
          make: vehicle.make,
          model: vehicle.model,
          batteryCapacityKwh: vehicle.batteryCapacityKwh,
          note: vehicle.note,
          locationId: vehicle.locationId,
          rfids: vehicle.rfids?.map((rfid) => rfid.cardNumber),
        },
        toast: {
          successTrans: 'vehicle_add_vehicle_success',
          values: {
            name: vehicle.vehicleName,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.VEHICLES, id: 'LIST' },
        { type: API_KEYS.CARDS, id: 'LIST' },
      ],
    }),
    editVehicle: builder.mutation<any, Vehicle>({
      query: (vehicle: Vehicle) => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}/${vehicle.vehicleId}`,
        method: 'PUT',
        body: {
          id: vehicle.vehicleId,
          name: vehicle.vehicleName,
          vin: vehicle.vin,
          make: vehicle.make,
          model: vehicle.model,
          batteryCapacityKwh: vehicle.batteryCapacityKwh,
          note: vehicle.note,
          locationId: vehicle.locationId,
          rfids: vehicle.rfids?.map((rfid) => rfid.cardNumber),
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.VEHICLES, id: 'LIST' },
        { type: API_KEYS.CARDS, id: 'LIST' },
      ],
    }),
    deleteVehicle: builder.mutation<any, Vehicle>({
      query: (vehicle) => ({
        url: `/internal/core/v2/${API_KEYS.VEHICLES}/${vehicle.vehicleId}`,
        method: 'DELETE',
        toast: {
          successTrans: 'vehicle_delete_vehicle_success',
          values: {
            name: vehicle.vehicleName,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.VEHICLES, id: 'LIST' },
        { type: API_KEYS.CARDS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useLazyValidateVehicleNameQuery,
  useLazyValidateVehicleVinQuery,
  useFetchVehiclesQuery,
  useAddVehicleMutation,
  useEditVehicleMutation,
  useDeleteVehicleMutation,
} = vehiclesApi;
