import { useTranslation } from 'react-i18next';
import { Breaker, UnmanagedLoad } from '../../stores/types/pm.interface';
import { Label, LabelType } from '../_ui/Label.component';
import { prependWiringText, transformWiringForSplitPhase } from './utils';
import { ColorType } from '../_ui';

export interface UnmanagedLoadDetailsProp {
  data: {
    unmanagedLoad: UnmanagedLoad;
    breaker: Breaker;
    isSplitPhase: boolean;
  };
}
export const UnmanagedLoadDetailsModal = ({
  data,
}: UnmanagedLoadDetailsProp) => {
  const { t } = useTranslation();
  return (
    <div className='divide-y divide-grey2 px-1'>
      <div className='flex flex-col pb-4 gap-y-4 px-1'>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('unmanaged_load_modal_field_1')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={data.unmanagedLoad.name}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('unmanaged_load_modal_field_2')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={`${data.unmanagedLoad.fixedAmperage} amps`}
          />
        </div>
      </div>
      <div className='flex flex-col pt-4 gap-y-4 px-1 pb-5'>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('unmanaged_load_modal_field_3')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={data.breaker.dedicatedBreaker ? 'Yes' : 'No'}
          />
        </div>
        {data.breaker.dedicatedBreaker && (
          <>
            <div className='flex flex-col gap-y-2'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('unmanaged_load_modal_field_4')}
              />
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${data.breaker.breakerRating} amps`}
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <div className='flex flex-row'>
                <Label
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  text={t('unmanaged_load_modal_field_5')}
                />
                <div>&nbsp;</div>
                <Label
                  type={LabelType.LABEL_M_MEDIUM}
                  color={ColorType.GREY6}
                  text={t('unmanaged_load_modal_field_6')}
                />
              </div>
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${data.breaker.continuousLoadLimit} amps`}
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('unmanaged_load_modal_field_7')}
              />
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={data.breaker.breakerSpace}
              />
            </div>
          </>
        )}

        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('unmanaged_load_modal_field_8')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={
              data.isSplitPhase
                ? transformWiringForSplitPhase(data.unmanagedLoad.wiringPhase)
                : prependWiringText(data.unmanagedLoad.wiringPhase)
            }
          />
        </div>
      </div>
    </div>
  );
};
