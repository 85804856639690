import React, { ChangeEvent, useState } from 'react';

interface InputAreaProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  width?: string;
  height?: string;
  placeholder?: string;
}

const InputArea: React.FC<InputAreaProps> = ({
  defaultValue = '',
  onChange,
  maxLength = 1000,
  width = '100%',
  height = '280px',
  placeholder = 'Enter your text here...',
}) => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <textarea
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      className='bg-grey1 focus:outline-none border-none focus:ring-0 rounded-sm resize-none'
      style={{ width, height }}
      placeholder={placeholder}
    />
  );
};

export default InputArea;
