import { RoutePath, getBaseRoute } from '../../routes';

export const getIsItemSelected = (pathname: string, currentPath: string) => {
  if (pathname === '/' || pathname === '/noaccess') {
    return currentPath === RoutePath.OVERVIEW;
  }
  const path = getBaseRoute(pathname);
  if (path === RoutePath.CHARGER_DETAIL && currentPath === RoutePath.CHARGERS) {
    return true;
  }
  if (path === RoutePath.PRICING_EDIT && currentPath === RoutePath.PRICING) {
    return true;
  }
  if (path === RoutePath.VEHICLE_DETAIL && currentPath === RoutePath.VEHICLES) {
    return true;
  }
  return path === currentPath;
};

export const getPathByIndex = (index: number, menus: any[]) => {
  let path = '';
  menus.forEach((menu) => {
    if (menu.items) {
      menu.items.forEach((item: any) => {
        if (index === item.index) {
          path = item.path;
        }
      });
    }
  });

  return path;
};

export const getSelectedMenuIndex = (pathname: string, menus: any[]) => {
  let resultIndex = -1;
  menus.forEach((menu) => {
    if (menu.items) {
      menu.items.forEach((item: any) => {
        const isSelected = getIsItemSelected(pathname, item.path);
        if (isSelected) {
          resultIndex = item.index;
        }
      });
    }
  });

  return resultIndex;
};
