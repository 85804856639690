import { enableMapSet } from 'immer';
import { combineReducers } from 'redux';
import { envApi } from '../services/env.api';
import { deploymentApi } from '../services/deployment.api';
import { Api } from '../services/base.api';
import { timeZoneApi } from '../services/timezone.api';

const rootReducer = combineReducers({
  [Api.reducerPath]: Api.reducer,
  [envApi.reducerPath]: envApi.reducer,
  [deploymentApi.reducerPath]: deploymentApi.reducer,
  [timeZoneApi.reducerPath]: timeZoneApi.reducer,
});
export default rootReducer;
