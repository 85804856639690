export const header = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

/**
 * Used for calling an endpoint without the predefined base url eg:
 * /deployment/cognito => {"region": "us-east-1", "userPoolId": "us-east-1_S1aRqShe6", "clientId": "4ahk7m1g54kodg0e3c9qedv6vg"}
 * @param url
 */
export const httpRawGet = async (url: string) => {
  try {
    const getRequest = await fetch(`${url}`, {
      method: 'GET',
      headers: {
        ...header,
      },
    });

    if (getRequest.ok) {
      return getRequest.json();
    }
    throw new Error('Something went wrong');
  } catch (err) {
    console.log('err - ', err);
  }
};
