import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../../services/translation/i18n';
import { ColorType, Dropdown, DropdownType, LabelType } from '../../_ui';
import { getWebLanguage } from './utils';

interface IProps {
  language: string;
  setLanguage: Function;
}
const SelectLanguage = ({ language, setLanguage }: IProps) => {
  const { t } = useTranslation();
  const languageDropdown = useMemo(() => {
    return LANGUAGES.map((item) => ({
      label: item,
      value: item,
      selected: item.toLowerCase() === getWebLanguage(language),
    }));
  }, [language]);

  const handleLanguageChange = (val: any) => {
    setLanguage(val.value);
  };

  return (
    <div className='mb-4 w-full'>
      <Dropdown
        placeholder={t('terminal_language')}
        placeholderLabelType={LabelType.BODY3_G4}
        type={DropdownType.SELECT}
        headerWidth='100%'
        items={languageDropdown}
        translationOn
        labelTypeHighlighted={LabelType.BODY3}
        labelType={LabelType.BODY3}
        headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded'
        chevdownIconHighlightColor={ColorType.GREY6}
        onItemClick={handleLanguageChange}
      />
    </div>
  );
};

export default SelectLanguage;
