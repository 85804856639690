import _ from 'lodash';

export const formatCardNumber = (value: string) => {
  const formattedString = value.replace(/[^a-zA-Z0-9]/g, '');

  return formattedString;
};

export const formatPercent = (value: string) => {
  if (_.isEmpty(value) || value === undefined) {
    return value;
  }
  const index = value.indexOf('.');
  // found .
  if (index !== -1) {
    const decimals = value.split('.')[1];
    // no decimals yet
    if (_.isEmpty(decimals) || decimals === undefined) {
      return value;
    }

    return `${value.substring(0, index)}.${decimals.substring(0, 2)}`;
  }

  return value;
};

export const formatChargelabAcceptedCharacters = (value: string) => {
  const formattedString = value.replace(
    /[^A-Za-z0-9!@#$&+\-()?,:;"'/.\s]/g,
    '',
  );

  return formattedString;
};
