import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from './GlobalModal.component';
import {
  Button,
  ButtonType,
  ButtonSize,
  Label,
  LabelType,
  Switch,
  FormInput,
  Icon,
  ColorType,
  Segment,
} from '..';
import { MODAL_LAYER_INDEX, validateEmail } from './utils';
import { ExitDeleteRemoveClose } from '../../../assets/icons';

Modal.setAppElement('#root');

export const AutoStartModal = () => {
  const { t } = useTranslation();

  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const {
    title,
    width = '380px',
    height = 'max-content',
    button = {
      label: '',
      type: ButtonType.PRIMARY,
      size: ButtonSize.SMALL_FULL,
    },
  } = modalProps;
  const options = ['auto_start_individual', 'auto_start_company'];
  const [selectedSegment, setSelectedSegment] = useState(options[0]);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [formInput, setFormInput] = useState('');
  const handleClose = () => {
    hideModal();
  };

  const renderHeader = () => {
    return (
      <div className='flex items-center justify-center sticky top-0 h-16'>
        <Icon
          src={ExitDeleteRemoveClose}
          className='w-[28px] h-[28px] hover:bg-grey2 rounded-full flex items-center justify-center  absolute left-2 cursor-pointer'
          onClick={handleClose}
        />
        <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
      </div>
    );
  };

  const handleSwitchCharge = (selected: string) => {
    if (selected === options[1]) {
      setButtonEnable(true);
    } else {
      setButtonEnable(validateEmail(formInput));
    }
    setSelectedSegment(selected);
  };

  const onInputChange = (event: any) => {
    setFormInput(event.target.value);
    setButtonEnable(validateEmail(event.target.value));
  };

  const renderBody = () => {
    const text =
      selectedSegment === options[1]
        ? t('auto_start_msg_company')
        : t('auto_start_msg_user');
    return (
      <div>
        <Segment
          className='whitespace-nowrap'
          segmentedItemWidth='w-[10.375rem]'
          segmentedDefault={selectedSegment}
          options={options}
          onChange={handleSwitchCharge}
        />
        <div className='mt-5'>
          <Label text={text} type={LabelType.BODY2} color={ColorType.GREY6} />
        </div>

        {selectedSegment !== options[1] && (
          <div className='mt-2'>
            <FormInput
              width='340px'
              label={t('auto_start_user_email')}
              placeholder={t('auto_start_user_email')}
              onChange={onInputChange}
              defaultValue={formInput}
            />
          </div>
        )}
      </div>
    );
  };

  const handleButtonClick = () => {
    handleClose();
    button.onClick && button.onClick(selectedSegment, formInput);
  };

  const renderFooter = () => {
    return (
      <div className='mb-5 mt-5'>
        <Button
          label={button.label}
          type={button.type}
          disabled={!buttonEnable}
          size={button.size}
          className='h-10'
          onClick={handleButtonClick}
        />
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <div className='flex flex-col px-4'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
