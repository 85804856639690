import { t } from 'i18next';
import { PRICING_SCHEDULED_TYPE } from '../../../constant/Text.constant';
import { Price } from '../../../stores/types/price.interface';
import { ColorType, Label, LabelType } from '../../_ui';

export const FreePricing = (pricingData: Price) => {
  const { scheduleType } = pricingData;
  const { FREE } = PRICING_SCHEDULED_TYPE;
  return (
    scheduleType === FREE && (
      <div className='py-2'>
        <Label
          text={t('free')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.BLACK}
        />
      </div>
    )
  );
};
