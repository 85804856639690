import { memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import timezones from 'timezones-list';
import { format } from 'date-fns-tz';
import { Country, State } from '../../lib/country-state';
import { Company, Location } from '../../stores/types';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Icon,
  Label,
  LabelType,
  getHexColorByType,
  useGlobalModalContext,
} from '../_ui';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  useAddLocationMutation,
  useUpdateLocationsMutation,
} from '../../services/location.api';
import { getCountriesByPreference } from '../Charger/utils';
import { Form } from '../_ui/Form.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { IconSize } from '../../constant/IconSize.constant';
import { Info } from '../../assets/icons';
import { useLazyFetchtTimezoneQuery } from '../../services/timezone.api';
import { isValidLat, isValidLong } from './utils';

interface Props {
  defaultLocation?: Location;
  company: Company | undefined;
}

export const LocationEditor = memo(({ defaultLocation, company }: Props) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const [triggerAddLocation, newLocationResult] = useAddLocationMutation();
  const [currentTime] = useState(format(new Date(), 't'));
  const [triggerUpdateLocation, updateLocationResult] =
    useUpdateLocationsMutation();

  const [location, setLocation] = useState<Location>(
    defaultLocation || ({} as Location),
  );

  const handleLocationChange = (key: string, value: any) => {
    setLocation({
      ...location,
      [key]: value,
      isoCountryCode: company?.isoCountryCode || '',
    });
  };

  const isNew = useMemo(() => {
    return !defaultLocation;
  }, [defaultLocation]);

  const provinceList = useMemo(() => {
    return State.getStatesOfCountry(company?.isoCountryCode || 'CA').map(
      (obj) => ({
        ...obj,
        id: obj.isoCode,
        selected: obj.isoCode === location?.stateOrRegion,
      }),
    );
  }, [company?.isoCountryCode, location?.stateOrRegion]);

  const country = useMemo(() => {
    const countries = Country.getAllCountries();
    const result = getCountriesByPreference(countries)?.find(
      (obj: any) => obj.isoCode === company?.isoCountryCode,
    );
    return result;
  }, [company?.isoCountryCode]);

  const timeZoneDropdownList = useMemo(() => {
    return timezones.map((timezone) => {
      return {
        ...timezone,
        id: timezone.tzCode,
        selected: timezone.tzCode === location.timeZone,
      };
    });
  }, [location]);

  const [triggerFetchTimezone, { data: defaultTimeZone }] =
    useLazyFetchtTimezoneQuery();

  useEffect(() => {
    if (isValidLat(location.latitude) && isValidLong(location.longitude)) {
      triggerFetchTimezone({
        location: `${location.latitude},${location.longitude}`,
        timestamp: currentTime,
      });
    }
  }, [
    location.latitude,
    location.longitude,
    currentTime,
    triggerFetchTimezone,
  ]);
  useEffect(() => {
    if (defaultTimeZone) {
      handleLocationChange('timeZone', defaultTimeZone?.timeZoneId);
    }
  }, [defaultTimeZone]);

  // Waits till AddLocation mutation is finished & then hideModal
  // useEffect(() => {
  //   if (newLocationResult.isSuccess || newLocationResult.isError) {
  //     hideModal();
  //   }
  // }, [newLocationResult.isSuccess, newLocationResult.isError]);

  // Waits till UpdateLocation mutation is finished & then hideModal
  // useEffect(() => {
  //   if (updateLocationResult.isSuccess || updateLocationResult.isError) {
  //     hideModal();
  //   }
  // }, [updateLocationResult.isSuccess, updateLocationResult.isError]);

  const onButtonValidation = () => {
    if (
      !location.street ||
      !location.streetNumber ||
      !location.name ||
      !location.city ||
      !location.zipOrPostalCode ||
      !location.latitude ||
      !Number(location.latitude) ||
      Number(location.latitude) < -90 ||
      Number(location.latitude) > 90 ||
      !location.longitude ||
      !Number(location.longitude) ||
      Number(location.longitude) < -180 ||
      Number(location.longitude) > 180 ||
      !location.timeZone
    ) {
      return false;
    }

    return true;
  };

  const handleSaveClick = () => {
    if (isNew) {
      triggerAddLocation(location);
    } else {
      triggerUpdateLocation(location);
    }
  };

  return (
    <Form
      onSubmit={handleSaveClick}
      queryResult={isNew ? newLocationResult : updateLocationResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='flex flex-col gap-[16px]'>
        <FormInput
          label={t('locaiton_name')}
          placeholder={t('locaiton_name')}
          onChange={(event: any) =>
            handleLocationChange('name', event.target.value)
          }
          validationType={ValidationType.NOT_EMPTY}
          defaultValue={location.name}
          width='100%'
          dataTestId='locationInput'
        />

        <div className='flex flex-col gap-2'>
          <div className='flex flex-row gap-1'>
            <Label
              text={t('location_address')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
          </div>
          <div className='flex flex-row gap-2'>
            <FormInput
              placeholder={t('location_edit_street_number_holder')}
              onChange={(event: any) =>
                handleLocationChange('streetNumber', event.target.value)
              }
              defaultValue={location.streetNumber}
              props={{
                style: { width: '85px' },
              }}
              dataTestId='locationStreetNumber'
            />
            <div className='grow'>
              <FormInput
                placeholder={t('location_edit_street_name_holder')}
                onChange={(event: any) =>
                  handleLocationChange('street', event.target.value)
                }
                defaultValue={location.street}
                width='100%'
                dataTestId='locationStreetName'
              />
            </div>
          </div>
          <FormInput
            placeholder={t('location_edit_unit_holder')}
            onChange={(event: any) =>
              handleLocationChange('unit', event.target.value)
            }
            defaultValue={location.unit}
            width='100%'
            dataTestId='locationUnitHolder'
          />
        </div>
        <div className='flex flex-row'>
          <div className='flex-1'>
            <FormInput
              label={t('location_city')}
              placeholder={t('location_city_placeholder')}
              onChange={(event: any) =>
                handleLocationChange('city', event.target.value)
              }
              defaultValue={location.city}
              props={{
                style: { width: '242px' },
              }}
              dataTestId='locationCity'
            />
          </div>
          <div className='flex-1 flex flex-col gap-2'>
            <FormInput
              label={t('location_zip')}
              placeholder={t('location_zip')}
              onChange={(event: any) =>
                handleLocationChange('zipOrPostalCode', event.target.value)
              }
              defaultValue={location.zipOrPostalCode}
              props={{
                style: { width: '242px' },
              }}
              dataTestId='locationZipCode'
            />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='flex-1 flex flex-col gap-2'>
            <Label
              text={t('location_country')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <FormInput
              width='242px'
              defaultValue={country?.name}
              props={{
                disabled: true,
                style: {
                  width: '242px',
                  color: 'rgb(209, 214, 219)',
                  backgroundColor: 'rgb(249, 250, 251)',
                },
              }}
            />
          </div>
          <div className='flex-1 flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label
                text={t('location_state')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </div>
            <Dropdown
              placeholder={t('location_state_placeholder')}
              headerWidth={242}
              label='name'
              items={provinceList}
              disabled={!company?.isoCountryCode}
              onItemClick={(item: any, index: number) =>
                handleLocationChange('stateOrRegion', item.isoCode)
              }
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
              contentDivHeight={300}
              dataTestId='locationStateOrProvince'
            />
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-row gap-2'>
            <Label
              text={t('location_geo')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Tooltip>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <span className='text-white font-medium text-sm'>
                  <Trans
                    i18nKey='location_geolocaiton_tip'
                    components={{
                      br: <br />,
                    }}
                  />
                </span>
              </TooltipContent>
            </Tooltip>
          </div>
          <div className='flex flex-row'>
            <div className='flex-1 flex-col'>
              <Label
                text={t('location_lat')}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
              <FormInput
                width='242px'
                placeholder={t('location_lat_placeholder')}
                onChange={(event: any) =>
                  handleLocationChange('latitude', event.target.value)
                }
                defaultValue={location.latitude}
                inputType={InputType.GEOLOCATION}
                validationType={ValidationType.GEO_LAT}
                errorLabel={t('location_wrong_lat')}
                dataTestId='locationLat'
              />
            </div>
            <div className='flex-1'>
              <Label
                text={t('location_lon')}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
              <FormInput
                width='242px'
                placeholder={t('location_lon_placeholder')}
                onChange={(event: any) =>
                  handleLocationChange('longitude', event.target.value)
                }
                defaultValue={location.longitude}
                inputType={InputType.GEOLOCATION}
                validationType={ValidationType.GEO_LON}
                errorLabel={t('location_wrong_lon')}
                dataTestId='locationLong'
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          <Label
            text={t('location_timezone')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <Dropdown
            placeholder={t('location_timezone_placeholder')}
            headerWidth='100%'
            contentDivWidth={500}
            items={timeZoneDropdownList}
            onItemClick={(item: any, index: number) =>
              handleLocationChange('timeZone', item.tzCode)
            }
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='hover:bg-grey2 bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            contentDivHeight={300}
            placement='top-start'
          />
        </div>

        <div className='h-[40px] mt-[8px] mb-[20px]'>
          <Button
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!onButtonValidation()}
            size={ButtonSize.SMALL_FULL}
            isSumbit
            dataTestId='locationSaveButton'
          />
        </div>
      </div>
    </Form>
  );
});
