/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState } from 'react';
import AcceptInviteModal from './AcceptInviteModal.component';
import { AlertModal } from './AlertModal.component';
import { AutoStartModal } from './AutoStartModal.component';
import { InfoModal } from './InfoModal.component';
import { LanguageModal } from './LanguageModal.component';
import { NotificationChargerModal } from './NotificationChargerModal.component';
import { NotificationSettingModal } from './NotificationSettingModal.component';
import { PortModal } from './PortModal.component';
import { SpinnerModal } from './SpinnerModal.component';
import { SuccessModal } from './SuccessModal.component';
import { WizardModal } from './WizardModal.component';

export const MODAL_TYPES = {
  ALERT_MODAL: 'ALERT_MODAL',
  INFO_MODAL: 'INFO_MODAL',
  PORT_MODAL: 'PORT_MODAL',
  AUTO_START_MODAL: 'AUTO_START_MODAL',
  LANG_MODAL: 'LANG_MODAL',
  SPINNER_MODAL: 'SPINNER_MODAL',
  NOTIFICATION_MODAL: 'NOTIFICATION_MODAL',
  NOTIFICATION_CHARGER_MODAL: 'NOTIFICATION_CHARGER_MODAL',
  WIZARD_MODAL: 'WIZARD_MODAL',
  ACCEPT_INVITE_MODAL: 'ACCEPT_INVITE_MODAL',
  SUCCESS_MODAL: 'SUCCESS_MODAL',
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.ALERT_MODAL]: AlertModal,
  [MODAL_TYPES.INFO_MODAL]: InfoModal,
  [MODAL_TYPES.PORT_MODAL]: PortModal,
  [MODAL_TYPES.AUTO_START_MODAL]: AutoStartModal,
  [MODAL_TYPES.LANG_MODAL]: LanguageModal,
  [MODAL_TYPES.SPINNER_MODAL]: SpinnerModal,
  [MODAL_TYPES.NOTIFICATION_MODAL]: NotificationSettingModal,
  [MODAL_TYPES.NOTIFICATION_CHARGER_MODAL]: NotificationChargerModal,
  [MODAL_TYPES.WIZARD_MODAL]: WizardModal,
  [MODAL_TYPES.ACCEPT_INVITE_MODAL]: AcceptInviteModal,
  [MODAL_TYPES.SUCCESS_MODAL]: SuccessModal,
};

type ContextType = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

const initalState: ContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal = (props: any) => {
  const { children } = props;
  const [store, setStore] = useState({ modalType: '', modalProps: {} });
  const { modalType, modalProps } = store;

  const showModal = (
    type: string = MODAL_TYPES.ALERT_MODAL,
    showModalProps: any = {},
  ) => {
    setStore({
      ...store,
      modalType: type,
      modalProps: showModalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: '',
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];

    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id='global-modal' {...modalProps} />;
  };

  const val = useMemo(
    () => ({ store, showModal, hideModal }),
    [hideModal, showModal, store],
  );
  return (
    <GlobalModalContext.Provider value={val}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
