import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { deploymentApi } from '../../services/deployment.api';

export const getMeasurementId = createSelector(
  [deploymentApi.endpoints.fetchEnvironment.select(undefined)],
  (endpoint) => endpoint.data?.googleAnalytics.measurementId || '',
);

export const selectDeploymentResult = createSelector(
  [deploymentApi.endpoints.fetchDeployment.select(undefined)],
  (endpoint) => endpoint.data || {},
);

export const getApiUrlPrefix = createSelector(
  [selectDeploymentResult],
  (data) => data.apiUrlPrefix || '',
);

export const getOldDashboardUrl = createSelector(
  [selectDeploymentResult],
  (data) => data.oldDashboardUrl || '',
);

export const getLegacyApiUrlPrefix = createSelector(
  [selectDeploymentResult],
  (data) => data.legacyApiUrlPrefix || '',
);

export const getSparkUrl = createSelector(
  [selectDeploymentResult],
  (data) => data.sparkUrl || '',
);

export const getSparkEnvironment = createSelector(
  [selectDeploymentResult],
  (data) => data.sparkEnvironment || '',
);

export const getIdleFeesEnabled = createSelector(
  [selectDeploymentResult],
  // Idle fee feature flag may be removed in future and hence we should show if the flag is missing
  // For more context https://chargelab.atlassian.net/browse/WEB-3144?focusedCommentId=46530
  (data) => (data.idleFeesEnabled !== undefined ? data.idleFeesEnabled : true),
);

/**
 * Based on Lenko and Simon's request, a temporary modification is being made.
 * The backend response now includes color(blue/green) information. This is causing errors
 *  in the printed QR code links. As a short-term solution, a fixed prod link
 * is being used, but this will result in errors for other environments.
 */
export const getWebAppUrlPrefix = createSelector(
  [selectDeploymentResult],
  (data) => 'https://charge.id', // data.webAppUrlPrefix || '', //
);
