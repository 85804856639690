import {
  getLastFifteenMin,
  getLastMonth,
  getLastOneDay,
  getLastOneHrs,
  getLastWeek,
} from '../../../utils/Date.Util';
import { DATE_RANGE } from '../../_ui/date-picker/Custom-Date-Picker.component';

export const getRangeByOption = (option: string) => {
  let date;
  switch (option) {
    case DATE_RANGE.LAST_FIFTEEN_MIN: {
      date = getLastFifteenMin();
      break;
    }

    case DATE_RANGE.LAST_ONE_HOUR: {
      date = getLastOneHrs();
      break;
    }

    case DATE_RANGE.LAST_ONE_DAY: {
      date = getLastOneDay();
      break;
    }

    case DATE_RANGE.LAST_WEEK: {
      date = getLastWeek();
      break;
    }
    case DATE_RANGE.LAST_MONTH: {
      date = getLastMonth();
      break;
    }
    default:
      date = null;
  }
  return date;
};
