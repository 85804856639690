import { format, getYear } from 'date-fns';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../hooks/useCompany';
import {
  useFetchStatisticsQuery,
  useLazyFetchTransactionReportCSVForPayoutQuery,
} from '../../../services/history.api';
import {
  PayoutReportType,
  useLazyExportPayoutReportPDFQuery,
  useLazyExportPayoutReportUserTransactionPDFQuery,
  useLazyFetchUsersEmailQuery,
} from '../../../services/payouts.api';
import { Statistics } from '../../../stores/types';
import { convertToLocaleCurrency } from '../../../utils/Currency.Util';
import { addMonths, convertToDate, formatDate } from '../../../utils/Date.Util';
import { displayEneryKwh } from '../../../utils/Number.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Dropdown,
  DropdownType,
  Grid,
  Label,
  LabelType,
} from '../../_ui';
import {
  EXPORT_BY_FILTER,
  EXPORT_TYPE,
  exportFilterDropdownData,
  exportTypeDropdownData,
} from '../utils';

const PayoutHistory = memo(() => {
  const { t } = useTranslation();
  const { companyId } = useCompany();

  // constants
  const today = new Date();

  // API
  const sortStats = (data: any) => {
    const sortedStats = [...data];
    return sortedStats.sort((a: any, b: any) =>
      new Date(a.date) < new Date(b.date) ? 1 : -1,
    );
  };
  const { stats, isStatsLoading } = useFetchStatisticsQuery(
    {
      toDate: formatDate(today, 'yyyy-MM'),
      currency: 'USD',
      aggregateLocations: true,
    },
    {
      selectFromResult: (endpoint) => ({
        stats: endpoint.data
          ? sortStats(endpoint.data).map((obj: Statistics, index: number) => {
              return {
                id: index,
                formatedDate: formatDate(convertToDate(obj.date), 'LLL yyyy'),
                ...obj,
              };
            })
          : [],
        isStatsLoading: endpoint.isFetching,
      }),
    },
  );
  const [triggerExportPayoutReportPDF] = useLazyExportPayoutReportPDFQuery();
  const [triggerExportPayoutReportCSV] =
    useLazyFetchTransactionReportCSVForPayoutQuery();
  const [triggerExportUserTransactionReportPDF] =
    useLazyExportPayoutReportUserTransactionPDFQuery();
  const [triggerUserEmailList, { userEmails, userEmailsFetching }] =
    useLazyFetchUsersEmailQuery({
      selectFromResult: (endPoint) => ({
        userEmails: [
          {
            id: '',
            label: 'payout_all_user_dropdown_title',
            selected: true,
            children: endPoint.data
              ? endPoint.data.map((email, index) => ({
                  id: index.toString(),
                  label: email,
                  selected: true,
                }))
              : [],
          },
        ],
        userEmailsFetching: endPoint.isFetching,
      }),
    });
  // states
  const [radioBtnSelectedId, setRadioBtnSelectedId] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportTypeDropdown, setExportTypeDropdown] = useState(
    exportTypeDropdownData,
  );
  const [exportByFilterDropdown, setExportByFilterDropdown] = useState(
    exportFilterDropdownData,
  );
  const [userEmailListDropdown, setUserEmailListDropdown] =
    useState<
      { id: string; label: string; selected: boolean; children: any[] }[]
    >(userEmails);

  const [showUserEmailDropdown, setShowUserEmailDropdown] = useState(false);

  useEffect(() => {
    setShowUserEmailDropdown(
      exportByFilterDropdown.filter((data) => data.selected === true)[0].id ===
        EXPORT_BY_FILTER.SESSIONS,
    );
  }, [exportByFilterDropdown]);

  useEffect(() => {
    if (userEmails && userEmails.length > 0) {
      setUserEmailListDropdown(userEmails);
    }
  }, [userEmailsFetching]);

  useEffect(() => {
    setRadioBtnSelectedId(-1);
  }, [companyId]);

  const columnsSettings = [
    { key: 'formatedDate', title: t('billing_cycle') },
    {
      key: 'energyDeliveredKWh',
      title: t('overivew_energy_used'),
      component: (row: any) => (
        <Label
          text={
            row.energyDeliveredKWh || row.energyDeliveredKWh === 0
              ? displayEneryKwh(row.energyDeliveredKWh, t('kwh'))
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isStatsLoading}
          skeletonHeight='h-5'
          className='w-50'
        />
      ),
    },
    {
      key: 'revenue',
      title: t('amount'),
      component: (row: any) => (
        <Label
          text={
            row.revenue || row.revenue === 0
              ? `${convertToLocaleCurrency(row.revenue, row.revenueCurrency)}`
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isStatsLoading}
          skeletonHeight='h-5'
          className='w-20'
        />
      ),
    },
  ];

  const handleTriggerUserEmailList = (radioBtnId: number) => {
    const statsObj = [...stats];
    const selectedStatsObj = statsObj[radioBtnId];
    triggerUserEmailList({
      year: getYear(convertToDate(selectedStatsObj.date)),
      month: format(convertToDate(selectedStatsObj.date), 'LLLL').toUpperCase(),
    });
  };

  const handleSelectedEmailId = () => {
    if (userEmailListDropdown && !userEmailListDropdown[0].selected) {
      return userEmailListDropdown[0].children
        .filter((emails) => emails.selected === true)
        .map((email) => email.label)
        .join(',');
    }
    return '';
  };

  const getReportType = (
    exportByFilter: EXPORT_BY_FILTER,
    exportReportType: EXPORT_TYPE,
  ) => {
    switch (exportByFilter) {
      case EXPORT_BY_FILTER.CHARGERS:
        return exportReportType === EXPORT_TYPE.PDF
          ? PayoutReportType.BY_CHARGER
          : 'chargers';
      case EXPORT_BY_FILTER.USERS:
        return exportReportType === EXPORT_TYPE.PDF
          ? PayoutReportType.BY_USER
          : 'users';
      case EXPORT_BY_FILTER.SESSIONS:
        return exportReportType === EXPORT_TYPE.PDF
          ? PayoutReportType.BY_SESSION
          : 'sessions';
      default:
        return '';
    }
  };

  const handleExportBtnClick = () => {
    const statsObj = [...stats];
    const selectedStatsObj = statsObj[radioBtnSelectedId];
    const exportReportType = exportTypeDropdown.filter(
      (item) => item.selected === true,
    )[0];
    const exportReportFilter = exportByFilterDropdown.filter(
      (item) => item.selected === true,
    )[0];
    if (exportReportType.id === EXPORT_TYPE.PDF) {
      if (exportReportFilter.id !== EXPORT_BY_FILTER.SESSIONS) {
        triggerExportPayoutReportPDF({
          year: getYear(convertToDate(selectedStatsObj.date)),
          month: format(
            convertToDate(selectedStatsObj.date),
            'LLLL',
          ).toUpperCase(),
          reportType: getReportType(exportReportFilter.id, exportReportType.id),
        });
      } else {
        triggerExportUserTransactionReportPDF({
          year: getYear(convertToDate(selectedStatsObj.date)),
          month: format(
            convertToDate(selectedStatsObj.date),
            'LLLL',
          ).toUpperCase(),
          reportType: getReportType(exportReportFilter.id, exportReportType.id),
          rfIds: handleSelectedEmailId(),
        });
      }
    } else if (exportReportType.id === EXPORT_TYPE.CSV) {
      triggerExportPayoutReportCSV({
        dateRange: [
          convertToDate(selectedStatsObj.date),
          addMonths(convertToDate(selectedStatsObj.date), 1),
        ],
        reportType: getReportType(exportReportFilter.id, exportReportType.id),
        usersEmail: handleSelectedEmailId(),
      });
    }
  };
  const handleExportTypeDropdownChange = (selectedItem: any, index: number) => {
    setExportTypeDropdown(
      exportTypeDropdown.map((item) => {
        return {
          ...item,
          selected: item.id === selectedItem.id,
        };
      }),
    );
  };
  const handleExportByFilterDropdownChange = (
    selectedItem: any,
    index: number,
  ) => {
    if (selectedItem.id === EXPORT_BY_FILTER.SESSIONS) {
      handleTriggerUserEmailList(radioBtnSelectedId);
    }
    setExportByFilterDropdown(
      exportByFilterDropdown.map((item) => {
        return {
          ...item,
          selected: item.id === selectedItem.id,
        };
      }),
    );
  };
  const handleAllUserDropdownChange = (
    updatedItems: any,
    clickedItem: any,
    index: number,
  ) => {
    setUserEmailListDropdown(updatedItems);
  };
  const renderButtons = () => {
    const isButtonEnabled = radioBtnSelectedId > -1;
    return (
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row gap-4'>
          <Dropdown
            placeholder={t('pdf')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth='max-content'
            items={exportTypeDropdown}
            onItemClick={handleExportTypeDropdownChange}
            translationOn
            dataTestId='export-type'
            disabled={!isButtonEnabled}
            headerClassName={
              isButtonEnabled
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            headerHighLightClassName={
              isButtonEnabled
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            labelTypeHighlighted={
              isButtonEnabled
                ? LabelType.DROPDOWN_HEADER
                : LabelType.BODY3_DISABLED
            }
            chevdownIconHighlightColor={
              isButtonEnabled ? ColorType.GREY6 : ColorType.GREY3
            }
          />
          <Dropdown
            placeholder={t('by_session')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth='max-content'
            items={exportByFilterDropdown}
            onItemClick={handleExportByFilterDropdownChange}
            translationOn
            dataTestId='export-by-filter'
            disabled={!isButtonEnabled}
            headerClassName={
              isButtonEnabled
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            headerHighLightClassName={
              isButtonEnabled
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            labelTypeHighlighted={
              isButtonEnabled
                ? LabelType.DROPDOWN_HEADER
                : LabelType.BODY3_DISABLED
            }
            chevdownIconHighlightColor={
              isButtonEnabled ? ColorType.GREY6 : ColorType.GREY3
            }
          />
          <Dropdown
            type={DropdownType.CHECKBOX_TREE}
            placeholder={
              userEmailListDropdown[0].selected
                ? t('payout_all_user_dropdown_title')
                : t('payout_all_user_dropdown_count', {
                    userCount: userEmailListDropdown[0].children.filter(
                      (email) => email.selected === true,
                    ).length,
                  })
            }
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth='max-content'
            items={userEmailListDropdown}
            onItemClick={handleAllUserDropdownChange}
            headerClassName={
              showUserEmailDropdown
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            headerHighLightClassName={
              showUserEmailDropdown
                ? 'hover:bg-grey2 bg-grey1 border-grey1 rounded'
                : 'bg-grey0'
            }
            labelTypeHighlighted={
              showUserEmailDropdown
                ? LabelType.DROPDOWN_HEADER
                : LabelType.BODY3_DISABLED
            }
            chevdownIconHighlightColor={
              showUserEmailDropdown ? ColorType.GREY6 : ColorType.GREY3
            }
            translationOn
            disabled={!showUserEmailDropdown}
            checkboxTreeParentPadding='pl-2 pr-2'
            checkboxTreeChildPadding='pl-2 pr-2'
            checkboxTreeRowClassName='divide-y divide-grey3'
            showFooter
            dataTestId='exportAllUsers'
          />
        </div>
        <Button
          size={ButtonSize.SMALL}
          label={t('export')}
          type={ButtonType.PRIMARY}
          onClick={handleExportBtnClick}
          dataTestId='export'
          disabled={!isButtonEnabled}
        />
      </div>
    );
  };
  const handleRadioBtnChange = (selectedIndex: number) => {
    setRadioBtnSelectedId(selectedIndex);
    if (
      exportByFilterDropdown.filter((data) => data.selected === true)[0].id ===
      EXPORT_BY_FILTER.SESSIONS
    ) {
      handleTriggerUserEmailList(selectedIndex);
    }
  };
  return (
    <Card>
      <div className='pb-4'>
        <Label
          text={t('payter_history_title')}
          type={LabelType.H4}
          color={ColorType.BLACK}
          dataTestId='payouts-history'
          className='mb-2'
        />
        <Label
          text={t('payter_history_desc')}
          type={LabelType.BODY3}
          color={ColorType.GREY6}
          className='mb-4'
        />
        {renderButtons()}
      </div>
      <Grid
        pageIndex={currentPage}
        loadPage={(page) => setCurrentPage(page)}
        local
        columns={columnsSettings}
        data={stats}
        primaryKey='id'
        radioBtn
        onRadioBtnChange={handleRadioBtnChange}
        totalPage={Math.ceil(stats.length / 12)}
        rowCount={12}
        isLoading={isStatsLoading}
        dataTestId='payout-data'
      />
    </Card>
  );
});

export default PayoutHistory;
