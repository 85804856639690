import { memo } from 'react';
import SvgColor from 'react-svg-color';
import { IconDimension } from './shared/interface/IconDimension.interface';
import { IconSize } from '../../constant/IconSize.constant';

export interface CircleIconButtonProps {
  src?: string;
  size?: IconDimension;
  color?: string;
  className?: string;
  onClick?: Function;
  style?: any;
  disabled?: boolean;
  dataTestId?: string;
}

export const CircleIconButton = memo(
  ({
    src,
    size = IconSize.SIZE_24x24,
    color,
    className,
    onClick,
    style = {},
    disabled = false,
    dataTestId = '',
  }: CircleIconButtonProps) => {
    const { width, height } = size;
    const handleClick = () => {
      !disabled && onClick && onClick();
    };

    return (
      <div
        className={`rounded-full flex items-center ${
          !disabled && 'cursor-pointer hover:bg-grey2'
        } ${className}`}
        onClick={handleClick}
        style={{ ...style, width: `${width}px`, height: `${height}px` }}
        data-testid={dataTestId}
      >
        <SvgColor svg={src} width={width} height={height} colors={[color]} />
      </div>
    );
  },
);
