// React
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// Hooks
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formatInTimeZone } from 'date-fns-tz';
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui/modal/GlobalModal.component';

// API
import {
  UpdateChargerInformation,
  useDeleteChargerMutation,
  useFetchChargerDetailQuery,
  useFetchWifiPasswordQuery,
  useIsValidChargerMutation,
  useRebootChargerMutation,
  useUpdateChargerMutation,
  useValidateOCPPMutation,
} from '../../services/charger.api';
import {
  useLazyFetchSessionsQuery,
  useSessionStartChargingMutation,
  useSessionStopChargingMutation,
} from '../../services/session.api';

// Selectors
import { getCurrentTheme } from '../../stores/selectors/theme.selector';

// Components
import {
  Button,
  ButtonType,
  Card,
  CheckBox,
  CheckBoxData,
  ColorType,
  DateTimePicker,
  Divider,
  Dropdown,
  FormInput,
  getHexColorByType,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  Menu,
  MenuItem,
  Pill,
  RadioGroup,
  Skeleton,
  Snackbar,
  Switch,
} from '../_ui';
import { ChargerStatus } from './ChargerStatus.component';

// Assets
import { ButtonSize } from '../_ui/Button.component';

// Utils
import {
  convertToDate,
  formatDate,
  getDifferenceInMinutes,
  getISO8601TimezoneOffSetFromTimeZone,
  getLocale,
  parseDate,
} from '../../utils/Date.Util';
import { validateEmail } from '../_ui/modal/utils';
import {
  getConnectorIcon,
  getRowStatus,
  getValidRawStatus,
  OCPP_ID_LENGTH,
} from './utils';

// CSS

// Constants
import {
  Bolt,
  ErrorWarningtraiangle,
  hidePassword,
  Info,
  maintenance as maintenanceIcon,
  More,
  ocppStatusIcon,
  Play,
  Reset,
  showPassword,
  Stop,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import {
  CHARGER_STATUS,
  ILLEGAL_KEYWORDS,
  OCPP_STATUS,
  TEXT,
} from '../../constant/Text.constant';
import { URL_SEARCH_PARAM_KEY } from '../../constant/UrlSearchParams.constant';
import { useAuth, USER_ROLE } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useLazyFetchCompanyDetailQuery } from '../../services/company.api';
import { useFetchLocationsQuery } from '../../services/location.api';
import {
  useClearProfileMutation,
  useLazyGetCircuitsQuery,
} from '../../services/pm.api';
import { useLazyFetchPricingModelsQuery } from '../../services/pricing.api';
import {
  useFetchChargerStaticDataQuery,
  useFetchModelsQuery,
} from '../../services/provision';
import { i18n } from '../../services/translation/i18n';
import { SCOPE_TYPE } from '../../services/utils';
import { getWebAppUrlPrefix } from '../../stores/selectors/deployment.selector';
import { Charger } from '../../stores/types';
import {
  Connectivity,
  OCCPValidationRequest,
} from '../../stores/types/chargers.interface';
import { InternalErrorCode } from '../../stores/types/error.interface';
import { Price } from '../../stores/types/price.interface';
import { ChargerPort } from '../../stores/types/sessions.interface';
import { addSearchParam } from '../../utils/SearchParam.Utils';
import { CustomInputType } from '../_ui/date-picker/Date-Picker.component';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { handleTimeCalculation } from '../Session/utils';
import ChargerHistory from './charger-history/ChargerHistory.component';
import ChargerTerminal from './charger-terminal/ChargerTerminal.component';
import ConnectorRowItem from './ConnectorRowItem.component';
import {
  ChargerCompany,
  ChargerConfirmation,
  ChargerSettings,
} from './move-charger';
import { ViewQRCode } from './ViewQRCode.component';

export const ChargerDetail = () => {
  const SIM_CARD_NUMBER_LEN = 22;
  const { t } = useTranslation();
  const auth = useAuth();
  const { showModal } = useGlobalModalContext();
  const { chargerId: id } = useParams();
  const { navigate } = useNavigateWithSearchParam();
  const { userScope } = useCompany();

  const [editEnabled, setEditEnabled] = useState(false);
  const { switchCompany, companies, isMultiViewActive } = useCompany();

  const { SIMULATED } = TEXT;
  const theme = useSelector(getCurrentTheme);
  const webAppUrlPrefix = useSelector(getWebAppUrlPrefix);

  const [updateCharger, updateChargerQueryResult] = useUpdateChargerMutation();
  const [triggerOcppValidate, validateResponse] = useValidateOCPPMutation();
  const { data: WifiPassword } = useFetchWifiPasswordQuery({
    id: id!,
    scope: userScope!,
  });
  const [loadSkeletonAfterEdit, setLoadSkeletonAfterEdit] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { allowPayterView } = useAllowedFeatures();

  const {
    data: chargerData,
    isFetching: isChargerLoading,
    isLoading,
    error,
  } = useFetchChargerDetailQuery(
    {
      id: id!,
      scope: userScope!,
    },
    { pollingInterval: editEnabled ? 0 : 15000 },
  );

  useEffect(() => {
    const errorObj = error as { originalStatus: number };
    if (errorObj && errorObj.originalStatus === 404) {
      navigate(RoutePath.NOT_FOUND, { replace: true });
    }
  }, [error]);

  const charger = useMemo(() => {
    return chargerData || ({} as Charger);
  }, [chargerData]);
  const companyId = useMemo(() => {
    return charger?.ownerId;
  }, [charger]);
  const [triggerGetCircuits, { data: circuitsData }] =
    useLazyGetCircuitsQuery();

  useEffect(() => {
    if (charger?.ownerId) {
      triggerGetCircuits({
        scope: SCOPE_TYPE.COMPANY,
        companyId: charger.ownerId,
      });
    }
  }, [charger?.ownerId]);

  const [triggerValidAutoStart, autoStartQueryResult] =
    useIsValidChargerMutation();

  const filteredCircuits = circuitsData?.entities.filter(
    (circuit) => circuit.locationId === chargerData?.location?.id,
  );

  const [triggerFetchSessions, { data: sessionData }] =
    useLazyFetchSessionsQuery();
  const [triggerFetchingPricingModels, { data: pricing }] =
    useLazyFetchPricingModelsQuery();
  useEffect(() => {
    if (companyId) {
      triggerFetchSessions({
        chargerIds: id ? [id] : [],
        sort: 'desc',
        scope: userScope!,
        companyId,
      });
      triggerFetchingPricingModels({ companyId: companyId, scope: userScope! });
    }
  }, [companyId, userScope]);

  const chargerSessions = useMemo(() => {
    return sessionData
      ? sessionData.entities.filter(
          (session) => !session.stopTime && !session.completeTime,
        )
      : [];
  }, [sessionData]);

  const { manufacturerModelData } = useFetchModelsQuery(undefined, {
    selectFromResult: (endpoint) => ({
      manufacturerModelData: endpoint.data ? endpoint.data : [],
    }),
  });

  const { locations } = useFetchLocationsQuery(
    { scope: userScope!, companyId: charger.ownerId },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint.data ? endPoint.data.entities : [],
      }),
    },
  );
  const { networkProvider } = useFetchChargerStaticDataQuery(undefined, {
    selectFromResult: (endpoint) => ({
      networkProvider: endpoint.data ? endpoint.data.simCardProviders : [],
    }),
  });

  const [rebootCharger] = useRebootChargerMutation();
  const [sessionStart] = useSessionStartChargingMutation();
  const [sessionStop] = useSessionStopChargingMutation();
  const [triggerFetchCompanyDetail, { data: company }] =
    useLazyFetchCompanyDetailQuery();

  const [updateChargerDetail, setUpdateChargerDetail] = useState(
    {} as UpdateChargerInformation,
  );

  const [manufacturer, setManufacturer] = useState('');

  const locationDropdown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      label: location.name,
      selected: location.id === charger?.location?.id,
    }));
  }, [locations, charger?.location?.id]);

  const networkProviderDropdown = useMemo(() => {
    return networkProvider.map((network: any, index: number) => ({
      id: index,
      label: network,
      selected: network === charger?.deploymentInfo?.simCardProvider,
    }));
  }, [networkProvider, charger?.deploymentInfo?.simCardProvider]);

  const pricingDropdown = useMemo(() => {
    return pricing?.map((pricingObj: Price) => ({
      id: pricingObj.id,
      label: pricingObj.displayName,
      selected: pricingObj.id === charger?.priceModelId,
    }));
  }, [pricing, charger?.priceModelId]);

  const manufacturerDropdown = useMemo(() => {
    return manufacturerModelData.reduce(
      (pre: any, curr: any, index: number) => {
        const exist = pre.find((obj: any) => {
          return obj.label === curr.vendor;
        });
        if (!exist) {
          pre.push({
            id: curr.vendor,
            label: curr.vendor,
            selected: curr.vendor === charger?.vendor,
          });
        }
        return pre;
      },
      [],
    );
  }, [manufacturerModelData, charger?.vendor]);

  const modelDropdown = useMemo(() => {
    return _.get(
      _.mapValues(
        _.groupBy(
          manufacturerModelData.map((model: any) => ({
            id: model.id,
            label: model.model,
            selected: model.model === charger?.model,
            manufacturer: model.vendor,
          })),
          (element) => element.manufacturer,
        ),
        (list) => list.map((obj) => _.omit(obj, 'manufacturer')),
      ),
      manufacturer,
    );
  }, [manufacturerModelData, manufacturer, manufacturerDropdown]);

  useEffect(() => {
    if (!manufacturer) {
      setManufacturer(
        manufacturerDropdown.find((item: any) => item.selected === true)?.label,
      );
    }
  }, [manufacturer, manufacturerDropdown]);

  useEffect(() => {
    if (charger) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        id: id || '',
        displayId: charger?.name || '',
        chargerModelId: charger.chargerModelId,
        locationId: charger?.location?.id || '',
        directions: charger?.directions,
        parkingSpot: charger?.parkingSpot,
        associatedBuildingUnit: charger?.associatedBuildingUnit,
        internalNote: charger?.internalNote,
        autoStartConfig: charger?.autoStartConfig,
        hiddenOnMap: charger?.hiddenOnMap,
        priceModelId: charger?.priceModelId,
        maintenanceFlag: charger?.maintenanceFlag,
        ocppId: charger?.ocppId,
        deploymentInfo: {
          deployedOverride: charger.deploymentInfo?.deployed,
          deployedDateOverride: charger.deploymentInfo?.deployedDate,
          installationDate: charger.deploymentInfo?.installationDate,
          installationPerson: charger.deploymentInfo?.installationPerson,
          installationEmail: charger.deploymentInfo?.installerContactEmail,
          connectivityType: charger?.deploymentInfo?.connectivityType
            ? charger?.deploymentInfo?.connectivityType
            : Connectivity.SIM_CARD,
          whitelabeledSimCard: charger.deploymentInfo?.whitelabeledSimCard,
          simCardType: charger.deploymentInfo?.simCardType,
          simCardProvider: charger.deploymentInfo?.simCardProvider,
          simCardId: charger.deploymentInfo?.simCardId,
          wifiName: charger.deploymentInfo?.wifiName,
          wifiPassword: WifiPassword?.wifiPassword,
        },
      });
    }
    if (!isChargerLoading) {
      setLoadSkeletonAfterEdit(false);
    }
  }, [charger, id, editEnabled, WifiPassword, isChargerLoading]);

  useEffect(() => {
    if (companyId) {
      triggerFetchCompanyDetail({ id: companyId, scope: userScope! });
    }
  }, [companyId, auth]);

  const handleInputChange = (key: string, value: any) => {
    setUpdateChargerDetail({ ...updateChargerDetail, [key]: value });
  };

  const renderImage = () => {
    if (
      (isChargerLoading && isLoading) ||
      (isChargerLoading && loadSkeletonAfterEdit)
    ) {
      return <Skeleton width='w-40' height='h-40' />;
    }
    if (!charger?.imageUrl) {
      return null;
    }
    return (
      <div className='flex self-center'>
        <img src={charger?.imageUrl} width={160} height={160} alt='' />
      </div>
    );
  };

  const getIsSinglePort = () => {
    return (charger?.ports?.length || 0) <= 1;
  };

  const handleRefresh = () => {
    window.location.replace(
      `${addSearchParam(
        `../${RoutePath.CHARGERS}/${id}`,
        URL_SEARCH_PARAM_KEY.COMPANY_ID,
        isMultiViewActive ? 'ALL' : companyId || '',
      )}`,
    );
  };

  const showSpinnerAlert = () => {
    showModal(MODAL_TYPES.SPINNER_MODAL, {
      delay: 5000,
      onClose: handleRefresh,
    });
  };

  const onStartClick = (portId?: string) => {
    portId &&
      sessionStart({ portId, scope: userScope!, companyId: charger.ownerId! });
    showSpinnerAlert();
  };

  const onStopClick = (portId?: string) => {
    const sessionId = chargerSessions?.find(
      (session) => session.port.id === portId,
    )?.id;

    sessionId &&
      sessionStop({
        sessionId,
        scope: userScope!,
        companyId: charger.ownerId!,
      });
    showSpinnerAlert();
  };

  const onRebootClick = (chargerId?: string) => {
    if (chargerId) {
      rebootCharger({
        id: chargerId,
        scope: userScope!,
        companyId: charger.ownerId!,
      });
      showSpinnerAlert();
    }
  };

  const handleStartClick = (portId: string, portPhysicalLocation: string) => {
    const title = t('charger_start_charging');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title,
      message: getIsSinglePort() ? (
        t('charger_start_msg')
      ) : (
        <Trans
          i18nKey='multiple_port_charger_start_msg'
          values={{
            physicalLocation: t(`${portPhysicalLocation.toLowerCase()}`),
          }}
        />
      ),
      icon: Info,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('start'),
          type: ButtonType.PRIMARY,
          style: { backgroundColor: theme.navigationSelectedColor },
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onStartClick(portId),
        },
      ],
    });
  };
  const handleStopClick = (portId: string, portPhysicalLocation: string) => {
    const title = t('charger_stop_charging');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title,
      message: getIsSinglePort() ? (
        t('charger_stop_msg')
      ) : (
        <Trans
          i18nKey='multiple_port_charger_stop_msg'
          values={{
            physicalLocation: t(`${portPhysicalLocation.toLowerCase()}`),
          }}
        />
      ),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('stop'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onStopClick(portId),
        },
      ],
    });
  };
  const handleRebootClick = () => {
    const title = t('charer_reboot_charger');
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title,
      message: t('charger_reboot_msg'),
      icon: Info,
      iconColor: ColorType.WARNING1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('reset'),
          type: ButtonType.PRIMARY,
          style: { backgroundColor: theme.navigationSelectedColor },
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: () => onRebootClick(charger?.id),
        },
      ],
    });
  };

  const handleEmailValidation = (val: any) => {
    if (val) {
      return validateEmail(val);
    }
    return true;
  };
  const handleOCPPValidation = (val: string) => {
    if (val) {
      const req: OCCPValidationRequest = {
        id: charger.id,
        chargerModelId: charger.chargerModelId,
        ocppId: val,
        priceModelId: charger.priceModelId,
        displayId: charger?.name,
        locationId: charger?.location?.id,
      };
      triggerOcppValidate({ request: req, scope: userScope! });
    }
  };
  const renderOCPPErrorLabel = () => {
    if (validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0003) {
        return t('invalid_ocpp_id');
      }
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0034) {
        return t('duplicate_ocpp_id');
      }
    }
  };

  const handleMaintenanace = (value: boolean) => {
    setUpdateChargerDetail({
      ...updateChargerDetail,
      maintenanceFlag: value,
    });
  };

  const handleValidateId = (value: any) => {
    const regex = /^([a-zA-Z0-9*-_|@])+$/;
    return (
      regex.test(value) && ILLEGAL_KEYWORDS.indexOf(value) === NUMBER.MINUS_ONE
    );
  };

  const calculateMin = (date: string) => {
    return getDifferenceInMinutes(new Date(), convertToDate(date));
  };

  const renderOcppStatus = (port: ChargerPort) => {
    if (!port?.rawChargerStatus?.rawChargerStatus) return null;
    const mins = calculateMin(
      port.rawChargerStatus?.rawChargerStatusChangedTime,
    );

    const rawStatus = getValidRawStatus(
      port.rawChargerStatus?.rawChargerStatus,
    );
    let text = t(rawStatus?.toLocaleLowerCase());
    if (rawStatus.toLowerCase() === OCPP_STATUS.SUSPENDED_EV) {
      text = 'SuspendedEV';
    } else if (rawStatus.toLowerCase() === OCPP_STATUS.SUSPENDED_EVSE) {
      text = 'SuspendedEVSE';
    }

    return (
      <ConnectorRowItem
        icon={ocppStatusIcon}
        leftText={t('ocpp_status')}
        rightText={`${text} (~${handleTimeCalculation(mins, true)})`}
      />
    );
  };

  const connectorStatus = (port: ChargerPort) => {
    // Single port charger that have no active transaction should return charger platform status for displaying in status pill(COR-183)
    if (
      charger?.ports?.length === 1 &&
      charger.status !== OCPP_STATUS.CHARGING
    ) {
      return charger.status;
    }
    // Multiport charger : If port have active transaction should return OCPP status else should return port status
    return port.status.toLowerCase() === OCPP_STATUS.CHARGING
      ? getValidRawStatus(
          port?.rawChargerStatus?.rawChargerStatus?.toLowerCase(),
        )
      : port.status.toLowerCase();
  };

  const renderConnector = () => {
    return (
      <div className='flex flex-col gap-4  w-full max-w-[265px]'>
        {((isChargerLoading && isLoading) ||
          (isChargerLoading && loadSkeletonAfterEdit)) && (
          <Skeleton width='w-52' height='h-5' />
        )}
        {(!isChargerLoading || !isLoading) &&
          (!isChargerLoading || !loadSkeletonAfterEdit) &&
          charger?.ports?.map((port) => {
            return (
              <div
                key={port.id}
                className='flex flex-col gap-2.5 bg-grey0 p-2 rounded-lg'
              >
                <div className='flex flex-row items-center justify-between'>
                  {charger?.ports?.length > 1 && ( // BF-677 hide port position for single port
                    <div className='w-[3.75rem]'>
                      <Label
                        text={t(`${port.physicalLocation.toLowerCase()}`)}
                        type={LabelType.LABEL_S}
                        color={ColorType.BLACK}
                      />
                    </div>
                  )}
                  {!editEnabled && (
                    <div className='flex flex-row items-center'>
                      <ChargerStatus
                        status={connectorStatus(port)}
                        timeStamp={calculateMin(
                          port.rawChargerStatus?.rawChargerStatusChangedTime,
                        )}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <ConnectorRowItem
                    leftText={t('session_connector')}
                    rightText={port.connectorTypes[0]}
                    renderIcon={port.connectorTypes.map((type) => (
                      <Icon
                        key={type}
                        size={IconSize.SIZE_16x16}
                        src={getConnectorIcon(type)}
                        color={ColorType.BLACK}
                        className='mr-1'
                      />
                    ))}
                  />
                  <ConnectorRowItem
                    leftText={t('speed')}
                    rightText={`${port.maxWattage} ${t('kw_maximum')}`}
                    icon={Bolt}
                  />

                  {renderOcppStatus(port)}
                </div>
                {!editEnabled && (
                  <div className='flex flex-row items-center h-8 justify-between gap-2'>
                    <Button
                      label={t('start')}
                      type={ButtonType.OUTLINE}
                      disabled={
                        !(
                          port.status.toLowerCase() === CHARGER_STATUS.AVAILABLE
                        )
                      }
                      icon={Play}
                      iconColor={ColorType.POSITIVE1}
                      size={ButtonSize.MINI}
                      onClick={() =>
                        handleStartClick(port.id, port.physicalLocation)
                      }
                      buttonStyle={{ borderWidth: 1, width: '100%' }}
                      labelContainerCss='label-flex-g-0'
                    />
                    <Button
                      label={t('stop')}
                      type={ButtonType.OUTLINE}
                      disabled={
                        !(port.status.toLowerCase() === OCPP_STATUS.CHARGING)
                      }
                      icon={Stop}
                      iconColor={ColorType.NEGATIVE1}
                      size={ButtonSize.MINI}
                      onClick={() =>
                        handleStopClick(port.id, port.physicalLocation)
                      }
                      buttonStyle={{ borderWidth: 1, width: '100%' }}
                      labelContainerCss='label-flex-g-0'
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const displayToast = () => {
    Snackbar.show({
      message: (
        <>
          <Label
            text={i18n.t('successfully_configured_auto_start_warning', {
              driverEmail: updateChargerDetail?.autoStartConfig?.email,
            })}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
          />
          <Label
            text={i18n.t('valid_payment_warning')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
            className='pt-[24px]'
          />
        </>
      ),
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: Info,
      height: 'h-auto',
      iconColor: getHexColorByType(ColorType.WARNING2),
      component: 'auto-start',
    });
  };

  useEffect(() => {
    if (autoStartQueryResult.isError && !autoStartQueryResult.isLoading) {
      displayToast();
    }
  }, [autoStartQueryResult]);

  const companyName = useMemo(() => {
    return companies?.find((c) => c.id === charger?.ownerId)?.name || '';
  }, [charger?.ownerId, companies]);
  const renderCompany = () => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('company')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <div className='flex flex-row gap-[8px]'>
          <Label
            text={companyName}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
        </div>
      </div>
    );
  };
  const renderChargerStatus = () => {
    const { timeStamp, status, ports } = getRowStatus(charger);
    return (
      charger?.ports?.length > 1 && (
        <div className='flex flex-row'>
          <Label
            text={t('overview_charger_status')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <div className='flex flex-row gap-[8px]'>
            {charger && (
              <ChargerStatus
                status={status}
                ports={ports}
                timeStamp={timeStamp}
                isLoading={
                  (isChargerLoading && isLoading) ||
                  (isChargerLoading && loadSkeletonAfterEdit)
                }
              />
            )}
            {updateChargerDetail.maintenanceFlag && (
              <Icon src={maintenanceIcon} size={IconSize.SIZE_20x20} />
            )}
          </div>
        </div>
      )
    );
  };

  const renderDisplayId = () => (
    <div className='flex gap-4 flex-col pt-4'>
      <div className='flex flex-row items-center'>
        <Label
          text={t('chargers_display_id')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />

        <FormInput
          defaultValue={charger?.name}
          onChange={(event: any) =>
            handleInputChange('displayId', event.target.value)
          }
          width='220px'
          height='36px'
          onValidation={handleValidateId}
          showErrorIcon
        />
      </div>
    </div>
  );

  const renderMakeAndModel = () => {
    return editEnabled ? (
      <div className='flex flex-col gap-1'>
        <div className='flex flex-row items-center'>
          <Label
            text={t('chargers_manufacturer')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!charger?.isPowerManaged && (
            <Dropdown
              headerWidth={220}
              items={manufacturerDropdown}
              onItemClick={(selectedItem: any, index: number) => {
                setManufacturer(selectedItem.label);
                handleInputChange('chargerModelId', '');
              }}
              labelType={LabelType.BODY3_G4}
              contentDivWidth={220}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
              headerClassName='bg-grey1 border-grey3 rounded pr-2'
              headerHeight='36px'
            />
          )}
          {charger?.isPowerManaged && (
            <Label
              text={manufacturer}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('chargers_model')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!charger?.isPowerManaged && (
            <Dropdown
              headerWidth={220}
              items={modelDropdown || []}
              disabled={!modelDropdown}
              onItemClick={(selectedItem: any, index: number) => {
                handleInputChange('chargerModelId', selectedItem.id);
              }}
              labelType={LabelType.BODY3_G4}
              contentDivWidth={220}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
              headerClassName='bg-grey1 border-grey3 rounded pr-2'
              headerHeight='36px'
            />
          )}
          {charger?.isPowerManaged && (
            <Label
              text={charger.model}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          )}
        </div>
      </div>
    ) : (
      <div className='flex flex-row items-center'>
        <Label
          text={t('charger_make')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={charger ? `${charger?.vendor} / ${charger?.model}` : ''}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={
            (isChargerLoading && isLoading) ||
            (isChargerLoading && loadSkeletonAfterEdit)
          }
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };
  const renderPortStatus = () => {
    return (
      <div className='flex flex-row'>
        <Label
          text={
            charger?.ports?.length > 1 ? t('port_status') : t('charger_status')
          }
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        {renderConnector()}
      </div>
    );
  };

  const renderMaintenanceMode = () => {
    return (
      <div className='flex flex-row items-center'>
        <div className='flex flex-row gap-[4px] min-w-[148px] max-w-[148px] mr-2.5 items-center'>
          <Label
            text={t('maintenance_flag')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <span
                className='text-sm text-white font-medium'
                style={{ fontFamily: 'Inter' }}
              >
                <Trans
                  i18nKey='maintenance_flag_tip'
                  components={{
                    br: <br />,
                  }}
                />
              </span>
            </TooltipContent>
          </Tooltip>
        </div>
        {editEnabled ? (
          <Switch
            value={updateChargerDetail.maintenanceFlag}
            enableLabel={t('under_maintenance')}
            disableLabel={t('not_under_maintenance')}
            onChange={(event: any) => handleMaintenanace(event)}
            dataTestId='charger-maintenance-switch'
          />
        ) : (
          <Label
            text={t(
              updateChargerDetail.maintenanceFlag
                ? 'under_maintenance'
                : 'not_under_maintenance',
            )}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={
              (isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)
            }
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        )}
      </div>
    );
  };

  const renderLocation = () => {
    return (
      <div className='flex flex-row items-center'>
        <Label
          text={t('charger_location')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        {!editEnabled && (
          <Label
            text={charger?.location?.name || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={
              (isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)
            }
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        )}
        {editEnabled && charger?.isPowerManaged && (
          <Label
            text={charger?.location?.name || ''}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        )}
        {editEnabled && !charger?.isPowerManaged && (
          <Dropdown
            placeholder={t('chargers_location')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth={220}
            items={locationDropdown}
            onItemClick={(selectedItem: any, index: number) => {
              handleInputChange('locationId', selectedItem.id);
            }}
            labelType={LabelType.BODY3_G4}
            contentDivWidth={220}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
            headerClassName='bg-grey1 border-grey3 rounded pr-2'
            headerHeight='36px'
          />
        )}
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('charger_address')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Label
          text={
            charger
              ? `${charger?.location?.streetAddress}, ${charger?.location?.city},
                  ${charger?.location?.stateOrRegion}, ${charger?.location?.isoCountryCode}, ${charger?.location?.zipOrPostalCode}`
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          className='w-52'
          isLoading={
            (isChargerLoading && isLoading) ||
            (isChargerLoading && loadSkeletonAfterEdit)
          }
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderLastUsed = () => {
    return (
      <div className='flex flex-row items-center'>
        <Label
          text={t('charger_last_used')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5 whitespace-nowrap'
        />
        <Label
          text={
            charger?.lastUsed && charger.location?.timeZone
              ? formatInTimeZone(
                  charger?.lastUsed,
                  charger.location.timeZone,
                  'LLL d, h:mm a',
                  getLocale(),
                ) + getISO8601TimezoneOffSetFromTimeZone(charger.location.timeZone)
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={
            (isChargerLoading && isLoading) ||
            (isChargerLoading && loadSkeletonAfterEdit)
          }
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderOpccId = () => {
    return (
      <div className='flex flex-row'>
        <div className='flex flex-row gap-[4px] min-w-[148px] max-w-[148px] mr-2.5 items-center'>
          <Label
            text={t('chargers_ocpp_id')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={t('charger_ocpp_tip')}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        </div>
        {!editEnabled && (
          <Tooltip>
            <TooltipTrigger>
              <Label
                text={charger?.ocppId || ''}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={
                  (isChargerLoading && isLoading) ||
                  (isChargerLoading && loadSkeletonAfterEdit)
                }
                skeletonWidth='w-52'
                skeletonHeight='h-5'
                className='truncate max-w-[265px]'
              />
            </TooltipTrigger>
            <TooltipContent style={{ maxWidth: 'fit-content' }}>
              <Label
                text={charger?.ocppId || ''}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        )}
        {editEnabled && (
          <FormInput
            defaultValue={charger?.ocppId}
            onChange={(event: any) => {
              handleOCPPValidation(event.target.value);
              handleInputChange('ocppId', event.target.value);
            }}
            width='220px'
            height='36px'
            maxLen={String(OCPP_ID_LENGTH)}
            inputType={InputType.TEXT}
            validaitonQueryResult={validateResponse.isError}
            showErrorIcon
            errorLabel={renderOCPPErrorLabel()}
            onValidation={handleValidateId}
          />
        )}
      </div>
    );
  };

  const renderSN = () => {
    return (
      <div className='flex flex-row items-center'>
        <Label
          text={t('charger_serial_number')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <Tooltip>
          <TooltipTrigger>
            <Label
              text={charger?.serialNumber || ''}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
              className='truncate max-w-[265px]'
            />
          </TooltipTrigger>
          <TooltipContent style={{ maxWidth: 'fit-content' }}>
            <Label
              text={charger?.serialNumber || ''}
              type={LabelType.BODY3}
              color={ColorType.WHITE}
            />
          </TooltipContent>
        </Tooltip>
      </div>
    );
  };

  const renderChargerAction = () => {
    return (
      <div className='flex flex-row items-center'>
        <Label
          text={t('charger_actions')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        <div className='flex flex-row h-8'>
          <Button
            label={t('reboot')}
            type={ButtonType.OUTLINE}
            icon={Reset}
            size={ButtonSize.MINI}
            onClick={handleRebootClick}
          />
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className='flex flex-col mt-4 pb-3'>
        {editEnabled && renderDisplayId()}
        {renderImage()}
        <div className='flex gap-4 flex-col pt-4'>
          {renderMakeAndModel()}
          {renderPortStatus()}
          {!editEnabled && renderChargerStatus()}
          {renderMaintenanceMode()}
          {renderCompany()}
          {renderLocation()}
          {renderAddress()}
          {renderLastUsed()}
          {renderOpccId()}
          {renderSN()}
          {!editEnabled && renderChargerAction()}
        </div>
      </div>
    );
  };

  const renderAdditionalInfo = () => {
    return (
      <div
        className={`flex ${editEnabled ? 'gap-1' : 'gap-4'} flex-col pt-4 pb-3`}
      >
        <div className='flex flex-row'>
          <Label
            text={t('charger_additional_info')}
            type={LabelType.LABEL_M}
            color={ColorType.GREY5}
            style={{ minHeight: '40px' }}
          />
        </div>
        <div className='flex flex-row'>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5 items-center'>
            <Label
              text={t('charger_directions')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
            <Tooltip>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <Label
                  text={t('charger_tip')}
                  type={LabelType.BODY3}
                  color={ColorType.WHITE}
                />
              </TooltipContent>
            </Tooltip>
          </div>
          {!editEnabled && (
            <Label
              text={charger?.directions}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className='text-ellipsis overflow-hidden text-justify'
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={charger?.directions}
              onChange={(event: any) =>
                handleInputChange('directions', event.target.value)
              }
              width='220px'
              height='36px'
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_parking_space')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />

          {!editEnabled && (
            <Label
              text={charger?.parkingSpot}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className='text-ellipsis overflow-hidden text-justify'
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={charger?.parkingSpot}
              onChange={(event: any) =>
                handleInputChange('parkingSpot', event.target.value)
              }
              width='220px'
              height='36px'
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_unit')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={charger?.associatedBuildingUnit}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className={`${
                !isChargerLoading ? 'w-[190px]' : ''
              } text-ellipsis overflow-hidden text-justify`}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={charger?.associatedBuildingUnit}
              onChange={(event: any) =>
                handleInputChange('associatedBuildingUnit', event.target.value)
              }
              width='220px'
              height='36px'
            />
          )}
        </div>
        <div className='flex flex-row'>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5 items-center'>
            <Label
              text={t('charger_internal_note')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
          </div>
          {!editEnabled && (
            <Label
              text={charger?.internalNote}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className={`${
                !isChargerLoading ? 'w-[190px]' : ''
              } text-ellipsis overflow-hidden text-justify`}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={charger?.internalNote}
              onChange={(event: any) =>
                handleInputChange('internalNote', event.target.value)
              }
              width='220px'
              height='36px'
            />
          )}
        </div>
      </div>
    );
  };

  const handleAutoStart = (event: any) => {
    if (event[0]?.selected) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        autoStartConfig: {
          type: SIMULATED,
        },
      });
    }
    if (event[1]?.selected) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        autoStartConfig: {
          type: SIMULATED,
          email: '',
        },
      });
    }
  };

  const handleAssociatedEmail = (value: any) => {
    setUpdateChargerDetail({
      ...updateChargerDetail,
      autoStartConfig: {
        type: SIMULATED,
        email: value,
      },
    });
  };

  const autoStartTypeData = () => {
    const isCompany = updateChargerDetail.autoStartConfig?.email === undefined;
    const data = [
      {
        id: 'company',
        label: t('company'),
        selected: isCompany,
      },
      {
        id: 'driver',
        label: t('driver'),
        selected: !isCompany,
      },
    ];
    return data;
  };

  const isAutoStartConfigSimulated =
    updateChargerDetail?.autoStartConfig?.type === SIMULATED;

  const renderAutoStart = () => {
    return (
      <div className='flex flex-col pt-4 pb-3'>
        <div className='flex flex-row gap-1 items-start'>
          <Label
            text={t('charger_auto_start')}
            type={LabelType.LABEL_M}
            color={ColorType.GREY5}
            style={{ minHeight: '40px' }}
          />
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans
                i18nKey='chargers_auto_start_hint'
                components={{
                  br: <br />,
                }}
              />
            </TooltipContent>
          </Tooltip>
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_auto_start_status')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={
                charger?.autoStartConfig
                  ? t('charger_auto_start_status_true')
                  : t('charger_auto_start_status_false')
              }
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <Switch
              value={isAutoStartConfigSimulated}
              enableLabel={t('enabled')}
              disableLabel={t('disabled')}
              onChange={(value: boolean) => {
                setUpdateChargerDetail({
                  ...updateChargerDetail,
                  autoStartConfig: {
                    type: value ? SIMULATED : 'DISABLED',
                  },
                });
              }}
              dataTestId='charger-auto-enable-switch'
            />
          )}
        </div>
        {editEnabled && isAutoStartConfigSimulated && (
          <div className='flex flex-row pt-2'>
            <Label
              text={t('charger_start_session')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex flex-col gap-0.5'>
              <RadioGroup
                direction={GroupDirection.Vertical}
                defaultItems={autoStartTypeData()}
                onChange={(event: any) => handleAutoStart(event)}
                itemHeight='30px'
              />
            </div>
          </div>
        )}
        {!editEnabled && isAutoStartConfigSimulated && (
          <>
            <div className='flex flex-row items-center pt-4'>
              <Label
                text={t('charger_start_session')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
                className='min-w-[148px] max-w-[148px] mr-2.5'
              />
              <div>
                <Label
                  text={`${
                    !charger?.autoStartConfig?.email
                      ? t('company')
                      : t('driver')
                  }`}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                  isLoading={
                    (isChargerLoading && isLoading) ||
                    (isChargerLoading && loadSkeletonAfterEdit)
                  }
                  skeletonWidth='w-52'
                  skeletonHeight='h-5'
                />
              </div>
            </div>
            {!!charger?.autoStartConfig?.email && (
              <div className='flex flex-row items-center pt-4'>
                <Label
                  text={t('charger_auto_start_associated_email')}
                  type={LabelType.LABEL_S}
                  color={ColorType.GREY6}
                  className='min-w-[148px] max-w-[148px] mr-2.5'
                />
                <div>
                  <Pill
                    label={charger?.autoStartConfig?.email}
                    labelColor={ColorType.GREY6}
                    bgColor={ColorType.GREY2}
                    isLoading={
                      (isChargerLoading && isLoading) ||
                      (isChargerLoading && loadSkeletonAfterEdit)
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}
        {editEnabled &&
          updateChargerDetail.autoStartConfig?.email !== undefined && (
            <div className='flex flex-row pt-4'>
              <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5 items-center'>
                <Label
                  text={t('charger_auto_start_associated_email')}
                  type={LabelType.LABEL_S}
                  color={ColorType.GREY6}
                />
              </div>
              <FormInput
                showErrorIcon
                onValidation={handleEmailValidation}
                width='220px'
                placeholder={t('auto_start_user_email')}
                errorLabel={t('chargers_email_validation_message')}
                onChange={(event: any) =>
                  handleAssociatedEmail(event.target.value)
                }
                defaultValue={updateChargerDetail.autoStartConfig?.email || ''}
              />
            </div>
          )}
      </div>
    );
  };

  const renderPricing = () => {
    const isAutoStartComapnyEnabled =
      updateChargerDetail.autoStartConfig?.type === 'SIMULATED' &&
      updateChargerDetail.autoStartConfig?.email === undefined;
    if (editEnabled) {
      return (
        <div className='w-[220px]'>
          {isAutoStartComapnyEnabled ? (
            <Label
              text={t('charger_auto_start_company_enabled')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className='w-auto py-2.5'
            />
          ) : (
            <Dropdown
              headerWidth={220}
              items={pricingDropdown}
              onItemClick={(selectedItem: any, index: number) =>
                handleInputChange('priceModelId', selectedItem.id)
              }
              labelType={LabelType.BODY3_G4}
              contentDivWidth={220}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
              headerClassName='bg-grey1 border-grey3 rounded pr-2'
              headerHeight='36px'
            />
          )}
        </div>
      );
    }
    return (
      <div className='flex flex-col'>
        <Label
          text={
            isAutoStartComapnyEnabled
              ? t('charger_auto_start_company_enabled')
              : charger?.priceModelDisplayName
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={
            (isChargerLoading && isLoading) ||
            (isChargerLoading && loadSkeletonAfterEdit)
          }
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  const renderSimCardConnectivity = () => {
    if (
      !editEnabled &&
      updateChargerDetail.deploymentInfo?.connectivityType ===
        Connectivity.SIM_CARD
    ) {
      return (
        <div className='flex flex-row gap-2 items-center'>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={`${t('charger_network')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={`${t('chargers_sim_card')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-col gap-0.5 items-center'>
            <Label
              text={updateChargerDetail?.deploymentInfo?.simCardProvider}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate min-h-[20px]'
            />
            <Label
              text={updateChargerDetail?.deploymentInfo?.simCardId}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate'
            />
          </div>
        </div>
      );
    }
    if (
      editEnabled &&
      updateChargerDetail?.deploymentInfo?.connectivityType ===
        Connectivity.SIM_CARD
    ) {
      return (
        <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-4'}`}>
          <div className='flex flex-row items-center'>
            <Label
              text={t('chargers_network_provider')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='w-[220px]'>
              <Dropdown
                items={networkProviderDropdown}
                onItemClick={(selectedItem: any, index: number) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    simCardProvider: selectedItem.label,
                  });
                }}
                headerWidth={220}
                labelType={LabelType.BODY3_G4}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                contentDivWidth={220}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
                headerClassName='bg-grey1 border-grey3 rounded pr-2'
                headerHeight='36px'
              />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <Label
              text={t('chargers_sim_card_number')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <FormInput
              defaultValue={updateChargerDetail?.deploymentInfo?.simCardId}
              onChange={(event: any) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  simCardId: event.target.value,
                });
              }}
              inputType={InputType.NUMBER}
              width='220px'
              height='36px'
              showErrorIcon
              maxLen={String(SIM_CARD_NUMBER_LEN)}
            />
          </div>
        </div>
      );
    }
  };
  const renderWifiConnectivity = () => {
    if (
      !editEnabled &&
      updateChargerDetail.deploymentInfo?.connectivityType === Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-row gap-2'>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={`${t('chargers_wifi_network_name_placeholder')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={`${t('chargers_wifi_network_password_placeholder')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={updateChargerDetail?.deploymentInfo?.wifiName}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate'
            />
            <div className='flex flex-row gap-1 items-start break-all inline-block'>
              <Label
                text={
                  !passwordVisibility
                    ? t('encrypted_password')
                    : updateChargerDetail?.deploymentInfo?.wifiPassword
                }
                type={LabelType.BODY3}
                color={ColorType.GREY6}
                className='flex'
              />
              <Icon
                src={!passwordVisibility ? showPassword : hidePassword}
                size={IconSize.SIZE_16x16}
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                className='flex pt-[5px]'
              />
            </div>
          </div>
        </div>
      );
    }
    if (
      editEnabled &&
      updateChargerDetail?.deploymentInfo?.connectivityType ===
        Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-col gap-0.5'>
          <div className='flex flex-row items-center'>
            <Label
              text={t('charger_wifi_name')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <FormInput
              defaultValue={updateChargerDetail?.deploymentInfo?.wifiName}
              onChange={(event: any) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  wifiName: event.target.value,
                });
              }}
              inputType={InputType.TEXT}
              width='220px'
              height='36px'
            />
          </div>
          <div className='flex flex-row items-center'>
            <Label
              text={t('charger_wifi_password')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <FormInput
              defaultValue={updateChargerDetail?.deploymentInfo?.wifiPassword}
              onChange={(event: any) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  wifiPassword: event.target.value,
                });
              }}
              inputType={InputType.TEXT}
              width='220px'
              height='36px'
            />
          </div>
        </div>
      );
    }
  };

  const displayConnectivityName = (displayId: Connectivity) => {
    if (displayId === Connectivity.SIM_CARD) {
      return t('chargers_sim_card');
    }
    if (displayId === Connectivity.WIFI) {
      return t('chargers_wifi');
    }
    if (displayId === Connectivity.ETHERNET) {
      return t('chargers_ethernet');
    }
    return '';
  };

  const connectionTypeData = () => {
    return [
      {
        id: Connectivity.SIM_CARD,
        label: t('chargers_sim_card'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.SIM_CARD,
        disabled: updateChargerQueryResult.isLoading,
      },
      {
        id: Connectivity.WIFI,
        label: t('chargers_wifi'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.WIFI,
        disabled: updateChargerQueryResult.isLoading,
      },
      {
        id: Connectivity.ETHERNET,
        label: t('chargers_ethernet'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.ETHERNET,
        disabled: updateChargerQueryResult.isLoading,
      },
    ];
  };

  const handleConnectionType = (items: CheckBoxData[]) => {
    const selectedConnection = items.find((item) => item.selected === true);
    handleInputChange('deploymentInfo', {
      ...updateChargerDetail.deploymentInfo,
      connectivityType: selectedConnection?.id as Connectivity,
    });
  };

  const getAccessText = () => {
    const text =
      updateChargerDetail?.autoStartConfig?.type === 'SIMULATED'
        ? t('charger_auto_start_enabled')
        : t((charger?.access || '').toLowerCase());
    return text;
  };

  const renderSettings = () => {
    return (
      <div
        className={`flex ${editEnabled ? 'gap-1' : 'gap-4'} flex-col pt-4 pb-3`}
      >
        <div className='flex flex-row'>
          <Label
            text={t('charger_settings')}
            type={LabelType.LABEL_M}
            color={ColorType.GREY5}
            style={{ minHeight: '40px' }}
          />
          <Label text='' type={LabelType.BODY3} color={ColorType.BLACK} />
        </div>
        {!editEnabled && (
          <div className='flex flex-row'>
            <Label
              text={t('network_connectivity')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex flex-col gap-0.5'>
              <Label
                text={displayConnectivityName(
                  charger?.deploymentInfo?.connectivityType as Connectivity,
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={
                  (isChargerLoading && isLoading) ||
                  (isChargerLoading && loadSkeletonAfterEdit)
                }
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
              {(!isChargerLoading || !isLoading) &&
                (!isChargerLoading || !loadSkeletonAfterEdit) &&
                renderSimCardConnectivity()}
              {(!isChargerLoading || !isLoading) &&
                (!isChargerLoading || !loadSkeletonAfterEdit) &&
                renderWifiConnectivity()}
            </div>
          </div>
        )}
        {editEnabled && (
          <div className='flex flex-row'>
            <Label
              text={t('chargers_connection_type')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex flex-col gap-0.5'>
              <RadioGroup
                direction={GroupDirection.Vertical}
                defaultItems={connectionTypeData()}
                onChange={handleConnectionType}
                itemHeight='30px'
              />
            </div>
          </div>
        )}
        {editEnabled && renderSimCardConnectivity()}
        {editEnabled && renderWifiConnectivity()}
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_pricing')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {renderPricing()}
        </div>
        <div
          className={`flex flex-row items-center ${editEnabled ? 'pt-2' : ''}`}
        >
          <Label
            text={t('charger_access')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={getAccessText()}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={
              (isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)
            }
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        </div>
        <div className={`flex flex-row ${editEnabled ? 'pt-2' : ''}`}>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5'>
            <Label
              text={t('chargers_map_visibility')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
            <Tooltip>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <Label
                  text={t('chargers_map_visibility_hint')}
                  type={LabelType.BODY3}
                  color={ColorType.WHITE}
                />
              </TooltipContent>
            </Tooltip>
          </div>
          {!editEnabled && (
            <Label
              text={
                charger?.hiddenOnMap
                  ? t('charger_map_visibility_false')
                  : t('chargers_visibility_user_access')
              }
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div>
              <CheckBox
                label={t('chargers_visibility_user_access')}
                key='visible'
                index={0}
                selected={!updateChargerDetail.hiddenOnMap}
                onChange={(selectedCheckbox: boolean, index: number) =>
                  handleInputChange('hiddenOnMap', !selectedCheckbox)
                }
                isDisabled={updateChargerQueryResult.isLoading}
                dataTestId='charger-map-visiblilty-button'
              />
            </div>
          )}
        </div>
        <div
          className={`flex flex-row items-center ${editEnabled ? 'pt-2' : ''}`}
        >
          <Label
            text={t('charger_power_managed')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={
              charger?.isPowerManaged ? t('chargers_yes') : t('chargers_no')
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={
              (isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)
            }
            skeletonWidth='w-52'
            skeletonHeight='h-5'
            className='mr-2'
          />
          {!charger?.isPowerManaged &&
            filteredCircuits &&
            filteredCircuits?.length >= 1 && (
              <Tooltip>
                <TooltipTrigger>
                  <Icon
                    src={Info}
                    size={IconSize.SIZE_20x20}
                    color={ColorType.WARNING2}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <span
                    className='text-sm text-white font-medium'
                    style={{ fontFamily: 'Inter' }}
                  >
                    <Trans
                      i18nKey='charger_detail_pm_soft_warning'
                      components={{
                        br: <br />,
                      }}
                    />
                  </span>
                </TooltipContent>
              </Tooltip>
            )}
        </div>
      </div>
    );
  };
  const renderNav = (parent: string, name?: string) => {
    return (
      <div className='flex flex-row gap-1'>
        <Label
          text={parent}
          type={LabelType.BODY2}
          color={ColorType.ACCENT1}
          style={{ color: theme.navigationSelectedColor }}
          onClick={() => switchCompany(companyId!, RoutePath.CHARGERS)}
        />
        <Label
          text={`/ ${name}`}
          type={LabelType.BODY2}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderError = () => {
    if (charger?.status === CHARGER_STATUS.OUT_OF_ORDER) {
      return (
        <div className='w-full mt-2'>
          <Card className='border-2 border-negative1' bg='bg-negative0'>
            <div className='flex flex-row gap-3'>
              <div className=''>
                <Icon src={ErrorWarningtraiangle} color={ColorType.NEGATIVE1} />
              </div>
              <div className='flex flex-col'>
                <Label
                  text={t('charger_detail_error')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <ul className='list-disc ml-6 mt-2'>
                  <li>
                    <Label
                      text={t('chargers_trouble_solution_3')}
                      type={LabelType.BODY3}
                      color={ColorType.GREY6}
                    />
                  </li>
                </ul>
                {/* TODO : Don't delete this commented code. Hiding this button for now */}
                {/* <div className='mt-6'>
                  <Button
                    label={t('charger_detail_err_button')}
                    type={ButtonType.DESTRUCTIVE}
                    size={ButtonSize.AUTO_RIGHT_ICON}
                    icon={coolicon}
                    iconColor={ColorType.WHITE}
                    iconRightAlign
                  />
                </div> */}
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (charger?.status === CHARGER_STATUS.OFFLINE) {
      return (
        <div className='w-full mt-2'>
          <Card className='border-2 border-warning1' bg='bg-warning0'>
            <div className='flex flex-row gap-3'>
              <div className='flex flex-row'>
                <Icon color={ColorType.WARNING1} src={ErrorWarningtraiangle} />
              </div>
              <div className='flex flex-col'>
                <Label
                  text={t('charger_offline_warning')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <ul className='list-disc ml-6 mt-2'>
                  <li>
                    <Label
                      text={t('chargers_trouble_solition_2')}
                      type={LabelType.BODY3}
                      color={ColorType.GREY6}
                    />
                  </li>
                  <li>
                    <Label
                      text={t('chargers_trouble_solution_3')}
                      type={LabelType.BODY3}
                      color={ColorType.GREY6}
                    />
                  </li>
                </ul>
                {/* TODO : Don't delete this commented code. Hiding this button for now */}
                {/* <div className='mt-6'>
                  <Button
                    label={t('charger_detail_err_button')}
                    type={ButtonType.Warning}
                    size={ButtonSize.AUTO_RIGHT_ICON}
                    icon={coolicon}
                    iconColor={ColorType.WHITE}
                    iconRightAlign
                  />
                </div> */}
              </div>
            </div>
          </Card>
        </div>
      );
    }
    return null;
  };

  const handleEditBtnClick = () => {
    setEditEnabled(true);
  };

  const handleViewChargerClick = () => {
    window.open(`${webAppUrlPrefix}/${charger?.name}`);
  };

  const handleDeleteCharger = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('charger_delete_warn_title'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      message: (
        <Trans
          i18nKey={t('charger_delete_warn_message')}
          components={{
            bold: <strong />,
          }}
        />
      ),
      mutationHook: useDeleteChargerMutation,
      mutationParams: {
        id,
        name: charger?.name,
        scope: userScope!,
        companyId: charger.ownerId!,
      },
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          disableDelayTime: 3000,
          disableDelayLabel: t('wait_3_sec'),
          className: 'min-w-fit w-20',
          isSubmit: true,
          dataTestId: 'delete-charger-button',
        },
      ],
      onClose: (qResult: any) => {
        if (qResult?.isSuccess) {
          switchCompany(companyId!, RoutePath.CHARGERS);
        }
      },
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleViewQRCodeClick = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('view_qr_code'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => (
        <ViewQRCode
          chargerName={charger?.name || ''}
          webAppUrl={webAppUrlPrefix}
        />
      ),
    });
  };

  const handleChargerMove = () => {
    showModal(MODAL_TYPES.WIZARD_MODAL, {
      width: '540px',
      height: 'max-content',
      defaultOverflow: 'auto',
      stepTitles: [
        t('charger_move_step_1_title'),
        t('charger_move_step_2_title'),
        t('charger_move_step_3_title'),
      ],
      stepComponents: [
        <ChargerCompany chargerInfo={charger} companyInfo={company} />,
        <ChargerSettings />,
        <ChargerConfirmation />,
      ],
      shouldCloseOnOverlayClick: false,
      onClose: () => {
        switchCompany(companyId!, RoutePath.CHARGERS);
      },
    });
  };

  const isAnyPortCharging = useMemo(() => {
    return charger?.ports?.some((port) => port?.status === 'CHARGING');
  }, [charger]);

  const showClearProfileAlert = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('are_you_sure'),
      message: (
        <Trans
          i18nKey='pm_clear_profile_desc'
          components={{
            br: <br />,
          }}
        />
      ),
      mutationHook: useClearProfileMutation,
      mutationParams: {
        scope: 'all',
        chargerId: charger.id,
      },
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
        },
        {
          label: t('clear_profile'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          isSubmit: true,
        },
      ],
      shouldCloseOnOverlayClick: false,
    });
  };

  const renderHeader = () => {
    if (editEnabled) {
      return (
        <div className='flex place-content-center'>
          <Label
            text={t('charger_edit_details')}
            type={LabelType.H4}
            color={ColorType.BLACK}
          />
        </div>
      );
    }
    return (
      <div className='flex flex-row relative justify-center items-center'>
        <Tooltip placement='top'>
          <TooltipTrigger>
            <Label
              text={charger?.name || ''}
              type={LabelType.H4}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonHeight='h-6'
              className='truncate max-w-[364px]'
            />
          </TooltipTrigger>
          <TooltipContent style={{ maxWidth: '420px', wordWrap: 'break-word' }}>
            <Label
              text={charger?.name || ''}
              type={LabelType.BODY3}
              color={ColorType.WHITE}
            />
          </TooltipContent>
        </Tooltip>

        <div className='absolute right-0'>
          <Menu
            placement='bottom-end'
            icon={More}
            dataTestId='chargers-details-menu'
          >
            <MenuItem
              label={t('edit_charger')}
              onClick={handleEditBtnClick}
              dataTestId='chargers-details-edit-button'
            />
            <MenuItem
              label={t('view_charger_as_driver')}
              onClick={handleViewChargerClick}
            />
            <MenuItem
              label={t('view_qr_code')}
              onClick={handleViewQRCodeClick}
            />
            <Divider margin='2px' />
            <MenuItem
              label={t('move_charger')}
              onClick={handleChargerMove}
              disabled={charger?.isPowerManaged}
            />
            <MenuItem
              label={t('pm_clear_profile')}
              labelColor={ColorType.NEGATIVE1}
              onClick={showClearProfileAlert}
              disabled={
                charger?.isPowerManaged ||
                charger.status === CHARGER_STATUS.COMING_SOON ||
                charger.status === CHARGER_STATUS.OFFLINE
              }
            />
            <MenuItem
              label={t('delete')}
              labelType={LabelType.BODY3}
              labelColor={ColorType.NEGATIVE1}
              onClick={handleDeleteCharger}
              disabled={charger?.isPowerManaged || isAnyPortCharging}
              dataTestId='chargers-details-delete-button'
            />
          </Menu>
        </div>
      </div>
    );
  };

  const renderDeployment = () => {
    return (
      <div className={`flex ${editEnabled ? 'gap-1' : 'gap-4'} flex-col pt-4`}>
        <Label
          text={t('charger_deployment')}
          type={LabelType.LABEL_M}
          color={ColorType.GREY5}
          style={{ minHeight: '40px' }}
        />
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_deployment_status')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Pill
              label={
                charger?.deploymentInfo?.deployed
                  ? t('charger_deployment_status_true')
                  : t('charger_deployment_status_false')
              }
              bgColor={
                charger?.deploymentInfo?.deployed
                  ? ColorType.POSITIVE1
                  : ColorType.GREY4
              }
              labelColor={ColorType.WHITE}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
            />
          )}
          {editEnabled && auth.role === USER_ROLE.NORMAL && (
            <Pill
              label={
                charger?.deploymentInfo?.deployed
                  ? t('charger_deployment_status_true')
                  : t('charger_deployment_status_false')
              }
              bgColor={
                charger?.deploymentInfo?.deployed
                  ? ColorType.POSITIVE1
                  : ColorType.GREY4
              }
              labelColor={ColorType.WHITE}
            />
          )}
          {/* Only support role should be allowed to deploy/un-deploy charger */}
          {editEnabled && auth.role === USER_ROLE.SUPPORT && (
            <Switch
              value={updateChargerDetail.deploymentInfo?.deployedOverride}
              enableLabel={t('charger_deployment_status_true')}
              disableLabel={t('charger_deployment_status_false')}
              onChange={(checked: boolean) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  deployedOverride: checked,
                });
              }}
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          {(!editEnabled || charger?.deploymentInfo?.deployedDate) && (
            <Label
              text={t('charger_deployment_date')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
          )}
          {!editEnabled &&
            ((isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)) && (
              <Skeleton height='h-5' width='w-52' />
            )}
          {!editEnabled &&
            (!isChargerLoading || !isLoading) &&
            (!isChargerLoading || !loadSkeletonAfterEdit) &&
            updateChargerDetail.deploymentInfo?.deployedOverride && (
              <Label
                text={formatDate(
                  parseDate(
                    charger?.deploymentInfo?.deployedDate
                      ? charger?.deploymentInfo?.deployedDate
                      : '',
                    'yyyy-MM-dd',
                  ),
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
            )}
          {editEnabled &&
            updateChargerDetail.deploymentInfo?.deployedOverride && (
              <Label
                text={formatDate(
                  parseDate(
                    charger?.deploymentInfo?.deployedDate
                      ? charger?.deploymentInfo?.deployedDate
                      : '',
                    'yyyy-MM-dd',
                  ),
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
            )}
          {/* Only support role should be allowed to make change to deployment date */}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('chargers_installer')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={charger?.deploymentInfo?.installationPerson}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={
                updateChargerDetail.deploymentInfo?.installationPerson
              }
              onChange={(event: any) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  installationPerson: event.target.value,
                });
              }}
              width='220px'
              height='36px'
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_installer_email')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={charger?.deploymentInfo?.installerContactEmail}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <FormInput
              defaultValue={
                updateChargerDetail.deploymentInfo?.installationEmail
              }
              onChange={(event: any) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  installationEmail: event.target.value,
                });
              }}
              width='220px'
              height='36px'
              inputType={InputType.TEXT}
              validationType={ValidationType.EMAIL}
              showErrorIcon
              errorLabel={t('chargers_email_validation_message')}
            />
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('chargers_installation_date')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={formatDate(
                parseDate(
                  charger?.deploymentInfo?.installationDate
                    ? charger?.deploymentInfo?.installationDate
                    : '',
                  'yyyy-MM-dd',
                ),
              )}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={
                (isChargerLoading && isLoading) ||
                (isChargerLoading && loadSkeletonAfterEdit)
              }
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex flex-row'>
              <DateTimePicker
                onChange={(date) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    installationDate: formatDate(date, 'yyyy-MM-dd'),
                  });
                }}
                format='MM/dd/yyyy'
                customInputType={CustomInputType.FORMINPUTDATE}
                customFormInputDivWidth='w-[220px]'
                showDefaultDate={
                  !!updateChargerDetail.deploymentInfo?.installationDate
                }
                defaultDate={
                  updateChargerDetail.deploymentInfo?.installationDate
                    ? parseDate(
                        updateChargerDetail.deploymentInfo?.installationDate ||
                          '',
                        'yyyy-MM-dd',
                      )
                    : new Date()
                }
                customFormInputErrorLabel={t(
                  'chargers_date_validation_message',
                )}
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_deployment_added_by')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {((isChargerLoading && isLoading) ||
            (isChargerLoading && loadSkeletonAfterEdit)) && (
            <Pill isLoading={isChargerLoading && isLoading} />
          )}
          {(!isChargerLoading || !isLoading) &&
            (!isChargerLoading || !loadSkeletonAfterEdit) &&
            charger?.deploymentInfo?.addedBy && (
              <Pill
                label={charger?.deploymentInfo?.addedBy}
                bgColor={ColorType.GREY2}
                labelColor={ColorType.GREY6}
              />
            )}
        </div>
        <div className='flex flex-row items-center'>
          <Label
            text={t('charger_deployment_date_added')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={formatDate(
              parseDate(
                charger?.deploymentInfo?.addedDate
                  ? charger?.deploymentInfo?.addedDate
                  : '',
                'yyyy-MM-dd',
              ),
            )}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={
              (isChargerLoading && isLoading) ||
              (isChargerLoading && loadSkeletonAfterEdit)
            }
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        </div>
      </div>
    );
  };

  const handleSaveBtnClick = () => {
    updateCharger({
      body: updateChargerDetail,
      params: {
        scope: userScope!,
        companyId: charger.ownerId,
      },
    });
  };
  const handleCancelBtnClick = () => {
    setManufacturer('');
    setEditEnabled(false);
  };

  const onSaveValidation = () => {
    if (
      !updateChargerDetail.displayId ||
      !updateChargerDetail.chargerModelId ||
      !updateChargerDetail.ocppId ||
      !handleValidateId(updateChargerDetail.ocppId) ||
      !updateChargerDetail.deploymentInfo?.connectivityType ||
      !updateChargerDetail.priceModelId ||
      // Need to keep save button disabled when AutoStart Switch is enabled & Driver is selected but no email id is added
      (updateChargerDetail.autoStartConfig?.type === SIMULATED &&
        updateChargerDetail.autoStartConfig?.email !== undefined &&
        !validateEmail(updateChargerDetail.autoStartConfig?.email)) ||
      validateResponse.isError
    ) {
      return false;
    }
    return true;
  };
  const renderBtnDiv = () => {
    if (editEnabled) {
      return (
        <div className='flex flex-row-reverse gap-2 pt-5'>
          <Button
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!onSaveValidation()}
            size={ButtonSize.SMALL}
            isSumbit
            dataTestId='chargers-save-details'
          />
          <Button
            label={t('cancel')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            onClick={handleCancelBtnClick}
          />
        </div>
      );
    }
  };

  const verifyAutoStart = () => {
    // verify when users enable autostart wth session === Driver and email is not empty or email changed

    const shouldVerify =
      updateChargerDetail.autoStartConfig?.type === SIMULATED &&
      updateChargerDetail.autoStartConfig?.email &&
      updateChargerDetail.autoStartConfig?.email !==
        charger.autoStartConfig?.email;

    if (shouldVerify) {
      triggerValidAutoStart({
        params: {
          failOnInvalidPaymentMethod: true,
          validateOnly: true,
          scope: userScope!,
          companyId: charger.ownerId,
        },
        body: updateChargerDetail,
        id,
      });
    }
  };

  const renderDetail = () => (
    <div className='w-[465px]'>
      <Card OnRenderTitle={renderHeader}>
        <Form
          onSubmit={handleSaveBtnClick}
          queryResult={updateChargerQueryResult}
          onQuerySuccess={() => {
            verifyAutoStart();
            setEditEnabled(false);
            setLoadSkeletonAfterEdit(true);
          }}
          onQueryFailed={() => {
            setEditEnabled(false);
          }}
        >
          <div className='flex flex-col'>
            {renderError()}
            <div className='divide-y divide-grey2'>
              {renderInfo()}
              {renderAdditionalInfo()}
              {renderSettings()}
              {allowPayterView && (
                <ChargerTerminal
                  charger={charger}
                  editEnabled={editEnabled}
                  handleInputChange={handleInputChange}
                />
              )}
              {renderAutoStart()}
              {renderDeployment()}
            </div>
            {renderBtnDiv()}
          </div>
        </Form>
      </Card>
    </div>
  );

  const renderSessions = useMemo(() => {
    if (!charger || !companyId) {
      return null;
    }
    return (
      <ChargerHistory
        chargerName={charger.name}
        chargerId={charger.id}
        chargerTimeZone={chargerData?.location?.timeZone!}
        companyId={companyId!}
      />
    );
  }, [charger]);

  return (
    <div className='flex flex-col gap-6'>
      {renderNav(t('chargers'), charger?.name || '')}
      <div className='flex flex-row gap-6'>
        {renderDetail()}
        {renderSessions}
      </div>
    </div>
  );
};
