import React, { useContext } from 'react';
import PayoutReport from './PayoutReport/PayoutReport.component';
import PayoutHistory from './PayoutHistory/PayoutHistory.component';
import { payterContext } from '../../hooks/usePayter';
import PayterTerminal from './PayoutTerminal/PayterTerminal.component';

const Payter = () => {
  const payterConsumer = useContext(payterContext);
  const { showTerminal } = payterConsumer;

  return (
    <>
      <PayoutReport />
      <div className='flex gap-4'>
        <PayoutHistory />
        {showTerminal && <PayterTerminal />}
      </div>
    </>
  );
};

export default Payter;
