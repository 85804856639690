import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Breaker, Circuit } from '../../../stores/types/pm.interface';
import {
  CheckBox,
  CheckBoxGroup,
  ColorType,
  Label,
  LabelType,
} from '../../_ui';
import { FormInput, InputType } from '../../_ui/Input.component';
import { ELECTRIC_SUPPLY } from '../utils';

export interface LoadDetailsProp {
  data: {
    breaker: Breaker;
    circuit: Circuit;
  };
  handleInputChange: (key: string, value: any) => void;
  loadData: any;
  activeIndex: number;
}

export const LoadDetailsForm = ({
  data,
  loadData,
  activeIndex,
  handleInputChange,
}: LoadDetailsProp) => {
  const { t } = useTranslation();

  // Wiring phases validation rules
  const disableWiringOption = (currentOption: string) => {
    const selectedOptions = loadData[activeIndex].wiringPhase.split('_');
    const currentOptionSelected = selectedOptions.indexOf(currentOption) !== -1;
    // If current option is already selected, don't disable it
    if (currentOptionSelected) {
      return false;
    }
    const numOfSelectedOptions = selectedOptions.length;
    const neutralSelected = selectedOptions.indexOf('N') !== -1;
    // Neutral can only be selected if there is at most one other phase selected
    if (
      (neutralSelected && numOfSelectedOptions > 1) ||
      (currentOption === 'N' && numOfSelectedOptions > 1)
    ) {
      return true;
    }
    // If circuit is 3-phase, user selects from Phase A, Phase B, Phase C and/or Neutral
    if (data.circuit.electricalSupply === ELECTRIC_SUPPLY.THREE_PHASE) {
      return numOfSelectedOptions > 2;
    }
    // If circuit is split-phase, user selects from Phase A, Phase B and/or Neutral
    if (data.circuit.electricalSupply === ELECTRIC_SUPPLY.SPLIT_PHASE) {
      return numOfSelectedOptions > 1;
    }
    return false;
  };

  const getWiringForSelectedPhases = (selectedPhases: string[]) => {
    const A = selectedPhases.includes('L1');
    const B = selectedPhases.includes('L2');
    const C = selectedPhases.includes('L3');
    const N = selectedPhases.includes('N');
    // L3_L1 is a special case where the Phases can't just be concatenated
    if (A && C && !B && !N) {
      return 'L3_L1';
    }
    return selectedPhases.join('_');
  };

  const wiringPhase = useMemo(() => {
    if (data.circuit.electricalSupply === ELECTRIC_SUPPLY.THREE_PHASE) {
      return [
        {
          id: 'L1',
          label: t('wiring_phase_a'),
          selected: loadData[activeIndex].wiringPhase.includes('L1'),
          disabled: disableWiringOption('L1'),
        },
        {
          id: 'L2',
          label: t('wiring_phase_b'),
          selected: loadData[activeIndex].wiringPhase.includes('L2'),
          disabled: disableWiringOption('L2'),
        },
        {
          id: 'L3',
          label: t('wiring_phase_c'),
          selected: loadData[activeIndex].wiringPhase.includes('L3'),
          disabled: disableWiringOption('L3'),
        },
        {
          id: 'N',
          label: t('wiring_phase_neutral'),
          selected: loadData[activeIndex].wiringPhase.includes('N'),
          disabled: disableWiringOption('N'),
        },
      ];
    }

    return [
      {
        id: 'L_L',
        label: t('phase_to_phase_option'),
        selected: loadData[activeIndex].wiringPhase.includes('L_L'),
      },
      {
        id: 'L_N',
        label: t('phase_to_neutral_option'),
        selected: loadData[activeIndex].wiringPhase.includes('L_N'),
      },
    ];
  }, [loadData[activeIndex].wiringPhase]);

  const maximumContinuousLoadPerc = () => {
    const breakerRating =
      loadData[activeIndex]?.dedicatedBreaker?.breakerRating || 0;
    if (!breakerRating) {
      return 0;
    }
    return +((breakerRating / 100) * 80).toFixed(2);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <Label
          text={t('load_connected_breaker_label')}
          type={LabelType.LABEL_M}
        />
        <CheckBox
          selected={loadData[activeIndex].ctdb}
          onChange={(value: boolean) => {
            handleInputChange('ctdb', value);
          }}
          label={t('yes')}
        />
      </div>
      {loadData[activeIndex].ctdb && (
        <>
          <div className='flex flex-col gap-2'>
            <Label text={t('load_modal_field_8')} type={LabelType.LABEL_M} />
            <div className='flex flex-row gap-2'>
              <div className='w-[58px]'>
                <FormInput
                  showErrorIcon
                  defaultValue={
                    loadData[activeIndex]?.dedicatedBreaker?.breakerRating
                  }
                  placeholder='0'
                  inputType={InputType.NUMBER}
                  onChange={(event: any) => {
                    handleInputChange('breakerRating', event.target.value);
                  }}
                  props={{ style: { width: '58px' } }}
                  errorLabel={t('sub_breaker_valid_rating')}
                  wrapperClassName='inline-block'
                  dataTestId='breakerRating'
                />
              </div>
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={t('pm_amps')}
                className='mt-2.5'
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-1'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('sub_breaker_modal_field_4')}
              />
              <Label
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
                text={t('sub_breaker_modal_field_5')}
              />
            </div>
            {loadData[activeIndex]?.dedicatedBreaker?.breakerRating ? (
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${maximumContinuousLoadPerc()} amps`}
              />
            ) : (
              <Label
                type={LabelType.BODY3}
                color={ColorType.GREY4}
                text={t('sub_breaker_label_3')}
              />
            )}
          </div>
          <div className='flex flex-col'>
            <FormInput
              defaultValue={
                loadData[activeIndex]?.dedicatedBreaker?.breakerSpace
              }
              label={t('sub_breaker_label_2')}
              secondLabel={`(${t('optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              placeholder={t('sub_breaker_label_2')}
              inputType={InputType.TEXT}
              onChange={(event: any) => {
                handleInputChange('breakerSpace', event.target.value);
              }}
              width='100%'
              dataTestId='breakerSpace'
            />
          </div>
        </>
      )}
      <div className='flex flex-col gap-2 pb-4'>
        <Label text={t('load_modal_field_12')} type={LabelType.LABEL_M} />
        <CheckBoxGroup
          dataTestId='wiringPhase'
          defaultItems={wiringPhase}
          onChange={(items: any) => {
            const selectedPhases = items
              .filter((item: any) => item.selected)
              .map((item: any) => item.id);
            const wiring = getWiringForSelectedPhases(selectedPhases);
            handleInputChange('wiringPhase', wiring);
          }}
          itemHeight='20px'
          singleSelection={
            data.circuit.electricalSupply === ELECTRIC_SUPPLY.SPLIT_PHASE
          }
        />
      </div>
    </div>
  );
};
