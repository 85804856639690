export const IconSize = {
  SIZE_14x14: { width: 14, height: 14 },
  SIZE_16x16: { width: 16, height: 16 },
  SIZE_20x20: { width: 20, height: 20 },
  SIZE_24x24: { width: 24, height: 24 },
  SIZE_28x28: { width: 28, height: 28 },
  SIZE_32x32: { width: 32, height: 32 },
  SIZE_36x36: { width: 36, height: 36 },
  SIZE_48x48: { width: 48, height: 48 },
  SIZE_52x12: { width: 52, height: 12 },
  SIZE_64x64: { width: 64, height: 64 },
};
