import { RfidCard, RfidCards } from '../stores/types/rfidcard.interface';
import { API_KEYS, Api } from './base.api';

const encodeRfidCardNumber = (cardNumber: string | undefined) => {
  if (cardNumber) {
    return encodeURIComponent(cardNumber);
  }
};

export const rfidApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRfidCards: builder.query<RfidCards, any>({
      query: (params: any) => ({
        url: `/internal/core/v2/${API_KEYS.CARDS}`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CARDS, id: 'LIST' },
      ],
    }),
    registerRfidCard: builder.mutation<any, RfidCard>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CARDS}/${encodeRfidCardNumber(
          payload.cardNumber,
        )}`,
        body: {
          virtualUserId: payload.virtualUserId,
          vehicleId: payload.vehicleId,
          note: payload.note,
        },
        method: 'PUT',
        toast: {
          successTrans: 'rfid_save_success_toast_message',
          failedTrans: 'rfid_save_error_toast_message',
          values: {
            cardNumber: payload.cardNumber,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CARDS, id: 'LIST' },
      ],
    }),
    deleteRfidCard: builder.mutation<any, RfidCard>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CARDS}/${encodeRfidCardNumber(
          payload.cardNumber,
        )}`,
        body: {
          virtualUserId: payload.virtualUserId,
          vehicleId: payload.vehicleId,
          note: payload.note,
        },
        method: 'PUT',
        toast: {
          successTrans: 'rfid_delete_success_toast_message',
          failedTrans: 'rfid_delete_error_toast_message',
          values: {
            cardNumber: payload.cardNumber,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CARDS, id: 'LIST' },
        { type: API_KEYS.VEHICLES, id: 'LIST' },
      ],
    }),
    editRfidCard: builder.mutation<any, RfidCard>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CARDS}/${encodeRfidCardNumber(
          payload.cardNumber,
        )}`,
        body: {
          vehicleId: payload.vehicleId,
          note: payload.note,
        },
        method: 'PATCH',
        toast: {
          successTrans: 'rfid_edit_success_toast_message',
          failedTrans: 'rfid_edit_error_toast_message',
          values: {
            cardNumber: payload.cardNumber,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CARDS, id: 'LIST' },
        { type: API_KEYS.VEHICLES, id: 'LIST' },
      ],
    }),
    validateRfidCard: builder.query<void, RfidCard>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CARDS}/${encodeRfidCardNumber(
          payload.cardNumber,
        )}`,
        method: 'HEAD',
      }),
    }),
  }),
});

export const {
  useFetchRfidCardsQuery,
  useRegisterRfidCardMutation,
  useEditRfidCardMutation,
  useLazyValidateRfidCardQuery,
  useDeleteRfidCardMutation,
} = rfidApi;
