export const searchOptions = [
  {
    id: 1,
    label: 'Authorize',
  },
  {
    id: 2,
    label: 'BootNotification',
  },
  {
    id: 3,
    label: 'ChangeConfiguration',
  },
  {
    id: 4,
    label: 'DataTransfer',
  },
  {
    id: 5,
    label: 'DiagnosticStatusNotification',
  },

  {
    id: 6,
    label: 'FirmwareStatusNotification',
  },
  {
    id: 7,
    label: 'GetConfiguration',
  },
  {
    id: 8,
    label: 'Heartbeat',
  },
  {
    id: 9,
    label: 'MeterValues',
  },
  {
    id: 10,
    label: 'RemoteStartTransaction',
  },
  {
    id: 11,
    label: 'RemoteStopTransaction',
  },
  {
    id: 12,
    label: 'Reset',
  },
  {
    id: 13,
    label: 'SetChargingProfile',
  },
  {
    id: 14,
    label: 'StartTransaction',
  },
  {
    id: 15,
    label: 'StopTransaction',
  },
  {
    id: 16,
    label: 'TriggerMessage',
  },
  {
    id: 17,
    label: 'UpdateFirmware',
  },
];
