import { NUMBER } from '../../../constant/Number.constant';
import { NoPowerManaged } from '../NoPowerManaged';
import { Location } from '../../../stores/types';
import LocationDetails from './LocationDetails.component';

interface LocationWoPowerManagementProps {
  locations: Location[];
  data: string[];
  showWarning: Function;
}

export const LocationWoPowerManagement = ({
  locations,
  data,
  showWarning,
}: LocationWoPowerManagementProps) => {
  if (!data?.length) return <NoPowerManaged isWithLocation />;

  const getLocationById = (id: string) =>
    locations.find((location) => location.id === id);

  return (
    <div data-testid='locationsWithoutPm'>
      {data.map((ele: string) => {
        const locationData = getLocationById(ele);
        return locationData ? (
          <LocationDetails location={locationData} showWarning={showWarning} />
        ) : null;
      })}
    </div>
  );
};
