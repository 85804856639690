import { TEXT } from '../constant/Text.constant';
import { API_KEYS, Api } from './base.api';

export const discountApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDiscounts: builder.query<any, void>({
      query: () => ({
        url: `/internal/core/v2/${API_KEYS.DISCOUNT}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.DISCOUNT, id: 'LIST' },
      ],
    }),
    addDiscount: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `/internal/core/v2/${API_KEYS.DISCOUNT}`,
        body: payload,
        method: 'POST',
        toast: {
          successTrans: 'discount_add_success_toast',
          failedTrans: 'discount_add_failed_toast',
          values: {
            type:
              payload.userGroupSelector.type === TEXT.INDIVIDUAL
                ? 'driver'
                : 'company',
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DISCOUNT, id: 'LIST' },
      ],
    }),
    deleteDiscount: builder.mutation<any, any>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.DISCOUNT}/${payload.id}`,
        method: 'DELETE',
        toast: {
          successTrans: 'discount_delete_success_toast_message',
          failedTrans: 'discount_delete_error_toast_message',
          values: {
            type: payload.type,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DISCOUNT, id: 'LIST' },
      ],
    }),
    updateDiscount: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `/internal/core/v2/${API_KEYS.DISCOUNT}/${payload.id}`,
        body: payload,
        method: 'PUT',
        toast: {
          successTrans: 'discount_edit_success_toast',
          failedTrans: 'discount_edit_failed_toast',
          values: {
            type:
              payload.userGroupSelector.type === TEXT.INDIVIDUAL
                ? 'driver'
                : 'company',
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.DISCOUNT, id: 'LIST' },
      ],
    }),
    preExistingDiscountValidaton: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `/internal/core/v2/${API_KEYS.DISCOUNT}?filter_eq[userGroupSelector.type]=${payload.type}&filter_eq[userGroupSelector.value]=${payload.value}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchDiscountsQuery,
  useLazyFetchDiscountsQuery,
  useAddDiscountMutation,
  useDeleteDiscountMutation,
  useUpdateDiscountMutation,
  usePreExistingDiscountValidatonMutation,
} = discountApi;
