import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { CheckBox, ColorType, Skeleton } from '..';
import { useAuth, useWindowSize } from '../../../hooks';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { Grid } from '../grid/Grid.component';
import { useGlobalModalContext } from './GlobalModal.component';

import { ChevronLeft, ExitDeleteRemoveClose } from '../../../assets/icons';
import { NUMBER } from '../../../constant/Number.constant';
import {
  useDeleteDelegationInvitesMutation,
  useFetchDelegationInvitesQuery,
  useUpdateDelegationInvitesMutation,
} from '../../../services/invite.api';
import { Invite } from '../../../stores/types';
import { Form } from '../Form.component';
import { MODAL_LAYER_INDEX, sortInvitedCompanyByDate } from './utils';

const AcceptInviteModal = () => {
  const [selectedCompany, setSelectedCompany] = useState<Array<Invite>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isIgnore, setIsIgnore] = useState(false);
  const auth = useAuth();
  const { hideModal, store } = useGlobalModalContext();
  const windowSize = useWindowSize();
  const { modalProps } = store;
  const { t } = useTranslation();
  const {
    width = '540px',
    height = 'max-content',
    overflow = 'auto',
    shouldCloseOnOverlayClick = true,
  } = modalProps;

  const {
    data: invitedCompanyies,
    isLoading: loading,
    isError: isInvitesError,
  } = useFetchDelegationInvitesQuery();

  const companyIdFromLocalStorage =
    auth.user.attributes.profile?.lastActiveCompanyId;

  const invitesList = useMemo(() => {
    return invitedCompanyies ? sortInvitedCompanyByDate(invitedCompanyies) : [];
  }, [invitedCompanyies]);

  const isInviteCompanies = !!invitesList?.length;

  const [updateInvites, updateQueryResult] =
    useUpdateDelegationInvitesMutation();

  const [deleteInvites, deleteQueryResult] =
    useDeleteDelegationInvitesMutation();

  const handleClose = () => {
    hideModal();
  };

  const updateCompanyId = async () => {
    const companyId = companyIdFromLocalStorage || selectedCompany[0].companyId;
    await auth.updateCompanyId(companyId);
    window.location.replace(`/?companyId=${companyId}`);
  };

  useEffect(() => {
    if (updateQueryResult.isSuccess) {
      updateCompanyId();
      hideModal();
    }
    if (deleteQueryResult.isSuccess) {
      hideModal();
    }
  }, [hideModal, updateQueryResult.isSuccess, deleteQueryResult.isSuccess]);

  const handleUpdateInvites = async () => {
    updateInvites({
      inviteIds: selectedCompany.map((item) => item.inviteId),
    });
  };

  const handleDeleteInvites = () => {
    deleteInvites({ inviteIds: selectedCompany.map((item) => item.inviteId) });
  };

  const ROW_PER_PAGE = NUMBER.TEN;

  const filteredComapany = useMemo(() => {
    if (invitesList && invitesList.length > NUMBER.ZERO) {
      const startIndex = (currentPage - 1) * ROW_PER_PAGE;
      return invitesList.slice(startIndex, startIndex + ROW_PER_PAGE);
    }
    return [];
  }, [ROW_PER_PAGE, currentPage, invitesList]);

  const isAllCurrentPageSelected = useMemo(() => {
    if (filteredComapany.length === NUMBER.ZERO) {
      return false;
    }
    return filteredComapany.every((ele: Invite) => {
      return (
        selectedCompany.findIndex(
          (company: Invite) => company.inviteId === ele.inviteId,
        ) > -1
      );
    });
  }, [filteredComapany, selectedCompany]);

  const handleCheckboxSelection = (checked: boolean, row: any) => {
    let selectedCompanyShallowCopy: Array<any> = [...selectedCompany];
    if (checked) {
      const filterCompany = filteredComapany.find(
        (ele: any) => ele.companyId === row.companyId,
      );
      if (filterCompany) selectedCompanyShallowCopy.push(filterCompany);
    } else {
      selectedCompanyShallowCopy = selectedCompany.filter(
        (ele: any) => ele.companyId !== row.companyId,
      );
    }
    setSelectedCompany(selectedCompanyShallowCopy);
  };

  const WINDOW_H_PADDING = 80; // top 40 bottom 40

  const modalMaxHeight = useMemo(() => {
    if (windowSize.height === 0) {
      return 640;
    }
    return windowSize.height - WINDOW_H_PADDING;
  }, [windowSize.height]);

  const handleAllSelected = (selected: boolean) => {
    if (selected) {
      setSelectedCompany(
        _.uniqBy([...selectedCompany, ...filteredComapany], 'companyId'),
      );
    } else {
      setSelectedCompany(
        _.differenceBy(selectedCompany, filteredComapany, 'companyId'),
      );
    }
  };

  const invitedDesc = isInviteCompanies
    ? 'received_invite_desc'
    : 'dont_have_company_invite';

  const headersubTitle = isIgnore ? 'delete_selected_company' : invitedDesc;

  const renderHeader = () => {
    return (
      <>
        <div className='flex items-center justify-center sticky top-0 h-16 bg-white'>
          {isIgnore ? (
            <Icon
              key='previcon'
              src={ChevronLeft}
              className='absolute left-2 cursor-pointer'
              onClick={() => setIsIgnore(false)}
            />
          ) : (
            <Icon
              key='closicon'
              src={ExitDeleteRemoveClose}
              className='absolute left-2 cursor-pointer'
              onClick={handleClose}
            />
          )}
          <Label
            text={t('company_invites')}
            type={LabelType.H5}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex items-center top-0'>
          <Label
            text={t(headersubTitle, {
              companyNumber: selectedCompany?.length,
            })}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      </>
    );
  };

  const renderBody = () => {
    return (
      isInviteCompanies &&
      !isIgnore && (
        <div className='mt-[16px]'>
          <Grid
            pageIndex={currentPage}
            loadPage={(page: number) => setCurrentPage(page)}
            columns={[
              {
                key: 'companyName',
                title: (
                  <CheckBox
                    label={t('company')}
                    onChange={handleAllSelected}
                    selected={isAllCurrentPageSelected}
                  />
                ),
                component: (row: any) =>
                  loading ? (
                    <Skeleton height='h-5' />
                  ) : (
                    <CheckBox
                      label={row.companyName}
                      onChange={(checked: boolean) =>
                        handleCheckboxSelection(checked, row)
                      }
                      selected={
                        selectedCompany.findIndex(
                          (ele: any) => ele.companyId === row.companyId,
                        ) > -1
                      }
                    />
                  ),
              },
              {
                key: 'dateRequested',
                title: t('date_invited'),
                component: (row: any) =>
                  loading ? (
                    <Skeleton height='h-5' />
                  ) : (
                    <Label
                      text={row.dateRequested}
                      type={LabelType.BODY3}
                      color={ColorType.BLACK}
                    />
                  ),
              },
            ]}
            totalPage={Math.ceil(
              (invitesList?.length || NUMBER.ZERO) / ROW_PER_PAGE,
            )}
            data={filteredComapany}
            primaryKey='name'
            isLoading={loading}
            showPageSearch={false}
          />
        </div>
      )
    );
  };
  const renderFooter = () => {
    return (
      <div className='pt-6 pb-5 bottom-0 sticky bg-white'>
        <div className='h-12 w-auto flex'>
          {isInviteCompanies ? (
            <>
              <Button
                className='mr-1.5'
                label={t('ignore_selected')}
                type={ButtonType.TERTIARY}
                disabled={selectedCompany?.length === 0}
                size={ButtonSize.MEDIUM_FULL}
                onClick={() => setIsIgnore(true)}
              />
              <Form
                className='w-full'
                onSubmit={handleUpdateInvites}
                queryResult={updateQueryResult}
              >
                <Button
                  className='ml-1.5'
                  label={t('accept_selected')}
                  type={ButtonType.PRIMARY}
                  disabled={selectedCompany?.length === 0}
                  size={ButtonSize.MEDIUM_FULL}
                  isSumbit
                />
              </Form>
            </>
          ) : (
            <Button
              label={t('ok')}
              type={ButtonType.PRIMARY}
              size={ButtonSize.MEDIUM_FULL}
              onClick={handleClose}
            />
          )}
        </div>
      </div>
    );
  };

  const renderIgnoreFooter = () => {
    return (
      <div className='pt-6 pb-5 bottom-0 sticky bg-white'>
        <div className='h-12 w-auto flex'>
          <Button
            className='mr-1.5'
            label={t('back')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.MEDIUM_FULL}
            onClick={() => setIsIgnore(false)}
            disabled={selectedCompany?.length < 1}
          />
          <Form
            className='w-full'
            onSubmit={handleDeleteInvites}
            queryResult={deleteQueryResult}
          >
            <Button
              className='ml-1.5'
              label={t('ignore')}
              type={ButtonType.PRIMARY}
              size={ButtonSize.MEDIUM_FULL}
              isSumbit
              disabled={selectedCompany?.length < 1}
            />
          </Form>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        content: {
          overflow,
          top: '50%',
          left: '50%',
          width,
          height,
          maxHeight: modalMaxHeight,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <div className='flex flex-col px-4'>
        {renderHeader()}
        {renderBody()}
        {isIgnore ? renderIgnoreFooter() : renderFooter()}
      </div>
    </Modal>
  );
};

export default AcceptInviteModal;
