export const convertToThousandSeperator = (
  input: number,
  maximumFractionDigits?: number,
  minimumFractionDigits: number = 0,
) => {
  if (Number.isNaN(input)) {
    return '';
  }
  const numberFormat = new Intl.NumberFormat('es-US', {
    maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits || maximumFractionDigits,
  });

  return numberFormat.format(input);
};

export const displayEneryKwh = (value = 0, unit = 'kWh', digit = 1) => {
  return `${convertToThousandSeperator(value, digit)} ${unit}`;
};
