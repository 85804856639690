/* eslint-disable prefer-destructuring */
import { API_KEYS, Api } from './base.api';
import { downloadBlob } from './utils';

export const ocppMessagesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchOcppMessages: builder.query<any, any>({
      query: (params) => ({
        url: `/internal/core/v2/chargers/${params.chargerId}/${API_KEYS.MESSAGE}`,
        params,
        method: 'GET',
      }),
    }),
    exportOcppMessages: builder.query<any, any>({
      query: (params) => ({
        url: `/internal/core/v2/chargers/${params.chargerId}/${API_KEYS.MESSAGE}`,
        params,
        method: 'GET',
        isCSV: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 &&
                downloadBlob(blob, `${params.chargerName} messages`, 'csv'),
            ),
      }),
    }),
  }),
});

export const { useFetchOcppMessagesQuery, useLazyExportOcppMessagesQuery } =
  ocppMessagesApi;
