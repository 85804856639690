import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { More } from '../../../assets/icons';
import { URL_SEARCH_PARAM_KEY } from '../../../constant/UrlSearchParams.constant';
import { payterContext } from '../../../hooks/usePayter';
import { useLazyFetchStatisticsQuery } from '../../../services/history.api';
import { useUpdatePaymentTerminalInfoVisiblityMutation } from '../../../services/payouts.api';
import { Statistics } from '../../../stores/types';
import { convertToLocaleCurrency } from '../../../utils/Currency.Util';
import {
  convertDateToString,
  convertMilliSecondToDate,
  formatDate,
} from '../../../utils/Date.Util';
import {
  Card,
  ColorType,
  DateTimePicker,
  Label,
  LabelType,
  Menu,
  MenuItem,
} from '../../_ui';
import ReportItem from './ReportItem.component';

const PayoutReport = () => {
  // This state is used for the purpose of hide payter revenue in the 080 relase
  const [showPayterRevenueCards] = useState(false);

  const today = useMemo(() => new Date(), []);

  const { t } = useTranslation();

  const payterConsumer = useContext(payterContext);
  const { showTerminal, isPayterConnected, company } = payterConsumer;

  const [summaryDate, setSummaryDate] = useQueryParam(
    URL_SEARCH_PARAM_KEY.OVERVIEW_SUMMARY_DATE,
    StringParam,
  );

  const date = useMemo(() => {
    return summaryDate ? convertMilliSecondToDate(summaryDate) : today;
  }, [summaryDate, today]);

  const dateChanged = (newDate: any) => {
    setSummaryDate(convertDateToString(newDate));
  };

  const [triggerFetchStatistics, { stat, isStatsLoading }] =
    useLazyFetchStatisticsQuery({
      pollingInterval: 60000,
      selectFromResult: (endPoint) => ({
        stat: endPoint.data ? (endPoint.data[0] as Statistics) : undefined,
        isStatsLoading: endPoint.isFetching,
      }),
    });

  const [updateShowTerminalInfoFlag] =
    useUpdatePaymentTerminalInfoVisiblityMutation();

  useEffect(() => {
    const params = {
      fromDate: formatDate(date, 'yyyy-MM'),
      toDate: formatDate(date, 'yyyy-MM'),
      currency: 'USD',
    };
    triggerFetchStatistics({ ...params });
  }, [date, triggerFetchStatistics]);

  const handleFeesCollectedDisplay = () => {
    return stat
      ? convertToLocaleCurrency(stat.revenue, stat.revenueCurrency, 2)
      : convertToLocaleCurrency(0, 'CAD', 2);
  };

  const onClickTerminalState = () => {
    updateShowTerminalInfoFlag({
      body: {
        ...company,
        showPaymentTerminalInfo: !showTerminal,
      },
    });
  };

  return (
    <Card className='flex flex-col mb-4' title=''>
      <div className='flex items-center justify-between'>
        <DateTimePicker
          showMonthYearPicker
          onChange={dateChanged}
          defaultDate={date}
          maxDate={today}
        />
        <Menu
          placement='bottom-end'
          icon={More}
          dataTestId='select-terminal-view-button'
        >
          <MenuItem
            label={`${
              showTerminal
                ? t('hide_payment_terminal')
                : t('show_payment_terminal')
            } `}
            onClick={onClickTerminalState}
            dataTestId='terminal-view-toggle-button'
          />
        </Menu>
      </div>
      {/* showPayterRevenueCards is permanently false in the current release */}
      {showTerminal && isPayterConnected && showPayterRevenueCards ? (
        <div className='flex items-center justify-between mt-4 gap-4'>
          {/* Static values are added here as it will be coming from api in future release. */}
          <ReportItem
            name={t('payter_total_revenue')}
            collection='$5,493.21'
            currency='USD'
            backGroundColor='bg-positive0'
          />
          <ReportItem
            name={t('payer_collected_directly')}
            collection='$5,493.21'
            currency='USD'
          />
          <ReportItem
            name={t('collected_via_payter')}
            collection='$5,493.21'
            currency='USD'
          />
        </div>
      ) : (
        <div className='mt-4'>
          <div className='mb-2'>
            <Label
              // This {text} will be recovered in the future release
              // text={
              //   isPayterConnected
              //     ? t('total_terminals_revenue')
              //     : t('payter_total_revenue')
              // }
              text={t('revenue_collected_directly')}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY5}
              className='inline'
              dataTestId='revenue-collected-directly'
            />
          </div>
          <Label
            text={handleFeesCollectedDisplay()}
            type={LabelType.H2}
            color={ColorType.BLACK}
            className='inline'
            isLoading={isStatsLoading}
            skeletonHeight='h-10'
            skeletonWidth='w-40'
            dataTestId='fees-collected'
          />
          {!isStatsLoading && (
            <Label
              text={stat?.revenueCurrency}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.GREY5}
              className='inline ml-4'
            />
          )}
          {!showTerminal && isPayterConnected && (
            <div className='mt-2'>
              <Label
                text={t('show_payment_info')}
                type={LabelType.BODY2}
                color={ColorType.BRAND2}
                className='inline'
                onClick={onClickTerminalState}
              />
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default PayoutReport;
