import { useTranslation } from 'react-i18next';
import { Card, ColorType, Icon, Label, LabelType } from '../_ui';
import { Location } from '../../assets/icons';

interface IProps {
  isWithPower?: boolean;
  isWithLocation?: boolean;
}

export const NoPowerManaged = ({
  isWithPower = false,
  isWithLocation = false,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className='flex flex-col items-center py-10'>
        <Icon src={Location} className='mb-4' />
        <Label
          text={
            isWithLocation
              ? isWithPower
                ? t('no_pm_locations')
                : t('no_locations_wo_pm')
              : t('no_locations_pm_message_1')
          }
          type={LabelType.H4}
          color={ColorType.BLACK}
          className='pb-2'
        />
        <Label
          text={
            isWithLocation
              ? isWithPower
                ? t('contact_support_pm')
                : t('all_locations_pm')
              : t('no_locations_pm_message_2')
          }
          type={LabelType.BODY2}
          color={ColorType.GREY5}
        />
      </div>
    </Card>
  );
};
