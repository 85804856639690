import React from 'react';
import Modal from 'react-modal';
import { useGlobalModalContext } from './GlobalModal.component';
import { Button, ButtonType, Label, LabelType, Icon, Spinner } from '..';
import { ButtonSize } from '../Button.component';
import { useTimeout } from '../../../hooks';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const SpinnerModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { delay = '5000', onClose } = modalProps || {};

  useTimeout(() => {
    hideModal();
    onClose && onClose();
  }, delay);

  return (
    <Modal
      isOpen
      style={{
        content: {
          top: '50%',
          left: '50%',
          width: '48px',
          height: '48px',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          margin: 0,
          borderRadius: 10,
          borderWidth: 0,
          overflow: 'hidden',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      <div className='flex flex-col justify justify-between w-full h-full'>
        <Spinner width='w-12' height='h-12' />
      </div>
    </Modal>
  );
};
