import { NUMBER } from '../../../constant/Number.constant';
import { Company } from '../../../stores/types';

export const countSelectedCompany = (companies: Array<Company>) => {
  return companies.length;
};

export const getCompanyIdsFromCompanies = (companies: Array<Company>) => {
  const companyIds = companies.map((company) => company.id);
  return [...companyIds];
};

export const sortComapniesById = (companies: Array<Company>, id: string) => {
  return [...companies].sort((item) =>
    item.id === id ? -NUMBER.ONE : NUMBER.ONE,
  );
};

export const getCompanyById = (companies: Array<Company>, id: string) => {
  const findCompany = companies.filter((company: Company) => company.id === id);
  return findCompany;
};

export const startPageValue = (currentPage: number, ROW_PER_PAGE: number) => {
  return (currentPage - NUMBER.ONE) * ROW_PER_PAGE + NUMBER.ONE;
};
