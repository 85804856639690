// React
import { useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAuth } from '../../hooks';

// Components
import { Card } from '../_ui';
import { LocationInfo } from './LocationInfo.component';
import { TeamInfo } from './TeamInfo.component';

// API
import { useCompany } from '../../hooks/useCompany';
import { useFetchCompanyDetailQuery } from '../../services/company.api';
import { useFetchLocationsQuery } from '../../services/location.api';
import {
  useLazyFetchInvitesQuery,
  useLazyFetchUserGroupsQuery,
} from '../../services/usergroups.api';
import { Company as CompanyObj, User } from '../../stores/types';
import { CompanyInfo } from './CompanyInfo.component';

const PENDING_INVITE_STATUS = 'pending';
export const Company = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const [companyId] = useQueryParam('companyId', StringParam);
  const { userScope } = useCompany();

  const { company, isCompanyLoading } = useFetchCompanyDetailQuery(
    { id: companyId!, scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        company: endPoint.data ? endPoint.data : ({} as CompanyObj),
        isCompanyLoading: endPoint.isFetching,
      }),
    },
  );

  const [triggerFetchInvites, { invites, isInviteLoading }] =
    useLazyFetchInvitesQuery({
      selectFromResult: (endPoint) => ({
        invites: endPoint.data
          ? endPoint.data.filter((invite) => {
              return invite.status.toLowerCase() === PENDING_INVITE_STATUS;
            })
          : [],
        isInviteLoading: endPoint.isFetching,
      }),
    });

  const { locations, isLocationLoading } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => {
        if (!endPoint.data) {
          return { locations: [] };
        }
        const res = [...endPoint.data.entities];
        res.sort((a, b) => {
          const nameA: any = a.name || '';
          const nameB = b.name || '';
          return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
        });
        const locationLoading = endPoint.isFetching;
        return { locations: res, isLocationLoading: locationLoading };
      },
    },
  );

  const [triggerFetchUserGroups, { users, isUserLoading }] =
    useLazyFetchUserGroupsQuery({
      selectFromResult: (endPoint) => ({
        users: endPoint.data ? endPoint.data : ([] as User[]),
        isUserLoading: endPoint.isFetching,
      }),
    });

  useEffect(() => {
    if (company?.adminUserGroupId) {
      triggerFetchUserGroups(company.adminUserGroupId);
      triggerFetchInvites(company.adminUserGroupId);
    }
  }, [company]);

  return (
    <div>
      <Card>
        <CompanyInfo
          company={company}
          isCompanyLoading={isCompanyLoading}
          isUserPresent={users?.length > 0 || false}
        />
        <div className='mt-[24px]'>
          <TeamInfo
            users={users}
            invites={invites}
            company={company}
            triggerFetchInvites={triggerFetchInvites}
            isUserLoading={isUserLoading}
            isInviteLoading={isInviteLoading}
          />
        </div>
        <div className='mt-[24px]'>
          <LocationInfo
            locations={locations}
            company={company}
            isLocationLoading={isLocationLoading}
          />
        </div>
      </Card>
    </div>
  );
};
